import axios from 'axios'

import { getToken } from '../../../providers/use-sign'

const api = axios.create({
    //baseURL: 'http://localhost:3333',
    baseURL: 'https://api.leiloae.com.br',
})

api.interceptors.request.use(async config => {

    const token = await getToken()

    if (token) 
        config.headers.Authorization = `Bearer ${token}`
    
    return config
})

api.interceptors.response.use((response) => {
    
    return response

}, (error) => {
    if(error?.response?.data?.data?.message)
        return Promise.reject(error.response.data.data?.message)
    return Promise.reject(error)
})

export {api}