/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

import { Form, Button } from 'react-bootstrap';

const ForgotPasswordForm: React.FC = () => {
  return (
    <>
        <Form>
            <Form.Group controlId="des_email">
                <Form.Control type="text" placeholder="Informe seu e-email" />
            </Form.Group>
            <Button className="col-12 btn btn-create" type="submit">
                Enviar
            </Button>
        </Form>
    </>
  );
}
export default ForgotPasswordForm;
