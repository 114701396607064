import React from 'react';
import * as S from './styles'

import imagemPadrao from "../../assets/image/Patrocinio_comprar.jpg"

export type AdCardProps = {
    link?: string
    src?: string
    alt?: string
}

const AdCard = ({ link, src, alt = "Propaganda dos patrocinadores." }: AdCardProps) => (
    <S.Wrapper>
        <a href={link} rel="noopener noreferrer" target="_blank">
            <img src={!!src ? src : imagemPadrao} alt={alt} />
        </a>
    </S.Wrapper>
)

export default AdCard;