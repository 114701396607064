/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';

import Header from '../../../components/HeaderAdmin'
import Sidebar from '../SideNav'

import 'rc-pagination/assets/index.css'
import './styles.css'
import { Button, Col, Container, Figure, Form, Row} from 'react-bootstrap';
import { checkDevice } from '../../../helpers/utils';

import imgNoProfilePhoto from '../../../assets/image/noprofilephoto.png'

import { toast } from 'react-toastify';
import { api } from '../../../services/apiLeloae/config';
import axios from 'axios';
import { FormatCpf, FormatDateHoursPtBr, FormatPhone, FormatPrice } from '../../../helpers/format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faIdCard, faPhone, faUserCircle, faMoneyBill, faIdCardAlt, faCalendar } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';

const AuctionRequest: React.FC = (props: any) => {
    const {id_product, id_bid_lots_auction} = props.match.params
    const history = useHistory()
    const [expandedResume, setExpandedResume] = useState({ expanded: null })
    const [bid, setBid] = useState<any>()
    const [user, setUser] = useState({
        des_person: '',
        des_lest_person: '',
        nr_cpf: '',
        nr_phone: '',
        des_email: '',
        dt_birth: '',
        nr_receive_ads_email: 0,
        nr_receive_ads_whatsapp: 0,
        des_type_user: '',
        open_id_network_social: '',
        created_at: ''
    })
    const [imgUser, setImgUser] = useState({
        des_profile_photo_url: '',
        id_profile_photo: 0,
        des_profile_photo: ''
    })
    const [buyProduct, setBuyProduct] = useState<any>({
        id_product: id_product,
        id_person: 0,
        nr_type_payment: 1,
        nr_terms: 1,
        auction: 1,
        vl_bid: 0,
        nr_status: 0
    })

    let mounted = true;
    const signal = axios.CancelToken.source();

    const getBid = async (page = 1) => {
        try {
            const responseBid: any = await api.get(`/bid/auction/show/${id_bid_lots_auction}`, {cancelToken: signal.token})
            setBid(responseBid.data.message)
        } catch (error) {
          toast.error('Error e buscar o lance', {toastId: 1})
        }
    }

    const getBidUser = async (page = 1) => {
        const responseUser: any = await api.get(`/admin/user/${bid?.id_person}`, {cancelToken: signal.token})

        try {
            setUser({
                des_person: responseUser?.data?.message.Person.des_person,
                des_lest_person: responseUser?.data?.message.Person.des_lest_person,
                nr_cpf: responseUser?.data?.message.Person.nr_cpf,
                nr_phone: responseUser?.data?.message.nr_phone,
                des_email: responseUser?.data?.message.des_email,
                dt_birth: responseUser?.data?.message.Person.dt_birth,
                nr_receive_ads_email: responseUser.data?.message.ReceiveAd?.nr_receive_ads_email,
                nr_receive_ads_whatsapp: responseUser.data?.message.ReceiveAd?.nr_receive_ads_whatsapp,
                des_type_user: responseUser.data?.message.des_type_user,
                open_id_network_social: responseUser.data?.message.open_id_network_social,
                created_at: responseUser.data?.message.createdAt
                })
            setImgUser({
                des_profile_photo_url: responseUser.data?.message.Person.ProfilePhoto?.des_profile_photo_url,
                id_profile_photo: responseUser.data?.message.Person.ProfilePhoto?.id_profile_photo,
                des_profile_photo: responseUser.data?.message.Person.ProfilePhoto?.des_profile_photo
            })
        } catch (error) {
          toast.error('Ocorreu um erro em buscar seus dados', {toastId: 1})
        }
    }

    const handleOptionPayment = (event: any) => {
        const value = event.target.value
        setBuyProduct(field => {
            return {
                ...field, nr_type_payment: value
            }
        })
    }

    const handleRequestBid = async (event: any) => {
        event.preventDefault()
        
        try {
            const responseSendProposal: any = await api.post('/buy/product', buyProduct)
            if(responseSendProposal.data?.message){
                toast.success('Compra efetuada com sucesso', {toastId: 1})
                history.push(`/admin/requests`)
            }
        } catch (error) {
            console.log(error)
          toast.error('Ocorreu um erro ao finalizar o pedido', {toastId: 1})
        }
    }

    useEffect(() => {
        setExpandedResume({expanded: checkDevice()})
    },[])
    

    useEffect(() => {
        if(bid?.id_person) {
            getBidUser()
            setBuyProduct(field => {
                return {
                    ...field, id_person: bid?.id_person, vl_bid: bid?.vl_bid
                }
            })
        }
    }, [bid])

    useEffect(() => {
        getBid()
        const cleanup = () => { mounted = false; };
        return cleanup;
    }, [mounted])
  return (
    <>
      <Container fluid id="bid-request">
          <Row>  
              <Sidebar />
              <Header />
                <div className={expandedResume.expanded ? 'resume' : 'resume-mobile'}>
                    <Container className="col-12">
                        <Col sm={12} className="shadow mb-3 bg-white colunm request text-center">
                            <Figure>
                                <Figure.Image
                                    roundedCircle
                                    src={imgUser.des_profile_photo_url ? imgUser.des_profile_photo_url : imgNoProfilePhoto}
                                />
                            </Figure>
                            <Row>
                                <div className="col-sm-12 col-md-12 col-lg-6">
                                    <div id="user" className="text-justify">
                                        <p><FontAwesomeIcon icon={faUserCircle} size="1x" /> {user?.des_person +' '+ user?.des_lest_person} </p>
                                        <hr/>
                                        <p><FontAwesomeIcon icon={faIdCard} size="1x" /> {FormatCpf(user?.nr_cpf)}</p>
                                        <hr/>
                                        <p><FontAwesomeIcon icon={faEnvelope} size="1x" /> {user?.des_email}</p>
                                        <hr/>
                                        <p><FontAwesomeIcon icon={faPhone} size="1x" /> {FormatPhone(user?.nr_phone)}</p>
                                        <hr/>
                                    </div>    
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-6">
                                    <div id="bid" className="text-justify">
                                        <p><FontAwesomeIcon icon={faIdCardAlt} size="1x" /> ID lote: <b>{bid?.id_product}</b> </p>
                                        <hr/>
                                        <p><FontAwesomeIcon icon={faMoneyBill} size="1x" /> Valor do lance: <b>{FormatPrice(bid?.vl_bid)}</b></p>
                                        <hr/>
                                        <p><FontAwesomeIcon icon={faCalendar} size="1x" /> Data do lance: <b>{FormatDateHoursPtBr(bid?.createdAt)}</b></p>
                                        <hr/>
                                    </div>    
                                </div>
                                <Form onSubmit={handleRequestBid}>
                                    <Form.Group controlId="id_uf">
                                        <Form.Control as="select" value={buyProduct?.nr_type_payment} name="id_uf" onChange={handleOptionPayment}>
                                            <option value="1">Transferência bancária</option>
                                            <option value="2">Boleto bancária</option>
                                        </Form.Control>
                                    </Form.Group>
                                    <Button type="submit" className="btn btn-create">Finalizar pedido</Button>
                                </Form>
                            </Row>
                        </Col>
                    </Container>
                </div>
          </Row>

      </Container>
    </>
  );
}
export default AuctionRequest;
