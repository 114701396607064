import React from 'react';
import { Nav } from 'react-bootstrap';


const CreateUserButton: React.FC = () => {
  return (
    <>
    <div className="col-12 col-lg-3 row justify-content-center">
      <Nav>
        <Nav.Link className="btn btn-create" href="/register/user">Criar conta</Nav.Link>
      </Nav>
    </div>
    </>
  );
}
export default CreateUserButton;
