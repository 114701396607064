import styled, { css } from "styled-components"

import { CarouselProps } from "."

type CarouselstyleProps = Pick<CarouselProps, "width" & "variant">

export const Carousel = styled.section<CarouselstyleProps>`
  ${({ width }) => css`
    max-width: ${width};
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 16px 0;
    position: relative;
  `}

  ${({ variant }) => variant === "product" ? css`
    > div {
    padding: 10px 12px;
    }
  ` : css`
    img {
      border-radius: 15px;
    }
  `}
`

const ArrowBase = css`
  position: absolute;
  top: 50%;

  width: 27px;
  height: 44px;

  transform: translateY(-50%);

  z-index: 10;
  cursor: pointer;

  background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat;

  transition: all 0.125s ease;

  &:hover {
    width: 32px;
    height: 49px;
    background-size: 32px 49px;
    transition: all 0.125s ease;
  }
`

export const RightArrow = styled.div<CarouselstyleProps>`
  ${ArrowBase};
  right: -5%;

  &:hover {
    right: -6%;
  }

  ${({ variant }) =>
    variant === "product" &&
    css`
      right: -3.5%;
      &:hover {
        right: -4.5%;
      }
  `}

    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23fd880d'%2F%3E%3C%2Fsvg%3E");

    @media screen and (min-width: 1680px) {
      ${({ animalList }) =>
    !!animalList ?
      css`
          background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
        ` : css`
          background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23fd880d'%2F%3E%3C%2Fsvg%3E");
      `}
    }
`

export const LeftArrow = styled.div`
  ${ArrowBase}
  left: -5%;
  
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2028%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23fd880d'%2F%3E%3C%2Fsvg%3E");
  
  @media screen and (min-width: 1680px) {
    ${({ animalList }) =>
    !!animalList ?
      css`
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2028%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
  ` : css`
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2028%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23fd880d'%2F%3E%3C%2Fsvg%3E");
  `}
  }

  &:hover {
    left: -6%;
  }
`
