/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react"

import { useDispatch, useSelector } from "react-redux"
import {
  actionProductUserRequestNegotiation,
  getProductStorage,
} from "../../../store/reduces/ReducerProductUserRequestNegotiation"
import { RootState } from "../../../store"
import {
  FormatDatePtBr,
  FormatPrice,
  onlyNumbers,
} from "../../../helpers/format"

import { Container } from "react-bootstrap"
import { Link, Redirect } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"

import Navbar from "../../../components/Navbar"
import Footer from "../../../components/Footer"
import Loading from "../../../components/Loading"

import "./styles.css"

const UserRequestNegotiationDetail: React.FC = (propos: any) => {
  const { reducerProductUserRequest } = useSelector((state: RootState) => state)
  const dispatch = useDispatch()
  const id_product = propos.match.params.id_product
  const [product, setProduct] = useState<any>(null)

  useEffect(() => {
    dispatch(actionProductUserRequestNegotiation.SelectProduct(id_product))
    setProduct(getProductStorage())
  }, [])

  if (!id_product) {
    return <Redirect to="/user/requests/negotiation"></Redirect>
  }

  return (
    <>
      {reducerProductUserRequest.httprequest.loading && <Loading />}
      <Navbar />
      <Container>
        <div id="request-detail-user-negotiation">
          <p>
            <Link to="/user">Gerenciar minha conta</Link>{" "}
            <FontAwesomeIcon icon={faAngleRight} size="1x" />{" "}
            <Link to="/user/requests/negotiation">
              Solicitação de negociação
            </Link>{" "}
            <FontAwesomeIcon icon={faAngleRight} size="1x" /> Detalhes do pedido
          </p>
          <h3 className="text-center title-custom-h3">Detalhes do pedido</h3>
          <div id="detail_status" className="shadow mb-3 bg-white">
            <div className="product-info row">
              <div className="col-12 col-md-3">
                <p id="text-status-1">
                  <b>Categoria</b>
                </p>
                <p id="text-status-2">
                  {product?.Product?.Animal.AnimalCategory.des_category}
                </p>
              </div>
              <div className="col-12 col-md-3">
                <p id="text-status-1">
                  <b>Número do pedido</b>
                </p>
                <p id="text-status-2">
                  {product?.id_product_negotiation_request}
                </p>
              </div>
              <div className="col-12 col-md-3">
                <p id="text-status-1">
                  <b>Valor total</b>
                </p>
                <p id="text-status-2">
                  {FormatPrice(product?.Product?.vl_price_total)}
                </p>
              </div>
              <div className="col-12 col-md-3">
                {product?.nr_status == 1 ? (
                  <p id="text-status-2" className="btn-info-status">
                    Negocio fechado
                  </p>
                ) : (
                  <p id="text-status-2" className="btn-info-status">
                    Aguardando confirmação
                  </p>
                )}
              </div>
            </div>
          </div>

          <div>
            <h3 className="text-center title-custom-h3">Ficha técnica</h3>
            <div className="col-12 data-sheet shadow mb-3 bg-white">
              <Link to="/">
                <b>Baixar documentos em anexo</b>
              </Link>
              <div className="shadow-sm  bg-light rounded colum-light-gray row">
                <b className="col-md-3 col-6">Tipo de produto:</b>{" "}
                <p>
                  {
                    product?.Product.Animal.TypeAnimalProduct
                      .des_type_product_animal
                  }
                </p>
              </div>
              <div className="shadow-sm  bg-white rounded colum-white row">
                <b className="col-md-3 col-6">Raça:</b>{" "}
                <p>{product?.Product.Animal.AnimalRace.des_races}</p>
                <hr />
              </div>
              <div className="shadow-sm  bg-light rounded colum-light-gray row">
                <b className="col-md-3 col-6">Categoria:</b>{" "}
                <p>{product?.Product.Animal.AnimalCategory.des_category}</p>
              </div>
              <div className="shadow-sm  bg-light rounded colum-light-gray row">
                <b className="col-md-3 col-6">Peso:</b>{" "}
                <p>{product?.Product?.nr_weight}</p>
              </div>
              <div className="shadow-sm  bg-white rounded colum-white row">
                <b className="col-md-3 col-6">Quantidade:</b>{" "}
                <p>{product?.Product.qtd_animals} Animais</p>
                <hr />
              </div>
              <div className="shadow-sm  bg-light rounded colum-light-gray row">
                <b className="col-md-3 col-6">Intervalo de peso:</b>{" "}
                <p>
                  {
                    product?.Product.Animal.AnimalAverageWeight
                      .des_average_weight
                  }
                </p>
              </div>
              <div className="shadow-sm  bg-light rounded colum-light-gray row">
                <b className="col-md-3 col-6">Sexo:</b>{" "}
                <p>{product?.Product?.Animal.AnimalSex.des_animal_sex}</p>
              </div>
              {Boolean(product?.Product?.Animal?.nr_pregnancy) && (
                <div className="shadow-sm  bg-light rounded colum-light-gray row">
                  <b className="col-md-3 col-6">Prenhez:</b>{" "}
                  <p>
                    {product?.Product?.Animal?.nr_pregnancy === 1
                      ? "Sim"
                      : "Não"}
                  </p>
                </div>
              )}
              {Boolean(product?.Product?.Animal?.nr_pregnancy_days) && (
                <div className="shadow-sm  bg-light rounded colum-light-gray row">
                  <b className="col-md-3 col-6">Gestação:</b>{" "}
                  <p>{product?.Product?.Animal?.nr_pregnancy_days + " Dias"}</p>
                </div>
              )}
              {Boolean(product?.Product?.Animal?.nr_liters_day) && (
                <div className="shadow-sm  bg-light rounded colum-light-gray row">
                  <b className="col-md-3 col-6">Litros produzidos/Dia:</b>{" "}
                  <p>{product?.Product?.Animal?.nr_liters_day}</p>
                </div>
              )}
              {Boolean(product?.Product?.Animal?.nr_lactations) && (
                <div className="shadow-sm  bg-light rounded colum-light-gray row">
                  <b className="col-md-3 col-6">Nº Lactações:</b>{" "}
                  <p>{product?.Product?.Animal?.nr_lactations}</p>
                </div>
              )}
              {Boolean(product?.Product?.Animal?.nr_age_months) && (
                <div className="shadow-sm  bg-light rounded colum-light-gray row">
                  <b className="col-md-3 col-6">Idade:</b>{" "}
                  <p>{product?.Product?.Animal?.nr_age_months + " Meses"}</p>
                </div>
              )}
              <div className="shadow-sm  bg-light rounded colum-light-gray row">
                <b className="col-md-3 col-6">Possui embarcador:</b>{" "}
                <p>
                  {product?.Product.nr_shipper_access === 1 ? "Sim" : "Não"}
                </p>
              </div>
              <div className="shadow-sm  bg-white rounded colum-white row">
                <b className="col-md-3 col-6">Possui acesso a caminhões:</b>{" "}
                <p>{product?.Product.nr_truck_access === 1 ? "Sim" : "Não"}</p>
                <hr />
              </div>
              <div className="shadow-sm  bg-light rounded colum-light-gray row">
                <b className="col-md-3 col-6">Possui balança:</b>{" "}
                <p>
                  {product?.Product.nr_balance_access === 1 ? "Sim" : "Não"}
                </p>
              </div>
              <div className="shadow-sm  bg-light rounded colum-light-gray row">
                <b className="col-md-3 col-6">Cidade - UF:</b>{" "}
                <p>
                  {product?.Product?.AddressProduct?.City?.des_city +
                    " - " +
                    product?.Product?.AddressProduct?.State?.uf}
                </p>
              </div>
              <div className="shadow-sm  bg-light rounded colum-light-gray row">
                <b className="col-md-3 col-6">Quilometros da cidade:</b>{" "}
                <p>{product?.Product?.AddressProduct?.nr_kilometers + " KM"}</p>
              </div>
              <div className="shadow-sm  bg-white rounded colum-white row">
                <b className="col-md-3 col-6">Data da publicação:</b>{" "}
                <p>{FormatDatePtBr(product?.Product.createdAt)}</p>
                <hr />
              </div>
            </div>
          </div>
          <div id="media-product">
            <h3 className="text-center title-custom-h3">Fotos e vídeos</h3>
            <div className="photo-video shadow mb-3 bg-white row">
              {product?.Product.ProductPhotos.map((photo: any) => (
                <div key={photo.des_product_photo} className="photo-view">
                  <img
                    src={photo.des_product_photo_url}
                    width="100%"
                    height="100%"
                    alt="img"
                  />
                </div>
              ))}
              {product?.Product.ProductVideos.map((video: any) => (
                <iframe
                  key={video.des_product_video}
                  title="video-product"
                  src={`https://player.vimeo.com/video/${onlyNumbers(
                    video.des_product_video
                  )}`}
                  width={150}
                  frameBorder="0"
                ></iframe>
              ))}
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  )
}
export default UserRequestNegotiationDetail
