/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';

import { Form, Accordion, Card, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { IAddress } from '../../../providers/use-create';

import { api } from '../../../services/apiLeloae/config';
import { useParamsProductContext } from '../../../providers/product-params';

const StateCity: React.FC = () => {
    const { setParamsProduct } = useParamsProductContext()

    const [attrRacesBox, setAttrRacesBox] = useState({
        location: '+',
    })
    const [dataAddress, setDataAddress] = useState<IAddress>({
        id_uf: 0,
        code_city: 0
    })
    const [listStateData, setListStateData] = useState<any>([])
    const [listCityData, setListCityData] = useState<any>([])

    const listState = async () => {
        try {
            const responseListState: any = await api.get('/address/list/state')
            setListStateData(responseListState?.data?.message)
        } catch (error) {
            toast.error('Ocorreu erro em buscar listar os estados', {toastId: 1})    
        }
    }

    const listCity = async () => {
        try {
            const responseListCity: any = await api.get(`/address/list/search/city/${dataAddress.id_uf}`)
            setListCityData(responseListCity?.data?.message)
        } catch (error) {
        toast.error('Ocorreu erro em buscar listar cidades', {toastId: 1})    
        }
    }
    
    const menuLeftBox = (event) => {
        const nameBox = event.target.dataset.id
        setAttrRacesBox(field => {
            return {
              ...field, [`${nameBox}`]:
              nameBox === 'location' ? attrRacesBox.location === '+' ? '-' : '+': ''
            }
          })
    }

    const handleChangeListFilter = async (event) => {
        const name = event.target.name
        const value = event.target.value

        setDataAddress(field => {
            return {
              ...field, [`${name}`]: value
            }
          })

        await setParamsProduct({type: name, value: value})
    }

    useEffect(() => {
        listState()
    }, [])


    useEffect(() => {   
        if(dataAddress.id_uf){
            listCity()
        }
    }, [dataAddress.id_uf])
  return (
    <>
        <Accordion defaultActiveKey="0">
            <Card className="shadow mb-3 bg-white">
                <Card.Header>
                    <Accordion.Toggle as={Button} data-id="location" onClick={menuLeftBox} className="text-left" variant="link" eventKey="1">
                        <b data-id="location"> Estado / Cidade <b data-id="location">{attrRacesBox.location}</b></b>
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                    <Card.Body>
                        <Form.Group className="row">
                            <Form.Control as="select" className="col-12 col-sm-12 col-md-11" value={listStateData.id_uf} name="id_uf" onChange={handleChangeListFilter}>
                                <option hidden value="0">Selecione o Estado</option>
                                <option value=''>Todos</option>
                                {listStateData.map((state: any) =>
                                    <option key={state.id_uf} value={state.id_uf}>{state.des_uf}</option>
                                )}
                            </Form.Control>
                            <br />
                            <br />
                            <Form.Control as="select" className="col-12 col-sm-12 col-md-11" value={dataAddress.code_city} name="code_city" onChange={handleChangeListFilter}>
                                <option hidden value="0">Selecione a Cidade</option>
                                <option value=''>Todas</option>
                                {listCityData.map((city: any) =>
                                    <option key={city.code_city} value={city.code_city}>{city.des_city}</option>
                                )}
                            </Form.Control>
                        </Form.Group>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    </>
  );
}
export default StateCity;
