import React, { useState } from "react"

import { Autoplay, Navigation, SwiperOptions } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js"

import "swiper/swiper.scss"
import "swiper/modules/navigation/navigation.scss"
import "swiper/modules/autoplay/autoplay.scss"

import MediaMatch from "../../components/Utils/MediaMatch"

import * as S from "./styles"

export type CarouselProps = {
  space?: number
  numSlides?: number
  breakpoints?: SwiperOptions["breakpoints"]
  slides?: Array<React.ReactNode>
  children?: Array<React.ReactNode>
  width: string
  variant?: "product"
  animalList?: boolean
}

const Carousel = ({
  space = 10,
  numSlides = 1.1,
  breakpoints,
  slides,
  children,
  width,
  variant,
  animalList = false
}: CarouselProps) => {
  const [prevEl, setPrevEl] = useState<HTMLElement | null>(null)
  const [nextEl, setNextEl] = useState<HTMLElement | null>(null)

  return (
    <>
      <S.Carousel width={width} variant={variant}>
        <Swiper
          navigation={{ prevEl, nextEl }}
          modules={[Autoplay, Navigation]}
          spaceBetween={space}
          slidesPerView={numSlides}
          loop={true}
          centeredSlides={true}
          autoplay={{
            delay: 8000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          breakpoints={breakpoints}
        >
          {!!slides &&
            slides.map((slide, index) => (
              <SwiperSlide key={index}>{slide}</SwiperSlide>
            ))}
          {!!children && children}
        </Swiper>
        {(!!slides || children.length > 0) && (
          <MediaMatch greaterThan={"1000px"}>
            <S.LeftArrow
              ref={(node) => setPrevEl(node)}
              role="button"
              aria-label="Imagem anterior"
              animalList={animalList}
            />
            <S.RightArrow
              ref={(node) => setNextEl(node)}
              role="button"
              aria-label="Próxima imagem"
              variant={variant}
              animalList={animalList}
            />
          </MediaMatch>
        )}
      </S.Carousel>
    </>
  )
}
export default Carousel
