import styled, { css } from "styled-components"
import media from "styled-media-query"

type MediaMatchProps = {
  lessThan?: string
  greaterThan?: string
  between?: string
}

const mediaMatchModifiers = {
  lessThan: (size) => css`
    ${media.lessThan(size)` display: block `}
  `,

  greaterThan: (size) => css`
    ${media.greaterThan(size)` display: block `}
  `,

  between: (size, size2) => css`
    ${media.between(size, size2)` display: block `}
  `,
}

export default styled.div<MediaMatchProps>`
  ${({ lessThan, greaterThan, between, between2 }) => css`
    display: none;
    ${!!lessThan && mediaMatchModifiers.lessThan(lessThan)}
    ${!!greaterThan && mediaMatchModifiers.greaterThan(greaterThan)}
    ${!!between && mediaMatchModifiers.between(between, between2)}
  `}
`
