/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

import Category from './Category'
import StateCity from './StateCity'
import Races from './Races'
import LitersPerDay from './LitersPerDay'
import NumberLactations from './NumberLactations'
import Pregnancy from './Pregnancy'
import VlLot from './VlLot'


const MenuLeftMilkCow: React.FC = () => {
  return (
    <>
       <Category />
       <StateCity />
       <Races />
       <LitersPerDay />
       <NumberLactations />
       <Pregnancy />
       <VlLot />
    </>
  );
}
export default MenuLeftMilkCow;
