/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import Pagination from 'rc-pagination';

import Header from '../../../components/HeaderAdmin'
import Sidebar from '../SideNav'

import 'rc-pagination/assets/index.css'
import './styles.css'
import { Col, Container, Row} from 'react-bootstrap';
import { checkDevice } from '../../../helpers/utils';

import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { api } from '../../../services/apiLeloae/config';
import axios from 'axios';
import { FormatPrice } from '../../../helpers/format';

const Lots: React.FC = (props: any) => {
    const {id_product} = props.match.params

    const [expandedResume, setExpandedResume] = useState({ expanded: null })
    const [listBid, setListBid] = useState<any>([])


    const [totalBid, setTotalBid] = useState(0)
    const [loadingPagination, setLoadingPagination] = useState(false)

    let mounted = true;
    const signal = axios.CancelToken.source();

    useEffect(() => {
        setExpandedResume({expanded: checkDevice()})
    },[])

    const getListBid = async (page = 1) => {
        if( loadingPagination ) {return}
        if ( totalBid > 0 && listBid.length == totalBid ) {return}
        setLoadingPagination(true)
        try {
        const responseListBid: any = await api.get(`/bid/auction/product/${id_product}`, {cancelToken: signal.token})
        if (mounted) {setListBid(responseListBid?.data?.message)}
        setTotalBid(responseListBid.headers['x-total-count'])
        setLoadingPagination(false)
        } catch (error) {
        toast.error('Erro em buscar lista de lotes', {toastId: 1})
        }
    }

    useEffect(() => {
        getListBid()
        const cleanup = () => { mounted = false; };
        return cleanup;
    }, [mounted])
  return (
    <>
      <Container fluid id="bid">
          <Row>  
              <Sidebar />
              <Header />
                <div className={expandedResume.expanded ? 'resume' : 'resume-mobile'}>
                    <Container className="col-12">
                        <Col sm={12} className="shadow mb-3 bg-white colunm list-register-bid  text-center">
                            <div id="lots" className="row">
                                <div className="description col-lg-1 col-md-4 col-sm-12">
                                    <b>ID lote</b>
                                </div>
                                <div className="description col-lg-2 col-md-4 col-sm-12">
                                    <b>ID Lance</b>
                                </div>
                                <div className="description col-lg-3 col-md-4 col-sm-12">
                                    <b>Nome do cliente</b>
                                </div>
                                <div className="description col-lg-3 col-md-4 col-sm-12">
                                    <b>Valor(R$)</b>
                                </div>
                                <div className="description col-lg-3 col-md-4 col-sm-12">
                                    <b style={{color: '#7808fd'}}>Valor Total: {FormatPrice(listBid[1]?.vl_total_bid)}</b>
                                </div>
                            </div>
                            <hr/>
                            {listBid[0] &&
                                listBid[0].map((bid: any) => 
                                    <div key={bid.id_bid_lots_auction}>
                                        <Col sm={12} className="row" style={{margin: '0'}}>
                                            <div className="description col-lg-1 col-md-4 col-sm-12">
                                                <p>{bid?.id_product}</p>
                                            </div>
                                            <div className="description col-lg-2 col-md-4 col-sm-12">
                                                <p>{bid?.id_bid_lots_auction}</p>
                                            </div>
                                            <div className="description col-lg-3 col-md-4 col-sm-12">
                                                <p>{bid?.Person.des_person}</p>
                                            </div>
                                            <div className="description col-lg-3 col-md-4 col-sm-12">
                                                <p>{FormatPrice(bid?.vl_bid)}</p>
                                            </div>
                                            <div className="description col-lg-3 col-md-4 col-sm-12">
                                                <Link className="btn btn-create" to={`/admin/bid/detail/${bid.id_product}/${bid?.id_bid_lots_auction}`}>Realizar pedido</Link>
                                            </div>
                                        </Col>
                                        <hr/>
                                    </div>
                                )
                            }
                            <Pagination defaultCurrent={1} onChange={getListBid} pageSize={10} total={totalBid} style={{ margin: '20px' }}  />
                        </Col>
                    </Container>
                </div>
          </Row>

      </Container>
    </>
  );
}
export default Lots;
