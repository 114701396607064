import styled, { css } from "styled-components"

type PhotoProps = {
  hasPhoto: boolean
}

export const Wrapper = styled.div`
  width: 100%;
  height: auto;

  display: flex;
  align-items: center;
`

export const ProfilePhoto = styled.div<PhotoProps>`
  width: 74px;
  height: 74px;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  z-index: 99;

  img {
    width: 74px;
    height: 74px;
    border-radius: 50%;
    z-index: 999;
    transform: translate(-1.5px, -2px);
  }

  ${({ hasPhoto }) =>
    hasPhoto
      ? css`
          background-color: unset;
          box-shadow: unset;
        `
      : css`
          img {
            width: 69px;
            height: 69px;
            transform: unset;
          }

          @media screen and (min-width: 1297px) {
            img {
              width: 69px;
              height: 69px;
              transform: translate(-1px, -2px);
            }
          }
        `}
`
