import { Authentication } from './apiLeloae/authentication'
import { CreateUser } from './apiLeloae/create-user'
import { Products } from './apiLeloae/products'
import { ListTypeProducts } from './apiLeloae/list-type-product'
import { Auction } from './apiLeloae/auction'

export interface HttpRequestI {
    error: boolean
    loading: boolean
    success: boolean
    loadingBar: any
    errorMsg: ''
}

class Api {
    readonly apiLeloae = {
        authentication: new Authentication(),
        createuser: new CreateUser(),
        product: new Products(),
        listtypeproduct: new ListTypeProducts(),
        auction: new Auction(),
    }
}

export const api = new Api()