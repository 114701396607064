/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from "react"

import Footer from "../../../components/Footer"
import Navbar from "../../../components/Navbar"
import Auction from "./auction"
import Company from "./company"

import "./styles.css"
import { Container, Row } from "react-bootstrap"
import { useCreateAuctionContext } from "../../../providers/auction-create"
import { FormatError } from "../../../helpers/formatError"
import { toast } from "react-toastify"
import Loading from "../../../components/Loading"
import { useHistory } from "react-router-dom"

const RegisterAction: React.FC = () => {
  const { loadMsg, progress, auctionData } = useCreateAuctionContext()
  let retgisterForm: {} | null | undefined
  let progressCreateAuction = progress
  const history = useHistory()

  if (progressCreateAuction === 1) {
    retgisterForm = <Auction />
  } else if (progressCreateAuction === 2) {
    retgisterForm = <Company />
  }

  useEffect(() => {
    if (loadMsg.msgError) {
      const errorFormat = FormatError(loadMsg.msgError)
      errorFormat.forEach((element: any) => {
        toast.error(element)
      })
    }
  }, [loadMsg.msgError])

  useEffect(() => {
    if (loadMsg.msgSuccess) {
      const errorFormat = FormatError(loadMsg.msgSuccess)
      errorFormat.forEach((element: any) => {
        toast.success(element)
      })
      history.push(`/register/auction/files/${auctionData.id_auction}`)
    }
  }, [history, loadMsg.msgSuccess])

  return (
    <>
      {loadMsg.createAuctionLoading && <Loading />}
      <Navbar />
      <Container>
        <div id="register-action">
          <Row>{retgisterForm}</Row>
        </div>
      </Container>
      <Footer />
    </>
  )
}
export default RegisterAction
