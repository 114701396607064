/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, createContext, useState, useCallback, useEffect } from "react";
import { api } from "../../services";

interface iResult {
  data: {
    message: {
      auction: any;
      company: ICompany;
    }
  }
  message: IAuction;
}

export interface IAuction {
  id_person?: number;
  des_title?: string;
  des_organizer?: string;
  dt_deadline?: any;
  time_deadline?: any;
};

export interface ICompany {
  id_person?: number
  nr_cnpj?: string,
  des_company?: string,
  des_company_fantasy?: string;
  des_situation?: string;
  nr_zip_code?: number;
  des_state?: string;
  des_city?: string;
}

type ILoadMsg = {
  createAuctionLoading?: boolean;
  msgError?: string;
  msgSuccess?: string;
}

type ICreateAuctionContext = {
  dataAuctionContext: IAuction;
  dataCompanyContext: ICompany;
  auctionData: any
  progress: Number;
  loadMsg: ILoadMsg;
  setAuctionStorage(UserStorage: IAuction): Promise<void>;
  setUserCompanyStorage(UserAddressStorage: ICompany): Promise<void>;
  createAuction(nr_policy_terms: any): Promise<void>;
};
const CreateAuctionContext = createContext<Partial<ICreateAuctionContext>>({});

export const CreateAuctionProvider: React.FC = ({ children }) => {
  const [dataAuctionContext, setDataAuctionContext] = useState<IAuction>();

  const [dataCompanyContext, setDataCompanyContext] = useState<ICompany>();

  const [progress, SetProgress] = useState(1)

  const [loadMsg, setLoadMsg] = useState<ILoadMsg>({
    msgError: '',
    msgSuccess: '',
    createAuctionLoading: false,
  });

  const [auctionData, setAuctionData] = useState<any>({})

  const dataUserMemory = useCallback(() => {
      return new Promise(async (resolve) => {
        const dataAuctionStorage: any = JSON.parse(localStorage.getItem('@leloae/user/auction/create'))
        const dataCompanyStorage = JSON.parse(localStorage.getItem('@leloae/user/company/create'))
        if(dataAuctionStorage){
          setDataAuctionContext(dataAuctionStorage)
          setDataCompanyContext(dataCompanyStorage)
          SetProgress(1)
        }
        resolve(true)
      })
  }, [])

  useEffect(() => {
    ( async () => {
        await dataUserMemory()
    })()
  }, [])

  const setAuctionStorage = async (AuctionStorage: IAuction) => {
    try {
      setLoadMsg({ createAuctionLoading: true });

        localStorage.setItem('@leloae/user/auction/create', JSON.stringify(AuctionStorage))

        setLoadMsg({createAuctionLoading: false});
        SetProgress(2)

    } catch (error) {
      setLoadMsg({
            createAuctionLoading: false,
            msgError: "Ocorreu algum erro, tente novamente"
        });
    }
  }

  const createAuction = async (data: any) => {
    try {

      const auctionData = await api.apiLeloae.auction.createAuction<iResult>(data)

      if(auctionData.data.message){
        localStorage.setItem('@leloae/user/auction/create', JSON.stringify(null))
        localStorage.setItem('@leloae/user/company/create', JSON.stringify(null))

        setAuctionData(auctionData.data.message.auction)
        setLoadMsg({
          createAuctionLoading: false,
          msgSuccess: 'Leilão cadastrado com sucesso',
          msgError: ''
        });
      }else{
        setLoadMsg({
          createAuctionLoading: false,
          msgError: 'Ocorreu algum erro, tente novamente'
        });
      }

    } catch (error) {
      SetProgress(1)
      setLoadMsg({
          createAuctionLoading: false,
          msgError: error.response.data.message,
        });
    }
  };

  return (
    <CreateAuctionContext.Provider
      value={{
        dataAuctionContext,
        dataCompanyContext,
        auctionData,
        progress,
        loadMsg,
        setAuctionStorage,
        createAuction
      }}
    >
      {children}
    </CreateAuctionContext.Provider>
  );
};

export const useCreateAuctionContext = () => {
  return useContext(CreateAuctionContext);
};