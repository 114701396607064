import React, { useState } from "react"
import { useHistory } from "react-router-dom"

import Footer from "../../components/Footer"
import Navbar from "../../components/Navbar"
import Loading from "../../components/Loading"

import "./styles.css"
import { Container, Row } from "react-bootstrap"

import { useUserContext } from "../../providers/use-sign"
import { api } from "../../services/apiLeloae/config"
import { toast } from "react-toastify"
import { FormatError } from "../../helpers/formatError"

const AccountDisabled: React.FC = () => {
  const history = useHistory()

  const [accountActiveLoading, setaccountActiveLoading] = useState(false)
  const { data, signingOut } = useUserContext()

  const handleClickAccountActive = async () => {
    setaccountActiveLoading(true)

    try {
      const updatePassword: any = await api.put(
        `/user/update/user/${data.user.id_person}`,
        { des_type_user: "inclient" }
      )

      setaccountActiveLoading(false)

      if (updatePassword.data?.message) {
        toast.success("Conta ativa novamente, faço login novamente", {
          toastId: 1,
        })
      }
      await new Promise((resolve) => setTimeout(resolve, 1000))

      signingOut()
      history.push("/authentication")
    } catch (error) {
      const errorFormat = FormatError(error)
      errorFormat.forEach((element) => {
        toast.error(element)
      })
      setaccountActiveLoading(false)
    }
  }
  return (
    <>
      {accountActiveLoading && <Loading />}
      <Navbar />
      <Container>
        <div id="account-disabled">
          <Row>
            <div className="col-12 text-center">
              <h2 className="title-custom-h2">Conta desativada</h2>
              <h4 className="">
                Sua conta está desativada, para reativar basta clicar no botão a
                abaixo
              </h4>
              <button
                onClick={handleClickAccountActive}
                className="btn btn-create text-center"
              >
                Ativar conta
              </button>
            </div>
          </Row>
        </div>
      </Container>
      <Footer />
    </>
  )
}
export default AccountDisabled
