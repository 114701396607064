/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import Pagination from 'rc-pagination';

import Header from '../../../components/HeaderAdmin'
import Sidebar from '../SideNav'

import 'rc-pagination/assets/index.css'
import './styles.css'

import {Col, Container, FormControl, InputGroup, Navbar, Row} from 'react-bootstrap';
import { checkDevice } from '../../../helpers/utils';
import { api } from '../../../services/apiLeloae/config';
import { toast } from 'react-toastify';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';


const Requests: React.FC = () => {

  const [expandedResume, setExpandedResume] = useState({ expanded: null })
  const [listRequest, setListRequest] = useState<any>([])

  const [totalRequest, setTotalRequest] = useState(0)
  const [loadingPagination, setLoadingPagination] = useState(false)
  let mounted = true;
  const signal = axios.CancelToken.source();

  const getListRequest = async (page = 1) => {
        if( loadingPagination ) {return}
        if ( totalRequest > 0 && listRequest.length == totalRequest ) {return}
        setLoadingPagination(true)
        try {
            const responseListRequest: any = await api.get('/admin/negotiation/request/product', {cancelToken: signal.token})
            if (mounted) {setListRequest(responseListRequest?.data?.message)}
            setTotalRequest(responseListRequest.headers['x-total-count'])
            setLoadingPagination(false)
        } catch (error) {
            toast.error('Error e buscar lista de lotes', {toastId: 1})
        }
  }

  const handleSearchRequest = (async (event: any) => {
      const value = event.target.value

      try {
          const responseListUsers: any = await api.get('/admin/negotiation/request/product/search', {params: {search: value} })
          if (mounted) {setListRequest(responseListUsers?.data?.message)}
      } catch (error) {
      toast.error('Error e buscar lista de lotes', {toastId: 1})
      }
  })

  useEffect(() => {
    getListRequest()
    const cleanup = () => { mounted = false; };
    return cleanup;
  }, [mounted])

  useEffect(() => {
    setExpandedResume({expanded: checkDevice()})
  },[])

  return (
    <>
      <Container fluid id="requests">
          <Row>  
              <Sidebar />
              <Header />
                <div className={expandedResume.expanded ? 'resume' : 'resume-mobile'}>
                  <Row className="row-requests">
                    <Col sm={12} className="shadow mb-3 bg-white colunm nav">
                      <Navbar id="navbar-custom" collapseOnSelect bg="light" expand="lg"  >
                            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                                <Navbar.Collapse id="responsive-navbar-nav" className="form-row">
                                    <Col sm={12}>
                                        <Row>
                                            <InputGroup className="col-sm-12 col-lg-12">
                                                <FormControl
                                                placeholder="Buscar"
                                                aria-label="Buscar"
                                                aria-describedby="basic-addon2"
                                                onChange={handleSearchRequest}
                                                />
                                                <InputGroup.Append>
                                                    <FontAwesomeIcon className="icon-search" icon={faSearch} size="2x" />
                                                </InputGroup.Append>
                                            </InputGroup>                                        
                                        </Row>
                                    </Col>
                                </Navbar.Collapse>
                            </Navbar>
                        </Col>
                        <Container className="col-12">
                            <Col sm={12} className="shadow mb-3 bg-white colunm list-register-request-lots  text-center">
                                <div id="lots" className="row">
                                    <div className="description col-md-2 col-sm-4">
                                        <b>Número do pedido</b>
                                    </div>
                                    <div className="description col-md-2 col-sm-4">
                                        <b>Lote</b>
                                    </div>
                                    <div className="description col-md-2 col-sm-4">
                                        <b>Solicitante</b>
                                    </div>
                                    <div className="description col-md-2 col-sm-4">
                                        <b>Vendedor</b>
                                    </div>
                                    <div className="description col-md-2 col-sm-4">
                                        <b>Status</b>
                                    </div>
                                </div>
                                <hr/>
                                {listRequest.map((request: any) => 
                                    <div key={request?.id_product_negotiation_request}>
                                        <Col sm={12} className="row">
                                            <div className="description col-md-2 col-sm-4">
                                                <p>{request?.id_product_negotiation_request}</p>
                                            </div>
                                            <div className="description col-md-2 col-sm-4">
                                                <p>{request?.Product?.des_title}</p>
                                            </div>
                                            <div className="description col-md-2 col-sm-4">
                                                <p>{request?.Person?.des_person}</p>
                                            </div>
                                            <div className="description col-md-2 col-sm-4">
                                                <p>{request?.Product?.Person?.des_person}</p>
                                            </div>
                                            <div className="description col-md-2 col-sm-4">
                                              {request?.nr_status == 1 ? <p>Negocio fechado</p>  : <p>Aguardando confirmação</p>}
                                            </div>
                                            <div className="description col-md-2 col-sm-4">
                                                <Link to={`/admin/requests/negotiation/detail/resume/${request?.id_product_negotiation_request}`}><FontAwesomeIcon icon={faEye} size="2x" /></Link>
                                            </div>
                                        </Col>
                                        <hr/>
                                    </div>
                                )}
                                <Pagination defaultCurrent={1} onChange={getListRequest} pageSize={10} total={totalRequest} style={{ margin: '20px' }}  />
                            </Col>
                        </Container>
                  </Row>
                </div>
          </Row>

      </Container>
    </>
  );
}
export default Requests;
