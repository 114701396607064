/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

import { Container, Row, Card } from 'react-bootstrap';
import Carousel from '@brainhubeu/react-carousel';

import imgMarchangus from '../../assets/image/homepage/parceiros/marchangus.jpg';
import imgHerdBovinos from '../../assets/image/homepage/parceiros/herdbovinos.png';
import imgCenterGen from '../../assets/image/homepage/parceiros/centergen.jpg';
import imgSudotec from '../../assets/image/homepage/parceiros/sudotec.png';
import imgSicoob from '../../assets/image/homepage/parceiros/sicoob.png';
import img4Thinks from '../../assets/image/homepage/parceiros/4thinks.png';

import './styles.css'

const Partner: React.FC = () => {
  return (
    <>
    <section id="partner">
    <Container>
        <h2 className="text-center title-custom-h2">Nossos parceiros</h2>
          <Row>
          <Carousel
            slidesPerPage={3}
            arrows
            autoPlay={5000}
            itemWidth={250}
            animationSpeed={150}
            infinite
            breakpoints={{
              360: {
                itemWidth: false,
                slidesPerPage: 1,
                arrows: false
              },
              640: {
                itemWidth: false,
                slidesPerPage: 1,
                arrows: false
              },
              900: {
                slidesPerPage: 1,
                arrows: false
              }
            }}
          >
          <Card className="text-center col-sm-6" style={{ width: '18rem' }}>
            <Card.Img className="marchangus-partner lazyload" variant="top" src={imgMarchangus} alt="Parceiro Composto São Marcos - Marchangus"/>
            <Card.Body>
              <Card.Title>Marchangus</Card.Title>
              {/* <Card.Text>
                Marchangus
              </Card.Text> */}
            </Card.Body>
          </Card>
          <Card className="text-center col-sm-6" style={{ width: '18rem' }}>
            <Card.Img className="herd-bovinos-partner lazyload" variant="top" src={imgHerdBovinos} alt="Parceiro Herd Bovinos"/>
            <Card.Body>
              <Card.Title>Herd Bovinos</Card.Title>
              {/* <Card.Text>
                Herd Bovinos
              </Card.Text> */}
            </Card.Body>
          </Card>
          <Card className="text-center col-sm-6" style={{ width: '18rem'}}>
            <Card.Img className="center-gen-partner lazyload" variant="top" src={imgCenterGen} alt="Parceiro Center Gen"/>
            <Card.Body>
              <Card.Title>Center Gen</Card.Title>
              {/* <Card.Text>
                Center Gen
              </Card.Text> */}
            </Card.Body>
          </Card>
          <Card className="text-center col-sm-6" style={{ width: '18rem' }}>
            <Card.Img className="sudotec-partner lazyload" variant="top" src={imgSudotec} alt="Parceiro Sudotec"/>
            <Card.Body>
              <Card.Title>Sudotec</Card.Title>
              {/* <Card.Text>
                Incubadora Sudotec
              </Card.Text> */}
            </Card.Body>
          </Card>
          <Card className="text-center col-sm-6" style={{ width: '18rem' }}>
            <Card.Img className="sicoob-partner lazyload" variant="top" src={imgSicoob} alt="Parceiro Sicoob"/>
            <Card.Body>
              <Card.Title>Sicoob - Vale Sul</Card.Title>
              {/* <Card.Text>
                Incubadora Sudotec
              </Card.Text> */}
            </Card.Body>
          </Card>
          <Card className="text-center col-sm-6" style={{ width: '18rem' }}>
            <Card.Img className="thinks-partner lazyload" variant="top" src={img4Thinks} alt="Parceiro 4Thinks"/>
            <Card.Body>
              <Card.Title>4Thinks</Card.Title>
              {/* <Card.Text>
                Incubadora Sudotec
              </Card.Text> */}
            </Card.Body>
          </Card>
          </Carousel>
          </Row>
        </Container>
      </section>
    </>
  );
}
export default Partner;
