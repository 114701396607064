import React from 'react';
import { Nav } from 'react-bootstrap';
import { useUserContext } from '../../providers/use-sign';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldAlt, faHandHoldingUsd, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { faFacebookSquare, faInstagram, faGoogle, faWhatsapp } from '@fortawesome/free-brands-svg-icons'

import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import './styles.css'


const Footer: React.FC = () => {

  const { signed } = useUserContext()

  const history = useHistory()


  const checkedLogin = () => {
    toast.warn('Você precisa está logando para anúnciar', { toastId: 1 })
    history.push('/authentication')
  }

  return (
    <footer>
      <div className="bg-clear-custom">
        <div className="container">
          <div id="info-footer-custom" className="row text-center">
            <div className="col-sm-3 col-md-4 pay">
              <FontAwesomeIcon className="icon-bell" icon={faHandHoldingUsd} size="6x" />
              <br />
              <b>Boleto e transferência bancária</b>
              <p>Formas de pagamento que facilitam o seu negócio.</p>
            </div>
            <div className="col-sm-3 col-md-4 safe">
              <FontAwesomeIcon className="icon-bell" icon={faShieldAlt} size="6x" />
              <br />
              <b>Segurança de ponta a ponta</b>
              <p>Site blindado. Suas transações sempre seguras e protegidas</p>
            </div>
            <div className="col-sm-3 col-md-4 tracking">
              <FontAwesomeIcon className="icon-bell" icon={faMapMarkerAlt} size="6x" />
              <br />
              <b>Rastreabilidade</b>
              <p>Acompanhe todo o processo de compra e venda com a Leiloaê.
            </p>
            </div>
          </div>
        </div>
      </div>

      {/* https://web.whatsapp.com/send?phone=46991130554 */}
      <a href="https://wa.me/5546991130554?" id="wpp-btn" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon className="icon-bell" icon={faWhatsapp} size="2x" />
      </a>
      <div className="bg-clear-custom-rodape">
        <div className="container ctn-rodape">
          <div className="col-12 flex-column flex-xl-row rodape" id="nav-custom-top">
            <Nav id="nav-footer-custom" className="justify-content-center" activeKey="/home">
              <Nav.Item>
                <Nav.Link href="/">Início</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/product/list/">Animais</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                {signed ? <Nav.Link href="/announce">Anunciar</Nav.Link> : <Nav.Link onClick={checkedLogin}>Anunciar</Nav.Link>}
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/downloads/app">Baixe nosso APP</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/about/us">Quem Somos</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/helps">Ajuda</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/about/us">CNPJ: 39.531.002/0001-42 - Leiloaê Agente Comercial Ltda.</Nav.Link>
              </Nav.Item>
            </Nav>
            <Nav id="nav-footer-social-custom" className="justify-content-center" activeKey="/home">
              <Nav.Item>
                <Nav.Link href="https://g.page/Leiloae?gm" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon className="icon-bell" icon={faGoogle} size="2x" /></Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="https://www.facebook.com/leiloae" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon className="icon-bell" icon={faFacebookSquare} size="2x" /></Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="https://www.instagram.com/leiloae.app/" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon className="icon-bell" icon={faInstagram} size="2x" /></Nav.Link>
              </Nav.Item>
            </Nav>
          </div>

        </div>
      </div>
    </footer>
  );
}
export default Footer;
