import React from 'react';
import CountDown from 'count-down-react'

import './styles.css'
import { Col } from 'react-bootstrap';

const CountDownTime: React.FC = (propos: any) => {

  const CoundownRenderer = ({ days, hours, minutes, seconds }) => (
    <>
      <div className="row numbers">
        <Col xs={3}>
          <p>{days}</p>  
        </Col>
        <Col xs={3}>
          <p>{hours}</p>  
        </Col>
        <Col xs={3}>
          <p>{minutes}</p>  
        </Col>
        <Col xs={3}>
          <p>{seconds}</p>  
        </Col>
      </div>
      <div  className="row chars">
        <Col xs={3}>
          <p>Dias</p>
        </Col>
        <Col xs={3}>
          <p>Horas</p>  
        </Col>
        <Col xs={3}>
          <p>Min</p>  
        </Col>
        <Col xs={3}>
          <p>Seg</p>  
        </Col>
      </div>
    </>
  )

    return (<CountDown date={propos.children} renderer={CoundownRenderer} />);
}
export default CountDownTime;