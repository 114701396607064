/* eslint-disable jsx-a11y/alt-text */
import React from "react"

import Footer from "../../components/Footer"
import Navbar from "../../components/Navbar"
import ForgotRestPasswordForm from "./forgotRestPassword"
import MsgSuccessRest from "./msgSuccessRest"

import { Container, Row } from "react-bootstrap"

let forgotPasswordSuccess = 1
let forgotPasswordcontant: {} | null | undefined

if (forgotPasswordSuccess === 2) {
  forgotPasswordcontant = <MsgSuccessRest />
} else {
  forgotPasswordcontant = <ForgotRestPasswordForm />
}

const ForgotPasswordRest: React.FC = () => {
  return (
    <>
      <Navbar />
      <Container>
        <div id="forgot-password-user" className="row justify-content-center">
          <Row className="bg-clear-custom">
            <div className="col-12 text-center forgot-password-user-form">
              <h2 className="title-custom-h2">Nova senha</h2>
              {forgotPasswordcontant}
            </div>
          </Row>
        </div>
      </Container>
      <Footer />
    </>
  )
}
export default ForgotPasswordRest
