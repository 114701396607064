import styled from "styled-components"

export const Wrapper = styled.section`
  width: 100%;
  height: 100%;
  background-color: #f7f7f7;
`

export const ContentDestaque = styled.div`
  width: 100%;
  height: auto;
`
