/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react"
import {
  FormatDatePtBr,
  FormatPriceInput,
  onlyNumbers,
} from "../../../helpers/format"
import { Container, Row, Figure, Nav, Modal } from "react-bootstrap"

import "react-responsive-carousel/lib/styles/carousel.min.css"
import "./styles.css"

import Navbar from "../../../components/Navbar"
import Footer from "../../../components/Footer"

import imgVideoIcon from "../../../assets/image/video-icon.png"

import { Link, Redirect, useHistory } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons"
import {
  faFacebookSquare,
  faInstagram,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons"

import imgIconCow from "../../../assets/image/icon-cow.png"

import { toast } from "react-toastify"
import Loading from "../../../components/Loading"
import { FormatError } from "../../../helpers/formatError"
import { api } from "../../../services"
import { Carousel } from "react-responsive-carousel"

const UserLotsSalesDetail: React.FC = (propos: any) => {
  const history = useHistory()

  const id_product = propos.match.params.id_product
  const [ProductLoading, setProductLoading] = useState(false)

  const [product, setProduct] = useState(null)
  const [showModalCarousel, setShowModalCarousel] = useState(false)

  const handleClose = () => setShowModalCarousel(false)
  const handleShow = () => setShowModalCarousel(true)
  const getProduct = async () => {
    setProductLoading(true)
    try {
      const product = await api.apiLeloae.product.get<any>(id_product)

      setProduct(product.data?.message)

      setProductLoading(false)
    } catch (error) {
      setProductLoading(false)
      const errorFormat = FormatError(error)
      errorFormat.forEach((element) => {
        toast.error(element)
      })
    }
  }

  useEffect(() => {
    getProduct()
  }, [])

  if (!id_product) {
    return <Redirect to="/product"></Redirect>
  }

  if (product?.nr_status == 1) {
    toast.error(
      "Você precisa enviar fotos do seu lote para deixar seu anúncio disponível",
      { toastId: 1 }
    )
    history.push(`/files/announce/${product.id_product}`)
  }

  return (
    <>
      {product && (
        <>
          {ProductLoading && <Loading />}
          <Navbar />
          <Container>
            <div id="lots-sales-user-detail">
              <Row className="row-top">
                <h2 className="col-12">{product.des_title}</h2>
                <p className="col-12">{product.des_description}</p>
                <Modal show={showModalCarousel} onHide={handleClose}>
                  <Modal.Header closeButton />
                  {product?.ProductVideos[0]?.des_product_video ? (
                    <>
                      <Carousel
                        showThumbs={true}
                        showStatus={true}
                        useKeyboardArrows
                        className="presentation-mode"
                      >
                        {product?.ProductPhotos.map((image: any) => (
                          <div key={image.id_product_photo}>
                            <img src={image.des_product_photo_url} />
                          </div>
                        ))}
                        <div key="video-1" className="my-slide content">
                          <iframe
                            width="560"
                            height="400"
                            src={`https://player.vimeo.com/video/${onlyNumbers(
                              product?.ProductVideos[0].des_product_video
                            )}`}
                          />
                          <img src={imgVideoIcon} className="thumb-img-video" />
                        </div>
                      </Carousel>
                    </>
                  ) : (
                    <>
                      <Carousel
                        showThumbs={true}
                        showStatus={true}
                        useKeyboardArrows
                        className="presentation-mode"
                      >
                        {product?.ProductPhotos.map((image: any) => (
                          <div key={image.id_product_photo}>
                            <img src={image.des_product_photo_url} />
                          </div>
                        ))}
                      </Carousel>
                    </>
                  )}
                </Modal>
                <div className="col-12 col-md-6">
                  {product?.ProductVideos[0]?.des_product_video ? (
                    <>
                      <Carousel
                        showThumbs={true}
                        showStatus={true}
                        useKeyboardArrows
                        className="presentation-mode"
                      >
                        {product?.ProductPhotos.map((image: any) => (
                          <div
                            key={image.id_product_photo}
                            onClick={handleShow}
                          >
                            <img src={image.des_product_photo_url} />
                          </div>
                        ))}
                        <div key="video-1" className="my-slide content">
                          <iframe
                            width="560"
                            height="315"
                            src={`https://player.vimeo.com/video/${onlyNumbers(
                              product?.ProductVideos[0].des_product_video
                            )}`}
                          />
                          <img src={imgVideoIcon} className="thumb-img-video" />
                        </div>
                      </Carousel>
                    </>
                  ) : (
                    <>
                      <Carousel
                        showThumbs={true}
                        showStatus={true}
                        useKeyboardArrows
                        className="presentation-mode"
                      >
                        {product?.ProductPhotos.map((image: any) => (
                          <div
                            key={image.id_product_photo}
                            onClick={handleShow}
                          >
                            <img src={image.des_product_photo_url} />
                          </div>
                        ))}
                      </Carousel>
                    </>
                  )}
                </div>
                <div className="col-12 col-md-6">
                  <div className="shadow mb-3 bg-white detail-buy">
                    <b>Valor do lote:</b>
                    <h3 className="vl">
                      <b>R$</b> {FormatPriceInput(product.vl_price_total)}
                    </h3>
                    <br />
                    <p style={{ marginBottom: "3px" }}>Estado do pedido</p>
                    <b>
                      {" "}
                      * {product.TypeProductStatus.des_type_product_status}
                    </b>
                    <hr />
                    <Row>
                      <div className="col-12 col-sm-6 row detail-info">
                        <div className="col-4 text-center">
                          <FontAwesomeIcon icon={faCheckSquare} size="3x" />
                        </div>
                        <div className="col-8">
                          <b>Tipo de produto</b>
                          <p>
                            {
                              product.Animal.TypeAnimalProduct
                                .des_type_product_animal
                            }
                          </p>
                        </div>
                        <div className="col-4 text-center">
                          <Figure>
                            <Figure.Image src={imgIconCow} />
                          </Figure>
                        </div>
                        <div className="col-8">
                          <b>Raça</b>
                          <p>
                            {product?.Animal.id_custom_races
                              ? "Raça cruzada"
                              : product?.Animal.AnimalRace.des_races}
                          </p>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <div className="col-12">
                          <b>Categoria</b>
                          <p>{product.Animal.AnimalCategory.des_category}</p>
                        </div>
                        <div className="col-12">
                          <b>Peso</b>
                          <p>{product?.nr_weight}</p>
                        </div>
                        <div className="col-12">
                          <b>Quantidade no lote</b>
                          <p>{product.qtd_animals} animais</p>
                        </div>
                      </div>
                    </Row>
                  </div>
                </div>
              </Row>
              <div>
                <b>Compartilhe nas suas redes sociais:</b>
                <Nav
                  id="nav-product-detail-social-custom"
                  className="justify-content-left"
                  activeKey="/home"
                >
                  <Nav.Item>
                    <Nav.Link href="#">
                      <FontAwesomeIcon
                        className="icon-WhatsApp"
                        icon={faWhatsapp}
                        size="2x"
                      />
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="#">
                      <FontAwesomeIcon
                        className="icon-Facebook"
                        icon={faFacebookSquare}
                        size="2x"
                      />
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="#">
                      <FontAwesomeIcon
                        className="icon-Instagram"
                        icon={faInstagram}
                        size="2x"
                      />
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
              <div>
                <h3 className="text-center title-custom-h3">Ficha técnica</h3>
                <div className="col-12 data-sheet shadow mb-3 bg-white">
                  <Link to="/">
                    <b>Baixar documentos em anexo</b>
                  </Link>
                  <div className="shadow-sm  bg-light rounded colum-light-gray row">
                    <b className="col-md-3 col-6">Tipo de produto:</b>{" "}
                    <p>
                      {
                        product?.Animal.TypeAnimalProduct
                          .des_type_product_animal
                      }
                    </p>
                  </div>
                  <div
                    className="shadow-sm  bg-white rounded colum-white row"
                    style={{ height: "100%" }}
                  >
                    {product?.Animal.id_custom_races ? (
                      <>
                        <b className="col-md-3 col-6">Raças cruzadas:</b>{" "}
                        <p>Segue lista abaixo</p>
                      </>
                    ) : (
                      <>
                        <b className="col-md-3 col-6">Raça:</b>
                        <p> {product?.Animal.AnimalRace.des_races}</p>
                      </>
                    )}
                    <hr />
                  </div>
                  {product?.Animal.AnimalCustomRaces.map((data: any) => (
                    <div
                      key={data.id_races}
                      style={{ marginLeft: "5%" }}
                      className="shadow-sm  bg-light rounded colum-light-gray row"
                    >
                      <b className="col-md-3 col-6">
                        * {data.AnimalRace.des_races}
                      </b>{" "}
                      <p>{data.nr_percentage}%</p>
                    </div>
                  ))}
                  <div className="shadow-sm  bg-light rounded colum-light-gray row">
                    <b className="col-md-3 col-6">Categoria:</b>{" "}
                    <p>{product?.Animal.AnimalCategory.des_category}</p>
                  </div>
                  <div className="shadow-sm  bg-white rounded colum-white row">
                    <b className="col-md-3 col-6">Quantidade:</b>{" "}
                    <p>{product?.qtd_animals} Animais</p>
                    <hr />
                  </div>
                  <div className="shadow-sm  bg-light rounded colum-light-gray row">
                    <b className="col-md-3 col-6">Intervalo de peso:</b>{" "}
                    <p>
                      {product?.Animal.AnimalAverageWeight.des_average_weight}
                    </p>
                  </div>
                  <div className="shadow-sm  bg-light rounded colum-light-gray row">
                    <b className="col-md-3 col-6">Sexo:</b>{" "}
                    <p>{product?.Animal.AnimalSex.des_animal_sex}</p>
                  </div>
                  {Boolean(product?.Animal?.nr_pregnancy) && (
                    <div className="shadow-sm  bg-light rounded colum-light-gray row">
                      <b className="col-md-3 col-6">Prenhez:</b>{" "}
                      <p>
                        {product?.Animal?.nr_pregnancy === 1 ? "Sim" : "Não"}
                      </p>
                    </div>
                  )}
                  {Boolean(product?.Animal?.nr_pregnancy_days) && (
                    <div className="shadow-sm  bg-light rounded colum-light-gray row">
                      <b className="col-md-3 col-6">Gestação:</b>{" "}
                      <p>{product?.Animal?.nr_pregnancy_days + " Dias"}</p>
                    </div>
                  )}
                  {Boolean(product?.Animal?.nr_liters_day) && (
                    <div className="shadow-sm  bg-light rounded colum-light-gray row">
                      <b className="col-md-3 col-6">Litros produzidos/Dia:</b>{" "}
                      <p>{product?.Animal?.nr_liters_day}</p>
                    </div>
                  )}
                  {Boolean(product?.Animal?.nr_lactations) && (
                    <div className="shadow-sm  bg-light rounded colum-light-gray row">
                      <b className="col-md-3 col-6">Nº Lactações:</b>{" "}
                      <p>{product?.Animal?.nr_lactations}</p>
                    </div>
                  )}
                  {Boolean(product?.Animal?.nr_age_months) && (
                    <div className="shadow-sm  bg-light rounded colum-light-gray row">
                      <b className="col-md-3 col-6">Idade:</b>{" "}
                      <p>{product?.Animal?.nr_age_months + " Meses"}</p>
                    </div>
                  )}
                  <div className="shadow-sm  bg-white rounded colum-white row">
                    <b className="col-md-3 col-6">Proprietário:</b>{" "}
                    <p>
                      {product?.Person.des_person}{" "}
                      {product?.Person.des_lest_person}
                    </p>
                    <hr />
                  </div>
                  <div className="shadow-sm  bg-light rounded colum-light-gray row">
                    <b className="col-md-3 col-6">Possui embarcador:</b>{" "}
                    <p>{product?.nr_shipper_access === 1 ? "Sim" : "Não"}</p>
                  </div>
                  <div className="shadow-sm  bg-white rounded colum-white row">
                    <b className="col-md-3 col-6">Possui acesso a caminhões:</b>{" "}
                    <p>{product?.nr_truck_access === 1 ? "Sim" : "Não"}</p>
                    <hr />
                  </div>
                  <div className="shadow-sm  bg-light rounded colum-light-gray row">
                    <b className="col-md-3 col-6">Possui balança:</b>{" "}
                    <p>{product?.nr_balance_access === 1 ? "Sim" : "Não"}</p>
                  </div>
                  <div className="shadow-sm  bg-light rounded colum-light-gray row">
                    <b className="col-md-3 col-6">Cidade - UF:</b>{" "}
                    <p>
                      {product?.AddressProduct?.City?.des_city +
                        " - " +
                        product?.AddressProduct?.State?.uf}
                    </p>
                  </div>
                  <div className="shadow-sm  bg-light rounded colum-light-gray row">
                    <b className="col-md-3 col-6">Quilometros da cidade:</b>{" "}
                    <p>{product?.AddressProduct?.nr_kilometers + " KM"}</p>
                  </div>
                  <div className="shadow-sm  bg-white rounded colum-white row">
                    <b className="col-md-3 col-6">Data da publicação:</b>{" "}
                    <p>{FormatDatePtBr(product?.createdAt)}</p>
                    <hr />
                  </div>
                </div>
              </div>
            </div>
          </Container>
          <Footer />
        </>
      )}
    </>
  )
}
export default UserLotsSalesDetail
