import React from 'react';
import { Col, Row } from 'react-bootstrap';

import AuthenticatedUser from './authenticatedUser'

import './styles.css'

const HeaderAdmin: React.FC = () => {

  return (
    <header id="header-admin" className="col-12">
      <div className="shadow mb-3 bg-white colunm">
        <Row>
            <Col className="text-center title-admin" sm={12} md={{ span: 3, offset: 3 }} xl={{ span: 3, offset: 2 }}><h5>Painel administrativo</h5></Col>
            <Col className="d-none d-md-block d-lg-block d-xl-block" md={{ span: 3, offset: 3 }} xl={{ span: 3, offset: 4 }}> <AuthenticatedUser /></Col>
        </Row>
      </div>
    </header>
  );
}
export default HeaderAdmin;
