/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

import Category from './Category' 
import StateCity from './StateCity'
import RacesPure from './RacesPure'
import RacesComposed from './RacesComposed'
import Sex from './Sex'
import VlKilo from './VlKilo'
import VlLot from './VlLot'
import AverageWeight from './AverageWeight'

const MenuLeftBeefCattle: React.FC = () => {
  return (
    <>
       <Category />
       <StateCity />
       <RacesPure />
       <RacesComposed />
       <Sex />
       <VlKilo />
       <VlLot />
       <AverageWeight />
    </>
  );
}
export default MenuLeftBeefCattle;
