import React, { } from 'react';


import './styles.css'


const BankTicket: React.FC = () => {

  return (
    <>
       <div className="shadow mb-3 bg-white colunm row">
            <div className="col-12 row">
                <h5 className="col-12 "><b>Boleto bancário</b></h5>
                <p className="col-12 ">O boleto bancário será enviado no email cadastrado após a sua confirmação e poderá ser impresso para pagamento em uma agência bancária, ou ter o número anotado para pagamento pelo telefone ou internet</p>
            </div>
        </div>
    </>
  );
}
export default BankTicket;
