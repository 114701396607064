/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react"
import { useHistory } from "react-router-dom"

import { useUserContext } from "../../providers/use-sign"

import { Nav, NavDropdown } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faUserAlt,
  faSignOutAlt,
  faFolderOpen,
  faListUl,
} from "@fortawesome/free-solid-svg-icons"
import imgNoProfilePhotoBlack from "../../assets/image/noprofilephoto.png"

import * as S from "./styles"

const AuthenticatedUser: React.FC = () => {
  const { signingOut, data } = useUserContext()
  const history = useHistory()

  const logout = () => {
    signingOut()
    history.push("/")
  }

  const hasPhoto = data?.user?.Person?.ProfilePhoto?.des_profile_photo_url
    ? true
    : false

  return (
    <>
      <S.Wrapper>
        <S.ProfilePhoto hasPhoto={hasPhoto}>
          <img
            src={
              data?.user?.Person.ProfilePhoto?.des_profile_photo_url
                ? data?.user?.Person.ProfilePhoto.des_profile_photo_url
                : imgNoProfilePhotoBlack
            }
            alt="Imagem de perfil do usuário autenticado no sistema"
          />
        </S.ProfilePhoto>
        <NavDropdown
          title={data?.user.Person.des_person}
          id="basic-nav-dropdown"
          style={{ width: "100%" }}
        >
          <div id="image-profile-icon-dropdown">
            <img
              src={
                data?.user.Person.ProfilePhoto?.des_profile_photo_url
                  ? data?.user.Person.ProfilePhoto.des_profile_photo_url
                  : imgNoProfilePhotoBlack
              }
              className="rounded-circle image-profile-icon"
              alt="profile-img"
            />
            <div>
              <pre>{data?.user.Person.des_person}</pre>
              <pre>{data?.user.des_email}</pre>
            </div>
          </div>
          <NavDropdown.Divider />
          {data?.user?.des_type_user === "inadmin" ? (
            <NavDropdown.Item href="/admin">
              <FontAwesomeIcon icon={faUserAlt} size="1x" /> Administração
            </NavDropdown.Item>
          ) : (
            <>
              <NavDropdown.Item href="/user">
                <FontAwesomeIcon icon={faUserAlt} size="1x" /> Perfil{" "}
              </NavDropdown.Item>
              <NavDropdown.Item href="/user/requests">
                <FontAwesomeIcon icon={faFolderOpen} size="1x" /> Meus pedidos{" "}
              </NavDropdown.Item>
              <NavDropdown.Item href="/user/lots/sales">
                <FontAwesomeIcon icon={faListUl} size="1x" /> Meus lotes{" "}
              </NavDropdown.Item>
            </>
          )}
          <NavDropdown.Item onClick={logout}>
            <FontAwesomeIcon icon={faSignOutAlt} size="1x" /> Sair
          </NavDropdown.Item>
        </NavDropdown>
      </S.Wrapper>
    </>
  )
}
export default AuthenticatedUser
