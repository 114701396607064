/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import Pagination from 'rc-pagination';

import Header from '../../../components/HeaderAdmin'
import Sidebar from '../SideNav'

import 'rc-pagination/assets/index.css'
import './styles.css'
import { Button, Col, Container, Figure, Form, FormControl, InputGroup, Modal, Navbar, NavDropdown, Row} from 'react-bootstrap';
import { checkDevice } from '../../../helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faSearch, faEdit } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { api } from '../../../services/apiLeloae/config';
import axios from 'axios';
import { FormatDateHoursPtBr, FormatPrice } from '../../../helpers/format';

const Lots: React.FC = () => {

    const [expandedResume, setExpandedResume] = useState({ expanded: null })
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [idProductEdit, setIdProductEdit] = useState(null);

    const [listLots, setListLots] = useState<any>([])
    const [listTypeProduct, setListTypeProduct] = useState<any>([])
    const [listTypeCategories, setListTypeCategories] = useState<any>([])
    const [listTypeProductStatus, setListTypeProductStatus] = useState<any>([])
    const [listParams, setListParams] = useState<any>({
        id_type_product_animal: '',
        id_category: '',
        nr_status: ''
    })

    const [totalLots, setTotalLots] = useState(0)
    const [loadingPagination, setLoadingPagination] = useState(false)

    let mounted = true;
    const signal = axios.CancelToken.source();

    const handleCloseEdit = () => setShowModalEdit(false);
    const handleShowEdit = (value: any) => {
        setIdProductEdit(value)
        setShowModalEdit(true)
    };

    useEffect(() => {
        setExpandedResume({expanded: checkDevice()})
    },[])

    const getListLots = async (page = 1) => {
        if( loadingPagination ) {return}
        if ( totalLots > 0 && listLots.length == totalLots ) {return}
        setLoadingPagination(true)
        try {
        const responseListLots: any = await api.get('/admin/product/search/params', {params: {page: page, limit: 10}, cancelToken: signal.token})
        if (mounted) {setListLots(responseListLots?.data?.message)}
        setTotalLots(responseListLots.headers['x-total-count'])
        setLoadingPagination(false)
        } catch (error) {
        toast.error('Erro em buscar lista de lotes', {toastId: 1})
        }
    }

    const listTypesProduct = async () => {
        try {
            const responseTypeProduct: any = await api.get(`/utils/type/animal/product`)
          
            setListTypeProduct(responseTypeProduct?.data?.message)
        } catch (error) {
            toast.error('Ocorreu erro em buscar lista tipo de produto', {toastId: 1})    
        }
    }

    const listTypesCategories = async (data: any) => {
        try {
            const responseTypeCategories: any = await api.get(`/utils/type/animal/categories/${data}`)
          
            setListTypeCategories(responseTypeCategories?.data?.message)
        } catch (error) {
            toast.error('Ocorreu erro em buscar lista categorias', {toastId: 1})    
        }
    }

    const listTypesProductStatus = async () => {
        try {
            const responseTypeProductStatus: any = await api.get(`/utils/type/animal/product/status`)
          
            setListTypeProductStatus(responseTypeProductStatus?.data?.message)
        } catch (error) {
            toast.error('Ocorreu erro em buscar lista de  status', {toastId: 1})    
        }
    }

    const handleSearchLots = (async (event: any) => {
        const value = event.target.value

        try {
            const responseListLots: any = await api.get('/admin/product/search', {params: {search: value} })
            if (mounted) {setListLots(responseListLots?.data?.message)}
        } catch (error) {
        toast.error('Error e buscar lista de lotes', {toastId: 1})
        }
    })

    const handleSearchParamsLots = async () => {
        try {
            const responseListLots: any = await api.get('/admin/product/search/params', {params: listParams})
            setListLots(responseListLots?.data?.message)
        } catch (error) {
            toast.error('Error e buscar lista de lotes', {toastId: 1})
        }
    }

    const handleChangeListFilter = async (event: any) => {
        const name = event.target.name
        const value = event.target.value

        if(name == 'id_type_product_animal') {
            (value == 1 ? listTypesCategories('1/2') : listTypesCategories('3'))

            setListParams({[`${name}`]: value, nr_status: 1})
        }

        setListParams(field =>{
            return {
                ...field, [`${name}`]:value
            }
        })
    }

    useEffect(() => {
        handleSearchParamsLots()
    }, [listParams])

    useEffect(() => {
        listTypesProduct()
        listTypesProductStatus()
        getListLots()
        const cleanup = () => { mounted = false; };
        return cleanup;
    }, [mounted])
  return (
    <>
      <Container fluid id="lots">
          <Row>  
              <Sidebar />
              <Header />
                <div className={expandedResume.expanded ? 'resume' : 'resume-mobile'}>
                    <Col sm={12} className="shadow mb-3 bg-white colunm nav">
                        <Navbar id="navbar-custom" collapseOnSelect bg="light" expand="lg"  >
                            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                            <Navbar.Collapse id="responsive-navbar-nav" className="form-row">
                                <Col sm={12}>
                                    <Row>
                                        <InputGroup className="col-sm-12 col-lg-4">
                                            <FormControl
                                            placeholder="Buscar"
                                            aria-label="Buscar"
                                            aria-describedby="basic-addon2"
                                            onChange={handleSearchLots}
                                            />
                                            <InputGroup.Append>
                                                <FontAwesomeIcon className="icon-search" icon={faSearch} size="2x" />
                                            </InputGroup.Append>
                                        </InputGroup>
                                        <div className="col-sm-12 col-lg-1 text-filter">
                                            <h6>Filtrar por:</h6>
                                        </div>
                                        <div className="col-sm-12 col-lg-3">
                                            <NavDropdown title="Tipo de produto" id="nav-dropdown">
                                                {listTypeProduct.map((typeProduct: any) =>
                                                    <Form.Check id={`typeProduct_${typeProduct?.id_type_product_animal}`} key={typeProduct?.id_type_product_animal} type="radio" onClick={handleChangeListFilter} value={typeProduct?.id_type_product_animal} name="id_type_product_animal" label={typeProduct?.des_type_product_animal} />
                                                )}
                                            </NavDropdown>
                                        </div>
                                        <div className="col-sm-12 col-lg-2">
                                            <NavDropdown title="Categoria" id="nav-dropdown">
                                                {listTypeCategories.map((categories: any) =>
                                                    <Form.Check id={`categories_${categories?.id_animal_categories}`} key={categories?.id_animal_categories} type="radio" onClick={handleChangeListFilter} value={categories?.id_animal_categories} name="id_category" label={categories?.des_category} />
                                                )}
                                            </NavDropdown>
                                        </div>
                                        <div className="col-sm-12 col-lg-2">
                                            <NavDropdown title="Status" id="nav-dropdown">
                                                {listTypeProductStatus.map((productStatus: any) =>
                                                    <Form.Check id={`productStatus_${productStatus?.id_type_product_status}`} key={productStatus?.id_type_product_status} type="radio" onClick={handleChangeListFilter} value={productStatus?.id_type_product_status} name="nr_status" label={productStatus?.des_type_product_status} />
                                                )}
                                            </NavDropdown>
                                        </div>
                                        
                                    </Row>
                                </Col>
                            </Navbar.Collapse>
                        </Navbar>
                        <div className="col-sm-12 col-lg-2">
                            <a href="/admin/lots/create/search/user" className="btn btn-create">Cadastro de lote</a>
                        </div>
                    </Col>
                    <Container className="col-12">
                        <Col sm={12} className="shadow mb-3 bg-white colunm list-register-lots  text-center">
                            <div id="lots" className="row">
                                <div className="description col-lg-3 col-md-4 col-sm-12">
                                    <b>Dados do lote</b>
                                </div>
                                <div className="description col-lg-2 col-md-4 col-sm-12">
                                    <b>Tipo de produto</b>
                                </div>
                                <div className="description col-lg-2 col-md-4 col-sm-12">
                                    <b>Valor(R$)</b>
                                </div>
                                <div className="description col-lg-2 col-md-4 col-sm-12">
                                    <b>Data de cadastro</b>
                                </div>
                                <div className="description col-lg-2 col-md-4 col-sm-12">
                                    <b>Status</b>
                                </div>
                            </div>
                            <hr/>
                            {listLots.map((lots: any) => 
                                <div key={lots.id_product}>
                                    <Col sm={12} className="row" style={{margin: '0'}}>
                                        <div className="description col-lg-3 col-md-4 col-sm-12 row">
                                            <Figure>
                                                <Figure.Image src={lots?.ProductPhotos[0]?.des_product_photo_url} width={80} height={80} />
                                            </Figure>
                                            <p style={{margin: '20px 0 0 10px'}}>{lots?.des_title}</p>
                                        </div>
                                        <div className="description col-lg-2 col-md-4 col-sm-12">
                                            <p>{lots?.Animal?.AnimalCategory?.des_category}</p>
                                        </div>
                                        <div className="description col-lg-2 col-md-4 col-sm-12">
                                            <p>{FormatPrice(lots?.vl_price_total)}</p>
                                        </div>
                                        <div className="description col-lg-2 col-md-4 col-sm-12">
                                            <p>{FormatDateHoursPtBr(lots?.createdAt)}</p>
                                        </div>
                                        <div className="description col-lg-2 col-md-4 col-sm-12">
                                            <p>{lots?.TypeProductStatus?.des_type_product_status}</p>
                                        </div>
                                        <div className="description col-lg-1 col-md-4 col-sm-12">
                                            <Link to={`/admin/lots/detail/${lots.id_product}`}><FontAwesomeIcon icon={faEye} size="2x" /></Link>
                                            <div className="icon-edit" onClick={() => handleShowEdit(lots.id_product)}><FontAwesomeIcon icon={faEdit} size="2x" /></div>
                                            <Modal show={showModalEdit} onHide={handleCloseEdit}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Escolha um opção</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>Lote #{idProductEdit}</Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="secondary" onClick={handleCloseEdit}>
                                                        Cancelar
                                                    </Button>
                                                    <Link className="btn btn-info" to={`/admin/lots/files/update/${idProductEdit}`}>Editar Imagens</Link>
                                                    <Link className="btn btn-primary" to={`/admin/lots/update/${idProductEdit}`}>Editar Informações</Link>

                                                </Modal.Footer>
                                            </Modal>
                                        </div>
                                    </Col>
                                    <hr/>
                                </div>
                            )}
                            <Pagination defaultCurrent={1} onChange={getListLots} pageSize={10} total={totalLots} style={{ margin: '20px' }}  />
                        </Col>
                    </Container>
                </div>
          </Row>

      </Container>
    </>
  );
}
export default Lots;
