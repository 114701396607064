/* eslint-disable react-hooks/exhaustive-deps */
 
import React from 'react'
import { Switch, BrowserRouter } from 'react-router-dom'


import RouterAuth from './auth.router'
import RouterClient from './user.client.router'
import RouterAdmin from './admin.router'
import RouterAccountDisabled from './account.disabled.router'

import Loading from '../components/Loading';

import { useUserContext } from '../providers/use-sign'

const Routes = () => {

    const {data, signed } = useUserContext()

    if (data.signedLoading){
        return (
            <Loading />
        )
    }

    if(!signed){
        return (
            <BrowserRouter>
                <Switch>
                    <RouterAuth />
                </Switch>
            </BrowserRouter>
        )
    }else {
        if(data.user?.des_type_user === 'inadmin'){
            return (
                <BrowserRouter>
                    <Switch>
                        <RouterAdmin />
                    </Switch>
                </BrowserRouter>
            )
        }
        if(data.user?.des_type_user === 'inclient'){

            return (
                <BrowserRouter>
                    <Switch>
                        <RouterClient />
                    </Switch>
                </BrowserRouter>
            )
        }
        if(data.user?.des_type_user === 'inbanuser'){
            return (
                <BrowserRouter>
                    <Switch>
                        <RouterAccountDisabled />
                    </Switch>
                </BrowserRouter>
            )
        }

        return <RouterAuth />
    }
}

export default Routes