
import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
export default function HelmetMetaData(props: any) {
   let location = useLocation();
   let currentUrl = "http://www.leiloae.com.br" + location.pathname;
   let quote = props.quote !== undefined ? props.quote : "";
   let title = props.title !== undefined ? props.title : "Leiloaê Bovinos | Conheça a nova vitrine virtual da agropecuária!";
   let image = props.image !== undefined ? props.image : "https://www.leiloae.com.br/BannerConhecaMarketplace.jpg";
   let description = props.description !== undefined ? props.description  : "A Leiloaê é uma plataforma que conecta compradores e vendedores de gado de todo o Brasil. Conheça agora o novo marketplace da pecuária!";
   let hashtag = props.hashtag !== undefined ? props.hashtag : "#leiloae";
return (
    <Helmet>
        <title>{title}</title>
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="csrf_token" content="" />
        <meta property="type" content="website" />
        <meta property="url" content={currentUrl} />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
        <meta name="theme-color" content="#000000" />
        <meta name="_token" content="" />
        <meta name="robots" content="noodp" />
        <meta property="title" content={title} />
        <meta property="quote" content={quote} />
        <meta name="description" content={description} />
        <meta property="image" content={image} />
        <meta property="og:locale" content="pt-br" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:quote" content={quote} />
        <meta property="og:hashtag" content={hashtag} />
        <meta property="og:image" content={image} />
        <meta content="image/*" property="og:image:type" />
        <meta property="og:url" content={currentUrl} />
        <meta property="og:site_name" content="Leiloae" />
        <meta property="og:description" content={description} />    
    </Helmet>
);
}