/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';

import { Form, Card, Button, Accordion } from 'react-bootstrap';

import { useParamsProductContext } from '../../../providers/product-params';

const Pregnancy: React.FC = () => {
    const { setParamsProduct } = useParamsProductContext()


    const [attrRacesBox, setAttrRacesBox] = useState({
        pregnancy: '+'
    })

    const menuLeftBox = (event: any) => {
        const nameBox = event.target.dataset.id
        setAttrRacesBox(field => {
            return {
              ...field, [`${nameBox}`]:
              nameBox === 'pregnancy' ? attrRacesBox.pregnancy === '+' ? '-' : '+' : ''
            }
        })
    }

    const handleChangeListFilter = async (event: any) => {
        const name = event.target.name
        const value = event.target.value

        await setParamsProduct({type: name, value: value})
    }

  return (
    <>
        <Accordion defaultActiveKey="0">
            <Card className="shadow mb-3 bg-white">
                <Card.Header>
                <Accordion.Toggle as={Button} data-id="pregnancy" onClick={menuLeftBox} className="text-left" variant="link" eventKey="1">
                    <b data-id="pregnancy">Prenhez <b data-id="pregnancy">{attrRacesBox.pregnancy}</b></b>
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                <Card.Body>
                    <Form.Check type="radio" id="pregnancy_1" onClick={handleChangeListFilter} value="" defaultChecked name="nr_pregnancy" label="Todas" />
                    <Form.Check type="radio"  id="pregnancy_1" name="nr_pregnancy" label="Sim" onClick={handleChangeListFilter} value="1" />
                    <Form.Check type="radio"  id="pregnancy_2" name="nr_pregnancy" label="Não" onClick={handleChangeListFilter} value="2" />
                </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    </>
  );
}
export default Pregnancy;
