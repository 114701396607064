/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from "react"
import Pagination from "rc-pagination"

import { useDispatch, useSelector } from "react-redux"
import { actionProductUserRequest } from "../../../store/reduces/ReducerProductUserRequest"
import { RootState } from "../../../store"

import { Container, Row, Figure } from "react-bootstrap"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"

import { useUserContext } from "../../../providers/use-sign"
import { useParamsProductContext } from "../../../providers/product-params"

import Navbar from "../../../components/Navbar"
import Footer from "../../../components/Footer"
import Loading from "../../../components/Loading"

import "rc-pagination/assets/index.css"
import "./styles.css"

const UserRequest: React.FC = () => {
  const { data } = useUserContext()
  const { params, setParamsProduct } = useParamsProductContext()

  const { reducerProductUserRequest } = useSelector((state: RootState) => state)
  const dispatch = useDispatch()

  const handlePage = (page: number) => {
    setParamsProduct({ type: "page", value: page })
  }

  useEffect(() => {
    dispatch(
      actionProductUserRequest.listProducts({
        ...params,
        id_person: data.user?.id_person,
      })
    )
  }, [data.user, dispatch, params])
  return (
    <>
      {reducerProductUserRequest.httprequest.loading && <Loading />}
      <Navbar />
      <Container>
        <div id="request-user">
          <Row>
            <div className="col-12 row nav-top">
              <p className="col-12 col-sm-6">
                <Link to="/user">Gerenciar minha conta</Link>{" "}
                <FontAwesomeIcon icon={faAngleRight} size="1x" /> Meus pedidos{" "}
              </p>
              <Link
                to="/user/requests/negotiation"
                className="btn btn-access col-12 col-sm-6"
              >
                Solicitação de negociação
              </Link>
            </div>
            <div className="col-12 row">
              <div className="col-12 col-sm-12 col-md-3 menu-left">
                <h2 className="title-custom-h2 col-12">Meus pedidos</h2>
                <p className="col-12">
                  {reducerProductUserRequest.data.length} resultados
                </p>
              </div>
              <div className="col-12 col-sm-12 col-md-9 list-right text-center">
                {reducerProductUserRequest.data.map((data: any) => (
                  <div
                    key={data.id_buy_product}
                    className="shadow mb-3 bg-white colunm row"
                  >
                    <div className="col-12 col-sm-4 col-md-4 col-lg-3">
                      <Figure>
                        <Figure.Image
                          src={
                            data?.Product.ProductPhotos[0]
                              ?.des_product_photo_url
                          }
                        />
                      </Figure>
                    </div>
                    <div className="col-12 col-sm-8 col-md-8 col-lg-9 info-lots">
                      <div className="row titles">
                        <h3>{data.Product.des_title} </h3>
                      </div>
                      <div className="description row">
                        <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                          <b>Categoria</b>
                          <p>Compra</p>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                          <b>Número do pedido</b>
                          <p>{data.id_buy_product}</p>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                          <b>Status</b>
                          <p>
                            {data.TypeBuyNegotiationStatus
                              ?.des_type_buy_negotiation_status
                              ? data.TypeBuyNegotiationStatus
                                  .des_type_buy_negotiation_status
                              : "Aguardando negócio"}
                          </p>
                        </div>
                        <div className="row vl-show-details col-12 col-sm-12 col-md-6 col-lg-4">
                          <Link
                            to={`/user/requests/detail/${data.id_buy_product}`}
                            className="btn btn-create"
                          >
                            Ver pedido
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <Pagination
                  current={params.page}
                  onChange={handlePage}
                  pageSize={10}
                  total={reducerProductUserRequest.headers["x-total-count"]}
                  style={{ margin: "20px" }}
                />
              </div>
            </div>
          </Row>
        </div>
      </Container>
      <Footer />
    </>
  )
}
export default UserRequest
