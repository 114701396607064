import * as React from 'react'
import { Route } from 'react-router-dom'
import AppRouter from './app.router'
import User from '../pages/User';
import UserUpdate from '../pages/User/UserUpdate';
import UserPasswordUpdate from '../pages/User/UserPasswordUpdate';
import UserLotsSales from '../pages/User/UserLotsSales';
import UserRequest from '../pages/User/UserRequest';
import UserRequestNegotiation from '../pages/User/UserRequestNegotiation';
import UserRequestDetail from '../pages/User/UserRequestDetail';
import UserRequestNegotiationDetail from '../pages/User/UserRequestNegotiationDetail';
import UserLotsSalesDetail from '../pages/User/UserLotsSalesDetail';
import Announce from '../pages/Announce';
import AnnounceFiles from '../pages/AnnounceFiles';
import ProductBuy from '../pages/ProductBuy';
import ProductSendProposal from '../pages/ProductSendProposal';

import RegisterAuction from '../pages/User/RegisterAuction';
import AuctionFiles from '../pages/User/AuctionFiles';
import AuctionRegisterUser from '../pages/User/AuctionRegisterUser';


const ClienteRouter = () => {
    return (
        <>
          <Route exact path="/user" component={User} />
          <Route exact path="/user/update/data" component={UserUpdate} />
          <Route exact path="/user/update/password" component={UserPasswordUpdate} />
          
          <Route exact path="/user/lots/sales" component={UserLotsSales} />
          <Route exact path="/user/lots/sales/detail/:id_product" component={UserLotsSalesDetail} />

          <Route exact path="/user/requests" component={UserRequest} />
          <Route exact path="/user/requests/detail/:id_buy_product" component={UserRequestDetail} />

          <Route exact path="/user/requests/negotiation" component={UserRequestNegotiation} />
          <Route exact path="/user/requests/negotiation/detail/:id_product" component={UserRequestNegotiationDetail} />

          <Route exact path="/announce/:id_auction?/:nr_type_sales?" component={Announce} />
          <Route exact path="/files/announce/:id_product/:id_auction?/:nr_type_sales?" component={AnnounceFiles} />

          <Route exact path="/product/buy/:id_product" component={ProductBuy} />
          <Route exact path="/product/send/proposal/:id_product" component={ProductSendProposal} />

          <Route exact path="/register/auction" component={RegisterAuction} />
          <Route exact path="/register/auction/files/:id_auction" component={AuctionFiles} />

          <Route exact path="/auction/active/user/:id_auction/:id_person" component={AuctionRegisterUser} />

          <AppRouter />
        </>
    );
}

export default ClienteRouter
