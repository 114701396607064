import React, { useState } from "react"
import { toast } from "react-toastify"
import { useHistory } from "react-router-dom"

import Button from "../Button"
import SearchField from "../SearchField"

import UserIcon from "../Icons/UserIcon"
import RegisterIcon from "../Icons/RegisterIcon"

import { useUserContext } from "../../providers/use-sign"

import AuthenticatedUser from "../Header/authenticatedUser"

import MediaMatch from "../Utils/MediaMatch"

import Logo from "../../assets/image/leiloae-logo-2@4x.png"

import * as S from "./styles"

export type NavbarProps = {}

const Navbar = () => {
  const history = useHistory()
  const { signed } = useUserContext()

  const [isOpen, setIsOpen] = useState(false)

  const checkedLogin = () => {
    toast.warn("Você precisa está logando para anunciar", { toastId: 1 })
    history.push("/authentication")
  }

  const linkList = [
    {
      id: "inicio",
      name: "Início",
      link: "/",
    },

    {
      id: "blog",
      name: "Blog",
      link: "http://blog.leiloae.com.br/",
    },
    {
      id: "ajuda",
      name: "Ajuda",
      link: "/contact",
    },
    {
      id: "quemSomos",
      name: "Quem somos",
      link: "/about/us",
    },

    {
      id: "leiloaeSistemas",
      name: "Leiloaê Sistemas",
      link: "/",
    },
  ]

  return (
    <>
      <MediaMatch lessThan={signed ? "1297px" : "1381px"}>
        <S.Wrapper>
          <a href="/">
            <S.Logo
              src={Logo}
              alt="Logomarca da empresa"
              width="87px"
              height="44px"
            />
          </a>
          <S.MenuButton
            aria-label="Botão expansivo menu"
            aria-expanded={isOpen}
            onClick={() => setIsOpen((prevState) => !prevState)}
            isOpen={isOpen}
          >
            <span />
          </S.MenuButton>
        </S.Wrapper>
        <S.MenuExpanded isOpen={isOpen}>
          <S.MenuExpandedContent>
            {signed ? (
              <AuthenticatedUser />
            ) : (
              <S.ButtonLine>
                <Button
                  icon={<RegisterIcon />}
                  width="185px"
                  href="/register/user"
                >
                  REGISTRAR
                </Button>
                <Button
                  icon={<UserIcon />}
                  width="150px"
                  href="/authentication"
                >
                  ENTRAR
                </Button>
              </S.ButtonLine>
            )}
            <SearchField />
            <S.ButtonLine>
              <Button variant="dark" href="/product/list/">
                COMPRAR
              </Button>
              {signed ? (
                <Button variant="dark" href="/announce">
                  ANUNCIAR
                </Button>
              ) : (
                <div onClick={checkedLogin}>
                  <Button variant="dark">ANUNCIAR</Button>
                </div>
              )}
            </S.ButtonLine>
            <S.NavList>
              {linkList.map((item) => (
                <li key={item.id}>
                  <S.NavListItem href={item.link}>{item.name}</S.NavListItem>
                </li>
              ))}
            </S.NavList>
          </S.MenuExpandedContent>
        </S.MenuExpanded>
      </MediaMatch>
      <MediaMatch greaterThan={signed ? "1297px" : "1381px"}>
        <S.WrapperWeb>
          <S.WrapperWebContent>
            <a href="/">
              <S.Logo
                src={Logo}
                alt="Logomarca da empresa"
                width="123px"
                height="62px"
              />
            </a>
            <S.CenterContent>
              <SearchField />
              <div>
                <S.NavList>
                  {linkList.map((item) => (
                    <li key={item.id}>
                      <S.NavListItem
                        signed={signed}
                        href={item.link}
                        target={item.id === "blog" ? "_blank" : undefined}
                      >
                        {item.name.toUpperCase()}
                      </S.NavListItem>
                    </li>
                  ))}
                </S.NavList>
                <S.ButtonLine>
                  <Button variant="dark" href="/product/list/">
                    COMPRAR
                  </Button>
                  {signed ? (
                    <Button variant="dark" href="/announce">
                      ANUNCIAR
                    </Button>
                  ) : (
                    <>
                      <div onClick={checkedLogin}>
                        <Button variant="dark">ANUNCIAR</Button>
                      </div>
                      <Button
                        icon={<RegisterIcon />}
                        width="185px"
                        href="/register/user"
                      >
                        REGISTRAR
                      </Button>
                      <Button
                        icon={<UserIcon />}
                        width="150px"
                        href="/authentication"
                      >
                        ENTRAR
                      </Button>
                    </>
                  )}
                </S.ButtonLine>
              </div>
            </S.CenterContent>
            {signed && (
              <S.UserProfile>
                <AuthenticatedUser />
              </S.UserProfile>
            )}
          </S.WrapperWebContent>
        </S.WrapperWeb>
      </MediaMatch>
    </>
  )
}

export default Navbar
