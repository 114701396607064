/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react"
import { FormatPrice } from "../../helpers/format"

import { Button, Container, Figure, Form, Row } from "react-bootstrap"

import Navbar from "../../components/Navbar"
import Footer from "../../components/Footer"
import BankTransfer from "./bankTransfer"
import BankTicket from "./bankTicket"

import "./styles.css"
import { Link, Redirect, useHistory } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faAngleRight,
  faCheckCircle,
  faDotCircle,
} from "@fortawesome/free-solid-svg-icons"

import { useUserContext } from "../../providers/use-sign"
import { api } from "../../services/apiLeloae/config"
import { toast } from "react-toastify"
import axios from "axios"
import Loading from "../../components/Loading"
import { FormatError } from "../../helpers/formatError"

const ProductBuy: React.FC = (propos: any) => {
  const signal = axios.CancelToken.source()
  const { data } = useUserContext()

  const [buyProductLoading, setBuyProductLoading] = useState(false)
  const id_product = propos.match.params.id_product
  const [product, setProduct] = useState(null)
  const [buyProduct, setBuyProduct] = useState<any>({
    id_product: id_product,
    id_person: data.user.id_person,
    nr_type_payment: 1,
    nr_terms: 1,
    nr_status: 0,
  })

  const history = useHistory()

  const getProduct = async () => {
    try {
      const responseProduct: any = await api.get(
        `/product/show/${id_product}`,
        { cancelToken: signal.token }
      )

      setProduct(responseProduct.data?.message)
    } catch (error) {
      toast.error("Ocorreu algum erro", { toastId: 1 })
    }
  }

  const handleChangeForm = (event: any) => {
    const checked = event.target.checked
    setBuyProduct((field) => {
      return {
        ...field,
        nr_terms: checked === true ? 1 : 0,
      }
    })
  }

  const handleOptionPayment = (event: any) => {
    const value = event.target.value
    setBuyProduct((field) => {
      return {
        ...field,
        nr_type_payment: value,
      }
    })
  }

  const handleSendProposal = async (event: any) => {
    event.preventDefault()

    if (!buyProduct.nr_terms || buyProduct.nr_terms == 0) {
      return toast.error("Você aceitar os termos")
    }

    setBuyProductLoading(true)

    try {
      const responseSendProposal: any = await api.post(
        "/buy/product",
        buyProduct
      )
      if (responseSendProposal.data?.message) {
        toast.success("Compra efetuada com sucesso", { toastId: 1 })
        history.push(`/user/requests`)
      }
    } catch (error) {
      setBuyProductLoading(false)
      const errorFormat = FormatError(error)
      errorFormat.forEach((element) => {
        toast.error(element)
      })
    }
  }

  useEffect(() => {
    getProduct()
  }, [])

  if (!id_product) {
    return <Redirect to="/product"></Redirect>
  }
  return (
    <>
      {product && (
        <>
          {buyProductLoading && <Loading />}
          <Navbar />
          <Container>
            <div id="product-buy">
              <p>
                <Link to={`/product/detail/${product.id_product}`}>
                  Detalhes do lote
                </Link>{" "}
                <FontAwesomeIcon icon={faAngleRight} size="1x" /> Finalizar
                compra do lote{" "}
              </p>
              <Row>
                <h2 className="title-custom-h2 col-12 text-center">
                  Finalizar compra do lote
                </h2>
                <div className="col-12 text-center">
                  <button
                    onClick={handleOptionPayment}
                    className="btn btn-trans-option"
                    value="1"
                  >
                    <FontAwesomeIcon
                      icon={
                        buyProduct.nr_type_payment == 1
                          ? faCheckCircle
                          : faDotCircle
                      }
                      size="1x"
                    />
                    Transferência bancária
                  </button>
                  <button
                    onClick={handleOptionPayment}
                    className="btn btn-trans-option"
                    value="2"
                  >
                    <FontAwesomeIcon
                      icon={
                        buyProduct.nr_type_payment == 2
                          ? faCheckCircle
                          : faDotCircle
                      }
                      size="1x"
                    />
                    Boleto bancário
                  </button>
                </div>

                <div className="col-12 col-sm-12 data-deposit">
                  {buyProduct.nr_type_payment == 1 && <BankTransfer />}
                  {buyProduct.nr_type_payment == 2 && <BankTicket />}
                </div>
                <div className="col-12 col-sm-12 detail-product">
                  <div className="shadow mb-3 bg-white colunm row">
                    <h5 className="col-12 text-center">
                      <b>Detalhes do lote</b>
                    </h5>
                    <div className="col-12 col-sm-12 col-md-3">
                      <Figure>
                        <Figure.Image
                          src={product.ProductPhotos[0]?.des_product_photo_url}
                        />
                      </Figure>
                    </div>
                    <div className="col-12 col-sm-12 col-md-9 info-lots">
                      <div className="row titles">
                        <h6>{product.des_title} </h6>
                      </div>
                      <div className="description">
                        <p>{product.des_description}.</p>
                      </div>
                      <div className="row vl-show-details">
                        <p className="type-coin text-center text-sm-center text-md-left">
                          R$
                        </p>
                        <div className="col-sm-12 col-md-4 text-center text-sm-center text-md-left">
                          <h3 className="vl">
                            {FormatPrice(product.vl_price_total)}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 text-center finish-buy">
                  <div className="shadow mb-3 bg-white colunm">
                    <Form onSubmit={handleSendProposal}>
                      <Form.Group
                        controlId="nr_terms"
                        className="row justify-content-center"
                      >
                        <Form.Check
                          type="checkbox"
                          name="nr_terms"
                          defaultChecked={Boolean(buyProduct.nr_terms)}
                          onClick={handleChangeForm}
                        />
                        <Form.Label>
                          Ao relizar uma compra você concorda e aceita os{" "}
                          <Link to="/terms">Termos e Condições do Leiloaẽ</Link>
                        </Form.Label>
                      </Form.Group>
                      <Button className="btn btn-create" type="submit">
                        Finalizar compra
                      </Button>
                      <Link
                        to={`/product/detail/${product.id_product}`}
                        className="btn btn-cancel"
                      >
                        Cancelar
                      </Link>
                    </Form>
                  </div>
                </div>
              </Row>
            </div>
          </Container>
          <Footer />
        </>
      )}
    </>
  )
}
export default ProductBuy
