/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';

import Header from '../../../components/HeaderAdmin'
import Sidebar from '../SideNav'

import 'rc-pagination/assets/index.css'
import './styles.css'

import {Col, Container, FormControl, InputGroup, Row} from 'react-bootstrap';
import { checkDevice } from '../../../helpers/utils';
import { api } from '../../../services/apiLeloae/config';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faIdCard, faPhone, faSearch, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FormatCpf, FormatPhone, onlyNumbers } from '../../../helpers/format';

const LotsCreateSearchUser: React.FC = () => {
  const [expandedResume, setExpandedResume] = useState({ expanded: null })
  const [user, setUser] = useState<any>()
  const [cpf, setCpf] = useState<any>('')

  const handleSetCpf = (async (event: any) => {
      const value = event.target.value
      setCpf(onlyNumbers(value))
  })

  const handleSearchUser = (async () => {
    if(cpf.length == 11){
      try {
          const responseListUsers: any = await api.get('/admin/user/search/show', {params: {search: cpf} })
          setUser(responseListUsers?.data?.message)
      } catch (error) {
      toast.error('Error e buscar usuário', {toastId: 1})
      }
    }
})

  useEffect(() => {
    handleSearchUser()
  },[cpf])

  useEffect(() => {
    setExpandedResume({expanded: checkDevice()})
  },[])

  return (
    <>
      <Container fluid id="lots-create-search-user">
          <Row>  
              <Sidebar />
              <Header />
                <div className={expandedResume.expanded ? 'resume' : 'resume-mobile'}>
                  <Row className="row-lots-create-search-user">
                        <Container className="col-12">
                            <Col sm={12}>
                                <Row>
                                    <InputGroup className="col-sm-12 col-lg-12">
                                        <FormControl
                                        placeholder="Digite CPF do usuário"
                                        aria-label="Digite ID ou CPF do usuário"
                                        aria-describedby="basic-addon2"
                                        onChange={handleSetCpf}
                                        value={FormatCpf(cpf)}
                                        />
                                        <InputGroup.Append>
                                            <FontAwesomeIcon className="icon-search" icon={faSearch} size="2x" />
                                        </InputGroup.Append>
                                    </InputGroup>                                        
                                </Row>
                            </Col>
                            {user &&
                              <>
                                <div className="col-sm-12">
                                  <div id="user" className="text-justify">
                                      <p><FontAwesomeIcon icon={faUserCircle} size="1x" /> {user?.Person?.des_person +' '+ user?.Person?.des_lest_person} </p>
                                      <hr/>
                                      <p><FontAwesomeIcon icon={faIdCard} size="1x" /> {FormatCpf(user?.Person?.nr_cpf)}</p>
                                      <hr/>
                                      <p><FontAwesomeIcon icon={faEnvelope} size="1x" /> {user?.des_email}</p>
                                      <hr/>
                                      <p><FontAwesomeIcon icon={faPhone} size="1x" /> {FormatPhone(user?.nr_phone)}</p>
                                      <hr/>
                                  </div>
                                </div>
                                <a href={`/admin/create/announce/lots/${user?.id_person}`} className="btn btn-primary">Cadastrar lote</a>
                              </>
                            }
                        </Container>
                  </Row>
                </div>
          </Row>

      </Container>
    </>
  );
}
export default LotsCreateSearchUser;
