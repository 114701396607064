/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */

import React, { useEffect, useState } from "react"
import { FormatPrice, FormatPriceInput } from "../../helpers/format"

import { Button, Container, Figure, Form, Row } from "react-bootstrap"

import Navbar from "../../components/Navbar"
import Footer from "../../components/Footer"

import "./styles.css"
import { Link, Redirect, useHistory } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"

import { useUserContext } from "../../providers/use-sign"
import { api } from "../../services/apiLeloae/config"
import { toast } from "react-toastify"
import axios from "axios"
import Loading from "../../components/Loading"
import { FormatError } from "../../helpers/formatError"

const ProductSendProposal: React.FC = (propos: any) => {
  const signal = axios.CancelToken.source()
  const { data } = useUserContext()

  const [sendProposalLoading, setSendProposalLoading] = useState(false)
  const id_product = propos.match.params.id_product
  const [product, setProduct] = useState(null)
  const [sendProposal, setSendProposal] = useState<any>({
    id_product: id_product,
    id_person: data.user?.id_person,
    vl_price: "",
    des_justification: "",
  })

  const history = useHistory()

  const handleChangeForm = (event: any) => {
    const name = event.target.name
    const value = event.target.value

    setSendProposal((field) => {
      return {
        ...field,
        [`${name}`]: value,
      }
    })

    if (name === "vl_price") {
      setSendProposal((field) => {
        return {
          ...field,
          [`${name}`]: FormatPriceInput(value),
        }
      })
    }
  }

  const getProduct = async () => {
    try {
      const responseProduct: any = await api.get(
        `/product/show/${id_product}`,
        { cancelToken: signal.token }
      )
      setProduct(responseProduct.data?.message)
    } catch (error) {
      toast.error("Ocorreu algum erro", { toastId: 1 })
    }
  }

  const handleSendProposal = async (event: any) => {
    event.preventDefault()

    if (!sendProposal.vl_price || sendProposal.vl_price == "") {
      return toast.error("Você precisa informa um valor")
    }

    setSendProposalLoading(true)

    try {
      const responseSendProposal: any = await api.post(
        "/negotiation/request/product",
        sendProposal
      )

      if (responseSendProposal.data?.message) {
        toast.success("Proposta enviada com sucesso", { toastId: 1 })
        history.push(`/user/requests/negotiation`)
      }
    } catch (error) {
      setSendProposalLoading(false)
      const errorFormat = FormatError(error)
      errorFormat.forEach((element) => {
        toast.error(element)
      })
    }
  }

  useEffect(() => {
    getProduct()
  }, [])

  if (!id_product) {
    return <Redirect to="/product"></Redirect>
  }
  return (
    <>
      {product && (
        <>
          {sendProposalLoading && <Loading />}
          <Navbar />
          <Container>
            <div id="product-buy">
              <p>
                <Link to={`/product/detail/${product.id_product}`}>
                  Detalhes do lote
                </Link>{" "}
                <FontAwesomeIcon icon={faAngleRight} size="1x" /> Enviar
                proposta{" "}
              </p>
              <Row>
                <h2 className="title-custom-h2 col-12 text-center">
                  Qual é sua proposta?
                </h2>
                <h5 className="col-12 text-center">
                  Sua oferta será analisada e retornaremos em breve.
                </h5>

                <div className="col-12 col-sm-12 detail-product">
                  <div className="shadow mb-3 bg-white colunm row">
                    <h5 className="col-12 text-center">
                      <b>Detalhes do lote</b>
                    </h5>
                    <div className="col-12 col-sm-12 col-md-3">
                      <Figure>
                        <Figure.Image
                          src={product.ProductPhotos[0]?.des_product_photo_url}
                        />
                      </Figure>
                    </div>
                    <div className="col-12 col-sm-12 col-md-9 info-lots">
                      <div className="row titles">
                        <h6>{product.des_title} </h6>
                      </div>
                      <div className="description">
                        <p>{product.des_description}.</p>
                      </div>
                      <div className="row vl-show-details">
                        <p className="type-coin text-center text-sm-center text-md-left">
                          R$
                        </p>
                        <div className="col-sm-12 col-md-4 text-center text-sm-center text-md-left">
                          <h3 className="vl">
                            {FormatPrice(product.vl_price_total)}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 text-center finish-buy">
                  <div className="shadow mb-3 bg-white colunm">
                    <p>
                      Informe abaixo sua proposta de negociação, os
                      administradores irão analisar e retornar em breve.
                    </p>
                    <Form onSubmit={handleSendProposal}>
                      <Form.Group
                        controlId="vl_price"
                        className="col-sm-12 col-md-4"
                      >
                        <Form.Control
                          type="text"
                          name="vl_price"
                          placeholder="(R$) Valor de negociação"
                          value={
                            sendProposal.vl_price
                              ? FormatPriceInput(sendProposal.vl_price)
                              : ""
                          }
                          onChange={handleChangeForm}
                        />
                      </Form.Group>
                      <Form.Group
                        controlId="des_justification"
                        className="col-sm-12"
                      >
                        <Form.Control
                          name="des_justification"
                          as="textarea"
                          rows={Number(4)}
                          placeholder="Gostaria de comentar? (opcional)"
                          value={sendProposal.des_justification}
                          onChange={handleChangeForm}
                        />
                      </Form.Group>
                      <Button className="btn btn-create" type="submit">
                        Enviar proposta
                      </Button>
                      <Link
                        to={`/product/detail/${product.id_product}`}
                        className="btn btn-cancel"
                      >
                        Cancelar
                      </Link>
                    </Form>
                  </div>
                </div>
              </Row>
            </div>
          </Container>
          <Footer />
        </>
      )}
    </>
  )
}
export default ProductSendProposal
