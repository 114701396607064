import React from 'react';
import { useHistory } from 'react-router-dom'

import { useUserContext } from '../../providers/use-sign'

import { Nav, NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserAlt, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

import imgNoProfilePhoto from '../../assets/image/noprofilephoto2.png'

const AuthenticatedUser: React.FC = () => {
    const { signingOut, data }  = useUserContext()
    const history = useHistory()

    const logout = () => {
        signingOut()
        history.push('/')
    }

  return (
    <>
    <div className="col-12 col-lg-3" style={{zIndex: 1000, marginTop: '10px'}}>
        <Nav>
            <div id="nav-dropdown-custom">
                <img src={data.user.Person.ProfilePhoto?.des_profile_photo_url ? data.user.Person.ProfilePhoto.des_profile_photo_url : imgNoProfilePhoto} className="rounded-circle image-profile-icon" alt=""/>
                <NavDropdown title={data.user.Person.des_person} id="basic-nav-dropdown">
                    <div id="image-profile-icon-dropdown">
                        <img src={data.user.Person.ProfilePhoto?.des_profile_photo_url ? data.user.Person.ProfilePhoto.des_profile_photo_url : imgNoProfilePhoto} className="rounded-circle image-profile-icon" alt="profile-img"/>
                        <pre>{data.user.Person.des_person}</pre>
                        <pre>{data.user.des_email}</pre>
                    </div>
                    <NavDropdown.Divider />
                    {data.user?.des_type_user === 'inadmin' ? 
                        <NavDropdown.Item href="/admin"><FontAwesomeIcon icon={faUserAlt} size="1x" /> Administração</NavDropdown.Item>
                    : 
                        <NavDropdown.Item href="/user"><FontAwesomeIcon icon={faUserAlt} size="1x" /> Gerenciar minha conta</NavDropdown.Item>
                    }
                    <NavDropdown.Item onClick={logout}><FontAwesomeIcon icon={faSignOutAlt} size="1x" /> Sair</NavDropdown.Item>
                </NavDropdown>
            </div>
        </Nav>
    </div>
    </>
  );
}
export default AuthenticatedUser;
