/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';

import { PieChart, ColumnChart } from 'react-chartkick'
import 'chart.js'

import './styles.css'
import { Col} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserFriends, faThumbsUp, faHandHoldingUsd, faGavel, faHandshake } from '@fortawesome/free-solid-svg-icons';
import { api } from '../../../services/apiLeloae/config';
import { FormatError } from '../../../helpers/formatError';
import { toast } from 'react-toastify';
import Loading from '../../../components/Loading';

const Resume: React.FC = () => {
    const [listRequest, setListRequest] = useState([])
    const [listNegotiation, setListNegotiation] = useState([])
    const [ProductLoading, setProductLoading] = useState(false)

    const year = new Date().getFullYear()

    const getListRequest = async () => {
        setProductLoading(true)
        try {
            const product: any = await api.get('/admin/buy/product')
            setListRequest(product.data?.message)
            setProductLoading(false)
        } catch (error) {
            setProductLoading(false)
            const errorFormat = FormatError(error)
            errorFormat.forEach(element => {
              toast.error(element)
            });
        }
    }

    const getListNegotiation = async () => {
        setProductLoading(true)
        try {
            const product: any = await api.get('/admin/negotiation/request/product')
            setListNegotiation(product.data?.message)
            setProductLoading(false)
        } catch (error) {
            setProductLoading(false)
            const errorFormat = FormatError(error)
            errorFormat.forEach(element => {
              toast.error(element)
            });
        }
    }

    const handleRequestStatus = (id: number) => {
        const requestStatus = listRequest.filter(requestStatus => parseInt(requestStatus.nr_status) === id).length
        return requestStatus
    }

    const handleRequestDate = (dateStart: String, dateEnd: String) => {
        const requestDate = listRequest.filter(requestDate => requestDate.createdAt >= dateStart && requestDate.createdAt <= dateEnd && requestDate.nr_status === 4).length
        return requestDate
    }

    useEffect(() => {
        getListRequest()
        getListNegotiation()
    }, [])

  return (
    <>
    {ProductLoading && 
        <Loading />
    }
        <Col sm={12} lg={4}>
            <Col sm={12} className="shadow mb-3 bg-white colunm done-deal">
                <div className="icon-user"><FontAwesomeIcon icon={faUserFriends} size="3x" /></div>
                <div className="description">
                    <h1>{handleRequestStatus(0)}</h1>
                    <p>Pedidos realizado</p>
                </div>
            </Col>
            <Col sm={12} className="shadow mb-3 bg-white colunm done-deal">
                <div className="icon-user"><FontAwesomeIcon icon={faHandshake} size="3x" /></div>
                <div className="description">
                    <h1>{handleRequestStatus(1)}</h1>
                    <p>Negócios fechados</p>
                </div>
            </Col>
            <Col sm={12} className="shadow mb-3 bg-white colunm done-deal">
                <div className="icon-user"><FontAwesomeIcon icon={faThumbsUp} size="3x" /></div>
                <div className="description">
                    <h1>{handleRequestStatus(2)}</h1>
                    <p>Aceites pelo comprador</p>
                </div>
            </Col>
            <Col sm={12} className="shadow mb-3 bg-white colunm done-deal">
                <div className="icon-user"><FontAwesomeIcon icon={faHandHoldingUsd} size="3x" /></div>
                <div className="description">
                    <h1>{handleRequestStatus(3)}</h1>
                    <p>pagamentos efetuados</p>
                </div>
            </Col>
            <Col sm={12} className="shadow mb-3 bg-white colunm done-deal">
                <div className="icon-user"><FontAwesomeIcon icon={faGavel} size="3x" /></div>
                <div className="description">
                    <h1>{handleRequestStatus(4)}</h1>
                    <p>Negociações concluídas</p>
                </div>
            </Col>
        </Col>
        <Col sm={12} lg={4} className="text-center">
            <div className="shadow mb-3 bg-white sales-category colunm">
            <h4>Vendas por categoria</h4>
            <div className="container">
                <PieChart donut={true} data={{"Pedidos de venda": listRequest.length, "Solicitação negociação": listNegotiation.length}} />
            </div>
            </div>
        </Col>
        <Col sm={12} lg={4} className="text-center">
            <div className="shadow mb-3 bg-white sales-months colunm">
            <h4>Vendas dos Últimos meses</h4>
            <div className="container">
                <ColumnChart data={[["Janeiro", handleRequestDate(`${year}-01-01`, `${year}-01-31`)], ["Fevereiro", handleRequestDate(`${year}-02-01`, `${year}-02-29`)], ["Março", handleRequestDate(`${year}-03-01`, `${year}-03-31`)],
                ["Abril", handleRequestDate(`${year}-04-01`, `${year}-04-30`)], ["Maio", handleRequestDate(`${year}-05-01`, `${year}-05-31`)], ["Junho", handleRequestDate(`${year}-06-01`, `${year}-06-30`)], 
                ["Julho", handleRequestDate(`${year}-07-01`, `${year}-07-31`)], ["Agosto", handleRequestDate(`${year}-08-01`, `${year}-08-31`)], ["Setembro", handleRequestDate(`${year}-09-01`, `${year}-09-30`)], 
                ["Outubro", handleRequestDate(`${year}-10-01`, `${year}-10-31`)], ["Novembro", handleRequestDate(`${year}-11-01`, `${year}-11-30`)], ["Dezembro", handleRequestDate(`${year}-12-01`, `${year}-12-31`)]]} />
            </div>
            </div>
        </Col>
    </>
  );
}
export default Resume;
