import React from "react"

import Footer from "../../components/Footer"
import Navbar from "../../components/Navbar"

import "./styles.css"
import { Accordion, Button, Card, Container } from "react-bootstrap"

const Helps: React.FC = () => {
  return (
    <>
      <Navbar />
      <Container>
        <div id="help-me">
          <h2 className="text-center title-custom-h2">Central de Ajuda</h2>
          <p className="description-title">
            Encontre respostas sobre os recursos da plataforma, aqui temos as
            perguntas mais frequentes e outras informações técnicas e sobre a
            operação da Leiloaê.
          </p>
          <Accordion defaultActiveKey="0">
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                  <b>01</b> - Como crio uma conta?
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  Acesse a página inicial da Leiloaê, e clique no botão “Criar
                  conta”. Em seguida, basta preencher os seus dados nos campos
                  solicitados, conferir as informações, e confirmar o seu
                  cadastro.
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                  <b>02</b> - Como cadastro meus lotes/animais?
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  Acesse a página inicial da Leiloaê, e clique no botão
                  “Anunciar”. Em seguida, responda algumas informações a
                  respeito do lote, preencha os campos solicitados sobre as
                  características dos animais, e informe a localização destes.
                  Por fim, adicione imagens e vídeos dos animais,clique em
                  finalizar, e o anúncio estará pronto.
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                  <b>03</b> - Como compro um lote?
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  Acesse a página inicial da Leiloaê, e clique no botão
                  “Produtos”. Em seguida, escolha o lote que deseja comprar e
                  clique em “Ver detalhes”, caso esteja de acordo com seu
                  interesse, clique em “Comprar lote”, escolha a forma de
                  pagamento, e finalize a compra. (que logo em seguida entramos
                  em contato para agendar a retirada dos animais)
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                  <b>04</b> - Como consigo enviar um pedido de negociação?
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  Acesse a página inicial da Leiloaê, e clique no botão
                  “Produtos”. Em seguida, escolha o lote que deseja negociar e
                  clique em “Ver detalhes”, caso esteja de acordo com seu
                  interesse, clique em “Enviar pedido de negociação”, preencha o
                  campo solicitado com o valor que deseja pagar, e envie a
                  proposta para análise.
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="4">
                  <b>05</b> - Como funciona a segurança da Leiloaê?
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="4">
                <Card.Body>
                  Garantimos os seus bens através do nosso mecanismo de
                  segurança já validado pelo mercado livre, onde o comprador
                  somente poderá agendar a retirada dos animais após o dinheiro
                  estar com a Leiloaê, e somente será repassado ao vendedor após
                  a conferência e o aceite do lote pelo comprador. Assim que
                  houver a confirmação do lote pelo comprador, no momento em que
                  ele for ver os animais, transferimos o dinheiro na hora para o
                  senhor, antes mesmo dele embarcar.
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="5">
                  <b>06</b> - É possível vender meus animais por fora da
                  plataforma após o cadastro no site?
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="5">
                <Card.Body>
                  Sim, caso os seus animais tenham sido negociados fora da
                  plataforma, o senhor deve apenas nos informar para que o
                  anúncio seja retirado do site.
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="6">
                  <b>07</b> - Quais as formas de pagamento a Leiloaê aceita?
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="6">
                <Card.Body>
                  O pagamento pode ser realizado através de boleto (até 3 dias),
                  transferência bancária (até 4 horas) ou PIX (até 10 segundos).
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="7">
                  <b>08</b> - Como eu sei que recebi o dinheiro?
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="7">
                <Card.Body>
                  Caso tenha acesso a internet o senhor poderá acompanhar o
                  depósito em sua conta pelo celular, caso não tenha, poderá
                  confirmar o depósito com o seu gerente de banco através de uma
                  ligação. Lembrando que a Leiloaê é uma empresa que possui
                  CNPJ, consolidada com parcerias fortes como Sicoob, assim
                  garantimos o seu dinheiro.
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="8">
                  <b>09</b> - Terei contato com o comprador?
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="8">
                <Card.Body>
                  Somente na hora da retirada, prezamos pela identidade de
                  nossos clientes e atuamos como agente intermediário.
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="9">
                  <b>10</b> - Como funciona o transporte do gado?
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="9">
                <Card.Body>
                  O transporte fica por conta do comprador, a menos que seja
                  ofertado por você (vendedor)
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="10">
                  <b>11</b> - Qual é a localização da empresa?
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="10">
                <Card.Body>
                  Nossa sede fica em Dois VIzinhos PR, porém atuamos no Brasil
                  inteiro.
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>
      </Container>
      <Footer />
    </>
  )
}
export default Helps
