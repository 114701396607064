/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import './styles.css'

import Header from '../../../components/HeaderAdmin'
import Sidebar from '../SideNav'

import imgVideoIcon from '../../../assets/image/video-icon.png'

import { Button, Col, Container, Modal, Row} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faCheckSquare, faMinusSquare } from '@fortawesome/free-solid-svg-icons';

import { checkDevice } from '../../../helpers/utils';
import { FormatError } from '../../../helpers/formatError';
import { toast } from 'react-toastify';
import { api } from '../../../services/apiLeloae/config';
import Loading from '../../../components/Loading';
import { FormatDatePtBr, FormatPrice, onlyNumbers } from '../../../helpers/format';
import { Carousel } from 'react-responsive-carousel';

const RequestDetail: React.FC = (propos: any) => {
    const id_product = propos.match.params.id_product
    const history = useHistory()

    const [expandedResume, setExpandedResume] = useState({ expanded: null })
    const [ProductLoading, setProductLoading] = useState(false)
    const [product, setProduct] = useState(null)
    const [showModalCarousel, setShowModalCarousel] = useState(false);

    const handleClose = () => setShowModalCarousel(false);
    const handleShow = () => setShowModalCarousel(true);

    const getProduct = async () => {
        setProductLoading(true)
        try {
            const product: any = await api.get(`/admin/product/show/${id_product}`)

            setProduct(product.data?.message)

            setProductLoading(false)
        } catch (error) {
            setProductLoading(false)
            const errorFormat = FormatError(error)
            errorFormat.forEach(element => {
              toast.error(element)
            });
        }
    }

    useEffect(() => {
        getProduct()
    }, [])

    useEffect(() => {
      setExpandedResume({expanded: checkDevice()})
    },[])

  if(!id_product){
    return <Redirect to="/admin/requests"></Redirect>
}

  return (
    <> 
    <Container fluid id="request-detail">
        <Row>  
            <Sidebar />
            <Header />
            <div className={expandedResume.expanded ? 'resume' : 'resume-mobile'}>
                <Col sm={12} className="shadow mb-3 bg-white colunm nav">
                      <Button className="btn-link" onClick={() => history.go(-1)}>Resumo</Button>
                      <Link className="btn-link" to={`/admin/requests/detail/${id_product}`}>Dados do lote</Link>
                </Col>
                <Col sm={12} className="shadow mb-3 bg-white data-lot-request">
                    <Modal show={showModalCarousel} onHide={handleClose} >
                        <Modal.Header closeButton />
                        {product?.ProductVideos[0]?.des_product_video ?
                            <>
                                <Carousel showThumbs={true} showStatus={true} useKeyboardArrows className="presentation-mode" >
                                    {product?.ProductPhotos.map((image: any) =>
                                        <div key={image.id_product_photo}>
                                            <img src={image.des_product_photo_url} />
                                        </div>
                                    )}
                                    <div key="video-1" className="my-slide content">
                                        <iframe width="560" height="400" src={`https://player.vimeo.com/video/${onlyNumbers(product?.ProductVideos[0].des_product_video)}`} />
                                        <img src={imgVideoIcon} className="thumb-img-video" />
                                    </div>
                                    
                                </Carousel>
                            </>
                        :
                            <>
                                <Carousel showThumbs={true} showStatus={true} useKeyboardArrows className="presentation-mode">
                                    {product?.ProductPhotos.map((image: any) =>
                                        <div key={image.id_product_photo}>
                                            <img src={image.des_product_photo_url} />
                                        </div>
                                    )}
                                </Carousel>
                            </>
                        }
                    </Modal>
                    <Row>
                        {product && (
                            <>
                            {ProductLoading && 
                                <Loading />
                            }
                            <Col sm={12} md={12} lg={6}>
                                <b>{product?.des_title}</b>
                                <p>{product?.des_description}</p>
                                <Row className="info-lote">
                                    <Col sm={6}>
                                    <b>Tipo do produto:</b>
                                    <p>{product?.Animal?.TypeAnimalProduct?.des_type_product_animal}</p>
                                    </Col>
                                    <Col sm={6}>
                                    <b>Categoria:</b>
                                    <p>{product?.Animal?.AnimalCategory?.des_category}</p>
                                    </Col>
                                    <Col sm={6}>
                                    {product?.Animal.id_custom_races ?
                                        <>
                                            <b>Raças cruzadas:</b>
                                            {product?.Animal.AnimalCustomRaces.map((data: any) =>
                                                <div key={data.id_races} style={{marginLeft: '1%'}} className="shadow-sm  bg-light rounded colum-light-gray row">
                                                    <b className="col-md-4 col-6"> {data.AnimalRace.des_races} </b> <p> {data.nr_percentage}%</p> 
                                                </div>
                                            )}
                                        </> 
                                        :
                                        <><b>Raça:</b><p> {product?.Animal.AnimalRace.des_races}</p></> 
                                    }
                                    </Col>
                                    <Col sm={6}>
                                    <b>Quantidade:</b>
                                    <p>{product?.qtd_animals} Animais</p>
                                    </Col>
                                    <Col sm={6}>
                                    <b>Peso:</b>
                                    <p>{product?.nr_weight} Kg</p>
                                    </Col>
                                    <Col sm={6}>
                                    <b>Intervalo de peso:</b>
                                    <p>{product?.Animal?.AnimalAverageWeight?.des_average_weight}</p>
                                    </Col>
                                    <Col sm={6}>
                                    <b>Sexo:</b>
                                    <p>{product?.Animal?.AnimalSex.des_animal_sex}</p>
                                    </Col>
                                    {Boolean(product?.Animal?.nr_pregnancy) &&
                                        <Col sm={6}>
                                        <b>Prenhez:</b>
                                        <p>{product?.Animal?.nr_pregnancy === 1 ? 'Sim' : 'Não'}</p>
                                        </Col>
                                    }
                                    {Boolean(product?.Animal?.nr_pregnancy_days) &&
                                        <Col sm={6}>
                                        <b>Gestação:</b>
                                        <p>{product?.Animal?.nr_pregnancy_days + 'Dias'}</p>
                                        </Col>
                                    }
                                    {Boolean(product?.Animal?.nr_liters_day) &&
                                        <Col sm={6}>
                                        <b>Litros produzidos/Dia:</b>
                                        <p>{product?.Animal?.nr_liters_day}</p>
                                        </Col>
                                    }
                                    {Boolean(product?.Animal?.nr_lactations) &&
                                        <Col sm={6}>
                                        <b>Nº Lactações:</b>
                                        <p>{product?.Animal?.nr_lactations}</p>
                                        </Col>
                                    }
                                    {Boolean(product?.Animal?.nr_age_months) &&
                                        <Col sm={6}>
                                        <b>Nº Idade:</b>
                                        <p>{product?.Animal?.nr_age_months + ' Meses'}</p>
                                        </Col>
                                    }
                                    <Col sm={6}>
                                    <b>Unidade:</b>
                                    <p>{product?.TypeCalculationAnimal?.des_type_calculation_animal}</p>
                                    </Col>
                                    <Col sm={6}>
                                    <b>Data do cadastro:</b>
                                    <p>{FormatDatePtBr(product?.createdAt)}</p>
                                    </Col>
                                    <Col sm={6}>
                                    <b>Valor por {product?.TypeCalculationAnimal?.des_type_calculation_animal}:</b>
                                    <p>{FormatPrice(product?.vl_price)}</p>
                                    </Col>
                                    <Col sm={6}>
                                    <b>Valor do lote:</b>
                                    <p>{FormatPrice(product?.vl_price_total)}</p>
                                    </Col>
                                </Row>
                                <hr/>
                                <Row>
                                    <Col sm={6}>
                                    <b>Propriedade de:</b>
                                    <p>{product?.Person?.des_person} {product?.Person?.des_lest_person}</p>
                                    </Col>
                                    <Col sm={12}>
                                    <b>Cidade - UF:</b>
                                    <p>{product?.AddressProduct?.City?.des_city +' - '+ product?.AddressProduct?.State?.uf }</p>
                                    </Col>
                                    <Col sm={12}>
                                    <b>Quantos Km da cidade o lote se encontra?</b>
                                    <p>{product?.AddressProduct?.nr_kilometers +' KM'}</p>
                                    </Col>
                                    <Col sm={12}>
                                    <Link to="#"><FontAwesomeIcon icon={faDownload} size="1x" /> Baixar documentos em anexo</Link>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={12} md={12} lg={6}>
                                <Col sm={12}>
                                    {product?.ProductVideos[0]?.des_product_video ?
                                        <>
                                            <Carousel showThumbs={true} showStatus={true} useKeyboardArrows className="presentation-mode">
                                                {product?.ProductPhotos.map((image: any) =>
                                                    <div key={image.id_product_photo} onClick={handleShow}>
                                                        <img src={image.des_product_photo_url} />
                                                    </div>
                                                )}
                                                <div key="video-1" className="my-slide content">
                                                    <iframe width="560" height="315" src={`https://player.vimeo.com/video/${onlyNumbers(product?.ProductVideos[0].des_product_video)}`} />
                                                    <img src={imgVideoIcon} className="thumb-img-video" />
                                                </div>
                                                
                                            </Carousel>
                                        </>
                                    :
                                        <>
                                            <Carousel showThumbs={true} showStatus={true} useKeyboardArrows className="presentation-mode">
                                                {product?.ProductPhotos.map((image: any) =>
                                                    <div key={image.id_product_photo} onClick={handleShow}>
                                                        <img src={image.des_product_photo_url} />
                                                    </div>
                                                )}
                                            </Carousel>
                                        </>
                                    }
                                </Col>
                                <Col sm={12}>
                                    <p><FontAwesomeIcon icon={product?.nr_shipper_access === 1 ? faCheckSquare : faMinusSquare} size="1x" /> Possui embarcador</p>
                                    <p><FontAwesomeIcon icon={product?.nr_balance_access === 1 ? faCheckSquare : faMinusSquare} size="1x" /> Possui balança</p>
                                    <p><FontAwesomeIcon icon={product?.nr_truck_access === 1 ? faCheckSquare : faMinusSquare} size="1x" /> Possui acesso a caminhões</p>
                                </Col>
                            </Col>
                            </>
                        )}
                    </Row>
                </Col>
            </div>
        </Row>
    </Container>
    </>
  );
}
export default RequestDetail;
