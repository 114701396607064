/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';

import Header from '../../../components/HeaderAdmin'
import Sidebar from '../SideNav'
import List from './list'
import Resume from './resume'

import './styles.css'
import { Button, Col, Container, Row} from 'react-bootstrap';
import { checkDevice } from '../../../helpers/utils';
import { useStateGlobalContext } from "../../../providers/state-global";


const Requests: React.FC = (props: any) => {
  const { adminRequestNavOption } = useStateGlobalContext()

  const [expandedResume, setExpandedResume] = useState({ expanded: null })
  const [navOption, setNavOption] = useState(<Resume/>)

  useEffect(() => {
    setExpandedResume({expanded: checkDevice()})
  },[])

  useEffect(() => {
    if(adminRequestNavOption === 2){
      setNavOption(<List/>)
    }
  },[adminRequestNavOption])

  const handleNavOption = (event: any) => {
    const value = event.target.value

    if(value == 1){
      setNavOption(<Resume/>)
    }else{
      setNavOption(<List/>)
    }
  }

  return (
    <>
      <Container fluid id="requests">
          <Row>  
              <Sidebar />
              <Header />
                <div className={expandedResume.expanded ? 'resume' : 'resume-mobile'}>
                  <Row className="row-requests">
                    <Col sm={12} className="shadow mb-3 bg-white colunm nav">
                      <Button value="1" onClick={handleNavOption} className="btn-link">Resumo</Button>
                      <Button value="2" onClick={handleNavOption} className="btn-link">Listagem</Button>
                    </Col>
                    {navOption}
                  </Row>
                </div>
          </Row>
      </Container>
    </>
  );
}
export default Requests;
