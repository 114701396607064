/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react"

import {
  FormatDateHoursPtBr,
  FormatDatePtBr,
  FormatPrice,
  onlyNumbers,
} from "../../../helpers/format"

import { Button, Container, ProgressBar } from "react-bootstrap"
import { Link, Redirect } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"

import Navbar from "../../../components/Navbar"
import Footer from "../../../components/Footer"
import Loading from "../../../components/Loading"

import checked_status from "../../../assets/image/checked_status.png"

import "./styles.css"

import { FormatError } from "../../../helpers/formatError"
import { toast } from "react-toastify"
import { api } from "../../../services"
import { api as apiConfig } from "../../../services/apiLeloae/config"

import { useUserContext } from "../../../providers/use-sign"

const UserRequestDetail: React.FC = (propos: any) => {
  const { data } = useUserContext()

  const [ProductLoading, setProductLoading] = useState(false)
  const id_buy_product = propos.match.params.id_buy_product
  const [product, setProduct] = useState<any>(null)

  const getProduct = async () => {
    setProductLoading(true)
    try {
      const product = await api.apiLeloae.product.getUserRequest<any>(
        data?.user.id_person,
        id_buy_product
      )

      setProduct(product.data?.message)

      setProductLoading(false)
    } catch (error) {
      setProductLoading(false)
      const errorFormat = FormatError(error)
      errorFormat.forEach((element) => {
        toast.error(element)
      })
    }
  }

  const handleAcceptLot = async (event: any) => {
    event.preventDefault()
    setProductLoading(true)

    try {
      await apiConfig.put(`/buy/product/update/${product?.id_buy_product}`, {
        ...product,
        nr_status: 3,
      })
      getProduct()
      toast.success("Lote aceito com sucesso!", { toastId: 1 })
      setProductLoading(false)
    } catch (error) {
      setProductLoading(false)

      toast.error("Ocorreu um erro ao aceitar o lote.", { toastId: 1 })
    }
  }

  useEffect(() => {
    getProduct()
  }, [])

  if (!id_buy_product) {
    return <Redirect to="/user/requests"></Redirect>
  }
  return (
    <>
      {ProductLoading && <Loading />}
      <Navbar />
      <Container>
        <div id="request-detail-user">
          <p>
            <Link to="/user">Gerenciar minha conta</Link>{" "}
            <FontAwesomeIcon icon={faAngleRight} size="1x" />{" "}
            <Link to="/user/requests">Meus pedidos</Link>{" "}
            <FontAwesomeIcon icon={faAngleRight} size="1x" /> Detalhes do pedido
          </p>
          <h3 className="text-center title-custom-h3">Detalhes do pedido</h3>
          <div id="detail_status" className="shadow mb-3 bg-white">
            <div className="text-progress">
              <p id="text-1">{FormatDateHoursPtBr(product?.updatedAt)}</p>
            </div>
            <div className="d-block d-lg-none">
              <div className="row justify-content-center bar-progress-vertical">
                <div className="col-6 text-right">
                  {product?.nr_status >= 1 ? (
                    <p id="text-vertical-status-1">Negócio fechado</p>
                  ) : (
                    <p id="text-vertical-status-1">Aguardando negócio</p>
                  )}
                  {product?.nr_status >= 2 ? (
                    <p id="text-vertical-status-2">Pagamento efetuado</p>
                  ) : (
                    <p id="text-vertical-status-2">Aguardando pagamento</p>
                  )}
                  {product?.nr_status >= 3 ? (
                    <p id="text-vertical-status-3">
                      Aceite do lote pelo comprador
                    </p>
                  ) : (
                    <p id="text-vertical-status-3">
                      Aceite do lote pelo comprador
                    </p>
                  )}
                  {product?.nr_status >= 4 ? (
                    <p id="text-vertical-status-4">Negociação concluída</p>
                  ) : (
                    <p id="text-vertical-status-4">Negociação concluída</p>
                  )}
                </div>
                <div className="col-6 row justify-content-left">
                  <div className="icon-progress ">
                    {product?.nr_status >= 1 ? (
                      <img
                        src={checked_status}
                        className="icon-vertical-1 "
                        alt="icon-vertical-1"
                      />
                    ) : (
                      <div className="cicle-icon-1 cicle-icon-extra-color icon-vertical-1 ">
                        1
                      </div>
                    )}
                    {product?.nr_status >= 2 ? (
                      <img
                        src={checked_status}
                        className="icon-vertical-2 "
                        alt="icon-vertical-2"
                      />
                    ) : product?.nr_status >= 1 ? (
                      <div className="cicle-icon-1 cicle-icon-extra-color icon-vertical-2 ">
                        2
                      </div>
                    ) : (
                      <div className="cicle-icon-1 icon-vertical-2 ">2</div>
                    )}
                    {product?.nr_status >= 3 ? (
                      <img
                        src={checked_status}
                        className="icon-vertical-3 "
                        alt="icon-vertical-3"
                      />
                    ) : product?.nr_status >= 2 ? (
                      <div className="cicle-icon-1 cicle-icon-extra-color icon-vertical-3 ">
                        3
                      </div>
                    ) : (
                      <div className="cicle-icon-1 icon-vertical-3 ">3</div>
                    )}
                    {product?.nr_status >= 4 ? (
                      <img
                        src={checked_status}
                        className="icon-vertical-4 "
                        alt="icon-vertical-4"
                      />
                    ) : product?.nr_status >= 3 ? (
                      <div className="cicle-icon-1 cicle-icon-extra-color icon-vertical-4 ">
                        4
                      </div>
                    ) : (
                      <div className="cicle-icon-1 icon-vertical-4 ">4</div>
                    )}
                  </div>
                  <div className="progress progress-bar-vertical">
                    {product?.nr_status === 1 ? (
                      <div
                        className="progress-bar progress-bar-success active bg-warning"
                        role="progressbar"
                        style={{ height: "33%" }}
                      ></div>
                    ) : (
                      ""
                    )}
                    {product?.nr_status === 2 ? (
                      <div
                        className="progress-bar progress-bar-success active bg-warning"
                        role="progressbar"
                        style={{ height: "66%" }}
                      ></div>
                    ) : (
                      ""
                    )}
                    {product?.nr_status === 3 ? (
                      <div
                        className="progress-bar progress-bar-success active bg-warning"
                        role="progressbar"
                        style={{ height: "99%" }}
                      ></div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="d-none d-lg-block">
              <div className="row justify-content-center">
                <div className="col-8">
                  <div className="icon-progress row">
                    {product?.nr_status >= 1 ? (
                      <img
                        src={checked_status}
                        className="icon-1 "
                        alt="icon-1"
                      />
                    ) : (
                      <div className="cicle-icon-1 cicle-icon-extra-color icon-1 ">
                        1
                      </div>
                    )}
                    {product?.nr_status >= 2 ? (
                      <img
                        src={checked_status}
                        className="icon-2 "
                        alt="icon-1"
                      />
                    ) : product?.nr_status >= 1 ? (
                      <div className="cicle-icon-1 cicle-icon-extra-color icon-2 ">
                        2
                      </div>
                    ) : (
                      <div className="cicle-icon-1 icon-2 ">2</div>
                    )}
                    {product?.nr_status >= 3 ? (
                      <img
                        src={checked_status}
                        className="icon-3 "
                        alt="icon-1"
                      />
                    ) : product?.nr_status >= 2 ? (
                      <div className="cicle-icon-1 cicle-icon-extra-color icon-3 ">
                        3
                      </div>
                    ) : (
                      <div className="cicle-icon-1 icon-3 ">3</div>
                    )}
                    {product?.nr_status >= 4 ? (
                      <img
                        src={checked_status}
                        className="icon-4 "
                        alt="icon-1"
                      />
                    ) : product?.nr_status >= 3 ? (
                      <div className="cicle-icon-1 cicle-icon-extra-color icon-4 ">
                        4
                      </div>
                    ) : (
                      <div className="cicle-icon-1 icon-4 ">4</div>
                    )}
                  </div>
                  <ProgressBar>
                    {product?.nr_status >= 1 ? (
                      <ProgressBar variant="warning" now={33} key={1} />
                    ) : (
                      ""
                    )}
                    {product?.nr_status >= 2 ? (
                      <ProgressBar variant="warning" now={33} key={2} />
                    ) : (
                      ""
                    )}
                    {product?.nr_status >= 3 ? (
                      <ProgressBar variant="warning" now={33} key={3} />
                    ) : (
                      ""
                    )}
                  </ProgressBar>
                </div>
              </div>
              <div className="text-progress-status">
                {product?.nr_status >= 1 ? (
                  <p id="text-status-1">Negócio fechado</p>
                ) : (
                  <p id="text-status-1">Aguardando negócio</p>
                )}
                {product?.nr_status >= 2 ? (
                  <p id="text-status-2">Pagamento efetuado</p>
                ) : (
                  <p id="text-status-2">Aguardando pagamento</p>
                )}
                {product?.nr_status >= 3 ? (
                  <p id="text-status-3">Aceite do lote pelo comprador</p>
                ) : (
                  <p id="text-status-3">Aceite do lote pelo comprador</p>
                )}
                {product?.nr_status >= 4 ? (
                  <p id="text-status-4">Negociação concluída</p>
                ) : (
                  <p id="text-status-4">Negociação concluída</p>
                )}
              </div>
            </div>
            <div className="product-info row">
              <div className="col-12 col-md-3">
                <p id="text-status-1">
                  <b>Categoria</b>
                </p>
                <p id="text-status-2">
                  {product?.Product?.Animal.AnimalCategory.des_category}
                </p>
              </div>
              <div className="col-12 col-md-3">
                <p id="text-status-1">
                  <b>Número do pedido</b>
                </p>
                <p id="text-status-2">{product?.id_buy_product}</p>
              </div>
              <div className="col-12 col-md-3">
                <p id="text-status-1">
                  <b>Valor total</b>
                </p>
                <p id="text-status-2">
                  {FormatPrice(
                    product?.Product?.nr_type_sales == 2
                      ? product?.Product?.vl_price_auction_total
                      : product?.Product?.vl_price_total
                  )}
                </p>
              </div>
            </div>
            {product?.nr_status === 2 ? (
              <Button onClick={handleAcceptLot} className="btn btn-create">
                Aceite do lote pelo comprador
              </Button>
            ) : (
              ""
            )}
          </div>

          <div>
            <h3 className="text-center title-custom-h3">Ficha técnica</h3>
            <div className="col-12 data-sheet shadow mb-3 bg-white">
              <Link to="/">
                <b>Baixar documentos em anexo</b>
              </Link>
              <div className="shadow-sm  bg-light rounded colum-light-gray row">
                <b className="col-md-3 col-6">Tipo de produto:</b>{" "}
                <p>
                  {
                    product?.Product.Animal.TypeAnimalProduct
                      .des_type_product_animal
                  }
                </p>
              </div>
              <div className="shadow-sm  bg-white rounded colum-white row">
                <b className="col-md-3 col-6">Raça:</b>{" "}
                <p>{product?.Product.Animal.AnimalRace.des_races}</p>
                <hr />
              </div>
              <div className="shadow-sm  bg-light rounded colum-light-gray row">
                <b className="col-md-3 col-6">Categoria:</b>{" "}
                <p>{product?.Product.Animal.AnimalCategory.des_category}</p>
              </div>
              <div className="shadow-sm  bg-white rounded colum-white row">
                <b className="col-md-3 col-6">Quantidade:</b>{" "}
                <p>{product?.Product.qtd_animals} Animais</p>
                <hr />
              </div>
              <div className="shadow-sm  bg-light rounded colum-light-gray row">
                <b className="col-md-3 col-6">Peso:</b>{" "}
                <p>{product?.Product?.nr_weight}</p>
              </div>
              <div className="shadow-sm  bg-light rounded colum-light-gray row">
                <b className="col-md-3 col-6">Intervalo de peso:</b>{" "}
                <p>
                  {
                    product?.Product?.Animal.AnimalAverageWeight
                      .des_average_weight
                  }
                </p>
              </div>
              <div className="shadow-sm  bg-light rounded colum-light-gray row">
                <b className="col-md-3 col-6">Sexo:</b>{" "}
                <p>{product?.Product?.Animal.AnimalSex.des_animal_sex}</p>
              </div>
              {Boolean(product?.Product?.Animal?.nr_pregnancy) && (
                <div className="shadow-sm  bg-light rounded colum-light-gray row">
                  <b className="col-md-3 col-6">Prenhez:</b>{" "}
                  <p>
                    {product?.Product?.Animal?.nr_pregnancy === 1
                      ? "Sim"
                      : "Não"}
                  </p>
                </div>
              )}
              {Boolean(product?.Product?.Animal?.nr_pregnancy_days) && (
                <div className="shadow-sm  bg-light rounded colum-light-gray row">
                  <b className="col-md-3 col-6">Gestação:</b>{" "}
                  <p>{product?.Product?.Animal?.nr_pregnancy_days + " Dias"}</p>
                </div>
              )}
              {Boolean(product?.Product?.Animal?.nr_liters_day) && (
                <div className="shadow-sm  bg-light rounded colum-light-gray row">
                  <b className="col-md-3 col-6">Litros produzidos/Dia:</b>{" "}
                  <p>{product?.Product?.Animal?.nr_liters_day}</p>
                </div>
              )}
              {Boolean(product?.Product?.Animal?.nr_lactations) && (
                <div className="shadow-sm  bg-light rounded colum-light-gray row">
                  <b className="col-md-3 col-6">Nº Lactações:</b>{" "}
                  <p>{product?.Product?.Animal?.nr_lactations}</p>
                </div>
              )}
              {Boolean(product?.Product?.Animal?.nr_age_months) && (
                <div className="shadow-sm  bg-light rounded colum-light-gray row">
                  <b className="col-md-3 col-6">Idade:</b>{" "}
                  <p>{product?.Product?.Animal?.nr_age_months + " Meses"}</p>
                </div>
              )}
              <div className="shadow-sm  bg-light rounded colum-light-gray row">
                <b className="col-md-3 col-6">Possui embarcador:</b>{" "}
                <p>
                  {product?.Product.nr_shipper_access === 1 ? "Sim" : "Não"}
                </p>
              </div>
              <div className="shadow-sm  bg-white rounded colum-white row">
                <b className="col-md-3 col-6">Possui acesso a Caminhões:</b>{" "}
                <p>{product?.Product.nr_truck_access === 1 ? "Sim" : "Não"}</p>
                <hr />
              </div>
              <div className="shadow-sm  bg-light rounded colum-light-gray row">
                <b className="col-md-3 col-6">Possui balança:</b>{" "}
                <p>
                  {product?.Product.nr_balance_access === 1 ? "Sim" : "Não"}
                </p>
              </div>
              <div className="shadow-sm  bg-light rounded colum-light-gray row">
                <b className="col-md-3 col-6">Cidade - UF:</b>{" "}
                <p>
                  {product?.Product?.AddressProduct?.City?.des_city +
                    " - " +
                    product?.Product?.AddressProduct?.State?.uf}
                </p>
              </div>
              <div className="shadow-sm  bg-light rounded colum-light-gray row">
                <b className="col-md-3 col-6">Quilometros da cidade:</b>{" "}
                <p>{product?.Product?.AddressProduct?.nr_kilometers + " KM"}</p>
              </div>
              <div className="shadow-sm  bg-white rounded colum-white row">
                <b className="col-md-3 col-6">Data da publicação:</b>{" "}
                <p>{FormatDatePtBr(product?.Product.createdAt)}</p>
                <hr />
              </div>
            </div>
          </div>
          <div id="media-product">
            <h3 className="text-center title-custom-h3">Fotos e vídeos</h3>
            <div className="photo-video shadow mb-3 bg-white row">
              {product?.Product.ProductPhotos.map((photo: any) => (
                <div key={photo.des_product_photo} className="photo-view">
                  <img
                    src={photo.des_product_photo_url}
                    width="100%"
                    height="100%"
                    alt="img"
                  />
                </div>
              ))}
              {product?.Product.ProductVideos.map((video: any) => (
                <iframe
                  key={video.des_product_video}
                  title="video-product"
                  src={`https://player.vimeo.com/video/${onlyNumbers(
                    video.des_product_video
                  )}`}
                  width={150}
                  frameBorder="0"
                ></iframe>
              ))}
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  )
}
export default UserRequestDetail
