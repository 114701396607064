/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react"

import InfiniteScroll from 'react-infinite-scroller';

import { useDispatch, useSelector } from "react-redux"
import { actionProduct } from "../../store/reduces/ReducerProduct"
import { RootState } from "../../store"
import { useParamsProductContext } from "../../providers/product-params"

import { toast } from "react-toastify"
import {
  Container,
  Row,
  Form,
  Navbar,
  Accordion,
  Card,
  Button,
} from "react-bootstrap"

import "rc-slider/assets/index.css"
import "rc-pagination/assets/index.css"
import "./styles.css"

import AnimalCard from "../AnimalCard"
import AdCard from "../AdCard"
import Loading from "../Loading"
import MenuLeftBeefCattle from "./MenuLeftBeefCattle"
import MenuLeftMilkCow from "./MenuLeftMilkCow"

import { FormatPrice } from "../../helpers/format"
import { api } from "../../services"
import { useHistory } from "react-router-dom"
import MediaMatch from "../Utils/MediaMatch"

import imagemPatrocinio from "../../assets/image/Patrocinio_comprar_grande.jpg"

const AnimalList = (props: any) => {
  const { params, setParamsProduct } = useParamsProductContext()

  const history = useHistory()
  const [MenuLeft, setMenuLeft] = useState("")
  const [attrRacesBoxDefault, setAttrRacesBoxDefault] = useState({
    typeProduct: "-",
  })
  const { reducerProduct } = useSelector((state: RootState) => state)
  const dispatch = useDispatch()
  const paramsURL = props?.props?.props?.match?.params?.paramsurl
  const valueURL = props?.props?.props?.match?.params?.valueurl
  const paramsURL2 = props?.props?.props?.match?.params?.paramsurl2
  const valueURL2 = props?.props?.props?.match?.params?.valueurl2

  useEffect(() => {
    dispatch(actionProduct.searchProducts({ filter: params }))
  }, [params])

  useEffect(() => {
    paramsFilterUrl()
  }, [Boolean(paramsURL) === true && valueURL])

  const paramsFilterUrl = async () => {
    if (Boolean(paramsURL2) === true) {
      await setParamsProduct({ type: [`${paramsURL}`], value: valueURL })
      await setParamsProduct({ type: [`${paramsURL2}`], value: valueURL2 })
    } else {
      await setParamsProduct({ type: [`${paramsURL}`], value: valueURL })
    }
  }

  const menuLeftType = async (event: any) => {
    const value = event.target.value

    await setParamsProduct({ type: "id_type_product_animal", value: value })

    if (value === "1") {
      setMenuLeft("MenuLeftBeefCattle")
    } else {
      setMenuLeft("MenuLeftMilkCow")
    }
  }

  const menuLeftBoxDefault = (event: any) => {
    const nameBox = event.target.dataset.id

    setAttrRacesBoxDefault((field) => {
      return {
        ...field,
        [`${nameBox}`]: attrRacesBoxDefault.typeProduct === "+" ? "-" : "+",
      }
    })
  }

  const handleCreateProductView = async (
    id_product: number,
    des_title: string,
    nr_type_sales: number
  ) => {
    try {
      await api.apiLeloae.product.createView({ id_product })
      if (nr_type_sales === 1) {
        history.push(`/product/detail/${id_product}/${des_title}`)
      } else {
        const result: any = await api.apiLeloae.auction.getAuctionByIdProduct(
          id_product
        )
        const { id_auction, dt_deadline, time_deadline } =
          result?.data?.message?.Auction
        history.push(
          `/auction/product/detail/${id_product}/${id_auction}/${dt_deadline}/${time_deadline}/${des_title}`
        )
      }
    } catch (error) {
      toast.error("Ocorreu algum erro", { toastId: 1 })
    }
  }

  const [page2, setPage2] = useState(1)

  const handlePage = () => {
    setTimeout(() => {
      setParamsProduct({ type: "page", value: page2 })
    }, 1000)
  }

  const [state, setState] = useState([])

  useEffect(() => {
    const last = state.slice(state.length - 1)


    if (last[0]?.id_product !== reducerProduct?.data[9]?.id_product) {
      setState([...state, ...reducerProduct.data])
      setPage2(prevState => prevState + 1)
    }

    console.log(state);
  }, [reducerProduct])

  return (
    <>
      {reducerProduct.httprequest.loading && <Loading />}
      <MediaMatch greaterThan="1296px">
        <div className="banner">
          <a href="/contact">
            <img src={imagemPatrocinio} alt="Imagem refrenciando este espaço para anunciantes" />
          </a>
        </div>
      </MediaMatch>
      <Container id="container">
        <div id="product">
          <Row>
            <div className="col-12 row">
              <div id="filtro" className="col-12 col-sm-12 col-md-3 menu-left">
                <h1 className="title-custom-h2 col-12">Lotes</h1>
                <Navbar bg="light" expand="lg">
                  <Navbar.Toggle aria-controls="" />
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Form.Group controlId="formBasicCheckbox">
                      <Accordion defaultActiveKey="1">
                        <Card className="shadow mb-3 bg-white">
                          <Card.Header>
                            <Accordion.Toggle
                              as={Button}
                              data-id="typeProduct"
                              onClick={menuLeftBoxDefault}
                              className="text-left"
                              variant="link"
                              eventKey="1"
                            >
                              <b data-id="typeProduct">
                                {" "}
                                Tipo de produto{" "}
                                <b data-id="typeProduct">
                                  {attrRacesBoxDefault.typeProduct}
                                </b>
                              </b>
                            </Accordion.Toggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey="1">
                            <Card.Body>
                              <Form.Check
                                type="radio"
                                id="id_type_product_animal_3"
                                onClick={menuLeftType}
                                value=""
                                defaultChecked
                                name="id_type_product_animal"
                                label="Todos"
                              />
                              <Form.Check
                                type="radio"
                                id="id_type_product_animal_1"
                                onClick={menuLeftType}
                                value="1"
                                name="id_type_product_animal"
                                label="Gado de Corte"
                              />
                              <Form.Check
                                type="radio"
                                id="id_type_product_animal_2"
                                onClick={menuLeftType}
                                value="2"
                                name="id_type_product_animal"
                                label="Gado de Leite"
                              />
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>
                      <div id="menu-left-filter">
                        {MenuLeft === "MenuLeftBeefCattle" && (
                          <MenuLeftBeefCattle />
                        )}
                        {MenuLeft === "MenuLeftMilkCow" && <MenuLeftMilkCow />}
                      </div>
                    </Form.Group>
                  </Navbar.Collapse>
                </Navbar>
              </div>
              {reducerProduct.httprequest.error &&
                toast.error("Ocorreu erro em buscar lista de lotes", {
                  toastId: 1,
                })
              }
              <div id="animalCards" className="col-12 col-sm-12 col-md-9 list-right text-center">
                <InfiniteScroll
                  loadMore={handlePage}
                  hasMore={true}
                  useWindow={true}
                  style={{ display: "flex", flexWrap: "wrap", gap: "18px" }}
                >
                  {/* {reducerProduct.data[0] ? (
                    <> */}
                  {state.map((product: any, index) => {
                    if ((index + 1) % 7 === 0) {
                      return (
                        <div key={index}>
                          <MediaMatch lessThan="1026px">
                            <AdCard link="/contact" />
                          </MediaMatch>
                          <MediaMatch between="1027px" between2="1522px">
                            <div style={{ display: 'flex', gap: '24px' }}>
                              <AdCard link="/contact" />
                              <AdCard link="/contact" />
                            </div>
                          </MediaMatch>
                          <MediaMatch greaterThan="1523px">
                            <div style={{ display: 'flex', gap: '24px' }}>
                              <AdCard link="/contact" />
                              <AdCard link="/contact" />
                              <AdCard link="/contact" />
                            </div>
                          </MediaMatch>
                        </div>
                      )
                    } else {
                      return (
                        <div onClick={() =>
                          handleCreateProductView(
                            product.id_product,
                            product.des_title,
                            product.nr_type_sales
                          )}
                          key={product.id_product}
                        >
                          <AnimalCard
                            key={product?.id_product}
                            image={product?.ProductPhotos[0]?.des_product_photo_url}
                            alt={product?.des_title}
                            title={product?.des_title}
                            price={FormatPrice(product?.vl_price_total)}
                            address={
                              product?.AddressProduct?.City?.des_city +
                              "/" +
                              product?.AddressProduct?.City?.uf
                            }
                            animalQtd={product?.qtd_animals}
                            animalWeight={product?.nr_weight}
                            variant="lista"
                          />
                        </div>
                      )
                    }

                  })}
                </InfiniteScroll>
              </div>
            </div>
          </Row>
        </div>
      </Container>
    </>
  )
}
export default AnimalList
