/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, createContext, useState } from "react";


interface DataParamsI {
  type: string,
  value: any
}

type IParamsProductContext = {
  params: any
  setParamsProduct(data: any): Promise<void>;
};
const ParamsProduct = createContext<Partial<IParamsProductContext>>({});

export const CreateParamsProvider: React.FC = ({ children }) => {
  const [params, setParams] = useState({page: 1, limit: 10, nr_status: 2});
  
  const setParamsProduct = async (data: DataParamsI) => {
    setParams(field => {
        return {
            ...field, [data.type]: data.value
        }
    })
  }

  return (
    <ParamsProduct.Provider
      value={{
        params,
        setParamsProduct
      }}
    >
      {children}
    </ParamsProduct.Provider>
  );
};

export const useParamsProductContext = () => {
  return useContext(ParamsProduct);
};