/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react"

import axios from "axios"

import {
  FormatDatePtBr,
  FormatPrice,
  FormatPricePerAnimal,
  FormatPricePerWeight,
  onlyNumbers,
} from "../../helpers/format"
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share"

import { Container, Row, Figure, Button, Modal } from "react-bootstrap"
import { Carousel } from "react-responsive-carousel"

import AdvertiverContact from "../../components/AdvertiserContact"
import Footer from "../../components/Footer"
import HelmetMetaData from "../../components/HelmetMetaData"
import Loading from "../../components/Loading"
import Navbar from "../../components/Navbar"

import ProductSimilar from "./productSimilar"

import imgVideoIcon from "../../assets/image/video-icon.png"

import "react-responsive-carousel/lib/styles/carousel.min.css"
import "./styles.css"

import { Link, Redirect, useHistory } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCheckSquare,
  faWeight,
  faTag,
  faVenusMars,
} from "@fortawesome/free-solid-svg-icons"

import imgIconCow from "../../assets/image/icon-cow.png"
import imgQuantity from "../../assets/image/quantidade.png"

import { useUserContext } from "../../providers/use-sign"
import { toast } from "react-toastify"
import { api } from "../../services"

import { FormatError } from "../../helpers/formatError"
import AnimalSection from "../../components/AnimalSection"

const ProductDetail: React.FC = (propos: any) => {
  const { data, signed } = useUserContext()

  const id_product = propos.match.params.id_product
  const [ProductLoading, setProductLoading] = useState(false)
  const [product, setProduct] = useState(null)
  const [productView, setProductView] = useState(null)
  const [showModalCarousel, setShowModalCarousel] = useState(false)

  const handleClose = () => setShowModalCarousel(false)
  const handleShow = () => setShowModalCarousel(true)

  const history = useHistory()

  const checkedLogin = (event: any) => {
    const value = event.target.value

    if (value == 1) {
      toast.warn("Você precisa está logando para efetuar compra", {
        toastId: 1,
      })
    }

    if (value == 2) {
      toast.warn(
        "Você precisa está logando para enviar solicitação de negociação",
        { toastId: 1 }
      )
    }
    history.push("/authentication")
  }

  const getProduct = async () => {
    setProductLoading(true)
    try {
      const product = await api.apiLeloae.product.get<any>(id_product)

      setProduct(product.data?.message)

      setProductLoading(false)
    } catch (error) {
      setProductLoading(false)
      const errorFormat = FormatError(error)
      errorFormat.forEach((element) => {
        toast.error(element)
      })
    }
  }

  const getProductView = async () => {
    setProductLoading(true)
    try {
      const product = await api.apiLeloae.product.getView<any>(id_product)

      setProductView(product.data?.message)

      setProductLoading(false)
    } catch (error) {
      setProductLoading(false)
      const errorFormat = FormatError(error)
      errorFormat.forEach((element) => {
        toast.error(element)
      })
    }
  }

  const downloadDocumentProduct = () => {
    if (!product?.ProductDocumentPhotos[0]) {
      toast.info("Documentos não anexados")
      return
    }
    product?.ProductDocumentPhotos.map((image: any) =>
      axios({
        url: image?.des_product_document_photo_url,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", image?.des_product_document_photo) //or any other extension
        document.body.appendChild(link)
        link.click()
      })
    )
  }

  useEffect(() => {
    getProduct()
    getProductView()
  }, [id_product])

  if (!id_product) {
    return <Redirect to="/product"></Redirect>
  }

  return (
    <>
      {product && (
        <>
          {ProductLoading && <Loading />}
          <HelmetMetaData
            title={product.des_title}
            description={product.des_description}
            image={product?.ProductPhotos[0].des_product_photo_url}
          ></HelmetMetaData>
          <Navbar />
          <Container id="container">
            <div id="product-detail">
              <Row className="row-top">
                <h1 className="col-12">{product.des_title}</h1>
                <p className="col-12">{product.des_description}</p>

                <Modal show={showModalCarousel} onHide={handleClose}>
                  <Modal.Header closeButton />
                  {product?.ProductVideos[0]?.des_product_video ? (
                    <>
                      <Carousel
                        showThumbs={true}
                        showStatus={true}
                        useKeyboardArrows
                        className="presentation-mode"
                      >
                        {product?.ProductPhotos.map((image: any) => (
                          <div key={image.id_product_photo}>
                            <img
                              className="lazyload img-carrousel"
                              data-src={image.des_product_photo_url}
                              alt={product?.des_title}
                            />
                          </div>
                        ))}
                        <div key="video-1" className="my-slide content">
                          <iframe
                            className="lazyload"
                            width="560"
                            height="400"
                            data-src={`https://player.vimeo.com/video/${onlyNumbers(
                              product?.ProductVideos[0].des_product_video
                            )}`}
                          />
                          <img
                            src={imgVideoIcon}
                            className="thumb-img-video"
                            alt="Icone de Vídeo"
                          />
                        </div>
                      </Carousel>
                    </>
                  ) : (
                    <>
                      <Carousel
                        showThumbs={true}
                        showStatus={true}
                        useKeyboardArrows
                        className="presentation-mode"
                      >
                        {product?.ProductPhotos.map((image: any) => (
                          <div key={image.id_product_photo}>
                            <img
                              className="lazyload"
                              data-src={image.des_product_photo_url}
                              alt={product?.des_title}
                            />
                          </div>
                        ))}
                      </Carousel>
                    </>
                  )}
                </Modal>
                <div className="col-12 col-md-6">
                  {product?.ProductVideos[0]?.des_product_video ? (
                    <>
                      <Carousel
                        showThumbs={true}
                        showStatus={true}
                        useKeyboardArrows
                        className="presentation-mode"
                      >
                        <div key="video-1" className="my-slide content">
                          <iframe
                            className="lazyload"
                            width="560"
                            height="400"
                            src={`https://player.vimeo.com/video/${onlyNumbers(
                              product?.ProductVideos[0].des_product_video
                            )}`}
                          />
                          <img
                            src={imgVideoIcon}
                            className="thumb-img-video"
                            alt="Icone de Vídeo"
                          />
                        </div>
                        {product?.ProductPhotos.map((image: any) => (
                          <div
                            key={image.id_product_photo}
                            onClick={handleShow}
                          >
                            <img
                              className="lazyload"
                              data-src={image.des_product_photo_url}
                              alt={product?.des_title}
                            />
                          </div>
                        ))}
                      </Carousel>
                    </>
                  ) : (
                    <>
                      <Carousel
                        showThumbs={true}
                        showStatus={true}
                        useKeyboardArrows
                        className="presentation-mode"
                      >
                        {product?.ProductPhotos.map((image: any) => (
                          <div
                            key={image.id_product_photo}
                            onClick={handleShow}
                          >
                            <img
                              className="lazyload"
                              data-src={image.des_product_photo_url}
                              alt={product?.des_title}
                            />
                          </div>
                        ))}
                      </Carousel>
                    </>
                  )}
                </div>
                <div className="col-12 col-md-6">
                  <div className="shadow mb-3 bg-white detail-buy">
                    <div className="row">
                      <div className="col-md-6 col-sm-12 text-center">
                        <b>Clique aqui e compartilhe:</b>
                        <br />
                        <FacebookShareButton
                          url={`https://www.leiloae.com.br/product/detail/${product.id_product}`}
                          quote={`${product.des_title} - Qtd: ${product.qtd_animals
                            } - Valor ${FormatPrice(product.vl_price_total)} - ${product?.AddressProduct?.City?.des_city +
                            " - " +
                            product?.AddressProduct?.State?.uf
                            }`}
                          hashtag="#leiloae"
                        >
                          <FacebookIcon size={36} />
                        </FacebookShareButton>
                        <WhatsappShareButton
                          url={`https://www.leiloae.com.br/product/detail/${product.id_product}`}
                          title={`${product.des_title} - Qtd: ${product.qtd_animals
                            } - Valor ${FormatPrice(product.vl_price_total)} - ${product?.AddressProduct?.City?.des_city +
                            " - " +
                            product?.AddressProduct?.State?.uf
                            }`}
                          separator=" - "
                        >
                          <WhatsappIcon size={36} />
                        </WhatsappShareButton>
                      </div>
                      <div className="col-md-6 col-sm-12 text-center">
                        <p className="visitors">
                          Esse lote recebeu{" "}
                          {productView?.nr_product_views_total * 2} visitas!
                        </p>
                      </div>
                    </div>
                    <div className="row row-valores-lote">
                      <div className="col-md-4 col-sm-12">
                        <b>Valor por Kg:</b>
                        <h4 className="vl">
                          {" "}
                          {FormatPricePerWeight(
                            product.vl_price_total,
                            product.qtd_animals,
                            product.nr_weight
                          )}
                        </h4>
                      </div>
                      <div className="col-md-4 col-sm-12">
                        <b>Valor por Animal:</b>
                        <h4 className="vl">
                          {" "}
                          {FormatPricePerAnimal(
                            product.vl_price_total,
                            product.qtd_animals
                          )}
                        </h4>
                      </div>
                      <div className="col-md-4 col-sm-12">
                        <b>Valor do Lote:</b>
                        <h4 className="vl">
                          {" "}
                          {FormatPrice(product.vl_price_total)}
                        </h4>
                      </div>
                    </div>
                    {data.user?.id_person == product.id_person ? (
                      <>
                        <p style={{ marginBottom: "3px", textAlign: "center" }}>
                          Estado do pedido
                        </p>
                        <b>
                          {" "}
                          * {product.TypeProductStatus.des_type_product_status}
                        </b>
                      </>
                    ) : (
                      <>
                        <b>Adquira este lote:</b>
                        <br />
                        {signed ? (
                          <>
                            <div className="actionSection">
                              <a
                                className="btn btn-create call-to-action-detail"
                                href={`/product/buy/${product.id_product}`}
                              >
                                Comprar lote
                              </a>
                              <a
                                className="btn btn-cancel call-to-action-detail"
                                href={`/product/send/proposal/${product.id_product}`}
                              >
                                Quero negociar
                              </a>
                              <a
                                className="btn btn-cancel call-to-action-detail"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`https://wa.me/5546991130554`}
                              >
                                Fale conosco
                              </a>
                            </div>
                            <AdvertiverContact phoneNumber={product?.User?.nr_phone} />
                          </>
                        ) : (
                          <>
                            <Button
                              className="btn btn-create call-to-action-detail"
                              value="1"
                              onClick={checkedLogin}
                            >
                              Comprar lote
                            </Button>
                            <Button
                              className="btn btn-cancel call-to-action-detail"
                              value="2"
                              onClick={checkedLogin}
                            >
                              Quero negociar
                            </Button>
                            <a
                              className="btn btn-cancel call-to-action-detail"
                              target="_blank"
                              rel="noopener noreferrer"
                              href={`https://wa.me/5546991130554`}
                            >
                              Fale conosco
                            </a>
                            <div onClick={checkedLogin}>
                              <AdvertiverContact disable phoneNumber={product?.User?.nr_phone} />
                            </div>
                          </>
                        )}
                      </>
                    )}
                    <hr />
                    <div className="row row-detail-product">
                      <div className="col-12 col-sm-6 row detail-info">
                        <div className="col-4 text-center">
                          <Figure className="icon-quantidade">
                            <Figure.Image src={imgQuantity} />
                          </Figure>
                        </div>
                        <div className="col-8">
                          <b>Quantidade no lote</b>
                          <p>{product.qtd_animals} animal(is)</p>
                        </div>
                        <div className="col-4 text-center">
                          <Figure>
                            <Figure.Image src={imgIconCow} />
                          </Figure>
                        </div>
                        <div className="col-8">
                          <b>Raça</b>
                          <p>
                            {product?.Animal.id_custom_races
                              ? "Raça cruzada"
                              : product?.Animal.AnimalRace.des_races}
                          </p>
                        </div>
                        <div className="col-4 text-center">
                          <FontAwesomeIcon icon={faCheckSquare} size="3x" />
                        </div>
                        <div className="col-8">
                          <b>Tipo de produto</b>
                          <p>
                            {
                              product.Animal.TypeAnimalProduct
                                .des_type_product_animal
                            }
                          </p>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6 row detail-info">
                        <div className="col-4 text-center">
                          <FontAwesomeIcon icon={faTag} size="3x" />
                        </div>
                        <div className="col-8">
                          <b>Categoria</b>
                          <p>{product.Animal.AnimalCategory.des_category}</p>
                        </div>
                        <div className="col-4 text-center">
                          <FontAwesomeIcon icon={faWeight} size="3x" />
                        </div>
                        <div className="col-8">
                          <b>Peso</b>
                          <p>{product?.nr_weight} Kg</p>
                        </div>
                        <div className="col-4 text-center">
                          <FontAwesomeIcon icon={faVenusMars} size="3x" />
                        </div>
                        <div className="col-8">
                          <b>Sexo</b>{" "}
                          <p>{product?.Animal.AnimalSex.des_animal_sex}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Row>

              <div>
                <h3 className="text-center title-custom-h3">Ficha técnica</h3>
                <div className="col-12 data-sheet shadow mb-3 bg-white">
                  <Link to="#" onClick={downloadDocumentProduct}>
                    <b>Baixar documentos em anexo</b>
                  </Link>

                  <div className="shadow-sm  bg-light rounded colum-light-gray row">
                    <b className="col-md-3 col-6">Tipo de produto:</b>{" "}
                    <p>
                      {
                        product?.Animal.TypeAnimalProduct
                          .des_type_product_animal
                      }
                    </p>
                  </div>
                  <div
                    className="shadow-sm  bg-white rounded colum-white row"
                    style={{ height: "100%" }}
                  >
                    {product?.Animal.id_custom_races ? (
                      <>
                        <b className="col-md-3 col-6">Raças cruzadas:</b>{" "}
                        <p>Segue lista abaixo</p>
                      </>
                    ) : (
                      <>
                        <b className="col-md-3 col-6">Raça:</b>
                        <p> {product?.Animal.AnimalRace.des_races}</p>
                      </>
                    )}
                    <hr />
                  </div>
                  {product?.Animal.AnimalCustomRaces.map((data: any) => (
                    <div
                      key={data.id_races}
                      style={{ marginLeft: "5%" }}
                      className="shadow-sm  bg-light rounded colum-light-gray row"
                    >
                      <b className="col-md-3 col-6">
                        * {data.AnimalRace.des_races}
                      </b>{" "}
                      <p>{data.nr_percentage}%</p>
                    </div>
                  ))}
                  <div className="shadow-sm  bg-light rounded colum-light-gray row">
                    <b className="col-md-3 col-6">Categoria:</b>{" "}
                    <p>{product?.Animal.AnimalCategory.des_category}</p>
                  </div>
                  <div className="shadow-sm  bg-white rounded colum-white row">
                    <b className="col-md-3 col-6">Quantidade:</b>{" "}
                    <p>{product?.qtd_animals} Animais</p>
                    <hr />
                  </div>
                  <div className="shadow-sm  bg-light rounded colum-light-gray row">
                    <b className="col-md-3 col-6">Intervalo de peso:</b>{" "}
                    <p>
                      {product?.Animal.AnimalAverageWeight.des_average_weight}
                    </p>
                  </div>
                  <div className="shadow-sm  bg-light rounded colum-light-gray row">
                    <b className="col-md-3 col-6">Sexo:</b>{" "}
                    <p>{product?.Animal.AnimalSex.des_animal_sex}</p>
                  </div>
                  {Boolean(product?.Animal?.nr_pregnancy) && (
                    <div className="shadow-sm  bg-light rounded colum-light-gray row">
                      <b className="col-md-3 col-6">Prenhez:</b>{" "}
                      <p>
                        {product?.Animal?.nr_pregnancy === 1 ? "Sim" : "Não"}
                      </p>
                    </div>
                  )}
                  {Boolean(product?.Animal?.nr_pregnancy_days) && (
                    <div className="shadow-sm  bg-light rounded colum-light-gray row">
                      <b className="col-md-3 col-6">Gestação:</b>{" "}
                      <p>{product?.Animal?.nr_pregnancy_days + " Dias"}</p>
                    </div>
                  )}
                  {Boolean(product?.Animal?.nr_liters_day) && (
                    <div className="shadow-sm  bg-light rounded colum-light-gray row">
                      <b className="col-md-3 col-6">Litros produzidos/Dia:</b>{" "}
                      <p>{product?.Animal?.nr_liters_day}</p>
                    </div>
                  )}
                  {Boolean(product?.Animal?.nr_lactations) && (
                    <div className="shadow-sm  bg-light rounded colum-light-gray row">
                      <b className="col-md-3 col-6">Nº Lactações:</b>{" "}
                      <p>{product?.Animal?.nr_lactations}</p>
                    </div>
                  )}
                  {Boolean(product?.Animal?.nr_age_months) && (
                    <div className="shadow-sm  bg-light rounded colum-light-gray row">
                      <b className="col-md-3 col-6">Idade:</b>{" "}
                      <p>{product?.Animal?.nr_age_months + " Meses"}</p>
                    </div>
                  )}
                  <div className="shadow-sm  bg-light rounded colum-light-gray row">
                    <b className="col-md-3 col-6">Possui embarcador:</b>{" "}
                    <p>{product?.nr_shipper_access === 1 ? "Sim" : "Não"}</p>
                  </div>
                  <div className="shadow-sm  bg-white rounded colum-white row">
                    <b className="col-md-3 col-6">Possui acesso a Caminhões:</b>{" "}
                    <p>{product?.nr_truck_access === 1 ? "Sim" : "Não"}</p>
                    <hr />
                  </div>
                  <div className="shadow-sm  bg-light rounded colum-light-gray row">
                    <b className="col-md-3 col-6">Possui balança:</b>{" "}
                    <p>{product?.nr_balance_access === 1 ? "Sim" : "Não"}</p>
                  </div>
                  <div className="shadow-sm  bg-light rounded colum-light-gray row">
                    <b className="col-md-3 col-6">Cidade - UF:</b>{" "}
                    <p>
                      {product?.AddressProduct?.City?.des_city +
                        " - " +
                        product?.AddressProduct?.State?.uf}
                    </p>
                  </div>
                  <div className="shadow-sm  bg-light rounded colum-light-gray row">
                    <b className="col-md-3 col-6">Quilometros da cidade:</b>{" "}
                    <p>{product?.AddressProduct?.nr_kilometers + " KM"}</p>
                  </div>
                  <div className="shadow-sm  bg-white rounded colum-white row">
                    <b className="col-md-3 col-6">Data da publicação:</b>{" "}
                    <p>{FormatDatePtBr(product?.createdAt)}</p>
                    <hr />
                  </div>
                </div>
              </div>
            </div>
          </Container>
          <AnimalSection>LOTES SIMILARES</AnimalSection>
          <ProductSimilar
            children={product?.Animal.id_type_product_animal}
          />
          <Footer />
        </>
      )}
    </>
  )
}
export default ProductDetail
