import React, { useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import { FormControl, InputGroup } from "react-bootstrap"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/free-solid-svg-icons"

import Outside from "../Outside"

import { useParamsProductContext } from "../../providers/product-params"
import { useStateGlobalContext } from "../../providers/state-global"

import { RootState } from "../../store"
import { actionProducttSearch } from "../../store/reduces/ReducerProductSearch"

import * as S from "./styles"

export type SearchFieldProps = {}

const SearchField = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [searchData, setSearchData] = useState("")

  const { params, setParamsProduct } = useParamsProductContext()
  const { statusOutSide, setStateSearchHide } = useStateGlobalContext()

  const { reducerProductSearch } = useSelector((state: RootState) => state)

  const handleSearch = (event: any) => {
    setStateSearchHide(true)
    const value = event.target.value
    if (event.target.dataset.id === "icon-search") {
      history.push(`/product/list/search/${searchData}`)
      return
    }
    if (event.which === 13) {
      history.push(`/product/list/search/${value}`)
      return
    }

    setSearchData(value)
    setParamsProduct({ type: "search", value: value })

    if (value) {
      dispatch(actionProducttSearch.searchProducts({ filter: params }))
    }
  }

  return (
    <S.Wrapper>
      <InputGroup>
        <FormControl
          placeholder="Buscar anúncios e muito mais"
          aria-label="Buscar anúncios e muito mais"
          aria-describedby="basic-addon2"
          onChange={handleSearch}
          onKeyPress={handleSearch}
          defaultValue={params?.search}
        />
        <InputGroup.Append>
          <FontAwesomeIcon
            onClick={handleSearch}
            data-id="icon-search"
            className="icon-search"
            icon={faSearch}
            size="2x"
          />
        </InputGroup.Append>
      </InputGroup>
      {statusOutSide && (
        <Outside>
          {reducerProductSearch.data[0] && searchData && (
            <ul className="search-list shadow mb-3 bg-white ">
              {reducerProductSearch.data.map((product: any) => (
                <Link
                  key={product.id_product}
                  to={`/product/list/search/${product.des_title}`}
                >
                  <li>
                    <FontAwesomeIcon
                      className="icon-search-list"
                      icon={faSearch}
                      size="1x"
                    />{" "}
                    {product.des_title}
                  </li>
                </Link>
              ))}
            </ul>
          )}
        </Outside>
      )}
    </S.Wrapper>
  )
}

export default SearchField
