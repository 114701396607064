/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Container, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';


import Header from '../../../components/HeaderAdmin';
import Sidebar from '../SideNav'

import { api } from '../../../services/apiLeloae/config';
import axios from 'axios';

import './styles.css'

import { Link, useHistory } from 'react-router-dom';
import { CalculationAnimalPriceTotal, FormatPriceInput, FormatPriceEua, FormatPrice } from '../../../helpers/format';
import { ValidationFormCreateAnnunce, ValidationInputCreateAnnunce } from '../../../helpers/dataValidation';
import { FormatError } from '../../../helpers/formatError';
import Loading from '../../../components/Loading';
import { checkDevice, checkIsDataValid } from '../../../helpers/utils';

const LotsUpdate: React.FC = (propos: any) => {
    const id_product = propos.match.params.id_product

    const [expandedResume, setExpandedResume] = useState({ expanded: null })

    const myRef = useRef<null | HTMLDivElement>(null);
    const [updateLoading, setUpdateLoading] = useState(false)
    const [listStateData, setListStateData] = useState([])
    const [listCityData, setListCityData] = useState([])
    const [typeListProduct, setTypeListProduct] = useState<any>({
        typeProduct: [],
        typePlacingRaces: [],
        typeRaces: [],
        typeCategories: [],
        typeAverageWeight: [],
        typeCalculation: [],
    })

    const [product, setProduct] = useState<any>({
        id_person: 0,
        id_type_product_animal: 0,
        id_type_animal_placing_races: 0,
        id_animal_sex: 0,
        id_category: 0,
        id_races: 0,
        id_custom_races: 0,
        custom_races: [],
        id_average_weight: 0,
        des_title: '',
        des_description: '',
        vl_price: '',
        vl_price_total: '',
        qtd_animals: 0,
        nr_weight: 0,
        nr_pregnancy: 0,
        nr_pregnancy_days: 0,
        nr_liters_day: 0,
        nr_age_months: 0,
        id_type_calculation_animal: 0,
        nr_shipper_access: 0,
        nr_truck_access: 0,
        nr_balance_access: 0,
        id_uf: 0,
        code_city: 0,
        nr_kilometers: 0,
        nr_terms:1,
        nr_status: 1
    })

    let mounted = true;
    const signal = axios.CancelToken.source();
    const history = useHistory()

    const listState = async () => {
        try {
            const responseListState: any = await api.get('/address/list/state', {cancelToken: signal.token})

            if (mounted) {
                setListStateData(responseListState?.data?.message)
            }
        } catch (error) {
            toast.error('Ocorreu erro em buscar listar os estados', {toastId: 1})    
        }
    }

    const listCity = async () => {
        try {
            const responseListCity: any = await api.get(`/address/list/search/city/${product.id_uf}`)
            setListCityData(responseListCity?.data?.message)
        } catch (error) {
        toast.error('Ocorreu erro em buscar listar cidades', {toastId: 1})    
        }
    }

    const getProduct = async () => {
        setUpdateLoading(true)
        try {
            const product: any = await api.get(`/admin/product/show/${id_product}`)

            setProduct({
                id_person: product?.data?.message.id_person,
                id_type_product_animal: product?.data?.message?.Animal?.id_type_product_animal,
                id_type_animal_placing_races: product?.data?.message?.Animal?.AnimalRace?.nr_type_races == 1 ? 1 : product?.data?.message?.Animal?.AnimalRace?.nr_type_races == 2 ? 2 : 3,
                id_animal_sex: checkIsDataValid(product?.data?.message?.Animal?.id_animal_sex, 1),
                id_category: product?.data?.message?.Animal?.id_category,
                id_races: product?.data?.message?.Animal?.id_races,
                id_custom_races: checkIsDataValid(product?.data?.message?.Animal?.id_custom_races, 1),
                custom_races: checkIsDataValid(product?.data?.message?.Animal?.custom_races, 2),
                id_average_weight: product?.data?.message?.Animal?.id_average_weight,
                des_title: product?.data?.message?.des_title,
                des_description: product?.data?.message?.des_description,
                vl_price: product?.data?.message?.vl_price,
                vl_price_total: product?.data?.message?.vl_price_total,
                qtd_animals: product?.data?.message?.qtd_animals,
                nr_weight: product?.data?.message?.nr_weight,
                nr_pregnancy: checkIsDataValid(product?.data?.message?.nr_pregnancy, 1),
                nr_pregnancy_days: checkIsDataValid(product?.data?.message?.nr_pregnancy_days, 1),
                nr_liters_day: checkIsDataValid(product?.data?.message?.nr_liters_day, 1),
                nr_age_months: checkIsDataValid(product?.data?.message?.nr_age_months, 1),
                id_type_calculation_animal: product?.data?.message?.id_type_calculation_animal,
                nr_shipper_access: product?.data?.message?.nr_shipper_access,
                nr_truck_access: product?.data?.message?.nr_truck_access,
                nr_balance_access: product?.data?.message?.nr_balance_access,
                id_uf: product?.data?.message?.AddressProduct?.id_uf,
                code_city: product?.data?.message?.AddressProduct?.code_city,
                nr_kilometers: product?.data?.message?.AddressProduct?.nr_kilometers,
                nr_terms:product?.data?.message?.nr_terms,
                nr_status: product?.data?.message?.nr_status
            })

            setUpdateLoading(false)
        } catch (error) {
            setUpdateLoading(false)
            const errorFormat = FormatError(error)
            errorFormat.forEach(element => {
              toast.error(element)
            });
        }
    }

    const getListTypeProduct = async () => {
        try {
          const responseTypeProduct: any = await api.get('/utils/type/animal/product', {cancelToken: signal.token})
          const responseTypePlacingRaces: any = await api.get('/utils/type/animal/placing/races', {cancelToken: signal.token})
          const responseTypeCategories: any = await api.get(`/utils/type/animal/categories/${product.id_type_product_animal == 2 ? 3 : product.id_animal_sex}`, {cancelToken: signal.token})
          const responseTypeRaces: any = await api.get(`/utils/type/animal/races/${product.id_type_product_animal == 2 ? '3' : product.id_type_animal_placing_races == 3 ? '1/2' : product.id_type_animal_placing_races}`, {cancelToken: signal.token})
          const responseTypeAverageWeight: any = await api.get('/utils/type/animal/average/weight', {cancelToken: signal.token})
          const responseTypeCalculation: any = await api.get(`/utils/type/animal/calculation/${product.id_type_product_animal}`, {cancelToken: signal.token})
          if (mounted) {
            setTypeListProduct({
                  typeProduct: responseTypeProduct?.data?.message,
                  typePlacingRaces: responseTypePlacingRaces?.data?.message,
                  typeCategories: responseTypeCategories?.data?.message,
                  typeRaces: responseTypeRaces?.data?.message,
                  typeAverageWeight: responseTypeAverageWeight?.data?.message,
                  typeCalculation: responseTypeCalculation?.data?.message,
                })
        }
        } catch (error) {
          toast.error('Erro em buscar lista de tipo de produto', {toastId: 1})
        }
    }

    const handleChangeForm = async (event: any) => {

        const name = event.target.name
        const value = event.target.value
        const checked = event.target.checked
        setProduct(field => {
          return {
            ...field, [`${name}`]:value
          }
        })
        if(name === 'id_races'){
            setProduct(field => {
                return {
                    ...field, id_custom_races: 0, custom_races: []
                }
            })
        }
        if(name === 'nr_terms'){
            setProduct(field => {
                return {
                    ...field, [`${name}`]: checked === true ? 1 : 0
                }
            })
        }
        if(name === 'vl_price'){
            setProduct(field => {
                return {
                    ...field, [`${name}`]: FormatPriceInput(value)
                }
            })
        }
        if(name === 'vl_price' || name === 'qtd_animals' || name === 'nr_weight' || name === 'id_type_calculation_animal'){
            setProduct(field => {
                return {
                    ...field, vl_price_total: 0
                }
            })
        }
        if(name === 'id_type_product_animal' || name === 'id_animal_sex' || name === 'id_category' || name === 'id_races' || 
        name === 'id_average_weight' || name === 'nr_balance_access' || name === 'id_type_animal_placing_races'){
            await new Promise(resolve => setTimeout(resolve, 20))
            console.log(myRef)
            myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }
    
    const handleChangeCustomRaces  = (event: any) => {
        const name = event.target.name
        const value = event.target.value
        setProduct(field => {
            return {
              ...field, id_custom_races: 0, id_races: null
            }
        })
        for (let index = 0; index < product.custom_races.length; index++) {
            if(product.custom_races[index]?.id.includes(name)) {
                product.custom_races[index].value = value
                if(product.custom_races[index].value === ''){
                   product.custom_races.splice(index,1)
                   return
                }
                return
            }
        }
        
        setProduct(field => {
            return {
              ...field, custom_races: field.custom_races.concat([{'id': name, 'value': value}])
            }
        })
    }

    const handleShowInputCustomRaces = (event: any) => {
        const id_custom_races = event.target.dataset.id
        if(id_custom_races){
            let inputStyle = document.getElementById(`custom_races_${id_custom_races}`).style
            let inputClass = event.target.classList

            if(inputStyle.display == 'none') {
                inputStyle.display = 'block'
                inputClass.remove('btn-announce-default') 
                inputClass.add('btn-announce-selected')
            }else {
                inputClass.remove('btn-announce-selected')
                inputClass.add('btn-announce-default') 
                inputStyle.display = 'none'
            }
        }
    }

    const handleVerificadCustomRacesFinish = (event: any) => {
        let sum = 0;
        for (let index = 0; index < product.custom_races.length; index++) {
            if(product.custom_races[index].value == 0) {
                toast.error('Percentual tem que ser maior que zero')
                return
            }
            sum += parseInt(product.custom_races[index].value)
        }
        if(sum > 100) {
            toast.error('Percentual da raça cruzada ultrapassou 100%')
            return
        }
        if(sum < 100) {
            toast.error('Percentual da raça cruzada precisa atingir 100%')
            return
        }
        if(sum <= 0) {
            toast.error('Você precisa informa o percentual das raças')
            return
        }
        setProduct(field => {
            return {
              ...field, id_custom_races: 1
            }
        })
        window.scrollTo(10,document.body.scrollHeight);
    }

    const handleFinishDetail = async () => {

        const errorMgs: any = ValidationInputCreateAnnunce(product);
        if (errorMgs) {
            return toast.error(errorMgs)
        }

        const result = CalculationAnimalPriceTotal(product.id_type_calculation_animal, product.qtd_animals, FormatPriceEua(product.vl_price), product.nr_weight)

        setProduct(field => {
            return {
                ...field, vl_price_total: result
            }
        })

        await new Promise(resolve => setTimeout(resolve, 20))
        myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    const handleSubimitFormUpdateAnnunce = async (event: any) => {
        event.preventDefault()
        const errorMgs: any = ValidationFormCreateAnnunce(product);
        if (errorMgs) {
            return toast.error(errorMgs)
        }
        setUpdateLoading(true)
        try {
            await api.put(`/admin/product/update/${id_product}`, product)

            toast.success('Lote atualizado com sucesso', {toastId: 1})
            history.push(`/admin/lots/detail/${id_product}`)
            
            setUpdateLoading(false)
        } catch (error) {
            setUpdateLoading(false)
            const errorFormat = FormatError(error)
            errorFormat.forEach(element => {
              toast.error(element)
            });
        }
    }

    useEffect(() => {
        setExpandedResume({expanded: checkDevice()})
    },[])
    

    useEffect(() => {
        if(product.qtd_animals > 0 && product.id_category > 0 && product.nr_weight > 0){
            setProduct(field => {
                return {
                    // Titulo Anuncio
                    ...field, des_title: `${product.qtd_animals} ${typeListProduct.typeCategories.filter((typeCategorie: any) => Number(typeCategorie.id_animal_categories) === Number(product.id_category))[0]?.des_category}  ${ product.id_races ? typeListProduct.typeRaces.filter((typeRaces: any) => Number(typeRaces.id_animal_races) === Number(product.id_races))[0]?.des_races : 'Raças cruzadas'} - Peso: ${product.nr_weight} Kg`
                }
            })
        }
    }, [product.qtd_animals, product.id_category, product.nr_weight])

    useEffect(() => {
        getListTypeProduct()
        listState()
        const cleanup = () => { mounted = false; };
        return cleanup;
    }, [mounted, product.id_type_animal_placing_races, product.id_animal_sex, product.id_type_product_animal == 2])

    useEffect(() => {
        if(product.id_uf){
            listCity()
        }
    }, [product])

    useEffect(() => {
        if (product?.id_person <= 0 && id_product > 0){
           getProduct()
        }
    }, [id_product])

  return (
      <>
        {updateLoading && 
            <Loading />
        }
        <Container fluid id="lots">
            <Row>  
                <Sidebar />
                <Header />
                <div className={expandedResume.expanded ? 'resume text-center' : 'resume-mobile text-center'} id="admin-lots-update">
                    <Row>
                        <h2 className="title-custom-h2 col-12">Atualizar Lote #{id_product}</h2>
                        <div className="col-12">
                            <Card.Body className="shadow p-3 mb-5 bg-white text-center">
                                <Card.Title><b>Qual tipo de produto deseja anunciar?</b></Card.Title>
                                {typeListProduct.typeProduct.map((type: any) =>
                                    <Button name="id_type_product_animal" value={type.id_type_product_animal} onClick={handleChangeForm} className={product?.id_type_product_animal == type.id_type_product_animal ? "btn-announce-selected" : "btn-announce-default"} key={type.id_type_product_animal}>{type.des_type_product_animal}</Button>
                                )}
                            </Card.Body>
                            
                                {product?.id_type_product_animal >= 1 && 
                                    <div ref={myRef}>
                                        <Card.Body className="shadow p-3 mb-5 bg-white text-center id_animal_sex">
                                            <Card.Title><b>Qual o sexo do animal?</b></Card.Title>
                                            <Button name="id_animal_sex" value={1} onClick={handleChangeForm} className={product?.id_animal_sex == 1? "btn-announce-selected" : "btn-announce-default"}>Macho</Button>
                                            <Button name="id_animal_sex" value={2} onClick={handleChangeForm} className={product?.id_animal_sex == 2? "btn-announce-selected" : "btn-announce-default"}>Fêmea</Button>                           
                                        </Card.Body>
                                    </div>
                                }
                            {product?.id_animal_sex >= 1 && 
                                <div ref={myRef}>
                                    <Card.Body className="shadow p-3 mb-5 bg-white text-center">
                                        <Card.Title><b>Qual é a categoria do animal?</b></Card.Title>
                                        {typeListProduct.typeCategories.map((type: any) =>
                                            <Button name="id_category" value={type.id_animal_categories} onClick={handleChangeForm} className={product?.id_category == type.id_animal_categories ? "btn-announce-selected" : "btn-announce-default"} key={type.id_animal_categories}>{type.des_category}</Button>
                                        )}
                                    </Card.Body>
                                </div>
                            }
                            {product?.id_type_product_animal == 1?
                            <>
                                {product?.id_category >= 1 && 
                                    <div ref={myRef}>
                                        <Card.Body className="shadow p-3 mb-5 bg-white text-center">
                                            <Card.Title><b>Qual é a raça do animal?</b></Card.Title>
                                            {typeListProduct.typePlacingRaces.map((type: any) =>
                                                <Button name="id_type_animal_placing_races" value={type.id_type_animal_placing_races} onClick={handleChangeForm} className={product.id_type_animal_placing_races == type.id_type_animal_placing_races ? "btn-announce-selected" : "btn-announce-default"} key={type.id_type_animal_placing_races}>{type.des_type_animal_placing_races}</Button>
                                            )}
                                        </Card.Body>
                                    </div>
                                }
                                {product.id_type_animal_placing_races >= 1 && 
                                    <div ref={myRef}>
                                        <Card.Body className="shadow p-3 mb-5 bg-white text-center">
                                            <Card.Title><b>Qual é a raça do animal?</b></Card.Title>
                                            <p>Para animais cruzados, informar o percentual de raças utilizadas</p>
                                            {product.id_type_animal_placing_races == 3 ?
                                            <>
                                            <div className="row justify-content-center " onChange={handleChangeCustomRaces}>
                                            {typeListProduct.typeRaces.map((type: any) =>
                                                <div id="custom-races-form" key={type.id_animal_races}>
                                                    <Button data-id={type.id_animal_races} value={type.id_animal_races} onClick={handleShowInputCustomRaces} className="btn-announce-default" >{type.des_races}</Button>
                                                    <Form.Group style={{display: 'none'}} id={`custom_races_${type.id_animal_races}`}>
                                                        <Form.Control type="number" name={type.id_animal_races} placeholder="Informe percentual" id={`input_custom_races_${type.id_animal_races}`} />
                                                    </Form.Group>
                                                </div>
                                            )}
                                            </div>
                                            <p>Após concluír clique no botão finalizar</p>
                                            {product.id_custom_races == 0 && 
                                                <Button onClick={handleVerificadCustomRacesFinish}  className="btn btn-create">Finalizar</Button>
                                            }
                                        
                                            </>
                                            :
                                            typeListProduct.typeRaces.map((type: any) =>
                                                <Button name="id_races" value={type.id_animal_races} onClick={handleChangeForm} className={product.id_races == type.id_animal_races ? "btn-announce-selected" : "btn-announce-default"} key={type.id_animal_races}>{type.des_races}</Button>
                                            )
                                            }
                                        </Card.Body>
                                    </div>
                                }
                            </>
                            :
                            <>
                                {product.id_category >= 1 && 
                                    <div ref={myRef}>
                                        <Card.Body className="shadow p-3 mb-5 bg-white text-center">
                                            <Card.Title><b>Qual é a raça do animal?</b></Card.Title>
                                            {typeListProduct.typeRaces.map((type: any) =>
                                                <Button name="id_races" value={type.id_animal_races} onClick={handleChangeForm} className={product.id_races == type.id_animal_races ? "btn-announce-selected" : "btn-announce-default"} key={type.id_animal_races}>{type.des_races}</Button>
                                            )}
                                        </Card.Body>
                                    </div>
                                }
                            </>
                            }
                            {product.id_custom_races >= 1  &&
                                <div ref={myRef}>
                                    <Card.Body className="shadow p-3 mb-5 bg-white text-center">
                                        <Card.Title><b>Qual intervalo de peso?</b></Card.Title>
                                        {typeListProduct.typeAverageWeight.map((type: any) =>
                                            <Button name="id_average_weight" value={type.id_animal_average_weight} onClick={handleChangeForm} className={product.id_average_weight == type.id_animal_average_weight ? "btn-announce-selected" : "btn-announce-default"} key={type.id_animal_average_weight}>{type.des_average_weight}</Button>
                                        )}
                                    </Card.Body>
                                </div>
                            }{product.id_races >= 1 && 
                                <div ref={myRef}>
                                    <Card.Body className="shadow p-3 mb-5 bg-white text-center">
                                        <Card.Title><b>Em qual intervalo de peso?</b></Card.Title>
                                        {typeListProduct.typeAverageWeight.map((type: any) =>
                                            <Button name="id_average_weight" value={type.id_animal_average_weight} onClick={handleChangeForm} className={product.id_average_weight == type.id_animal_average_weight ? "btn-announce-selected" : "btn-announce-default"} key={type.id_animal_average_weight}>{type.des_average_weight}</Button>
                                        )}
                                    </Card.Body>
                                </div>
                            }
                            {product.id_average_weight >= 1 && 
                                <div ref={myRef}>
                                    <Card.Body className="shadow p-3 mb-5 bg-white text-center detail-lots">
                                        <Card.Title><b>Preencha agora os detalhes do seu lote</b></Card.Title>
                                        <div className="form-detail-lots">
                                            <Row>
                                                <Form.Group controlId="des_title" className="col-8">
                                                    <Form.Label className="labelControl text-center">Título do anúncio</Form.Label>
                                                    {product.qtd_animals && product.id_animal_sex && product.nr_weight ?
                                                        <Form.Control type="text" name="des_title" value={product.des_title} onChange={handleChangeForm} />
                                                    :
                                                        <Form.Control type="text" name="des_title"disabled={product.des_title ? false : true} value={product.des_title ? product.des_title : 'Será preenchido automaticamente...'} onChange={handleChangeForm} />            
                                                    }
                                                </Form.Group>
                                                <Form.Group controlId="qtd_animals" className="col-4">
                                                    <Form.Label className="labelControl text-center">Qtd. Animais</Form.Label>
                                                    <Form.Control type="number" name="qtd_animals" value={product.qtd_animals ? product.qtd_animals : ''} onChange={handleChangeForm} />
                                                </Form.Group>
                                            </Row>
                                                <Form.Group controlId="des_description">
                                                    <Form.Label className="labelControl text-center">Descrição</Form.Label>
                                                    <Form.Control type="text" name="des_description" value={product.des_description} onChange={handleChangeForm} />
                                                </Form.Group>
                                        
                                            <Row>
                                                <Form.Group controlId="id_type_calculation_animal" className="col-sm-12 col-md-6">
                                                    <Form.Label className="labelControl text-center">Tipo de cálculo total</Form.Label>
                                                    <Form.Control as="select" value={product.id_type_calculation_animal} name="id_type_calculation_animal" onChange={handleChangeForm}>
                                                        <option value="DEFAULT" hidden>Selecione tipo de calculo</option>
                                                        {typeListProduct.typeCalculation.map((type: any) =>
                                                        <option key={type.id_type_calculation_animal} defaultValue={parseInt(product.id_type_calculation_animal) === type.id_type_calculation_animal ? 'true' : 'false' } value={type.id_type_calculation_animal}>{type.des_type_calculation_animal}</option>
                                                        )}
                                                    </Form.Control>
                                                </Form.Group>
                                                <Form.Group controlId="vl_price" className="col-sm-6 col-md-3">
                                                    <Form.Label className="labelControl text-center">Valor (R$)</Form.Label>
                                                    <Form.Control type="text" name="vl_price" value={product.vl_price ? FormatPriceInput(product.vl_price) : ''} onChange={handleChangeForm} />
                                                </Form.Group>
                                                <Form.Group controlId="nr_weight" className="col-sm-6 col-md-3">
                                                    <Form.Label className="labelControl text-center">Peso</Form.Label>
                                                    <Form.Control type="number" name="nr_weight" value={product.nr_weight ? product.nr_weight : ''} onChange={handleChangeForm} />
                                                </Form.Group>
                                                
                                                {product.id_animal_sex == 2 && 
                                                    <div ref={myRef} className="input-radio-pregnancy col-sm-12 col-md-4">
                                                        <Form.Label className="label-nr-pregnancy">Prenhez(opcional)</Form.Label>
                                                        <Form.Group controlId="nr_pregnancy_yes">              
                                                            <Form.Check type="radio" name="nr_pregnancy" value="1" label="Sim" onChange={handleChangeForm} />
                                                        </Form.Group>
                                                        <Form.Group controlId="nr_pregnancy_no">
                                                            <Form.Check type="radio" name="nr_pregnancy" value="2" label="Não" onChange={handleChangeForm} />
                                                        </Form.Group>
                                                    </div>
                                                }
                                                {product.id_type_product_animal == 2 && product.id_animal_sex == 2 && 
                                                <>
                                                    
                                                    <Form.Group controlId="nr_pregnancy_days" className="col-sm-6 col-md-4 mt-2">
                                                        <Form.Label className="labelControl text-center">Dias de Gestação (Opcional)</Form.Label>
                                                        <Form.Control type="number" name="nr_pregnancy_days" value={product.nr_pregnancy_days ? product.nr_pregnancy_days : ''} onChange={handleChangeForm} />
                                                    </Form.Group>
                                                    <Form.Group controlId="nr_liters_day" className="col-sm-6 col-md-4 mt-2">
                                                        <Form.Label className="labelControl text-center">Litros produzidos/Dia (Opcional)</Form.Label>
                                                        <Form.Control type="number" name="nr_liters_day" value={product.nr_liters_day ? product.nr_liters_day : ''} onChange={handleChangeForm} />
                                                    </Form.Group>
                                                    <Form.Group controlId="nr_lactations" className="col-sm-6 col-md-6">
                                                        <Form.Label className="labelControl text-center">Nº Lactações (Opcional)</Form.Label>
                                                        <Form.Control type="number" name="nr_lactations" value={product.nr_lactations ? product.nr_lactations : ''} onChange={handleChangeForm} />
                                                    </Form.Group>
                                                  
                                                </>
                                                }
                                                {product.id_type_product_animal == 2 &&
                                                <>  
                                                    <div ref={myRef}>
                                                    <Form.Group controlId="nr_age_months" className="col-sm-6 col-md-6">
                                                        <Form.Label className="labelControl text-center">Idade em meses (Opcional)</Form.Label>
                                                        <Form.Control type="number" name="nr_age_months" value={product.nr_age_months ? product.nr_age_months : ''} onChange={handleChangeForm} />
                                                    </Form.Group>
                                                    </div>
                                                </>
                                                }
                                            </Row>
                                        </div>
                                        <Row className="rowFinishDetail">
                                            {product.vl_price_total == 0 && 
                                                <Button onClick={handleFinishDetail} className="btn btn-create">
                                                    Avançar
                                                </Button>
                                            }
                                        </Row>
                                        <Row className="rowFinishDetailPriceTotal">
                                            <Form.Group controlId="vl_price_total" className="col-8 vlPriceTotalInput">
                                                <Form.Label className="labelControl text-center">Valor total do lote (R$)</Form.Label>
                                                <Form.Control className="text-center" readOnly type="text" name="vl_price_total" placeholder="..." value={product.vl_price_total ? FormatPrice(product.vl_price_total) : ''} onChange={handleChangeForm} />
                                            </Form.Group>
                                        </Row>                                
                                    </Card.Body>
                                </div>
                            }
                            {product.vl_price_total >= 1 && 
                                <div ref={myRef}>
                                    <Card.Body className="shadow p-3 mb-5 bg-white text-center address-lots">
                                        <Card.Title><b>Localização do lote</b></Card.Title>
                                        <div className="form-address-lots">
                                            <Row>
                                                <Form.Group controlId="id_uf" className="col-sm-12 col-md-4">
                                                    <Form.Label className="labelControl text-center">Estado</Form.Label>
                                                    <Form.Control as="select" value={product.id_uf} name="id_uf" onChange={handleChangeForm}>
                                                        <option value="DEFAULT" hidden>Selecione seu Estado</option>
                                                        {listStateData.map((state: any) =>
                                                            <option key={state.id_uf} value={state.id_uf}>{state.des_uf}</option>
                                                        )}
                                                    </Form.Control>
                                                </Form.Group>
                                                <Form.Group controlId="code_city" className="col-sm-12 col-md-4">
                                                    <Form.Label className="labelControl text-center">Cidade</Form.Label>
                                                    <Form.Control as="select" value={product.code_city} name="code_city" onChange={handleChangeForm}>
                                                        <option value="DEFAULT" hidden>Selecione sua Cidade</option>
                                                        {listCityData.map((city: any) =>
                                                            <option key={city.code_city} value={city.code_city}>{city.des_city}</option>
                                                        )}
                                                    </Form.Control>
                                                </Form.Group>
                                                <Form.Group controlId="nr_kilometers" className="col-sm-12 col-md-4">
                                                    <Form.Label className="labelControl text-center">Quantos KM da cidade</Form.Label>
                                                    <Form.Control type="number" name="nr_kilometers" value={product?.nr_kilometers} onChange={handleChangeForm} />
                                                </Form.Group>
                                                <div className="input-radio-shipper-access col-sm-12 col-md-4">
                                                    <Form.Label className="label-nr-shipper-access">Possui embarcador?</Form.Label>
                                                    <Row className="justify-content-center">
                                                        <Form.Group controlId="nr_shipper_access_yes">              
                                                            <Form.Check type="radio" name="nr_shipper_access" value="1" defaultChecked={product?.nr_shipper_access == 1} label="Sim" onClick={handleChangeForm} />
                                                        </Form.Group>
                                                        <Form.Group controlId="nr_shipper_access_no">
                                                            <Form.Check type="radio" name="nr_shipper_access" value="2" defaultChecked={product?.nr_shipper_access == 2} label="Não" onClick={handleChangeForm} />
                                                        </Form.Group>
                                                    </Row>
                                                </div>
                                                <div className="input-radio-truck-access col-sm-12 col-md-4">
                                                    <Form.Label className="label-nr-truck-access">Possui acesso a caminhões?</Form.Label>
                                                    <Row className="justify-content-center">
                                                        <Form.Group controlId="nr_truck_access_yes">              
                                                            <Form.Check type="radio" name="nr_truck_access" value="1" defaultChecked={product?.nr_truck_access == 1} label="Sim" onClick={handleChangeForm} />
                                                        </Form.Group>
                                                        <Form.Group controlId="nr_truck_access_no">
                                                            <Form.Check type="radio" name="nr_truck_access" value="2" defaultChecked={product?.nr_truck_access == 2} label="Não" onClick={handleChangeForm} />
                                                        </Form.Group>
                                                    </Row>
                                                </div>
                                                <div className="input-radio-balance-access col-sm-12 col-md-4">
                                                    <Form.Label className="label-nr-balance-access">Possui balança</Form.Label>
                                                    <Row className="justify-content-center">
                                                        <Form.Group controlId="nr_balance_access_yes">              
                                                            <Form.Check type="radio" name="nr_balance_access" value="1" defaultChecked={product?.nr_balance_access == 1} label="Sim" onClick={handleChangeForm} />

                                                        </Form.Group>
                                                        <Form.Group controlId="nr_balance_access_no">
                                                            <Form.Check type="radio" name="nr_balance_access" value="2" defaultChecked={product?.nr_balance_access == 2} label="Não" onClick={handleChangeForm} />
                                                        </Form.Group>
                                                    </Row>
                                                </div>
                                            </Row>
                                        </div>
                                    </Card.Body>
                                </div>
                            }
                            {product.nr_balance_access >= 1 && 
                                <div ref={myRef}>
                                    <Form onSubmit={handleSubimitFormUpdateAnnunce}>
                                        <Card.Body className="shadow p-3 mb-5 bg-white text-center check-public">
                                            <Card.Title><b>Publicar</b></Card.Title>
                                            <Form.Group controlId="nr_terms" className="row justify-content-center" style={{marginLeft: '0', marginRight: '0'}}>
                                                <Form.Check type="checkbox" name="nr_terms" defaultChecked={Boolean(product.nr_terms)} onClick={handleChangeForm} />
                                                <Form.Label>Ao publicar um anúncio você concorda e aceita os <Link to="/terms" target="_blank">Termos e Condições do Leiloaẽ</Link></Form.Label>
                                            </Form.Group>
                                            <Button className="btn btn-create" type="submit">
                                                Atualizar
                                            </Button>
                                        </Card.Body>
                                    </Form>
                                </div>
                            }
                        </div>
                    </Row>
                </div>
            </Row>
        </Container>
    </>
  );
}
export default LotsUpdate;
