import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { api, HttpRequestI } from '../../services'

const name = 'StateAuction'


interface StateAuctionI {
    id_auction: number,
    AuctionPhotos: any,
    AuctionVideos: any
}

interface StateResponseI {
    data: Array<StateAuctionI>
    headers: []
    auctionSelected: StateAuctionI
}

interface MessageResponseI {
    headers: any
    data: {
        message: Array<StateAuctionI>
    }
}

interface ParamsI {
    page?: number
    limit?: number
    nr_status?: number | string
    filter?: Partial<{}>

}

type StateAuctionType = StateResponseI & {httprequest?: Partial<HttpRequestI>, params?:Partial<ParamsI>}

const initialState: StateAuctionType = {
    data: [],
    headers: [],
    auctionSelected: {} as StateAuctionI,
    httprequest: {error: false, loading: false, success: false},
    params: {page: 1, limit: 10, nr_status: 2} as ParamsI
}

const searchAuctions = createAsyncThunk(
    `${name}/SEARCH_AUCTION`, 
    async(state: Partial<ParamsI>, agrsThunk?: any) => {

        try {  
            const auctionResult = await api.apiLeloae.auction.listAuction<MessageResponseI>({page: state.page, limit: state.limit, nr_status: state.nr_status, ...state.filter})
            return auctionResult
        } catch (error) {
            return agrsThunk.rejectWithValue(error)
        }

    }
)

const StateAuction = createSlice({
    name,
    initialState,
    reducers:{
        SelectAuction:  (state, action: PayloadAction<number>) => {
            const auctionSelected = state.data.find((auction: any) => Number(auction.id_auction) === Number(action.payload))
            if(Boolean(auctionSelected) === true) {
                localStorage.setItem('@leloae/auction/selected', JSON.stringify(auctionSelected))
            }
            
            return {...state, auctionSelected}
        },
        ParamsUrl: (state, action: PayloadAction<ParamsI>) => {
            return {...state, params: action.payload}
        }
    },
    extraReducers: build => {
        build.addCase(searchAuctions.pending, (state) => {
            return {...state, httprequest:{
                loading: true,
                error: false,
                success: false
            }}
        })
        build.addCase(searchAuctions.fulfilled, (state, action: PayloadAction<StateResponseI[] | any>) => {
            return {...state, data: action.payload.data.message, headers: action.payload.headers, httprequest: {
                error: false,
                loading: false,
                success: true
            }}
        })
        build.addCase(searchAuctions.rejected, (state, action:PayloadAction<any>) => {
            return {...state, httprequest:{
                error: true,
                loading: false,
                success: false,
                errorMsg: action.payload
            }}
        })
    }
})

export const getAuctionStorage = () => {
    return Boolean(localStorage.getItem('@leloae/auction/selected')) === true && localStorage.getItem('@leloae/auction/selected') !== 'undefined' ? JSON.parse(localStorage.getItem('@leloae/auction/selected')) : null 
}

export const reducerAuction = StateAuction.reducer
export const actionAuction = {...StateAuction.actions, searchAuctions}