import styled from "styled-components"

export const Wrapper = styled.div`
    width: 100%;
    height: auto;
    overflow: hidden;
    border-radius: 15px;
    
    img {
        width: 348px;
	    transition: .3s ease-in-out;
        
        &:hover {
            transform: scale(1.1);
        }
    }
`