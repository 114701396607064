/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

import { Form, Button } from 'react-bootstrap';

const ForgotRestPasswordForm: React.FC = () => {
  return (
    <>
        <Form>
            <Form.Group controlId="des_new_password">
                <Form.Control type="text" placeholder="Informe seu nova senha" />
            </Form.Group>
            <Button className="col-12 btn btn-create" type="submit">
                Alterar
            </Button>
        </Form>
    </>
  );
}
export default ForgotRestPasswordForm;
