/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';

import Header from '../../../components/HeaderAdmin'
import Sidebar from '../SideNav'

import './styles.css'
import { Button, Col, Container, Row} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandHoldingUsd, faFile, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FormatCep, FormatCpf, FormatDateHoursPtBr, FormatPhone, FormatPrice } from '../../../helpers/format';

import { checkDevice } from '../../../helpers/utils';
import { Link } from 'react-router-dom';
import Loading from '../../../components/Loading';
import { FormatError } from '../../../helpers/formatError';
import { toast } from 'react-toastify';
import { api } from '../../../services/apiLeloae/config';


const RequestsNegotiationDetailResume: React.FC = (propos: any) => {
    const id_product_negotiation_request = propos.match.params.id_product_negotiation_request

    const [expandedResume, setExpandedResume] = useState({ expanded: null })
    const [ProductLoading, setProductLoading] = useState(false)
    const [product, setProduct] = useState(null)
    const [userBuy, setUserBuy] = useState(null)
    const [userBuyAddress, setUserBuyAddress] = useState(null)
    const [userSeller, setUserSeller] = useState(null)
    const [userSellerAddress, setUserSellerAddress] = useState(null)

    const getProduct = async () => {
        setProductLoading(true)
        try {
            const product: any = await api.get(`/admin/negotiation/request/product/show/${id_product_negotiation_request}`)

            setProduct(product.data?.message)

            setProductLoading(false)
        } catch (error) {
            setProductLoading(false)
            const errorFormat = FormatError(error)
            errorFormat.forEach(element => {
            toast.error(element)
            });
        }
    }


    const getUserBuy = async (id_person) => {
        setProductLoading(true)
        try {
            const user: any = await api.get(`/admin/user/${id_person}`)
            const address: any = await api.get(`/admin/address/${id_person}`)

            setUserBuy(user.data?.message)
            setUserBuyAddress(address.data?.message)

            setProductLoading(false)
        } catch (error) {
            setProductLoading(false)
            const errorFormat = FormatError(error)
            errorFormat.forEach(element => {
            toast.error(element)
            });
        }
    }

    const getUserseller = async (id_person) => {
        setProductLoading(true)
        try {
            const user: any = await api.get(`/admin/user/${id_person}`)
            const address: any = await api.get(`/admin/address/${id_person}`)

            setUserSeller(user.data?.message)
            setUserSellerAddress(address.data?.message)

            setProductLoading(false)
        } catch (error) {
            setProductLoading(false)
            const errorFormat = FormatError(error)
            errorFormat.forEach(element => {
            toast.error(element)
            });
        }
    }

    const handlerStatusNegotiation = async (event: any) => {
        event.preventDefault()
        const value = event.target.value

        setProductLoading(true)
        try {
            await api.put(`/admin/negotiation/request/product/update/${id_product_negotiation_request}`, {id_product: product?.id_product, nr_status: value})

            getProduct()

            setProductLoading(false)
        } catch (error) {
            setProductLoading(false)
            const errorFormat = FormatError(error)
            errorFormat.forEach(element => {
            toast.error(element)
            });
        }
    }

    useEffect(() => {
        getProduct()
        setExpandedResume({expanded: checkDevice()})
    }, [])

    useEffect(() => {
        if(product?.id_person > 0) {
        getUserBuy(product?.id_person)
        getUserseller(product?.Product?.id_person)
        }
    }, [product])

    console.log(product)


  return (
    <>
      <Container fluid id="requests-negotiation-detail">
          <Row>  
              <Sidebar />
              <Header />
                <div className={expandedResume.expanded ? 'resume' : 'resume-mobile'}>
                  <Row className="row-requests-detail">
                    <Col sm={12} className="shadow mb-3 bg-white colunm nav">
                      <Link className="btn-link" to={`/admin/requests/negotiation/detail/resume/${id_product_negotiation_request}`}>Resumo</Link>
                      <Link className="btn-link" to={`/admin/requests/detail/${product?.id_product}`}>Dados do lote</Link>
                    </Col>
                    {product && (
                      <>
                      {ProductLoading && 
                          <Loading />
                      }
                      <Col sm={12} className="shadow mb-3 bg-white resume-request">
                        <Col sm={12}>
                            <div id="detail_status">
                                <div className="text-progress">
                                    <p id="text-1">{FormatDateHoursPtBr(product?.updatedAt)}</p>
                                </div>
                                <div className="product-info row">
                                    <div className="col-sm-1">
                                        <FontAwesomeIcon icon={faFile} size="3x" />
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <p id="text-status-1"><b>Número do pedido</b></p>
                                        <p id="text-status-2">{product?.id_product}</p>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <p id="text-status-1"><b>Valor total do lote</b></p>
                                        <p id="text-status-2">{FormatPrice(product?.Product?.vl_price_total)}</p>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <p id="text-status-1"><b>Forma de pagamento</b></p>
                                        <p id="text-status-2">{parseInt(product?.nr_type_payment) === 1 ? 'Transferência bancária' : 'Boleto bancário'}</p>
                                    </div>
                                    <div className="col-12 col-md-2">
                                        {product?.nr_status == 1 ? <p id="text-status-2" className="btn-info-status">Negocio fechado</p> : product?.nr_status == 2 ? <p id="text-status-2" className="btn-info-status red">Negócio não aceita</p> : <p id="text-status-2" className="btn-info-status">Aguardando confirmação</p>}
                                    </div>
                                    <div className="col-12 col-md-3" style={{marginTop: '20px'}}>
                                        <p id="text-status-1"><b>Valor para negociação</b></p>
                                        <p id="text-status-2" style={{color: '#ba7606'}}>{FormatPrice(product?.vl_price)}</p>
                                    </div>
                                    <div className="col-12 col-md-3" style={{marginTop: '20px'}}>
                                        <p id="text-status-1"><b>justificativa</b></p>
                                        <p id="text-status-2">{product?.des_justification}</p>
                                    </div>
                                </div>
                                {product?.nr_status !== 1 && 
                                    <Button className="btn btn-create" onClick={handlerStatusNegotiation} value="1">Aceitar</Button>
                                }
                                <Button className="btn btn-cancel" onClick={handlerStatusNegotiation} value="2">recusar</Button>
                            </div>
                        </Col>
                        <Col sm={12} id="data-buyer">
                            <h4>Dados do solicitante</h4>
                            <Row>
                                <Col sm={1}>
                                    <FontAwesomeIcon icon={faHandHoldingUsd} size="3x" />
                                </Col>
                                <Col sm={11}>
                                    <Row>
                                        <Col sm={12} md={3}>
                                            <b>Nome</b>
                                            <p>{userBuy?.Person?.des_person} {userBuy?.Person?.des_lest_person}</p>
                                        </Col>
                                        <Col sm={12} md={3}>
                                            <b>CPF</b>
                                            <p>{FormatCpf(userBuy?.Person?.nr_cpf)} </p>
                                        </Col>
                                        <Col sm={12} md={3}>
                                            <b>E-mail</b>
                                            <p>{userBuy?.des_email}</p>
                                        </Col>
                                        <Col sm={12} md={3}>
                                            <b>Telefone</b>
                                            <p>{FormatPhone(userBuy?.nr_phone)}</p>
                                        </Col>
                                        <Col sm={12}>
                                            <b>Endereço</b>
                                            <p>{userBuyAddress?.des_address}, {userBuyAddress?.des_number}</p>
                                            <p>{userBuyAddress?.City?.des_city} - {userBuyAddress?.State?.uf}</p>
                                            <p>{FormatCep(userBuyAddress?.nr_zip_code)}</p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={12} id="data-salesman">
                            <h4>Dados do vendedor</h4>
                            <Row>
                                <Col sm={1}>
                                    <FontAwesomeIcon icon={faUserCircle} size="3x" />
                                </Col>
                                <Col sm={11}>
                                    <Row>
                                        <Col sm={12} md={3}>
                                            <b>Nome</b>
                                            <p>{userSeller?.Person?.des_person} {userSeller?.Person?.des_lest_person}</p>
                                        </Col>
                                        <Col sm={12} md={3}>
                                            <b>CPF</b>
                                            <p>{FormatCpf(userSeller?.Person?.nr_cpf)} </p>
                                        </Col>
                                        <Col sm={12} md={3}>
                                            <b>E-mail</b>
                                            <p>{userSeller?.des_email}</p>
                                        </Col>
                                        <Col sm={12} md={3}>
                                            <b>Telefone</b>
                                            <p>{FormatPhone(userSeller?.nr_phone)}</p>
                                        </Col>
                                        <Col sm={12}>
                                            <b>Endereço</b>
                                            <p>{userSellerAddress?.des_address}, {userSellerAddress?.des_number}</p>
                                            <p>{userSellerAddress?.City?.des_city} - {userSellerAddress?.State?.uf}</p>
                                            <p>{FormatCep(userSellerAddress?.nr_zip_code)}</p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Col>
                  </>
                  )}
                  </Row>
                </div>
          </Row>

      </Container>
    </>
  );
}
export default RequestsNegotiationDetailResume;
