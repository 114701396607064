import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { api, HttpRequestI } from '../../services'

const name = 'StateProductSearch'


interface StateProductSearchI {
    id_product: number,
    ProductPhotos: any
}

interface StateResponseI {
    data: Array<StateProductSearchI>
    productSelected: StateProductSearchI
}

interface MessageResponseI {
    data: {
        message: Array<StateProductSearchI>
    }
}

interface ParamsI {
    page?: number
    limit?: number
    filter?: Partial<{
        id_type_product_animal: number
        id_category: any
        paramsURL: any
        name: any
        nr_status: number,
        search: string
    }>

}

type StateProductSearchType = StateResponseI & {httprequest?: Partial<HttpRequestI>, params?:Partial<ParamsI>}

const initialState: StateProductSearchType = {
    data: [],
    productSelected: {} as StateProductSearchI,
    httprequest: {error: false, loading: false, success: false},
    params: {page: 1, limit: 10, nr_status: 2} as ParamsI
}

const searchProducts = createAsyncThunk(
    `${name}/SEARCH_PRODUCT`, 
    async(state: Partial<ParamsI>, agrsThunk?: any) => {
        try {
            const productResult = await api.apiLeloae.product.listSearch<MessageResponseI>({page: state.page, limit: state.limit, ...state.filter})
            await new Promise(resolve => setTimeout(resolve, 1000))
            // console.log(productResult)

            return productResult.data?.message
        } catch (error) {
            return agrsThunk.rejectWithValue(error)
        }

    }
)

const StateProductSearch = createSlice({
    name,
    initialState,
    reducers:{
        SelectProduct:  (state, action: PayloadAction<number>) => {
            const productSelected = state.data.find((product: any) => Number(product.id_product) === Number(action.payload))
            if(Boolean(productSelected) === true) {
                localStorage.setItem('@leloae/product/search/selected', JSON.stringify(productSelected))
            }
            
            return {...state, productSelected}
        },
        ParamsUrl: (state, action: PayloadAction<ParamsI>) => {
            return {...state, params: action.payload}
        }
    },
    extraReducers: build => {
        build.addCase(searchProducts.pending, (state) => {
            return {...state, httprequest:{
                loading: true,
                error: false,
                success: false
            }}
        })
        build.addCase(searchProducts.fulfilled, (state, action: PayloadAction<StateProductSearchI[]>) => {
            return {...state, data: action.payload, httprequest: {
                error: false,
                loading: false,
                success: true
            }}
        })
        build.addCase(searchProducts.rejected, (state, action:PayloadAction<any>) => {
            return {...state, httprequest:{
                error: true,
                loading: false,
                success: false,
                errorMsg: action.payload
            }}
        })
    }
})

export const getProductStorage = () => {
    return Boolean(localStorage.getItem('@leloae/product/search/selected')) === true && localStorage.getItem('@leloae/product/search/selected') !== 'undefined' ? JSON.parse(localStorage.getItem('@leloae/product/search/selected')) : null 
}

export const reducerProductSearch = StateProductSearch.reducer
export const actionProducttSearch = {...StateProductSearch.actions, searchProducts}