/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';

import './styles.css'
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import imgLogo from '../../../assets/image/leiloae-logo-2@4x.png'

import AuthenticatedUser from './authenticatedUser'

import { Container, Figure} from 'react-bootstrap';
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';

import { checkDevice } from '../../../helpers/utils';
import { Link } from 'react-router-dom';

const SideNavMenu: React.FC = () => {

    const [expandedNav, setExpandedNav] = useState({ expanded: null })

    useEffect(() => {
        setExpandedNav({expanded: checkDevice()})
      },[])

  return (
    <>
      <Container id="menu-side" >
        <SideNav
            expanded={expandedNav.expanded}
            onToggle={(expanded) => {
                setExpandedNav({expanded})
            }}
        >
        <SideNav.Toggle  />
            <div className="logo">
                <Figure.Image className="d-none d-lg-block" width={80} alt="Leiloaê" src={imgLogo}/>
            </div>
          <SideNav.Nav defaultSelected="home">
              <NavItem eventKey="home">
                <NavIcon>
                </NavIcon>
                  <NavText>
                    <Link to="/admin">Início</Link>
                  </NavText>
              </NavItem>
              <NavItem eventKey="requests">
                  <NavIcon>
                    </NavIcon>
                  <NavText>
                    <Link to="/admin/requests">Pedidos</Link>
                  </NavText>
              </NavItem>
              <NavItem eventKey="requestsNegotiation">
                  <NavIcon>
                    </NavIcon>
                  <NavText>
                    <Link to="/admin/requests/negotiation">Pedidos de negociação</Link>
                  </NavText>
              </NavItem>
              <NavItem eventKey="auctionLots">
                  <NavIcon>
                    </NavIcon>
                  <NavText>
                    <Link to="/admin/auction/lots">Leilões</Link>
                  </NavText>
              </NavItem>
              <NavItem eventKey="lots">
                  <NavIcon>
                    </NavIcon>
                  <NavText>
                    <Link to="/admin/lots">Lotes</Link>
                  </NavText>
              </NavItem>
              <NavItem eventKey="users">
                  <NavIcon>
                    </NavIcon>
                  <NavText>
                    <Link to="/admin/users">Usuários</Link>
                  </NavText>
              </NavItem>
              <NavItem eventKey="posts">
                  <NavIcon>
                    </NavIcon>
                  <NavText>
                    <Link to="/admin/messages">Mensagens</Link>
                  </NavText>
              </NavItem>
              <NavItem className="d-md-none d-lg-none" eventKey="authentication">
                  <NavIcon>
                    </NavIcon>
                  <NavText>
                    <AuthenticatedUser />
                  </NavText>
              </NavItem>
          </SideNav.Nav>
        </SideNav>
      </Container>
    </>
  );
}
export default SideNavMenu;
