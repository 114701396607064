/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-unreachable */
/* eslint-disable eqeqeq */
import React, { useEffect, useMemo, useRef, useState } from "react"
import { useHistory } from "react-router-dom"

import Footer from "../../components/Footer"
import Navbar from "../../components/Navbar"
import Loading from "../../components/Loading"

import "./styles.css"
import { Button, Card, Container, Figure, Form, Row } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCamera,
  faVideo,
  faFile,
  faTrash,
} from "@fortawesome/free-solid-svg-icons"

import { toast } from "react-toastify"
import axios from "axios"
import { api } from "../../services/apiLeloae/config"
import { FormatError } from "../../helpers/formatError"
import { useUserContext } from "../../providers/use-sign"

const AnnounceFiles: React.FC = (propos: any) => {
  const { id_auction, id_product } = propos.match.params

  const myRef = useRef<null | HTMLDivElement>(null)
  const [updateLoading, setUpdateLoading] = useState(false)
  const [imgProduct, setImgProduct] = useState([])
  const [videoProduct, setVideoProduct] = useState([])
  const [imgDocumentProduct, setImgDocumentProduct] = useState([])
  const [imgPreview, setimgPreview] = useState<any>([])
  const [videoPreview, setVideoPreview] = useState<any>([])
  const [documentPreview, setDocumentPreview] = useState<any>([])
  const [ProgressUpload, setProgressUpload] = useState<any>(0)
  const [product, setProduct] = useState<any>([])

  const signal = axios.CancelToken.source()
  const { data } = useUserContext()
  const history = useHistory()

  useEffect(() => {
    renderViewImg()
  }, [imgProduct])

  const renderViewImg = () => {
    let url: any
    for (let index = 0; index < imgProduct.length; index++) {
      url = imgProduct ? [...imgProduct].map(URL.createObjectURL) : null
    }
    setimgPreview(url)
  }

  useMemo(() => {
    let url: any
    for (let index = 0; index < videoProduct.length; index++) {
      url = videoProduct ? [...videoProduct].map(URL.createObjectURL) : null
    }
    setVideoPreview(url)
  }, [videoProduct])

  useEffect(() => {
    renderViewImgDocument()
  }, [imgDocumentProduct])

  const renderViewImgDocument = () => {
    let url: any
    for (let index = 0; index < imgDocumentProduct.length; index++) {
      url = imgDocumentProduct
        ? [...imgDocumentProduct].map(URL.createObjectURL)
        : null
    }
    setDocumentPreview(url)
  }

  const handleChangeFormProductImg = (event: any) => {
    const images = []

    const files = event.target.files
    images.push(...imgProduct, ...files)

    setImgProduct(images)
  }

  const handleChangeFormProductVideo = (event: any) => {
    setVideoProduct(event.target.files)
  }
  const handleChangeFormProductDocumentImg = (event: any) => {
    const imagesDocuments = []

    const files = event.target.files
    imagesDocuments.push(...imgDocumentProduct, ...files)

    setImgDocumentProduct(imagesDocuments)
  }

  const handleDeleteImg = (index: number) => {
    imgProduct.splice(index, 1)

    setImgProduct(imgProduct)
    renderViewImg()
  }

  const handleDeleteImgDocuments = (index: number) => {
    imgDocumentProduct.splice(index, 1)

    setImgDocumentProduct(imgDocumentProduct)
    renderViewImgDocument()
  }

  const handleProgressUpload = async (event: any) => {
    const value = event.target.value

    if (!imgProduct[0] && !videoProduct[0]) {
      return toast.error("O envio de fotos é obrigatório")
    }
    setProgressUpload(value)
    await new Promise((resolve) => setTimeout(resolve, 20))
    myRef.current.scrollIntoView({ behavior: "smooth", block: "start" })
  }

  const checkStateProduct = async () => {
    try {
      const responseProduct: any = await api.get(
        `/product/show/${id_product}`,
        { cancelToken: signal.token }
      )

      setProduct(responseProduct.data?.message)

      if (responseProduct.data?.message.nr_status != 1) {
        toast.error("Seu lote já está completo", { toastId: 1 })
        history.push("/product")
      }
    } catch (error) {
      toast.error("Ocorreu algum erro", { toastId: 1 })
    }
  }

  const handleSubmitPhofilePhoto = async (event: any) => {
    event.preventDefault()
    setUpdateLoading(true)
    try {
      const dataPhotoProduct = new FormData()
      for (let index = 0; index < imgProduct.length; index++) {
        dataPhotoProduct.append("des_product_photos", imgProduct[index])
      }
      dataPhotoProduct.append("id_product", String(id_product))
      dataPhotoProduct.append("id_person", String(data.user.id_person))
      await api.post(`/product/photo`, dataPhotoProduct)

      if (videoProduct[0]) {
        const dataVideoProduct = new FormData()
        for (let index = 0; index < videoProduct.length; index++) {
          dataVideoProduct.append("des_product_video", videoProduct[index])
        }
        dataVideoProduct.append("id_product", String(id_product))
        dataVideoProduct.append("id_person", String(data.user.id_person))
        dataVideoProduct.append("des_title", String(product.des_title))
        await api.post(`/video/product`, dataVideoProduct)
      }

      if (imgDocumentProduct[0]) {
        const dataDocumentProduct = new FormData()
        for (let index = 0; index < imgDocumentProduct.length; index++) {
          dataDocumentProduct.append(
            "des_product_document_photos",
            imgDocumentProduct[index]
          )
        }
        dataDocumentProduct.append("id_product", String(id_product))
        dataDocumentProduct.append("id_person", String(data.user.id_person))
        await api.post(`/product/document/photo`, dataDocumentProduct)
      }

      await api.put(`/product/update/${id_product}`, product)

      setUpdateLoading(false)

      toast.success("Lote finalizado com sucesso!", { toastId: 1 })
      if (id_auction) {
        history.push(`/auction/detail/${id_auction}`)
      } else {
        history.push("/user/lots/sales")
      }
    } catch (error) {
      const errorFormat = FormatError(error)
      errorFormat.forEach((element) => {
        toast.error(element)
      })
      setUpdateLoading(false)
    }
  }

  useEffect(() => {
    setProduct((field) => {
      return {
        ...field,
        nr_status: 2,
      }
    })
  }, [imgProduct])

  const scrollTop = async () => {
    myRef.current.scrollIntoView({ behavior: "smooth", block: "start" })
  }

  useEffect(() => {
    checkStateProduct()
    scrollTop()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {updateLoading && <Loading />}
      <div ref={myRef}></div>
      <Navbar />
      <Container>
        <div id="send-files-announce" className="text-center">
          <Row>
            <h2 className="title-custom-h2 col-12">Envio de arquivos</h2>
            <Card.Body
              id="photo-product"
              className="shadow p-3 mb-5 bg-white text-center col-12"
            >
              <Card.Title className="col-12">
                <b>Deixe seu anúncio mais atrativo com fotos</b>
              </Card.Title>
              <Row>
                <Figure className="row">
                  {imgPreview
                    ? imgPreview.map((img: any, index: number) => (
                        <div key={img}>
                          <Figure.Image src={img} />
                          <Button
                            className="btn-trash"
                            onClick={() => handleDeleteImg(index)}
                          >
                            <FontAwesomeIcon icon={faTrash} size="1x" />
                          </Button>
                        </div>
                      ))
                    : ""}
                </Figure>
                <Form>
                  <label className="upload-foto-profile">
                    <input
                      type="file"
                      accept="image/png, image/jpeg, image/jpg"
                      multiple
                      onChange={handleChangeFormProductImg}
                    />
                    <p className="upload-foto-profile-input">
                      <FontAwesomeIcon icon={faCamera} size="1x" />{" "}
                    </p>
                  </label>
                </Form>
                <p className="col-12 text-left">Arquivos PNG,JPG, JPEG</p>
              </Row>
              {ProgressUpload === 0 && (
                <Button
                  value="1"
                  onClick={handleProgressUpload}
                  className="btn btn-create"
                >
                  Avançar
                </Button>
              )}
            </Card.Body>
            {ProgressUpload >= 1 && (
              <div ref={myRef} className="col-12">
                <Card.Body
                  id="video-product"
                  className="shadow p-3 mb-5 bg-white text-center col-12"
                >
                  <Card.Title className="col-12">
                    <b>Deixe seu anúncio mais completo enviado vídeo</b>
                  </Card.Title>
                  <p>Opcional</p>
                  <Row>
                    <Figure>
                      {videoPreview
                        ? videoPreview.map((video: any) => (
                            <div key={video}>
                              <video src={video}></video>
                              <Button
                                className="btn-trash"
                                onClick={() => setVideoProduct([])}
                              >
                                <FontAwesomeIcon icon={faTrash} size="1x" />
                              </Button>
                            </div>
                          ))
                        : ""}
                    </Figure>
                    <Form>
                      <label className="upload-foto-profile">
                        <input
                          type="file"
                          accept="video/*"
                          onChange={handleChangeFormProductVideo}
                        />
                        <p className="upload-foto-profile-input">
                          <FontAwesomeIcon icon={faVideo} size="1x" />{" "}
                        </p>
                      </label>
                    </Form>
                    <p className="col-12 text-left">
                      Arquivos MP4, AVI, WMV, 3GPP, limite tempo 2 minutos
                    </p>
                  </Row>
                  {ProgressUpload == 1 && (
                    <Button
                      value="2"
                      onClick={handleProgressUpload}
                      className="btn btn-create"
                    >
                      Avançar
                    </Button>
                  )}
                </Card.Body>
              </div>
            )}
            {ProgressUpload >= 2 && (
              <div ref={myRef} className="col-12">
                <Card.Body
                  id="document-product"
                  className="shadow p-3 mb-5 bg-white text-center col-12"
                >
                  <Card.Title className="col-12">
                    <b>Adicionar informações anexas</b>
                  </Card.Title>
                  <p>Opcional</p>
                  <Row>
                    <Figure className="row">
                      {documentPreview
                        ? documentPreview.map(
                            (imgDocument: any, index: number) => (
                              <div key={imgDocument}>
                                <Figure.Image src={imgDocument} />
                                <Button
                                  className="btn-trash"
                                  onClick={() =>
                                    handleDeleteImgDocuments(index)
                                  }
                                >
                                  <FontAwesomeIcon icon={faTrash} size="1x" />
                                </Button>
                              </div>
                            )
                          )
                        : ""}
                    </Figure>
                    <Form>
                      <label className="upload-foto-profile">
                        <input
                          type="file"
                          accept="image/png, image/jpeg, image/jpg"
                          multiple
                          onChange={handleChangeFormProductDocumentImg}
                        />
                        <p className="upload-foto-profile-input">
                          <FontAwesomeIcon icon={faFile} size="1x" />{" "}
                        </p>
                      </label>
                    </Form>
                    <p className="col-12 text-left">Arquivos PNG,JPG, JPEG</p>
                  </Row>
                  <Button
                    onClick={handleSubmitPhofilePhoto}
                    className="btn btn-create"
                  >
                    Finalizar
                  </Button>
                </Card.Body>
              </div>
            )}
          </Row>
        </div>
      </Container>
      <Footer />
    </>
  )
}
export default AnnounceFiles
