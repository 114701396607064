import React from "react"

import Button from "../Button"

import CertifiedIcon from "../Icons/CertifiedIcon"
import DestaqueIcon from "../Icons/DestaqueIcon"
import NewIcon from "../Icons/NewIcon"

import arrobaIcon from "../../assets/image/arrobaIcon.png"
import freteIcon from "../../assets/image/freteIcon.png"
import localizationIcon from "../../assets/image/localizationIcon.png"
import quantidade from "../../assets/image/quantidade.png"
import semImagem from "../../assets/image/sem-imagem.png"

import * as S from "./styles"

export type AnimalCardProps = {
  image: string
  alt: string
  title: string
  price: string
  address: string
  animalQtd: number
  animalWeight: string
  variant: "destaque" | "novo" | "lista"
}

const AnimalCard = ({
  image,
  alt,
  title,
  price,
  address,
  animalQtd,
  animalWeight,
  variant,
}: AnimalCardProps) => {
  const arroba = (parseInt(animalWeight) / 15).toFixed(2)

  return (
    <S.Wrapper variant={variant}>
      <S.CardTop>
        <S.TopIcons>
          {!!variant && variant === "destaque" && <DestaqueIcon />}
          {!!variant && variant === "novo" && <NewIcon />}
          <CertifiedIcon />
        </S.TopIcons>
        <S.Image
          src={!!image ? image : semImagem}
          alt={alt}
          className="lazyload"
          width="100%"
          height="100%"
        />
        <S.Title>{title}</S.Title>
      </S.CardTop>
      <S.CardBottom>
        <S.InfoSection1>
          <S.Line1>
            <S.InfoText>
              <img
                src={localizationIcon}
                alt="Ícone de localização"
                width="28px"
                height="28px"
                role="none"
              />
              <span>{address}</span>
            </S.InfoText>
          </S.Line1>
          <S.Line2>
            <S.InfoText>
              <img
                src={quantidade}
                alt="Ícone de quantidade"
                width="28px"
                height="28px"
                role="none"
                style={{ margin: "0 0 -15px" }}
              />
              {animalQtd === 1 ? (
                <span>1 animal</span>
              ) : (
                <span>{animalQtd} animais</span>
              )}
            </S.InfoText>
            <S.InfoText>
              <img
                src={arrobaIcon}
                alt="Ícone de arrobas"
                width="27px"
                height="29px"
                role="none"
              />
              <span>{arroba}@</span>
            </S.InfoText>
          </S.Line2>
        </S.InfoSection1>
        <S.InfoSection2>
          {!!variant && variant !== "lista" && (
            <S.FreteCol>
              <img
                src={freteIcon}
                alt="Ícone de frete"
                width="64px"
                height="22px"
                role="none"
              />
              <span>Frete à combinar</span>
            </S.FreteCol>
          )}
          <S.PriceCol variant={variant}>
            <span>Valor do lote:</span>
            <strong>{price}</strong>
          </S.PriceCol>
        </S.InfoSection2>
        <S.ButtonSection>
          <Button width={"305px"} variant={"icon"}>
            Ver mais detalhes
          </Button>
        </S.ButtonSection>
      </S.CardBottom>
    </S.Wrapper>
  )
}

export default AnimalCard
