import styled from "styled-components"

export const Wrapper = styled.div`
  padding: 0;
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: column;

  .input-group {
    z-index: 15;
  }

  .search-input {
    width: 60vh !important;
  }

  .input-group > .form-control {
    border-radius: 25px !important;
    height: calc(1.9em + 0.8rem + 5px);
    font-size: 1.2rem;
  }

  .icon-search {
    background: #fff;
    color: #fd880d;
    height: 38px;
    padding: 3px;
    right: 13px;
    position: absolute;
    top: 8px;
    cursor: pointer;
  }

  .input-group-append {
    z-index: 10;
  }

  .search-list {
    position: absolute;
    background: #fff;
    width: 100%;
    padding: 50px 0px 10px;
    margin-top: -35px;
    border-radius: 20px;
    z-index: 10;
    font-size: 18px;
  }

  .search-list li {
    list-style-type: none;
    margin: 0px 0;
    padding: 6px 20px;
  }

  .search-list li .icon-search-list {
    color: #beb5b5;
    margin: 0 0px 0 0px;
  }

  .search-list a {
    color: rgb(87, 78, 78);
    font-weight: 600;
    margin-top: 5px;
    width: 100%;
    text-decoration: none;
  }

  .search-list li:hover {
    background: rgb(58, 166, 255);
    font-weight: 600;
  }
`
