/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';

import { Form, Card, Button, Accordion } from 'react-bootstrap';

import { useParamsProductContext } from '../../../providers/product-params';

const LitersPerDay: React.FC = () => {
    const { setParamsProduct } = useParamsProductContext()

    const [attrRacesBox, setAttrRacesBox] = useState({
        litersPerDay: '+'
    })

    const menuLeftBox = (event: any) => {
        const nameBox = event.target.dataset.id
        setAttrRacesBox(field => {
            return {
              ...field, [`${nameBox}`]:
              nameBox === 'litersPerDay' ? attrRacesBox.litersPerDay === '+' ? '-' : '+' : ''
            }
        })
    }

    const handleChangeListFilter = async (event: any) => {
        const name = event.target.name
        const value = event.target.value

        await setParamsProduct({type: name, value: value})
    }

  return (
    <>
        <Accordion defaultActiveKey="0">
            <Card className="shadow mb-3 bg-white">
                <Card.Header>
                <Accordion.Toggle as={Button} data-id="litersPerDay" onClick={menuLeftBox} className="text-left" variant="link" eventKey="1">
                    <b data-id="litersPerDay">Litros por dia <b data-id="litersPerDay">{attrRacesBox.litersPerDay}</b></b>
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                <Card.Body>
                    <Form.Check type="radio" id="pregnancy_days_0" onClick={handleChangeListFilter} value="" defaultChecked name="nr_liters_day" label="Todos" />
                    <Form.Check type="radio" id="pregnancy_days_1" name="nr_liters_day" label="Até 10" onClick={handleChangeListFilter} value="10" />
                    <Form.Check type="radio" id="pregnancy_days_2" name="nr_liters_day" label="Até 20" onClick={handleChangeListFilter} value="20" />
                    <Form.Check type="radio" id="pregnancy_days_3" name="nr_liters_day" label="Até 30" onClick={handleChangeListFilter} value="30" />
                    <Form.Check type="radio" id="pregnancy_days_4" name="nr_liters_day" label="Até 40" onClick={handleChangeListFilter} value="40" />
                    <Form.Check type="radio" id="pregnancy_days_5" name="nr_liters_day" label="Acima de 50" onClick={handleChangeListFilter} value="50" />
                </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    </>
  );
}
export default LitersPerDay;
