/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import Pagination from 'rc-pagination';

import Header from '../../../components/HeaderAdmin'
import Sidebar from '../SideNav'

import imgIconEye from '../../../assets/image/icon_eye.png'

import 'rc-pagination/assets/index.css'
import './styles.css'

import { Col, Container, FormControl, InputGroup, Row} from 'react-bootstrap';
import { checkDevice } from '../../../helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faMapMarked, faCalendar } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { api } from '../../../services/apiLeloae/config';
import { FormatPhone, FormatCpf, FormatDatePtBr } from '../../../helpers/format';

const Users: React.FC = () => {
    const [expandedResume, setExpandedResume] = useState({ expanded: null })
    const [showIfo, setShowInfo] = useState(null)
    const [listUsers, setListUsers] = useState<any>([])
    const [totalUsers, setTotalUsers] = useState<any>([])
    const [addressUser, setAddressUser] = useState<any>([])  
    const [loadingPagination, setLoadingPagination] = useState(false)

    let mounted = true;
    const signal = axios.CancelToken.source();

    useEffect(() => {
        setExpandedResume({expanded: checkDevice()})
    },[])
    const handleClickShowInfo = async (event: any) => {
        const id = event.target.dataset.id
        setShowInfo(id)
        try {
            const responseAddressUsers: any = await api.get(`/admin/address/${id}`, {cancelToken: signal.token})
            if (mounted) {setAddressUser(responseAddressUsers?.data?.message)}
          } catch (error) {
            setAddressUser([])
            toast.error('Error e buscar o endereço', {toastId: 1})
        }
    }

    const getListUsers = async (page = 1) => {
        if( loadingPagination ) {return}
        if ( totalUsers > 0 && listUsers.length == totalUsers ) {return}
        setLoadingPagination(true)
        try {
            const responseListUsers: any = await api.get('/admin/user', {params: {page: page, limit: 10}, cancelToken: signal.token})
            if (mounted) {setListUsers(responseListUsers?.data?.message)}
            setTotalUsers(responseListUsers.headers['x-total-count'])
            setLoadingPagination(false)
        } catch (error) {
          toast.error('Error e buscar lista de usuários', {toastId: 1})
        }
    }

    const handleSearchUser = (async (event: any) => {
        const value = event.target.value
        try {
            const responseListUsers: any = await api.get('/admin/user/search', {params: {search: value} })
            if (mounted) {setListUsers(responseListUsers?.data?.message)}
          } catch (error) {
            toast.error('Error e buscar lista de usuários', {toastId: 1})
          }
    })
      
    useEffect(() => {
        getListUsers()
        const cleanup = () => { mounted = false; };
        return cleanup;
    }, [mounted])

  return (
    <>
      <Container fluid id="users">
          <Row>  
              <Sidebar />
              <Header />
                <div className={expandedResume.expanded ? 'resume' : 'resume-mobile'}>
                    <Col sm={12} className="shadow mb-3 bg-white colunm nav">
                        <InputGroup className="col-sm-12 col-lg-4">
                            <FormControl
                            placeholder="Buscar"
                            aria-label="Buscar"
                            aria-describedby="basic-addon2"
                            onChange={handleSearchUser}
                            />
                            <InputGroup.Append>
                                <FontAwesomeIcon className="icon-search" icon={faSearch} size="2x" />
                            </InputGroup.Append>
                        </InputGroup>
                    </Col>
                    <Container className="col-12">
                        <Col sm={12} className="shadow mb-3 bg-white colunm list-register-users  text-center">
                            <div id="user" className="row">
                                <div className="description col-md-3 col-sm-4">
                                    <b>Nome completo</b>
                                </div>
                                <div className="description col-md-3 col-sm-4">
                                    <b>CPF</b>
                                </div>
                                <div className="description col-md-3 col-sm-4">
                                    <b>E-mail</b>
                                </div>
                                <div className="description col-md-2 col-sm-4">
                                    <b>Telefone</b>
                                </div>
                            </div>
                            <hr/>
                            {listUsers.map((user: any) => 
                                <div key={user?.Person.id_person} style={user?.des_type_user === 'inban' ? {backgroundColor: '#920a0a4f'} : {backgroundColor: ''}}>
                                    <Col sm={12} className="row" style={{margin: '0'}}>
                                        <div className="description col-md-3 col-sm-4 ">
                                            <p>{user?.Person.des_person} {user?.Person.des_lest_person}</p>
                                        </div>
                                        <div className="description col-md-3 col-sm-4">
                                            <p>{FormatCpf(user?.Person.nr_cpf)}</p>
                                        </div>
                                        <div className="description col-md-3 col-sm-4">
                                            <p>{user?.des_email}</p>
                                        </div>
                                        <div className="description col-md-2 col-sm-4">
                                            <p>{FormatPhone(user?.nr_phone)}</p>
                                        </div>
                                        <div className="description col-md-1 col-sm-4">
                                        <button data-id={user?.Person.id_person} className="btn-none" onClick={handleClickShowInfo}><img data-id={user?.Person.id_person}  src={imgIconEye} alt="Icon Eye" width={40}/></button>
                                        </div>
                                        <div className="description col-sm-12 text-left" style={showIfo == user?.Person.id_person ? {display: 'block'} : {display: 'none'}}>
                                            <Row>
                                                <div className="col-sm-12 col-md-8">
                                                    <div className="address row">
                                                        <FontAwesomeIcon icon={faMapMarked} size="1x" />
                                                        <div>
                                                            <p>{addressUser?.des_address}, {addressUser?.des_number}</p>
                                                            <p>{addressUser?.City?.des_city} - {addressUser?.State?.uf}</p>
                                                            <p>{addressUser?.des_address}</p>
                                                        </div>
                                                    </div>
                                                    <div className="dt-birth row">
                                                        <FontAwesomeIcon icon={faCalendar} size="1x" />
                                                        <p>{FormatDatePtBr(user?.Person.dt_birth)}</p>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-4 btn-function">
                                                    <Link to={`/admin/user/update/${user?.Person.id_person}`} className="btn btn-primary col-12">Editar</Link>
                                                </div>
                                            </Row>
                                        </div>
                                    </Col>
                                    <hr/>
                                </div>
                            )}
                            <Pagination defaultCurrent={1} onChange={getListUsers} pageSize={10} total={totalUsers} style={{ margin: '20px' }}  />
                        </Col>
                    </Container>
                </div>
          </Row>
      </Container>
    </>
  );
}
export default Users;
