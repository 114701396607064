import React from "react"

import Footer from "../../components/Footer"
import Navbar from "../../components/Navbar"

import imgLogo from "../../assets/image/leiloae-logo-1@4x.png"
import imgMission from "../../assets/image/mission.jpg"
import imgVision from "../../assets/image/vision.jpg"
import imgValue from "../../assets/image/value.jpg"
import imgJoinUs from "../../assets/image/join_us.jpg"

//time Leiloaê
import imgMarcos from "../../assets/image/about-us/team/mp1.jpg"
import imgPedro from "../../assets/image/about-us/team/pedro.jpg"
import imgCelia from "../../assets/image/about-us/team/celia.jpg"
import imgRichard from "../../assets/image/about-us/team/richard.jpg"
import imgCamilo from "../../assets/image/about-us/team/camilo.jpg"

import "./styles.css"
import { Col, Container, Figure, Row } from "react-bootstrap"
import { Link } from "react-router-dom"

const AboutUs: React.FC = () => {
  return (
    <>
      <Navbar />
      <Container>
        <div id="about-us">
          <Col sm={12} className="about-leiloae shadow mb-3 bg-white">
            <Row>
              <Col sm={12} md={6} className="text-center about-img">
                <Figure>
                  <Figure.Image width={250} height={250} src={imgLogo} />
                </Figure>
              </Col>
              <Col sm={12} md={6} className="about-info about-text">
                <h2>Quem somos</h2>
                <p>
                  Somos a Leiloaê! Uma plataforma de marketplace de bovinos com
                  exposição online que permite segurança e inova o processo
                  atual na mobilidade do seu celular e na comodidade da sua
                  fazenda ou da sua casa. De forma diferente do modelo de
                  negócio presencial atual, estamos automatizando etapas e
                  economizando tempo e dinheiro dos nossos parceiros.
                </p>
              </Col>
            </Row>
          </Col>
          <Col sm={12} className="about-leiloae shadow mb-3 bg-white">
            <Row>
              <Col sm={12} md={2} className="about-mission">
                <Figure>
                  <Figure.Image width={250} height={250} src={imgMission} />
                </Figure>
              </Col>
              <Col sm={12} md={10} className="about-text">
                <h2>Missão</h2>
                <p>
                  A Leiloae tem como missão fornecer uma plataforma de
                  marketplace em âmbito nacional e global, com garantia de
                  segurança na comercialização. A Leiloae deve proporcionar
                  praticidade na realização das transações e fornecer um
                  ambiente de negócios que permita amplas oportunidades de
                  ganhos econômicos para os clientes, bem como em seu bem-estar.
                  Assim pretende ser ferramenta importante para o agronegócio.
                </p>
              </Col>
            </Row>
          </Col>
          <Col sm={12} className="about-leiloae shadow mb-3 bg-white">
            <Row>
              <Col sm={12} md={2} className="text-center">
                <Figure>
                  <Figure.Image width={250} height={250} src={imgVision} />
                </Figure>
              </Col>
              <Col sm={12} md={10} className="about-text">
                <h2>Visão</h2>
                <p>
                  Inovar o mercado de compra e venda de animais, através do uso
                  de tecnologias de software e comunicação aplicadas e
                  ferramentas modernas de transações financeiras.
                </p>
              </Col>
            </Row>
          </Col>
          <Col sm={12} className="about-leiloae shadow mb-3 bg-white">
            <Row>
              <Col sm={12} md={2} className="text-center">
                <Figure>
                  <Figure.Image width={250} height={250} src={imgValue} />
                </Figure>
              </Col>
              <Col sm={12} md={10} className="about-text">
                <h2>Valores</h2>
                <p>
                  <li> O negócio é bom, quando é bom para todos.</li>
                  <li> Segurança é nosso compromisso.</li>
                  <li> Ética e transparência são inegociáveis.</li>
                  <li> Ambiente com inovação, paixão e colaboração.</li>
                  <li> Em busca de sempre evoluir.</li>
                </p>
              </Col>
            </Row>
          </Col>
          <Col sm={12} className="text-center team-title">
            <h1>Conheça nosso time</h1>
          </Col>
          <Col sm={12}>
            <Row>
              <Col sm={6} md={4}>
                <div className="about-team shadow mb-3 bg-white text-center">
                  <Figure>
                    <Figure.Image
                      roundedCircle
                      width={150}
                      height={150}
                      src={imgMarcos}
                    />
                  </Figure>
                  <h6>Marcos Andrade</h6>
                  <p>Estratégia</p>
                </div>
              </Col>
              <Col sm={6} md={4}>
                <div className="about-team shadow mb-3 bg-white text-center">
                  <Figure>
                    <Figure.Image
                      roundedCircle
                      width={150}
                      height={150}
                      src={imgPedro}
                    />
                  </Figure>
                  <h6>Pedro Silveira</h6>
                  <p>Operações</p>
                </div>
              </Col>
              <Col sm={6} md={4}>
                <div className="about-team shadow mb-3 bg-white text-center">
                  <Figure>
                    <Figure.Image
                      className="image-celia"
                      roundedCircle
                      width={145}
                      height={150}
                      src={imgCelia}
                    />
                  </Figure>
                  <h6>Celia Montagner</h6>
                  <p>Gestão</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={6} md={4}>
                <div className="about-team shadow mb-3 bg-white text-center">
                  <Figure>
                    <Figure.Image
                      roundedCircle
                      width={150}
                      height={150}
                      src={imgRichard}
                    />
                  </Figure>
                  <h6>Richard Valario</h6>
                  <p>Vendas</p>
                </div>
              </Col>
              <Col sm={6} md={4}>
                <div className="about-team shadow mb-3 bg-white text-center">
                  <Figure>
                    <Figure.Image
                      roundedCircle
                      width={150}
                      height={150}
                      src={imgCamilo}
                    />
                  </Figure>
                  <h6>Camilo Moraes</h6>
                  <p>Vendas</p>
                </div>
              </Col>
            </Row>
          </Col>
          <Col sm={12} className="about-leiloae shadow mb-3 bg-white join-us">
            <Row>
              <Col sm={12} md={2} className="text-center">
                <Figure>
                  <Figure.Image width={250} height={250} src={imgJoinUs} />
                </Figure>
              </Col>
              <Col sm={12} md={10}>
                <h2>Junte-se a nós</h2>
                <p>
                  Venha com a família Leiloaê e faça parte da revolução na
                  maneira de negociar rebanhos! Através da nossa plataforma web
                  e mobile, você economiza tempo e dinheiro com a Leiloaê!{" "}
                </p>
                <Link to="/register/user" className="btn btn-create">
                  Crie uma conta gratuita
                </Link>
              </Col>
            </Row>
          </Col>
          {/* <Col sm={12} className="text-center team-title">
            <h1>Parceiros</h1>
          </Col>
          <Col sm={12}>
            <Row>
              <Col sm={6} md={4}>
                <div className="about-team shadow mb-3 bg-white text-center">
                  <Figure>
                    <Figure.Image
                      roundedCircle
                      width={150}
                      height={150}
                      src={imgMarcos}
                    />
                  </Figure>
                  <h6>Marcos Andrade</h6>
                  <p>Estratégia</p>
                </div>
              </Col>
              <Col sm={6} md={4}>
                <div className="about-team shadow mb-3 bg-white text-center">
                  <Figure>
                    <Figure.Image
                      roundedCircle
                      width={150}
                      height={150}
                      src={imgPedro}
                    />
                  </Figure>
                  <h6>Pedro Silveira</h6>
                  <p>Operações</p>
                </div>
              </Col>
              <Col sm={6} md={4}>
                <div className="about-team shadow mb-3 bg-white text-center">
                  <Figure>
                    <Figure.Image
                      className="image-celia"
                      roundedCircle
                      width={145}
                      height={150}
                      src={imgCelia}
                    />
                  </Figure>
                  <h6>Celia Montagner</h6>
                  <p>Gestão</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={6} md={4}>
                <div className="about-team shadow mb-3 bg-white text-center">
                  <Figure>
                    <Figure.Image
                      roundedCircle
                      width={150}
                      height={150}
                      src={imgWill}
                    />
                  </Figure>
                  <h6>William Lagassi</h6>
                  <p>Tecnologia</p>
                </div>
              </Col>
              <Col sm={6} md={4}>
                <div className="about-team shadow mb-3 bg-white text-center">
                  <Figure>
                    <Figure.Image
                      roundedCircle
                      width={150}
                      height={150}
                      src={imgRichard}
                    />
                  </Figure>
                  <h6>Richard Valario</h6>
                  <p>Vendas</p>
                </div>
              </Col>
              <Col sm={6} md={4}>
                <div className="about-team shadow mb-3 bg-white text-center">
                  <Figure>
                    <Figure.Image
                      roundedCircle
                      width={150}
                      height={150}
                      src={imgCamilo}
                    />
                  </Figure>
                  <h6>Camilo Moraes</h6>
                  <p>Vendas</p>
                </div>
              </Col>
            </Row>
          </Col> */}
        </div>
      </Container>
      <Footer />
    </>
  )
}
export default AboutUs
