import React from 'react';
import WhatsappIcon from '../Icons/WhatsappIcon';

import * as S from "./styles"

type AdvertiverContactProps = {
    phoneNumber: string;
    disable?: boolean;
}

const AdvertiverContact = ({ phoneNumber, disable = false }: AdvertiverContactProps) => {
    console.log(phoneNumber);
    const number = Number(phoneNumber);
    console.log(number);


    return (
        <S.Wrapper>
            {!!disable ? (
                <a href=" ">
                    <WhatsappIcon color="#faa00a" width="36px" height="36px" />
                    <span>Entre em contato com o anunciante</span>
                </a>
            ) : (

                <a target="_blank"
                    rel="noopener noreferrer"
                    href={`https://wa.me/55${number}`}>
                    <WhatsappIcon color="#faa00a" width="36px" height="36px" />
                    <span>Entre em contato com o anunciante</span>
                </a>
            )
            }

        </S.Wrapper >
    )
}

export default AdvertiverContact;