/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

import './styles.css'

const BankTransfer: React.FC = (propos: any) => {
  return (
    <>
        <div className="shadow mb-3 bg-white colunm row">
            <div className="col-12 row">
                <h5 className="col-12 text-center"><b>Conta de destino</b></h5>
                <div className="col-12 col-md-4">
                    <b>Titular</b>
                    <p>Leiloaê</p>
                </div>
                <div className="col-12 col-md-4">
                    <b>CNPJ</b>
                    <p>39.531.002/001-42</p>
                </div>
                <div className="col-12 col-md-4">
                    <b>Banco</b>
                    <p>736 - Sicoob</p>
                </div>
            </div>

            <div className="col-12 row">
                <div className="col-12 col-md-4">
                    <b>Agência</b>
                    <p>4342</p>
                </div>
                <div className="col-12 col-md-4">
                    <b>Número da conta e dígito</b>
                    <p>46317-5</p>
                </div>
                <div className="col-12 col-md-4">
                    <b>Tipo de conta</b>
                    <p>Conta corrente</p>
                </div>
            </div>
        </div>
    </>
  );
}
export default BankTransfer;
