/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';

import { Form, Button, Container, Row } from 'react-bootstrap';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import Loading from '../../../components/Loading';

import './styles.css'

import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { FormatError } from '../../../helpers/formatError';
import { ValidationFormPasswordChange } from '../../../helpers/dataValidation';
import { api } from '../../../services/apiLeloae/config';

const AdminUserPasswordUpdate: React.FC = (propos: any) => {
    const id_person = propos.match.params.id_person

    const [updateLoading, setUpdateLoading] = useState(false)
    const [password, setPassword] = useState({
        des_current_password: '',
        des_new_password: '',
        des_confirmation_new_password: ''
    })

    const handleChangeFormPassword = (event: any) => {
        const name = event.target.name
        const value = event.target.value

        setPassword(field => {
            return {
                ...field, [`${name}`]:value
            }
        })
    }

    const handleSubmitPasswordChange  = async (event: any) => {
        event.preventDefault()

        const errorMgs: any = ValidationFormPasswordChange(password)

        if (errorMgs) {
        return toast.error(errorMgs)
        }
        setUpdateLoading(true)
        try {
            const updatePassword: any = await api.put(`/admin/user/update/user/${id_person}`, password)

            setUpdateLoading(false)

            
            if(updatePassword.data?.message){
            toast.success(updatePassword.data?.message, {toastId: 1})
            }
        } catch (error) {
            const errorFormat = FormatError(error)
            errorFormat.forEach(element => {
                toast.error(element)
            });

            setUpdateLoading(false)
        }
    }

    return (
        <>
            {updateLoading && 
                <Loading />
            }
            <Header />
                <Container >
                    <div id="update-password-user" className="row justify-content-center">
                    <Row className="bg-clear-custom">
                        <div className="col-12 text-center update-password-user-form">
                            <h2 className="title-custom-h2">Alterar senha</h2>
                            <Form onSubmit={handleSubmitPasswordChange}>
                                <Form.Group controlId="des_current_password">
                                    <Form.Control type="password" name="des_current_password" placeholder="Senha atual" onChange={handleChangeFormPassword} />
                                </Form.Group>
                                <Form.Group controlId="des_new_password">
                                    <Form.Control type="password" name="des_new_password" placeholder="Nova senha" onChange={handleChangeFormPassword}  />
                                </Form.Group>
                                <Form.Group controlId="des_confirmation_new_password">
                                    <Form.Control type="password" name="des_confirmation_new_password" placeholder="Repetir nova senha" onChange={handleChangeFormPassword}  />
                                </Form.Group>
                                <Row>
                                    <div className="col-12 text-center text-sm-right buttons">
                                        <Button className="btn btn-create" type="submit">
                                            Salvar
                                        </Button>
                                        <Link to={`/admin/user/update/${id_person}`} className="btn btn-cancel">Cancelar</Link>
                                    </div>
                                </Row>
                            </Form>
                        </div>
                    </Row>
                    </div>
                </Container>
            <Footer />
        </>
    );
}
export default AdminUserPasswordUpdate;
