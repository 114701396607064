import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { api, HttpRequestI } from '../../services'

const name = 'StateProductUserSales'

interface StateProductUserSalesI {
    id_product: number,
    ProductPhotos: any
}

interface StateResponseI {
    data: Array<StateProductUserSalesI>
    headers: []
    productSelected: StateProductUserSalesI
}

interface MessageResponseI {
    headers: any
    data: {
        message: Array<StateProductUserSalesI>
    }
}

interface ParamsI {
    id_person: number
    page?: number
    limit?: number
}

type StateProductUserSalesType = StateResponseI & {httprequest?: Partial<HttpRequestI>, params?:Partial<ParamsI>}

const initialState: StateProductUserSalesType = {
    data: [],
    headers: [],
    productSelected: {} as StateProductUserSalesI,
    httprequest: {error: false, loading: false, success: false},
    params: {page: 1, limit: 10, id_person: null} as ParamsI,
}

const listProducts = createAsyncThunk(
    `${name}/LIST_USER_PRODUCT`, 
    async(state: Partial<ParamsI>, agrsThunk?: any) => {

        try {  
            const productResult = await api.apiLeloae.product.listUserSales<MessageResponseI>(state.id_person, {page: state.page, limit: state.limit})

            return productResult
        } catch (error) {
            return agrsThunk.rejectWithValue(error)
        }

    }
)

const StateProductUserSales = createSlice({
    name,
    initialState,
    reducers:{
        SelectProduct:  (state, action: PayloadAction<number>) => {
            const productSelected = state.data.find((product: any) => Number(product.id_product) === Number(action.payload))
            if(Boolean(productSelected) === true) {
                localStorage.setItem('@leloae/product/sales/selected', JSON.stringify(productSelected))
            }
            
            return {...state, productSelected}
        },
        ParamsUrl: (state, action: PayloadAction<ParamsI>) => {
            return {...state, params: action.payload}
        }
    },
    extraReducers: build => {
        build.addCase(listProducts.pending, (state) => {
            return {...state, httprequest:{
                loading: true,
                error: false,
                success: false
            }}
        })
        build.addCase(listProducts.fulfilled, (state, action: PayloadAction<StateProductUserSalesI[] | any>) => {
            return {...state, data: action.payload.data.message, headers: action.payload.headers, httprequest: {
                error: false,
                loading: false,
                success: true
            }}
        })
        build.addCase(listProducts.rejected, (state, action:PayloadAction<any>) => {
            return {...state, httprequest:{
                error: true,
                loading: false,
                success: false,
                errorMsg: action.payload
            }}
        })
    }
})

export const getProductStorage = () => {
    return Boolean(localStorage.getItem('@leloae/product/sales/selected')) === true && localStorage.getItem('@leloae/product/sales/selected') !== 'undefined' ? JSON.parse(localStorage.getItem('@leloae/product/sales/selected')) : null 
}

export const reducerProductUserSales = StateProductUserSales.reducer
export const actionProductUserSales = {...StateProductUserSales.actions, listProducts}