/* eslint-disable jsx-a11y/alt-text */
import React from "react"

import ConnectBusinesses from "./connectBusinesses"
import OptionProduct from "./optionProduct"
import Partner from "./partner"

import AnimalSection from "../../components/AnimalSection"
import Banner from "../../components/Banner"
import CarouselDestaque from "../../components/CarouselDestaque"
import CarouselHome from "../../components/CarouselHome"
import CarouselNovos from "../../components/CarouselNovos"
import Footer from "../../components/Footer"
import Navbar from "../../components/Navbar"

import patrocinioHome from "../../assets/image/homepage/patrocinio/patrocinio-home.jpg"
import testeApp from "../../assets/image/homepage/patrocinio/teste-nosso-app.png"

import "@brainhubeu/react-carousel/lib/style.css"

import "./styles.css"

const Home: React.FC = () => {
  return (
    <>
      <Navbar />
      <CarouselHome />
      <OptionProduct />
      <Banner
        image={testeApp}
        link="/downloads/app"
        alt={"Banner baixe nosso app"}
      />
      <AnimalSection>VITRINE VIRTUAL</AnimalSection>
      <CarouselDestaque />
      <Banner
        image={patrocinioHome}
        link="/contact"
        alt={"Banner patrocínio"}
      />
      <AnimalSection>NOVOS ANIMAIS</AnimalSection>
      <CarouselNovos />
      <Partner />
      <ConnectBusinesses />
      <Footer />
    </>
  )
}
export default Home
