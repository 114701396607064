import styled from "styled-components"

export const Wrapper = styled.div`
  width: fit-content;
  height: auto;
  background-color: #fff;
  border-radius: 50px;
  padding: 5px 26px;
  margin: 24px auto 8px;
  display: flex;
  gap: 7px;
  align-items: center;
  justify-content: flex-start;
  border: 0.25px solid rgba(230, 230, 230, 1);

  span {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;

    color: #faa00a;
  }
`
