/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react"
import GoogleLogin from "react-google-login"
import FacebookLogin from "react-facebook-login"

import Navbar from "../../components/Navbar"

import imgLogo from "../../assets/image/leiloae-logo-2@4x.png"

import "./styles.css"
import { Container, Row, Form, Button, Figure, Col } from "react-bootstrap"
import { Link, useHistory } from "react-router-dom"

import { useUserContext } from "../../providers/use-sign"
import { toast } from "react-toastify"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebookSquare, faGoogle } from "@fortawesome/free-brands-svg-icons"

const Login: React.FC = () => {
  const [formLogin, setFormLogin] = useState({
    des_username: "",
    des_password: "",
  })

  const history = useHistory()

  const { signing, signingGoogle, signingFacebook, data } = useUserContext()

  const handleSubmit = async (event: any) => {
    event.preventDefault()

    if (!formLogin.des_username) {
      return toast.error("Informe seu e-mail ou telefone")
    }
    if (!formLogin.des_password) {
      return toast.error("Informe sua senha")
    }
    await signing(formLogin)
  }

  const responseFacebook = async (response: any) => {
    if (response.status == "unknown") {
      return toast.error("Você precisa confirmar para acessar sua conta")
    }
    signingFacebook(response)
  }

  const responseSuccessGoogle = async (response: any) => {
    signingGoogle(response?.profileObj)
  }

  const responseErrorGoogle = async (response: any) => {
    if (response.error == "popup_closed_by_user") {
      return toast.error("Você precisa confirmar para acessar sua conta")
    }
  }

  useEffect(() => {
    if (data.msgError) {
      toast.error(data.msgError)
    }
    if (data.statusCode === 201) {
      history.push("/user/update/data")
      toast.success("Cadastro realizado com sucesso.")
      toast.info("Finalize seu cadastro!")
      return
    }
    if (data.user?.des_type_user === "inadmin") {
      history.push("/admin")
    }
    if (data.user?.des_type_user === "inclient") {
      history.push("/")
    }
  }, [data])

  const handleChangeForm = (event: any) => {
    const name = event.target.name
    const value = event.target.value

    setFormLogin((field) => {
      return {
        ...field,
        [`${name}`]: value,
      }
    })
  }

  return (
    <>
      <Navbar />
      <Container fluid id="login-bg">
        <div id="login-user">
          <Row className="bg-clear-custom">
            <div className="col-12 text-center login-user-form">
              <Figure.Image width={120} alt="Leiloaê Bovinos" src={imgLogo} />
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="des_username">
                  <Form.Control
                    type="text"
                    name="des_username"
                    placeholder="E-mail/Telefone"
                    value={formLogin.des_username}
                    onChange={handleChangeForm}
                  />
                </Form.Group>
                <Form.Group controlId="des_password">
                  <Form.Control
                    type="password"
                    name="des_password"
                    placeholder="Senha"
                    value={formLogin.des_password}
                    onChange={handleChangeForm}
                  />
                </Form.Group>
                <Row>
                  <Col sm={12} md={6} className="col-forgot">
                    <meta
                      name="description"
                      content="Recuperar senha no sistema Leiloaê"
                    />
                    <Link className="btn-forgot" to="/forgot/password">
                      Esqueceu sua senha?
                    </Link>
                  </Col>
                  <Col sm={12} md={6}>
                    <meta
                      name="description"
                      content="Entrar no sistema Leiloaê"
                    />
                    <Button className="btn btn-create" type="submit">
                      Entrar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Row>
          <div id="other-login" className="text-center">
            <p>Ou faça login usando</p>
            <meta
              name="description"
              content="Logar no sistema Leiloaê utilizando sua conta do Google"
            />
            <GoogleLogin
              clientId="529901719768-1i2stqiq5r80tsrqcid716jibkcsvgk7.apps.googleusercontent.com"
              icon={false}
              style={{ backgroundColor: "#ffffff00" }}
              onSuccess={responseSuccessGoogle}
              onFailure={responseErrorGoogle}
              cookiePolicy={"single_host_origin"}
            >
              <FontAwesomeIcon
                className="icon-bell google"
                icon={faGoogle}
                size="2x"
              />
            </GoogleLogin>
            <meta
              name="description"
              content="Logar no sistema Leiloaê utilizando sua conta do Facebook"
            />
            <FacebookLogin
              appId="1325691441107237"
              autoLoad={false}
              fields="name,email,picture"
              icon={
                <FontAwesomeIcon
                  className="icon-bell instagram"
                  icon={faFacebookSquare}
                  size="2x"
                />
              }
              size="medium"
              textButton=""
              callback={responseFacebook}
            />
            {/* <Link to="#"><FontAwesomeIcon className="icon-bell instagram" icon={faInstagram} size="2x" /></Link> */}
          </div>
          <div id="no-register" className="text-center">
            <p>Não possui cadastro?</p>
            <Link to="/register/user" className="btn-no-register">
              <meta
                name="description"
                content="Cadastrar-se no sistema Leiloaê"
              />
              <Button className="btn btn-create btn-register">
                Cadastre-se agora!
              </Button>
            </Link>
          </div>
        </div>
      </Container>
    </>
  )
}
export default Login
