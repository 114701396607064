import React from "react"

import { Link, useHistory } from "react-router-dom"
import { toast } from "react-toastify"

import "swiper/swiper.scss"
import "swiper/modules/navigation/navigation.scss"
import "swiper/modules/pagination/pagination.scss"
import "swiper/modules/autoplay/autoplay.scss"

import Carousel from "../Carousel"

import { useUserContext } from "../../providers/use-sign"

import bannerCarousel01 from "../../assets/image/homepage/carrousel/1-CriarConta.jpg"
import bannerCarousel02 from "../../assets/image/homepage/carrousel/2-SegurançaeRastreabilidade.jpg"
import bannerCarousel03 from "../../assets/image/homepage/carrousel/3-Compreparceladoerecebaavistaa.jpg"
import bannerCarousel04 from "../../assets/image/homepage/carrousel/4-Anunciarlote.jpg"
import bannerCarousel05 from "../../assets/image/homepage/carrousel/5-Verlotes.jpg"

const CarouselHome = () => {
  const { signed } = useUserContext()
  const history = useHistory()

  const checkedLogin = () => {
    toast.warn("Você precisa está logando para anúnciar", { toastId: 1 })
    history.push("/authentication")
  }

  const breakpoint = {
    900: {
      slidesPerView: 1,
    },
  }

  return (
    <Carousel
      width="940px"
      space={10}
      numSlides={1.1}
      breakpoints={breakpoint}
      slides={[
        <Link to="/register/user">
          <img
            data-src={bannerCarousel01}
            className="lazyload"
            width="100%"
            height="100%"
            alt="Cadastre-se ou entre no site Leiloaê"
          />
        </Link>,
        <Link to="/helps">
          <img
            data-src={bannerCarousel02}
            className="lazyload"
            width="100%"
            height="100%"
            alt="Ajuda Leiloaê Garante"
          />
        </Link>,
        <Link to="/product/list/">
          <img
            data-src={bannerCarousel03}
            className="lazyload"
            width="100%"
            height="100%"
            alt="Ajuda Leiloaê Garante"
          />
        </Link>,
        <Link
          to={signed ? "/announce" : "/authentication"}
          onClick={!signed && checkedLogin}
        >
          <img
            data-src={bannerCarousel04}
            className="lazyload"
            width="100%"
            height="100%"
            alt="Anuncie com a Leiloaê"
          />
        </Link>,
        <Link to="/product/list/">
          <img
            data-src={bannerCarousel05}
            className="lazyload"
            width="100%"
            height="100%"
            alt="Ajuda Leiloaê Garante"
          />
        </Link>,
      ]}
    />
  )
}
export default CarouselHome
