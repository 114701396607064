/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from "react"
import Pagination from "rc-pagination"

import { useDispatch, useSelector } from "react-redux"
import { actionProductUserSales } from "../../../store/reduces/ReducerProductUserSales"
import { RootState } from "../../../store"

import { Container, Row, Figure } from "react-bootstrap"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"

import { useUserContext } from "../../../providers/use-sign"
import { useParamsProductContext } from "../../../providers/product-params"

import Navbar from "../../../components/Navbar"
import Footer from "../../../components/Footer"
import Loading from "../../../components/Loading"

import "rc-pagination/assets/index.css"
import "./styles.css"

import { FormatPriceInput } from "../../../helpers/format"

const UserLotsSales: React.FC = () => {
  const { data } = useUserContext()
  const { params, setParamsProduct } = useParamsProductContext()

  const { reducerProductUserSales } = useSelector((state: RootState) => state)
  const dispatch = useDispatch()

  const handlePage = (page: number) => {
    setParamsProduct({ type: "page", value: page })
  }

  useEffect(() => {
    dispatch(
      actionProductUserSales.listProducts({
        ...params,
        id_person: data.user?.id_person,
      })
    )
  }, [data.user, dispatch, params])

  return (
    <>
      {reducerProductUserSales.httprequest.loading && <Loading />}
      <Navbar />
      <Container>
        <div id="lots-sales-user">
          <Row>
            <p>
              <Link to="/user">Gerenciar minha conta</Link>{" "}
              <FontAwesomeIcon icon={faAngleRight} size="1x" /> Meu Perfil{" "}
            </p>
            <div className="col-12 row">
              <div className="col-12 col-sm-12 col-md-3 menu-left">
                <h2 className="title-custom-h2 col-12">Meus Lotes</h2>
                <p className="col-12">
                  {reducerProductUserSales.data.length} resultados
                </p>
              </div>
              <div className="col-12 col-sm-12 col-md-9 list-right text-center">
                {reducerProductUserSales.data.map((product: any) => (
                  <div
                    key={product.id_product}
                    className="shadow mb-3 bg-white colunm row"
                  >
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                      <Figure>
                        <Figure.Image
                          src={product?.ProductPhotos[0]?.des_product_photo_url}
                        />
                      </Figure>
                    </div>
                    <div className="col-12 col-sm-6 col-md-8 col-lg-9 info-lots">
                      <div className="row titles">
                        <h3>{product?.des_title} </h3> <p> MarketPlace </p>{" "}
                        <p>
                          {product?.TypeProductStatus?.des_type_product_status}
                        </p>
                      </div>
                      <div className="description">
                        <p>{product?.des_description}</p>
                      </div>
                      <div className="row vl-show-details">
                        <p className="type-coin text-center text-sm-center text-md-left">
                          R$
                        </p>
                        <div className="col-sm-12 col-md-12 col-lg-4  text-center text-sm-center text-md-left">
                          <h3 className="vl">
                            {FormatPriceInput(product?.vl_price_total)}
                          </h3>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-8 text-center text-sm-center text-md-right">
                          <Link
                            to={`/user/lots/sales/detail/${product?.id_product}`}
                            className="btn btn-create"
                          >
                            Ver detalhes do lote
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <Pagination
                  current={params.page}
                  onChange={handlePage}
                  pageSize={10}
                  total={reducerProductUserSales.headers["x-total-count"]}
                  style={{ margin: "20px" }}
                />
              </div>
            </div>
          </Row>
        </div>
      </Container>
      <Footer />
    </>
  )
}
export default UserLotsSales
