import styled from "styled-components"

export const Wrapper = styled.div`
    width: 100%;
    height: auto;

    > a {
        width: 100%;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        gap: 16px;

        @media screen and (min-width: 1201px) {
            padding-top: 16px;
        }
        > span {
            color: #ffa00a;
            font-size: 18px;
            font-weight: bold;
        }
    }
`
