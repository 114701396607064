export const FormatError = (errorMsg: any) => {

    if(errorMsg?.response?.data?.message){
        return errorMsg.response.data.message.split(".")

    }else if(errorMsg?.response?.status === 500) {
        return "Ocorreu um erro, tente novamente mais tarde"
    }else {
        return errorMsg.split(".")
    }
}