import styled, { css } from "styled-components"

import { AnimalCardProps } from "."

type StyledAnimalCardProps = Pick<AnimalCardProps, "variant">

const text = css`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  color: #fa7a0a;
`

export const Wrapper = styled.div`
  width: 348px;
  height: auto;
  display: flex;
  margin: 16px 0px;
  flex-direction: column;
  border-radius: 25px;
  background-color: #ffffff;
  box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  transition: all 0.25s ease;

  &:hover {
    transform: scale(1.02);
    box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.18);
  }
`

export const CardTop = styled.div`
  width: 100%;
  min-height: 246px;
  background-color: #d5d5d5;
  border-radius: 25px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`

export const Image = styled.img`
  width: 348px;
  height: 196px;
  border-radius: 25px;
  object-fit: cover;
`

export const Title = styled.span`
  ${text}
  max-width: 284px;
  padding: 16px 0 12px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: #000000;
`

export const TopIcons = styled.div`
  width: 100%;
  height: auto;
  padding: 6px;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const CardBottom = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const InfoSection1 = styled.div`
  width: 100%;
  height: auto;
  padding: 16px 32px 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 0.5px solid rgba(112, 112, 112, 0.37);
`

export const Line1 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 48px;
  padding-bottom: 8px;
`

export const Line2 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 48px;
`

export const InfoText = styled.div`
  width: fit-content;
  height: auto;
  display: flex;
  align-items: center;
  gap: 12px;

  span {
    ${text}
  }
`

export const InfoSection2 = styled.div`
  width: 100%;
  height: auto;
  border-bottom: 0.5px solid rgba(112, 112, 112, 0.37);
  display: flex;
`

export const FreteCol = styled.div`
  width: 50%;
  height: auto;
  border-right: 0.5px solid rgba(112, 112, 112, 0.37);
  padding: 10px 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span {
    ${text}
    color: #2BDE59;
  }
`

export const PriceCol = styled.div<StyledAnimalCardProps>`
  ${({ variant }) => variant === 'lista' ? css`
    width: 100%;
    gap: 16px;
    align-items: center;
    justify-content: center;

    strong {
      max-width: unset;
    }
  ` : css`
    width: 50%;
    flex-direction: column;

    strong {
      max-width: 157px;
    }
  `}

  height: auto;
  padding: 10px 12px 10px 5px;
  display: flex;

  span {
    ${text}
    font-size: 14px;
    line-height: 16px;
  }

  strong {
    ${text}
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    font-weight: bold;
    font-size: 26px;
    line-height: 30px;
  }  
`

export const ButtonSection = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px 22px;
`
