/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';

import { Form, Button, Row } from 'react-bootstrap';

import { useCreateUserContext, IUser } from '../../providers/use-create'
import { FormatDate, FormatPhone } from '../../helpers/format';
import { ValidationFormCreateUser } from '../../helpers/dataValidation';
import { toast } from 'react-toastify';

const RegisterPersonalData: React.FC = () => {
    const { dataUserContext, setUserStorage } = useCreateUserContext()
    const [dataUser, setDataUser] = useState<IUser>({
        des_person: '',
        des_lest_person: '',
        nr_phone: '',
        dt_birth: '',
        des_email: '',
        des_password: '',
        nr_receive_ads_email: 0,
        nr_policy_terms: 1
    })
    

    useEffect(() => {
        if(dataUserContext) {
            setDataUser(dataUserContext)
        }
    },[dataUserContext])

    const handleFormChangeCreateUser = (event: any) => {
        const name = event.target.name
        const value = event.target.value
        const checked = event.target.checked

        setDataUser(field =>{
            return {
                ...field, [`${name}`]:value
            }
        })

        if(name === 'nr_receive_ads_email'){
            setDataUser(field => {
                return {
                    ...field, [`${name}`]: checked === true ? 1 : 0
                }
            })
        }
    }

    const handleSubimitStorageUser = async (event: any) => {
        event.preventDefault();
        const errorMgs: any = ValidationFormCreateUser(dataUser);
        if (errorMgs) {
            return toast.error(errorMgs)
        }
        await setUserStorage(dataUser);
    }
    
  return (
    <>
    <Form onSubmit={handleSubimitStorageUser}>
        <Row>
            <Form.Group controlId="des_person" className="col-sm-6">
            <Form.Label className="label-control">Nome</Form.Label>
            <Form.Control type="text" name="des_person" value={dataUser.des_person} onChange={handleFormChangeCreateUser} />
            </Form.Group>
            <Form.Group controlId="des_lest_person" className="col-sm-6">
            <Form.Label className="label-control">Sobrenome</Form.Label>
            <Form.Control type="text" name="des_lest_person" value={dataUser.des_lest_person} onChange={handleFormChangeCreateUser} />
            </Form.Group>
        </Row>
        <Form.Group controlId="nr_phone">
            <Form.Label className="label-control">Telefone</Form.Label>
            <Form.Control type="text" name="nr_phone" value={FormatPhone(dataUser.nr_phone)} onChange={handleFormChangeCreateUser} />
        </Form.Group>
        <Form.Group controlId="dt_birth">
            <Form.Label className="label-dt-brith">Data nascimento</Form.Label>
            <Form.Control type="date" name="dt_birth" className="d-none d-lg-block d-xl-block" placeholder="00/00/0000" value={dataUser.dt_birth} onChange={handleFormChangeCreateUser} />
            <Form.Control type="text" name="dt_birth" className="d-block d-lg-none d-xl-none" value={FormatDate(dataUser.dt_birth)} placeholder="00/00/0000" onChange={handleFormChangeCreateUser} />
        </Form.Group>
        <Form.Group controlId="des_email">
            <Form.Label className="label-control">Email</Form.Label>
            <Form.Control type="text" name="des_email" value={dataUser.des_email} onChange={handleFormChangeCreateUser} />
        </Form.Group>
        <Form.Group controlId="des_password">
            <Form.Label className="label-control">Senha</Form.Label>
            <Form.Control type="password" name="des_password" value={dataUser.des_password} onChange={handleFormChangeCreateUser} />
        </Form.Group>
        <Form.Group controlId="nr_receive_ads_email">
            <Form.Check type="checkbox" name="nr_receive_ads_email" label="Receber novidades por Email" defaultChecked={Boolean(dataUser.nr_receive_ads_email)} onClick={handleFormChangeCreateUser} />
        </Form.Group>
        <Button className="col-12 btn btn-create" type="submit">
            Avançar
        </Button>
    </Form>
    </>
  );
}
export default RegisterPersonalData;
