import React from "react"

const CertifiedIcon = () => (
  <svg width="39" height="39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.5 39C30.27 39 39 30.27 39 19.5S30.27 0 19.5 0 0 8.73 0 19.5 8.73 39 19.5 39Z"
      fill="#FAA00A"
    />
    <path
      d="M19.971 33.567S6.806 28.667 7.854 8.923a37.687 37.687 0 0 0 7.97-1.973 28.46 28.46 0 0 0 3.637-1.583 55.82 55.82 0 0 0 5.446 2.146 24.651 24.651 0 0 0 6.25 1.406c.35 10.776-.92 17.268-11.7 24.715"
      fill="#C26F02"
    />
    <path
      d="m13.948 19.69 4.882 3.577 8.118-9.083"
      stroke="#fff"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default CertifiedIcon
