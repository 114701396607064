export const checkDevice = () => {
    if( navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)
        ){
            return false
         }
        else {
            return true
         }
}

export const checkIsDataValid = (data: any, type?: number) => {

    switch (type) {
        case 1:
            return data ? data : 0
        case 2:
            return data ? data : []
        default:
            return data ? data : ''
    }
}