import React from "react"

import marketplaceIcon from "../../assets/image/marketplaceIcon.png"

import * as S from "./styles"

const AnimalSection = ({ children }) => {
  return (
    <S.Wrapper>
      <img
        src={marketplaceIcon}
        alt="Ícone de loja"
        role="none"
        width="40px"
        height="40px"
      />
      <span>{children}</span>
    </S.Wrapper>
  )
}

export default AnimalSection
