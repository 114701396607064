import styled, { css } from "styled-components"

type MenuButtonProps = {
  isOpen: boolean
}

type LogoProps = {
  width: string
  height: string
}

type SignedProps = {
  signed: string
}

export const Wrapper = styled.nav`
  width: 100%;
  height: 80px;
  background-color: #4a4a4a;

  position: fixed;
  top: 0;
  z-index: 9999;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 16px 24px 16px 32px;

  will-change: transition;
  transition: height 0.35s ease;
`

export const Logo = styled.img<LogoProps>`
  ${({ width, height }) =>
    css`
      width: ${width};
      height: ${height};
    `}
`

export const MenuButton = styled.button<MenuButtonProps>`
  width: 64px;
  height: 50px;
  background-color: #fd880d;

  margin: 0;
  border: 0;
  border-radius: 7px;
  padding: 12px 10px;

  position: relative;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;

  transition: 0.1s ease;

  &:hover {
    opacity: 0.8;
  }

  > span {
    width: 44px;
    height: 5px;
    display: block;
    position: absolute;
    border-radius: 2px;
    transition: 0.25s ease-in-out;
    background-color: #ffffff;

    ::before,
    ::after {
      content: "";
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      border-radius: 2px;
      transition: 0.25s ease-in-out;
      background-color: #ffffff;
    }

    ::before {
      top: -12px;
    }

    ::after {
      bottom: -12px;
    }

    ${({ isOpen }) =>
      isOpen &&
      css`
        will-change: transform;
        transform: rotate(45deg);

        ::before {
          transform: rotate(90deg);
          top: 0;
        }

        ::after {
          transform: rotate(90deg);
          bottom: 0;
        }
      `}
  }
`

export const MenuExpanded = styled.div<MenuButtonProps>`
  width: 100%;
  height: auto;
  background-color: #202020;

  position: fixed;
  z-index: 999;

  will-change: transform;
  transform: translateY(-105%);
  transition: 0.35s ease;

  margin: 0;
  padding: 16px 8px;

  display: flex;
  justify-content: center;
  align-items: center;

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: translateY(0);
      transition: 0.35s ease;
    `}

  @media screen and (min-width: 425px) {
    padding: 16px 32px;
  }
`

export const MenuExpandedContent = styled.div`
  width: fit-content;

  margin: 0;
  padding: 0;

  display: flex;
  flex-direction: column;
  gap: 14px;
`

export const ButtonLine = styled.div`
  gap: 20px;
  display: flex;
  justify-content: space-between;
`

export const NavList = styled.ul<SignedProps>`
  margin: 0;
  padding: 0;
  list-style: none;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;

  @media screen and (min-width: 1297px) {
    width: fit-content;
    padding-left: 12px;
    padding-right: 22px;

    gap: 14px;
    flex-direction: row;
    justify-content: flex-start;
  }
`

export const NavListItem = styled.a<SignedProps>`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;

  color: #ffffff;

  text-decoration: none !important;

  transition: 0.1s ease;

  &:hover {
    color: #cccccc;
  }

  ${({ signed }) =>
    signed
      ? css`
          @media screen and (min-width: 1297px) {
            font-size: 16px;
            line-height: 19px;
          }
        `
      : css`
          @media screen and (min-width: 1381px) {
            font-size: 16px;
            line-height: 19px;
          }
        `}
`

export const WrapperWeb = styled.nav`
  width: 100%;
  height: 130px;
  background-color: #4a4a4a;

  position: fixed;
  top: 0;
  z-index: 9999;

  display: flex;
  align-items: center;
  justify-content: center;
`

export const WrapperWebContent = styled.div`
  width: fit-content;
  height: 100%;
  padding: 12px;

  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  gap: 16px;
`

export const CenterContent = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > div {
    display: flex;
    justify-content: space-between;
`

export const UserProfile = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
`
