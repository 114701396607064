import { api } from './config'

export class Products {
    async list<T>(data: any): Promise<T> {
       return await api.get('/product/search/params', {params: data})
    }
    async listSearch<T>(data: any): Promise<T> {
        return await api.get('/product/search', {params: data})
     }
    async listViews<T>(data: any): Promise<T> {
       return await api.get('/product/search/params/views', {params: data})
    }
    async listUserSales<T>(id_person: number, data: any): Promise<T> {
        return await api.get(`/product/user/${id_person}`, {params: data})
    }

    async listUserRequest<T>(id_person: number, data: any): Promise<T> {
        return await api.get(`/buy/product/user/${id_person}`, {params: data})
    }

    async listUserRequestNegotiation<T>(id_person: number, data: any): Promise<T> {
        return await api.get(`/negotiation/request/product/user/${id_person}`, {params: data})
    }

    async get<T>(id_product: number): Promise<T> {
        return await api.get(`/product/show/${id_product}`)
    }

    async getView<T>(id_product: number): Promise<T> {
        return await api.get(`/product/show/view/${id_product}`)
    }

    async getUserRequest<T>(id_person: number, id_buy_product: number): Promise<T> {
        return await api.get(`/buy/product/show/${id_person}/${id_buy_product}`)
    }

    async createView<T>(data: any): Promise<T> {
        return await api.post('/product/register/view', data)
    }

} 