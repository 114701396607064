import React from "react"

import * as S from "./styles"

export type BannerProps = {
  image: string
  link: string
  alt?: string
  p?: string
}

const Banner = ({
  image,
  link,
  alt = "Imagem do patrocinador",
  p = "0 18px",
}: BannerProps) => {
  return (
    <S.Wrapper p={p}>
      <a href={link}>
        <S.Image
          src={image}
          alt={alt}
          className="lazyload"
          width="100%"
          height="100%"
        />
      </a>
    </S.Wrapper>
  )
}

export default Banner
