/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';

import './styles.css'
import { Row, Form, Button, Col } from 'react-bootstrap';
import { FormatCep, FormatCnpj } from '../../../helpers/format';
import { ICompany, useCreateAuctionContext } from '../../../providers/auction-create';
import { ValidationInputCreateCompany } from '../../../helpers/dataValidation';
import { toast } from 'react-toastify';

const Company: React.FC = () => {
    const { createAuction } = useCreateAuctionContext()
    const [dataCompany, setDataCompany] = useState<ICompany>({
        nr_cnpj: '',
        des_company: '',
        des_company_fantasy: '',
        des_situation: '',
        nr_zip_code: 0,
        des_state: '',
        des_city: '',
    })

    const handleFormChangeCreateCompany = (event: any) => {
        const name = event.target.name
        const value = event.target.value

        setDataCompany(field =>{
            return {
                ...field, [`${name}`]:value
            }
        })

    }

    const handleSubimitCompany = async (event: any) => {
        event.preventDefault()
        const errorMgs: any = ValidationInputCreateCompany(dataCompany);
        if (errorMgs) {
            return toast.error(errorMgs)
        }
        const dataAuctionStorage = await JSON.parse(localStorage.getItem('@leloae/user/auction/create'))

        await createAuction({...dataAuctionStorage, ...dataCompany})
    }
  return (
    <>
        <div className="company col-sm-12 text-center">
            <h2 className="title-custom-h2">Cadastrar leilão</h2>
            <Form onSubmit={handleSubimitCompany}>
                <Form.Group controlId="nr_cnpj">
                    <Form.Control type="text" name="nr_cnpj" value={FormatCnpj(dataCompany.nr_cnpj)}  placeholder="CNPJ" onChange={handleFormChangeCreateCompany} />
                </Form.Group>
                <Form.Group controlId="des_company">
                    <Form.Control type="text" name="des_company" value={dataCompany.des_company} placeholder="Razão social" onChange={handleFormChangeCreateCompany} />
                </Form.Group>
                <Form.Group controlId="des_company_fantasy">
                    <Form.Control type="text" name="des_company_fantasy" value={dataCompany.des_company_fantasy}  placeholder="Nome fantasia" onChange={handleFormChangeCreateCompany} />
                </Form.Group>
                <Row>
                    <Col sm="12" md="12" lg="4">
                        <Form.Group controlId="nr_zip_code">
                            <Form.Control type="text" name="nr_zip_code" value={dataCompany.nr_zip_code ? FormatCep(dataCompany.nr_zip_code) : ''} placeholder="CEP" onChange={handleFormChangeCreateCompany} />
                        </Form.Group>
                    </Col>
                    <Col sm="12" md="12" lg="4">
                        <Form.Group controlId="des_state">
                            <Form.Control type="text" name="des_state" value={dataCompany.des_state} placeholder="Estado" onChange={handleFormChangeCreateCompany} />
                        </Form.Group>
                    </Col>
                    <Col sm="12" md="12" lg="4">
                        <Form.Group controlId="des_city">
                            <Form.Control type="text" name="des_city" value={dataCompany.des_city} placeholder="Cidade" onChange={handleFormChangeCreateCompany} />
                        </Form.Group>
                    </Col>
                </Row>
                <Button type="submit" className="col-12 btn btn-create">
                    Continuar
                </Button>
            </Form>
        </div>
    </>
  );
}
export default Company;
