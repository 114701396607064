import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
// import { NumberLiteralType } from 'typescript'
import { api, HttpRequestI } from '../../services'

const name = 'StateProduct'


interface StateProductI {
    id_product: number,
    ProductPhotos: any
}

interface StateResponseI {
    data: Array<StateProductI>
    headers: []
    productSelected: StateProductI
}

interface MessageResponseI {
    headers: any
    data: {
        message: Array<StateProductI>
    }
}

interface ParamsI {
    page?: number
    limit?: number
    filter?: Partial<{
        id_type_product_animal: number
        id_category: any
        paramsURL: any
        name: any
        nr_status: number
        nr_type_sales: number
    }>

}

type StateProductType = StateResponseI & {httprequest?: Partial<HttpRequestI>, params?:Partial<ParamsI>}

const initialState: StateProductType = {
    data: [],
    headers: [],
    productSelected: {} as StateProductI,
    httprequest: {error: false, loading: false, success: false},
    params: {page: 1, limit: 30, nr_status: 2} as ParamsI
}

const searchProducts = createAsyncThunk(
    `${name}/SEARCH_PRODUCT`, 
    async(state: Partial<ParamsI>, agrsThunk?: any) => {

        try {  
            const productResult = await api.apiLeloae.product.list<MessageResponseI>({page: state.page, limit: state.limit, ...state.filter})
            return productResult
        } catch (error) {
            return agrsThunk.rejectWithValue(error)
        }

    }
)

const StateProduct = createSlice({
    name,
    initialState,
    reducers:{
        SelectProduct:  (state, action: PayloadAction<number>) => {
            const productSelected = state.data.find((product: any) => Number(product.id_product) === Number(action.payload))
            if(Boolean(productSelected) === true) {
                localStorage.setItem('@leloae/product/selected', JSON.stringify(productSelected))
            }
            
            return {...state, productSelected}
        },
        ParamsUrl: (state, action: PayloadAction<ParamsI>) => {
            return {...state, params: action.payload}
        }
    },
    extraReducers: build => {
        build.addCase(searchProducts.pending, (state) => {
            return {...state, httprequest:{
                loading: true,
                error: false,
                success: false
            }}
        })
        build.addCase(searchProducts.fulfilled, (state, action: PayloadAction<StateResponseI[] | any>) => {
            return {...state, data: action.payload.data.message, headers: action.payload.headers, httprequest: {
                error: false,
                loading: false,
                success: true
            }}
        })
        build.addCase(searchProducts.rejected, (state, action:PayloadAction<any>) => {
            return {...state, httprequest:{
                error: true,
                loading: false,
                success: false,
                errorMsg: action.payload
            }}
        })
    }
})

export const getProductStorage = () => {
    return Boolean(localStorage.getItem('@leloae/product/selected')) === true && localStorage.getItem('@leloae/product/selected') !== 'undefined' ? JSON.parse(localStorage.getItem('@leloae/product/selected')) : null 
}

export const reducerProduct = StateProduct.reducer
export const actionProduct = {...StateProduct.actions, searchProducts}