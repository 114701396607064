import { api } from "./config"

export class Auction {
  async get<T>(id_auction: number): Promise<T> {
    return await api.get(`/auction/lots/show/${id_auction}`)
  }

  async getAuctionByIdProduct<T>(id_product: number): Promise<T> {
    return await api.get(`/auction/lots/show/by/product/${id_product}`)
  }

  async getBidValues<T>(id_product: number): Promise<T> {
    return await api.get(`/bid/auction/product/${id_product}`)
  }

  async getUserActiveAuction<T>(nr_cpf: string): Promise<T> {
    return await api.get(`/auction/user/show/${nr_cpf}`)
  }

  async createAuction<T>(data: any): Promise<T> {
    return await api.post("/auction/lots", data)
  }

  async createBidAuction<T>(data: any): Promise<T> {
    return await api.post("/bid/auction", data)
  }

  async createUsersActiveAuction<T>(id_person: number, data: any): Promise<T> {
    return await api.post(`/auction/user/${id_person}`, data)
  }

  async update<T>(id_auction: number, data: any): Promise<T> {
    return await api.put(`/auction/lots/update/${id_auction}`, data)
  }

  async listAuction<T>(data: any): Promise<T> {
    return await api.get("/auction/lots", { params: data })
  }

  async listAuctionProduct<T>(id_auction: number, data: any): Promise<T> {
    return await api.get(`/product/auction/${id_auction}`, { params: data })
  }

  async getProductAuction<T>(id_product: number): Promise<T> {
    return await api.get(`/product/show/${id_product}`)
  }
}
