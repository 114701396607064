import React from "react"
import ProductList from "../AnimalList"
import CarouselDestaque from "../CarouselDestaque"

import * as S from "./styles"

const BuyPage = (props: any) => {
  return (
    <S.Wrapper>
      <S.ContentDestaque>
        <CarouselDestaque animalList />
        <ProductList props={props} />
      </S.ContentDestaque>
    </S.Wrapper>
  )
}

export default BuyPage
