/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';

import { Form, Button, Row } from 'react-bootstrap';

import { useCreateUserContext, IAddress } from '../../providers/use-create'
import { FormatCpf, FormatCep } from '../../helpers/format';
import { api } from '../../services/apiLeloae/config';
import { toast } from 'react-toastify';
import { ValidationFormCreateAddress } from '../../helpers/dataValidation';

const RegisterContact: React.FC = () => {
    const { dataAddressContext, setUserAddressStorage } = useCreateUserContext()
    const [listStateData, setListStateData] = useState<any>([])
    const [listCityData, setListCityData] = useState<any>([])
    const [dataAddress, setDataAddress] = useState<IAddress>({
        nr_zip_code: '',
        des_address: '',
        des_number: '',
        des_complement: '',
        id_uf: 0,
        code_city: 0,
        des_uf: '',
        des_city: '',
        nr_cpf: '',
        nr_receive_ads_whatsapp: 0
    })

    useEffect(() => {
        if(dataAddressContext) {
            setDataAddress(dataAddressContext)
        }
    },[dataAddressContext])

    const listState = async () => {
        try {
            const responseListState: any = await api.get('/address/list/state')
            setListStateData(responseListState?.data?.message)
        } catch (error) {
            toast.error('Ocorreu erro em buscar listar os estados', {toastId: 1})    
        }
    }

    const listCity = async () => {
        try {
            const responseListCity: any = await api.get(`/address/list/search/city/${dataAddress.id_uf}`)
            setListCityData(responseListCity?.data?.message)
        } catch (error) {
        toast.error('Ocorreu erro em buscar listar cidades', {toastId: 1})    
        }
    }

    const handleFormChangeCreateUserAddress = (event: any) => {
        const name = event.target.name
        const value = event.target.value
        const checked = event.target.checked

        setDataAddress(field =>{
            return {
                ...field, [`${name}`]:value
            }
        })

        if(name === 'code_city'){
            const textSelect = event.target.selectedOptions[0].textContent
            setDataAddress(field => {
                return {
                    ...field, des_city: textSelect
                }
            })
        }

        if(name === 'id_uf'){
            const textSelect = event.target.selectedOptions[0].textContent
            setDataAddress(field => {
                return {
                    ...field, des_uf: textSelect
                }
            })
        }

        if(name === 'nr_receive_ads_whatsapp'){
            setDataAddress(field => {
                return {
                    ...field, [`${name}`]: checked === true ? 1 : 0
                }
            })
        }
    }

    const handleSubimitStorageUserAddress = async (event: any) => {
        event.preventDefault()
        const errorMgs: any = ValidationFormCreateAddress(dataAddress);
        if (errorMgs) {
            return toast.error(errorMgs)
        }
        await setUserAddressStorage(dataAddress);
    }

    useEffect(() => {
        listState()
    }, [])


    useEffect(() => {   
        if(dataAddress.id_uf){
            listCity()
        }
    }, [dataAddress.id_uf])
  return (
    <>
    <Form onSubmit={handleSubimitStorageUserAddress}>
        <Form.Group controlId="id_uf">
            <Form.Label className="label-control">Estado</Form.Label>
            <Form.Control as="select" value={dataAddress.id_uf} name="id_uf" onChange={handleFormChangeCreateUserAddress}>
                <option value="DEFAULT" hidden>Selecione seu Estado</option>
                {listStateData.map((state: any) =>
                    <option key={state.id_uf} value={state.id_uf}>{state.des_uf}</option>
                )}
            </Form.Control>
        </Form.Group>
        <Form.Group controlId="code_city">
            <Form.Label className="label-control">Cidade</Form.Label>
            <Form.Control as="select" value={dataAddress.code_city} name="code_city" onChange={handleFormChangeCreateUserAddress}>
                <option value="DEFAULT" hidden>Selecione sua Cidade</option>
                {listCityData.map((city: any) =>
                    <option key={city.code_city} value={city.code_city}>{city.des_city}</option>
                )}
            </Form.Control>
        </Form.Group>
        <Form.Group controlId="nr_zip_code">
            <Form.Label className="label-control">CEP</Form.Label>
            <Form.Control type="text" placeholder="CEP" name="nr_zip_code" value={FormatCep(dataAddress.nr_zip_code)} onChange={handleFormChangeCreateUserAddress} />
        </Form.Group>
        <Row>
            <Form.Group controlId="des_address" className="col-sm-9">
                <Form.Label className="label-control">Endereço</Form.Label>
                <Form.Control type="text" name="des_address" value={dataAddress.des_address} onChange={handleFormChangeCreateUserAddress} />
            </Form.Group>
            <Form.Group controlId="des_number" className="col-sm-3">
                <Form.Label className="label-control">Nº.</Form.Label>
                <Form.Control type="text" name="des_number" value={dataAddress.des_number} onChange={handleFormChangeCreateUserAddress} />
            </Form.Group>
        </Row>
        <Form.Group controlId="des_complement">
            <Form.Label className="label-control">Complemento</Form.Label>
            <Form.Control type="text" name="des_complement" value={dataAddress.des_complement} onChange={handleFormChangeCreateUserAddress} />
        </Form.Group>
        <Form.Group controlId="nr_cpf">
            <Form.Label className="label-control">CPF</Form.Label>
            <Form.Control type="text" name="nr_cpf" value={FormatCpf(dataAddress.nr_cpf)} onChange={handleFormChangeCreateUserAddress} />
        </Form.Group>
        <Form.Group controlId="nr_receive_ads_whatsapp">
            <Form.Check type="checkbox" label="Receber novidades por WhatsApp" name="nr_receive_ads_whatsapp" value={dataAddress.nr_receive_ads_whatsapp} onChange={handleFormChangeCreateUserAddress} />
        </Form.Group>
        <Button className="col-12 btn btn-create" type="submit">
            Avançar
        </Button>
    </Form>
    </>
  );
}
export default RegisterContact;
