/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';

import Header from '../../../components/HeaderAdmin'
import Sidebar from '../SideNav'
import Auction from './auction'
import Company from './company'

import './styles.css'
import { Container, Row } from 'react-bootstrap';
import { useCreateAuctionContext } from '../../../providers/auction-create';
import { FormatError } from '../../../helpers/formatError';
import { toast } from 'react-toastify';
import Loading from '../../../components/Loading';
import { useHistory } from 'react-router-dom';
import { checkDevice } from '../../../helpers/utils';

const RegisterAction: React.FC = (propos: any) => {
  const { id_person } = propos.match.params

  const { loadMsg, progress, auctionData  } = useCreateAuctionContext()
  const [expandedResume, setExpandedResume] = useState({ expanded: null })
  let retgisterForm: {} | null | undefined;
  let progressCreateAuction = progress;
  const history = useHistory()

  if(progressCreateAuction === 1) {
    retgisterForm = <Auction children={id_person} />
  }else if (progressCreateAuction === 2) {
    retgisterForm = <Company />
  }

  useEffect(() => {
    if(loadMsg.msgError) {
      const errorFormat = FormatError(loadMsg.msgError)
      errorFormat.forEach((element: any) => {
          toast.error(element)
      });
    }
  }, [loadMsg.msgError])

  useEffect(() => {
    if(loadMsg.msgSuccess) {
      const errorFormat = FormatError(loadMsg.msgSuccess)
      errorFormat.forEach((element: any) => {
          toast.success(element)
    });
      history.push(`/admin/create/auction/files/${auctionData.id_auction}/${id_person}`)
    }
  }, [history, loadMsg.msgSuccess])
  
  useEffect(() => {
    setExpandedResume({expanded: checkDevice()})
  },[])
  
  return (
    <>
      {loadMsg.createAuctionLoading && 
        <Loading />
      }
      <Container fluid id="register-action" >
        <Row> 
          <Sidebar />
          <Header />
          <div className={expandedResume.expanded ? 'resume' : 'resume-mobile'}>
            {retgisterForm}
          </div>
        </Row>
      </Container>
    </>
  );
}
export default RegisterAction;
