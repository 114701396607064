/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Header from '../../../components/HeaderAdmin'
import Sidebar from '../SideNav'

import './styles.css'
import { Button, Card, Container, Figure, Jumbotron, Row} from 'react-bootstrap';
import { checkDevice } from '../../../helpers/utils';
import Pagination from 'rc-pagination';
import { Redirect, useHistory } from 'react-router-dom';

import { actionAuctionProduct  } from '../../../store/reduces/ReducerAuctionProduct';
import { actionAuction } from '../../../store/reduces/ReducerAuction';
import { RootState } from '../../../store';

import { FormatError } from '../../../helpers/formatError';
import { toast } from 'react-toastify';

import { api } from '../../../services';
import { formatDataToCountDown, FormatDatePtBr, FormatHours, FormatPrice } from '../../../helpers/format';
import CountDownTime from '../../../components/CountDownTime';

const Auction: React.FC = (props: any) => {
    const id_auction = props.match.params.id_auction

    const [auction, setAuction] = useState(null)
    const history = useHistory();
    const dispatch = useDispatch()
    const { reducerAuctionProduct } = useSelector((state: RootState) => state)
    
    const [params, setParams] = useState({
      id_auction,
      page: 1,
      limit: 10,
      nr_status: 2
    })
    
  const [expandedResume, setExpandedResume] = useState({ expanded: null })

  const handleDetailProductauction = async (id_product: number) => {
      try {
          await api.apiLeloae.product.createView({id_product})
          history.push(`/admin/auction/product/detail/${id_product}/${auction.id_auction}`);

      } catch (error) {
          toast.error('Ocorreu algum erro', { toastId: 1 })
      }
  }

  const handlePage = (page: number) => {
    setParams(field => {
      return {
          ...field, page: page
      }
    })
  }

  const getAuction = async () => {
    try {
        const product = await api.apiLeloae.auction.get<any>(id_auction)

        setAuction(product.data?.message)
    } catch (error) {
        const errorFormat = FormatError(error)
        errorFormat.forEach(element => {
            toast.error(element)
        });
    }
  }

  const handleFinishAuction = async () => {

    try {
        const result = await api.apiLeloae.auction.update<any>(id_auction, {id_person: auction?.id_person, nr_status: 3})

        if(result.status === 200) {
            toast.success('Leilão finalizado com sucesso')
            setAuction(field => {
                return {
                  ...field, nr_status: 3
                }
            })
        }
        
    } catch (error) {
        const errorFormat = FormatError(error)
        errorFormat.forEach(element => {
            toast.error(element)
        });
    }
  }

  useEffect(() => {
    dispatch(actionAuction.searchAuctions(params))
  }, [dispatch, params])

  useEffect(() => {
    setExpandedResume({expanded: checkDevice()})
  },[])

  useEffect(() => {
    getAuction()
  }, [id_auction])

  useEffect(() => {
    dispatch(actionAuctionProduct.searchProducts(params))
  }, [params])

  console.log(auction)

  if(!id_auction){
    return <Redirect to="/list/auctions"></Redirect>
  }

  return (
    <>
      <Container fluid id="admin-auction-detail">
          <Row>  
              <Sidebar />
              <Header />
                <div className={expandedResume.expanded ? 'resume' : 'resume-mobile'}>
                  <div className="row-auction">
                    <Container className="col-12">
                        <div>
                            <Row>
                                <div className="col-12 row">
                                    {/* TODO colocar margem para a parte lateral da tela */}
                                    <div className="col-12 col-sm-12 col-md-3 menu-left">
                                        <h2 className="title-custom-h2 col-12">Leilão Virtual</h2>
                                        <Jumbotron id="side-info-auction">
                                            <Container>
                                                {auction?.dt_deadline && 
                                                    <h3> {FormatDatePtBr(auction?.dt_deadline)} as {FormatHours(auction?.time_deadline)} </h3>
                                                }
                                                <p>Data de término do Leilão</p>

                                                <br/>
                                                <h4>Informações do leilão</h4> <br /><br />
                                                <p>Aqui fica reservado para a parte institucional do Leilão</p>
                                                <br/>
                                                {auction?.nr_status != 3 &&
                                                    <>  
                                                        <a className="btn btn-primary" href={`/admin/auction/active/user/${id_auction}/${auction?.id_person}`}>Cadastrar usuário</a>
                                                        <a className="btn btn-primary" href={`/admin/create/announce/lots/${auction?.id_person}/${id_auction}/2`}>Cadastrar lote</a>
                                                        <Button className="btn btn-danger" onClick={ ()=> handleFinishAuction() }>Finalizar leilão</Button>
                                                    </>
                                                }
                                            </Container>
                                        </Jumbotron>
                                    </div>
                                    
                                    {reducerAuctionProduct.httprequest.error && toast.error('Ocorreu erro em buscar lista de lotes', { toastId: 1 })}
                                    <div className="col-12 col-sm-12 col-md-9 list-right text-center">
                                        {auction?.nr_status != 3 ?
                                        auction?.dt_deadline && 
                                            <>
                                                <CountDownTime children={formatDataToCountDown(auction?.dt_deadline, auction?.time_deadline)} />
                                            </>
                                        :
                                            <h1>Leilão finalizado</h1>
                                        }
                                        <Card>
                                            <Card.Img variant="top" src={auction?.AuctionPhotos[0]?.des_auction_photo_url} />
                                            <Card.Body>
                                                <Card.Title>{auction?.des_title}</Card.Title>
                                                <Card.Text>
                                                    {auction?.des_organizer}
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                        {auction && 
                                            reducerAuctionProduct.data.map((product: any) =>
                                            <div className="shadow mb-3 bg-white colunm row item-product" key={product.id_product}>
                                                <div className="col-sm-12 col-md-5 div-figureProduct">
                                                    <Figure>
                                                        <Figure.Image className="lazyload" src={product?.Product?.ProductPhotos[0]?.des_product_photo_url} alt="Leilão mermo" />
                                                    </Figure>
                                                </div>
                                                <div className="col-sm-12 col-md-7 info-lots">
                                                    <div className="row titles"><h2>{product?.Product?.des_title} </h2> <p>Leilão Virtual</p></div>
                                                    <div className="description"><p>{product?.Product?.des_description}</p></div>
                                                    <div className="row vl-show-details">
                                                        <p className="type-coin text-center text-sm-center text-lg-left"></p>
                                                        <div className="col-sm-12 col-lg-4 text-center text-sm-center text-lg-left">
                                                            <h3 className="vl">{FormatPrice(product?.Product?.vl_price_total)}</h3>
                                                        </div>
                                                        <div className="col-12 col-lg-8 text-sm-center text-md-right d-none d-md-block d-lg-block d-xl-block">
                                                            <Button className="btn btn-create" onClick={ ()=> handleDetailProductauction(product.id_product) }>Ver Detalhes</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 d-block d-sm-block d-md-none d-lg-none d-xl-none">
                                                    <Button className="btn btn-create" onClick={ ()=> handleDetailProductauction(product.id_product) }>Ver Detalhes</Button>
                                                </div>
                                            </div>
                                            )
                                        }
                                        <Pagination current={params.page} onChange={handlePage} pageSize={10} total={reducerAuctionProduct.headers['x-total-count']} style={{ margin: '20px' }} />
                                    </div>
                                </div>
                            </Row>
                        </div>
                    </Container>
                  </div>
                </div>
            </Row>
      </Container>
    </>
  );
}
export default Auction;
