/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react"

import Footer from "../../../components/Footer"
import Navbar from "../../../components/Navbar"

import "./styles.css"
import { Button, Container, Form } from "react-bootstrap"
import { FormatError } from "../../../helpers/formatError"
import { toast } from "react-toastify"
import Loading from "../../../components/Loading"
import { FormatCpf, onlyNumbers } from "../../../helpers/format"
import { api } from "../../../services"
import { useUserContext } from "../../../providers/use-sign"
import { useHistory } from "react-router-dom"

const RegisterAction: React.FC = (propos: any) => {
  const id_auction = propos.match.params.id_auction

  const valueCpfBase = { nr_cpf: "" }
  const { data } = useUserContext()
  const history = useHistory()

  const [usersCpf, setUsersCpf] = useState([{ ...valueCpfBase }])
  const [loading, setLoading] = useState(false)

  const handleChange = (e, ix) => {
    const { name, value } = e.target
    let values = [...usersCpf]
    values[ix][name] = onlyNumbers(value)
    setUsersCpf((state) => [...values])

    if (onlyNumbers(value).length == 11) {
      setUsersCpf((state) => [...state, { ...valueCpfBase }])
    }
  }
  const handleDelete = (ix: number) => {
    let values = usersCpf.filter((a, b) => {
      if (b !== ix) {
        return a
      }
    })
    setUsersCpf((state) => [...values])
  }

  const handleSubimitAuctionRegisterUsers = async (event) => {
    event.preventDefault()

    if (!usersCpf[0].nr_cpf) {
      return toast.error("Informe o CPF do usuário")
    }

    try {
      setLoading(true)
      const result: any = await api.apiLeloae.auction.createUsersActiveAuction(
        data.user?.id_person,
        usersCpf
      )

      if (result.data?.message.id_users_auction) {
        toast.success("CPF de usuários registrados com sucesso.", {
          toastId: 1,
        })
        history.push(`/auction/detail/${id_auction}`)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      if (error.response.data.message) {
        const errorFormat = FormatError(error.response.data.message)
        errorFormat.forEach((element) => {
          toast.error(element)
        })
      } else {
        const errorFormat = FormatError(error)
        errorFormat.forEach((element) => {
          toast.error(element)
        })
      }
    }
  }

  return (
    <>
      {loading && <Loading />}
      <Navbar />
      <Container>
        <div
          id="auction-register-user"
          className="col-sm-12 text-center row justify-content-center"
        >
          <h2 className="title-custom-h2">
            Cadastrar usuário para participar do leilão
          </h2>
          <div className="content">
            <Form onSubmit={handleSubimitAuctionRegisterUsers}>
              {usersCpf.map((data, index) => (
                <div key={data.nr_cpf}>
                  <Form.Group controlId="des_title">
                    <Form.Control
                      type="text"
                      name="nr_cpf"
                      value={FormatCpf(data.nr_cpf)}
                      max={11}
                      placeholder="Informe CPF do usuário"
                      onChange={(event) => handleChange(event, index)}
                    />{" "}
                    <Button
                      className="btn btn-danger"
                      onClick={() => handleDelete(index)}
                    >
                      Remover
                    </Button>
                  </Form.Group>
                </div>
              ))}
              <p>
                Basta inserir CPF do usuário que os próximo campos será revelado
                para próximo CPF
              </p>

              <Button type="submit" className=" btn btn-create">
                Cadastrar
              </Button>
            </Form>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  )
}
export default RegisterAction
