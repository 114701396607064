/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-unreachable */
/* eslint-disable eqeqeq */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Sidebar from '../SideNav'
import Header from '../../../components/HeaderAdmin'
import Loading from '../../../components/Loading';


import './styles.css'
import { Button, Card, Container, Figure, Form, Modal, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faVideo, faFile, faTrash } from '@fortawesome/free-solid-svg-icons';

import { toast } from 'react-toastify';
import { api } from '../../../services/apiLeloae/config';
import { FormatError } from '../../../helpers/formatError';
import { checkDevice } from '../../../helpers/utils';


const AdminLotsFilesUpdate: React.FC = (propos: any) => {
  const {id_auction, id_product} = propos.match.params
  
  const myRef = useRef<null | HTMLDivElement>(null);
  const [expandedResume, setExpandedResume] = useState({ expanded: null })
  const [updateLoading, setUpdateLoading] = useState(false)
  const [imgProduct, setImgProduct] = useState([]);
  const [videoProduct, setVideoProduct] = useState([]);
  const [imgDocumentProduct, setImgDocumentProduct] = useState([]);
  const [imgPreview, setimgPreview] = useState<any>([]);
  const [videoPreview, setVideoPreview] = useState<any>([]);
  const [documentPreview, setDocumentPreview] = useState<any>([]);
  const [ProgressUpload, setProgressUpload] = useState<any>(0);
  const [product, setProduct] = useState<any>([])
  const [lengthFiles, setLengthFiles] = useState({
    productPhoto: 0,
    productDocument: 0,
    productVideo: 0
  })
  const [showModal, setShowModal] = useState(false);
  const [filesDelete, setFilesDelete] = useState({
    id_file: 0,
    des_file: '',
    index: 0
  });

  const history = useHistory()

  useEffect(() => {
    renderViewImg()
  },[imgProduct])

  const renderViewImg = () => {
    let url: any
    for (let index = 0; index < imgProduct.length; index++) {
      url = imgProduct ? [...imgProduct].map(URL.createObjectURL) : null
    }
    setimgPreview(url)
  }

  useMemo(() => {
    let url: any
    for (let index = 0; index < videoProduct.length; index++) {
      url = videoProduct ? [...videoProduct].map(URL.createObjectURL) : null
    }
    setVideoPreview(url)
  }, [videoProduct])

  useEffect(() => {
    renderViewImgDocument()
  }, [imgDocumentProduct])

  const renderViewImgDocument = () => {
    let url: any
    for (let index = 0; index < imgDocumentProduct.length; index++) {
      url = imgDocumentProduct ? [...imgDocumentProduct].map(URL.createObjectURL) : null
    }
    setDocumentPreview(url)
  }

  const getProduct = async () => {
    setUpdateLoading(true)
    try {
        const product: any = await api.get(`/admin/product/show/${id_product}`)
        
        setProduct(product.data.message)
        setLengthFiles({
          productPhoto: product?.data?.message?.ProductPhotos?.length,
          productDocument: product?.data?.message?.ProductDocumentPhotos?.length,
          productVideo: product?.data?.message?.ProductVideos?.length
        })

        setUpdateLoading(false)
    } catch (error) {
        setUpdateLoading(false)
        const errorFormat = FormatError(error)
        errorFormat.forEach(element => {
          toast.error(element)
        });
    }
  }

  const handleChangeFormProductImg = (event: any) => {
    const images = []
    const files = event.target.files

    if(files.length > 10) {
      return toast.error('Limite de fotos ultrapassou o permitido de dez fotos.') 
    }

    if((lengthFiles.productPhoto + files.length) > 10) {
      return toast.error('Limite de fotos ultrapassou o permitido de dez fotos.') 
    }

    setLengthFiles(field => {
      return  {
        ...field, productPhoto: (lengthFiles.productPhoto + files.length)
      }
    })

    images.push(...imgProduct, ...files)

    setImgProduct(images)
  }

  const handleChangeFormProductVideo = (event: any) => {
    if(videoProduct.length >= 1) {
      return toast.error('Limite de vídeo ultrapassou o permitido de um vídeo.') 
    }
    setVideoProduct(event.target.files)
  }
  const handleChangeFormProductDocumentImg = (event: any) => {
    const imagesDocuments = []
    const files = event.target.files


    if(files.length > 10) {
      return toast.error('Limite de documentos ultrapassou o permitido de dez fotos de documentos.') 
    }

    if((lengthFiles.productDocument + files.length) > 10) {
      return toast.error('Limite de documentos ultrapassou o permitido de dez fotos de documentos!') 
    }

    setLengthFiles(field => {
      return  {
        ...field, productDocument: (lengthFiles.productDocument ? lengthFiles.productDocument + files.length : files.length)
      }
    })
    
    imagesDocuments.push(...imgDocumentProduct, ...files)
    
    setImgDocumentProduct(imagesDocuments)
  }

  const handleDeleteImg = (index: number) =>{

    setLengthFiles(field => {
      return  {
        ...field, productPhoto: (lengthFiles.productPhoto - 1)
      }
    })

    imgProduct.splice(index, 1)

    setImgProduct(imgProduct)
    renderViewImg()
  }

  const handleDeleteImgDocuments = (index: number) =>{
    setLengthFiles(field => {
      return  {
        ...field, productDocument: (lengthFiles.productDocument - 1)
      }
    })
    imgDocumentProduct.splice(index, 1)

    setImgDocumentProduct(imgDocumentProduct)
    renderViewImgDocument()
  }

  const handleDeleteApiPhoto = async (data: any) => {
    try {
      await api.delete(`/product/photo/${product?.id_person}/${id_product}/${data.des_file}`)

      product.ProductPhotos.splice(data.index, 1)

      setProduct(field => {
        return  {
          ...field, ProductPhotos: product.ProductPhotos
        }
      })
      handleClose()
    } catch (error) {
      const errorFormat = FormatError(error)
        errorFormat.forEach(element => {
          toast.error(element)
        });
    }
  }

  const handleDeleteApiDocumentPhoto = async (data: any) => {
    try {
      await api.delete(`/product/document/photo/${product?.id_person}/${id_product}/${data.des_file}`)

      product.ProductDocumentPhotos.splice(data.index, 1)

      setProduct(field => {
        return  {
          ...field, ProductDocumentPhotos: product.ProductDocumentPhotos
        }
      })
      handleClose()
    } catch (error) {
      const errorFormat = FormatError(error)
        errorFormat.forEach(element => {
          toast.error(element)
        });
    }
  }

  const handleDeleteApiVideo = async (data: any) => {
    try {
      await api.delete(`/video/product/delete/${product?.id_person}/${id_product}`)

      product.ProductVideos.splice(data.index, 1)

      setProduct(field => {
        return  {
          ...field, ProductVideos: product.ProductVideos
        }
      })
      handleClose()
    } catch (error) {
      const errorFormat = FormatError(error)
        errorFormat.forEach(element => {
          toast.error(element)
        });
    }
  }

  const handleClose = () => setShowModal(false);

  const handleShow = (id_file: number, des_file: string, index: number) => {
    setFilesDelete({
      id_file,
      des_file,
      index
    })
    setShowModal(true)
};


  const handleProgressUpload = async (event: any) => {
    const value = event.target.value
  
    if(!imgProduct[0] && !product?.ProductPhotos[0]) {
      return toast.error('Envio de fotos e obrigatório') 
    }
      setProgressUpload(value)
      await new Promise(resolve => setTimeout(resolve, 20))
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  
  }

  const handleSubmitFiles = async (event: any) => {
    event.preventDefault()
    setUpdateLoading(true)
    try {
        const dataPhotoProduct = new FormData();
        for (let index = 0; index < imgProduct.length; index++) {
          dataPhotoProduct.append('des_product_photos', imgProduct[index])
        }
        dataPhotoProduct.append('id_product', String(id_product))
        dataPhotoProduct.append('id_person', String(product?.id_person))
        await api.post(`/product/photo`, dataPhotoProduct)


        if(videoProduct[0]){
          const dataVideoProduct = new FormData();
          for (let index = 0; index < videoProduct.length; index++) {
            dataVideoProduct.append('des_product_video', videoProduct[index])
          }
          dataVideoProduct.append('id_product', String(id_product))
          dataVideoProduct.append('id_person', String(product?.id_person))
          dataVideoProduct.append('des_title', String(product.des_title))
          await api.post(`/video/product`, dataVideoProduct)
        } 

        if(imgDocumentProduct[0]){
          const dataDocumentProduct = new FormData();
          for (let index = 0; index < imgDocumentProduct.length; index++) {
            dataDocumentProduct.append('des_product_document_photos', imgDocumentProduct[index])
          }
          dataDocumentProduct.append('id_product', String(id_product))
          dataDocumentProduct.append('id_person', String(product?.id_person))
          await api.post(`/product/document/photo`, dataDocumentProduct)
        } 

        await api.put(`/product/update/${id_product}`, product)

        setUpdateLoading(false)

        toast.success('Lote finalizado com sucesso!', {toastId: 1})  
        if(id_auction){
          history.push(`/admin/auction/detail/${id_auction}`)
        }else{
          history.push(`/admin/lots/detail/${id_product}`)
        }

    } catch (error) {
        const errorFormat = FormatError(error)
        errorFormat.forEach(element => {
            toast.error(element, {toastId: 1})
        });
        setUpdateLoading(false)
    }
  }
  useEffect(() => {
    setExpandedResume({expanded: checkDevice()})
  },[])

  const scrollTop = async () => {
    myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  useEffect(() =>{
    scrollTop()
    getProduct()
  }, [])

  console.log(product)

  return (
    <>
      {updateLoading && 
          <Loading />
      }
      <div ref={myRef}></div>
      <Container fluid id="files-update-admin">
        <Row>  
          <Sidebar />
          <Header />
          <div className={expandedResume.expanded ? 'resume text-center' : 'resume-mobile text-center'}>
            <Row>
            <h2 className="title-custom-h2 col-12">Envio de arquivos</h2>
              <div  className="col-12">
                <Card.Body id="photo-product" className="shadow p-3 mb-5 bg-white text-center">
                    <Card.Title className="col-12"><b>Deixe seu anúncio mais atrativo com fotos</b></Card.Title>
                    <Row>
                    <Figure className="row">
                        {product?.ProductPhotos ? product?.ProductPhotos.map((img: any, index: number) =>
                          <div key={img.id_product_photo}>
                            <Figure.Image
                              src={img.des_product_photo_url}
                            />
                            <Button className="btn-trash" onClick={() => handleShow(img.id_product_photo, img.des_product_photo, index)}>
                              <FontAwesomeIcon icon={faTrash} size="1x" />
                            </Button>
                            <Modal show={showModal} onHide={handleClose}>
                              <Modal.Header closeButton>
                                  <Modal.Title>Tem certeza desta ação?</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>ID# {filesDelete.id_file}</Modal.Body>
                              <Modal.Footer>
                                  <Button variant="secondary" onClick={handleClose}>
                                      Cancelar
                                  </Button>
                                  <Button variant="danger" onClick={() => handleDeleteApiPhoto(filesDelete)}>
                                      Deletar
                                  </Button>
                              </Modal.Footer>
                            </Modal>
                          </div>
                        ) : '' }
                    </Figure>

                    <Figure className="row">
                        {imgPreview ? imgPreview.map((img: any, index: number) =>
                          <div key={img}>
                            <Figure.Image
                              src={img}
                            />
                            <Button className="btn-trash" onClick={() => handleDeleteImg(index)}>
                              <FontAwesomeIcon icon={faTrash} size="1x" />
                            </Button>
                          </div>
                        ) : '' }
                    </Figure>
                    {product?.ProductPhotos?.length < 10 && 
                      <Form>
                          <label className="upload-foto-profile">
                              <input type="file" accept="image/png, image/jpeg, image/jpg" multiple onChange={handleChangeFormProductImg}/>
                              <p className="upload-foto-profile-input" ><FontAwesomeIcon icon={faCamera} size="1x" /> </p>
                          </label>
                      </Form>
                    }
                    <p className="col-12 text-left">Arquivos  PNG,JPG, JPEG</p>
                    </Row>
                    {ProgressUpload === 0 &&
                      <Button value="1" onClick={handleProgressUpload} className="btn btn-create">
                          Avançar
                      </Button>
                    }
                </Card.Body>
              </div>
              {ProgressUpload >= 1 &&
                <div ref={myRef} className="col-12">
                  <Card.Body id="video-product" className="shadow p-3 mb-5 bg-white text-center">
                      <Card.Title className="col-12"><b>Deixe seu anúncio mais completo enviado vídeo</b></Card.Title>
                      <p>Opcional</p>
                      <Row>
                        <Figure>
                          {product?.ProductVideos ? product?.ProductVideos.map((video: any, index) =>
                            <div  key={video.id_product_video}>
                              <video src={video?.des_product_video_url}></video>
                              <Button className="btn-trash" onClick={() => handleShow(video.id_product_video, video.des_product_video, index)}>
                                <FontAwesomeIcon icon={faTrash} size="1x" />
                              </Button>
                              <Modal show={showModal} onHide={handleClose}>
                              <Modal.Header closeButton>
                                  <Modal.Title>Tem certeza desta ação?</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>ID# {filesDelete.id_file}</Modal.Body>
                              <Modal.Footer>
                                  <Button variant="secondary" onClick={handleClose}>
                                      Cancelar
                                  </Button>
                                  <Button variant="danger" onClick={() => handleDeleteApiVideo(filesDelete)}>
                                      Deletar
                                  </Button>
                              </Modal.Footer>
                            </Modal>
                            </div>
                          ) : '' }
                        </Figure>
                        <Figure>
                          {videoPreview ? videoPreview.map((video: any) =>
                            <div  key={video}>
                              <video src={video}></video>
                              <Button className="btn-trash" onClick={() => setVideoProduct([])}>
                                <FontAwesomeIcon icon={faTrash} size="1x" />
                              </Button>
                            </div>
                          ) : '' }
                        </Figure>
                        {product?.ProductVideos?.length < 1 &&
                          <Form>
                            <label className="upload-foto-profile">
                                <input type="file" accept="video/*" onChange={handleChangeFormProductVideo}/>
                                <p className="upload-foto-profile-input" ><FontAwesomeIcon icon={faVideo} size="1x" /> </p>
                            </label>
                          </Form>
                        }
                      <p className="col-12 text-left">Arquivos MP4, AVI, WMV, 3GPP, limite tempo 2 minutos</p>
                      </Row>
                      {ProgressUpload == 1 &&
                      <Button value="2" onClick={handleProgressUpload} className="btn btn-create">
                          Avançar
                      </Button>
                    }
                  </Card.Body>
                </div>
              }
              {ProgressUpload >= 2 &&
                <div ref={myRef} className="col-12">
                  <Card.Body id="document-product" className="shadow p-3 mb-5 bg-white text-center">
                      <Card.Title className="col-12"><b>Adicionar informações anexas</b></Card.Title>
                      <p>Opcional</p>
                      <Row>
                      <Figure className="row">
                          {product?.ProductDocumentPhotos ? product.ProductDocumentPhotos.map((imgDocument: any, index: number) =>
                            <div key={imgDocument.id_product_document_photo}>
                              <Figure.Image
                                src={imgDocument.des_product_document_photo_url}
                              />
                              <Button className="btn-trash" onClick={() => handleShow(imgDocument.id_product_document_photo, imgDocument.des_product_document_photo, index)}>
                                <FontAwesomeIcon icon={faTrash} size="1x" />
                              </Button>
                              <Modal show={showModal} onHide={handleClose}>
                              <Modal.Header closeButton>
                                  <Modal.Title>Tem certeza desta ação?</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>ID# {filesDelete.id_file}</Modal.Body>
                              <Modal.Footer>
                                  <Button variant="secondary" onClick={handleClose}>
                                      Cancelar
                                  </Button>
                                  <Button variant="danger" onClick={() => handleDeleteApiDocumentPhoto(filesDelete)}>
                                      Deletar
                                  </Button>
                              </Modal.Footer>
                            </Modal>
                            </div>
                          ) : '' }
                      </Figure>
                      <Figure className="row">
                          {documentPreview ? documentPreview.map((imgDocument: any, index: number) =>
                            <div key={imgDocument}>
                              <Figure.Image
                                src={imgDocument}
                              />
                              <Button className="btn-trash" onClick={() => handleDeleteImgDocuments(index)}>
                                <FontAwesomeIcon icon={faTrash} size="1x" />
                              </Button>
                            </div>
                          ) : '' }
                      </Figure>
                      {product?.ProductDocumentPhotos?.length < 10 &&
                        <Form>
                            <label className="upload-foto-profile">
                                <input type="file" accept="image/png, image/jpeg, image/jpg" multiple onChange={handleChangeFormProductDocumentImg}/>
                                <p className="upload-foto-profile-input" ><FontAwesomeIcon icon={faFile} size="1x" /> </p>
                            </label>
                        </Form>
                      }
                      <p className="col-12 text-left">Arquivos PNG,JPG, JPEG</p>
                      </Row>
                      <Button onClick={handleSubmitFiles} className="btn btn-create">
                        Finalizar
                      </Button>
                  </Card.Body>
                </div>
              }
            </Row>
          </div>
        </Row> 
      </Container>
    </>
  );
}
export default AdminLotsFilesUpdate;
