/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react"
import { Navbar, Nav, FormControl, InputGroup, Figure } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../store"

import { useUserContext } from "../../providers/use-sign"
import { useStateGlobalContext } from "../../providers/state-global"
import { actionProducttSearch } from "../../store/reduces/ReducerProductSearch"
import Outside from "../../components/Outside"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/free-solid-svg-icons"

import imgLogo from "../../assets/image/leiloae-logo-2@4x.png"

import "./styles.css"

import AuthenticatedUser from "./authenticatedUser"
import CreateUserButton from "./createUserButton"
import { toast } from "react-toastify"
import { Link, useHistory } from "react-router-dom"
import { useParamsProductContext } from "../../providers/product-params"

const Header: React.FC = () => {
  const [searchData, setSearchData] = useState("")
  const { reducerProductSearch } = useSelector((state: RootState) => state)
  const { params, setParamsProduct } = useParamsProductContext()
  const dispatch = useDispatch()

  let unauthenticatedCreate: {} | null | undefined
  let unauthenticatedLogin: {} | null | undefined

  const { signed } = useUserContext()
  const { statusOutSide, setStateSearchHide } = useStateGlobalContext()
  const history = useHistory()

  if (signed) {
    unauthenticatedCreate = <AuthenticatedUser />
  } else {
    unauthenticatedCreate = <CreateUserButton />
    unauthenticatedLogin = (
      <Nav.Link className="btn btn-login" href="/authentication">
        Já possui uma conta? <b>Entrar.</b>
      </Nav.Link>
    )
  }

  const checkedLogin = () => {
    toast.warn("Você precisa está logando para anúnciar", { toastId: 1 })
    history.push("/authentication")
  }

  const handleSearch = (event: any) => {
    setStateSearchHide(true)
    const value = event.target.value
    if (event.target.dataset.id === "icon-search") {
      history.push(`/product/list/search/${searchData}`)
      return
    }
    if (event.which === 13) {
      history.push(`/product/list/search/${value}`)
      return
    }

    setSearchData(value)
    setParamsProduct({ type: "search", value: value })

    if (value) {
      dispatch(actionProducttSearch.searchProducts({ filter: params }))
    }
  }

  return (
    <header>
      <div className="bg-dark-custom">
        <div className="container">
          <Navbar
            id="navbar-custom"
            collapseOnSelect
            expand="lg"
            bg="bg-dark-custom"
            variant="dark"
          >
            <Navbar.Brand id="logo" className="d-block d-lg-none" href="/">
              <Figure.Image width={80} alt="Leiloaê" src={imgLogo} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav" className="form-row">
              <div
                className="col-12 flex-column flex-lg-row"
                id="nav-custom-top"
              >
                <div className="col-12 col-lg-9 form-row">
                  <Navbar.Brand
                    id="outra-logo"
                    className="d-none d-lg-block d-xl-block col-lg-2"
                    href="/"
                  >
                    <Figure.Image
                      width={290}
                      height={340}
                      alt="Leiloaê"
                      src={imgLogo}
                    />
                  </Navbar.Brand>
                  <div className="col-lg-10 col-10">
                    <InputGroup>
                      <FormControl
                        placeholder="Buscar anúncios e muito mais"
                        aria-label="Buscar anúncios e muito mais"
                        aria-describedby="basic-addon2"
                        onChange={handleSearch}
                        onKeyPress={handleSearch}
                        defaultValue={params?.search}
                      />
                      <InputGroup.Append>
                        <FontAwesomeIcon
                          onClick={handleSearch}
                          data-id="icon-search"
                          className="icon-search"
                          icon={faSearch}
                          size="2x"
                        />
                      </InputGroup.Append>
                    </InputGroup>
                    {statusOutSide && (
                      <Outside>
                        {reducerProductSearch.data[0] && searchData && (
                          <ul className="search-list shadow mb-3 bg-white ">
                            {reducerProductSearch.data.map((product: any) => (
                              <Link
                                key={product.id_product}
                                to={`/product/list/search/${product.des_title}`}
                              >
                                <li>
                                  <FontAwesomeIcon
                                    className="icon-search-list"
                                    icon={faSearch}
                                    size="1x"
                                  />
                                  {product.des_title}
                                </li>
                              </Link>
                            ))}
                          </ul>
                        )}
                      </Outside>
                    )}
                  </div>
                </div>
                {unauthenticatedCreate}
              </div>
              <div className="col-12">
                <Nav id="nav-extra-custom">
                  <Nav.Link href="/">Início</Nav.Link>
                  <Nav.Link href="/about/us">Quem Somos</Nav.Link>
                  <Nav.Link className="destaque-btn" href="/product/list/">
                    Comprar
                  </Nav.Link>
                  {signed ? (
                    <Nav.Link className="destaque-btn" href="/announce">
                      Vender
                    </Nav.Link>
                  ) : (
                    <Nav.Link className="destaque-btn" onClick={checkedLogin}>
                      Anunciar
                    </Nav.Link>
                  )}
                  <Nav.Link href="/list/auctions">Leilões</Nav.Link>
                  <Nav.Link href="/downloads/app">Baixe nosso APP</Nav.Link>
                  <Nav.Link href="/contact">Fale conosco</Nav.Link>
                  <Nav.Link href="http://blog.leiloae.com.br/">Blog</Nav.Link>
                  {unauthenticatedLogin}
                </Nav>
              </div>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </div>
    </header>
  )
}
export default Header
