/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SwiperSlide } from "swiper/react/swiper-react.js"

import AnimalCard from '../../components/AnimalCard';
import Carousel from '../../components/Carousel';

import { FormatPrice } from '../../helpers/format';

import { RootState } from '../../store';
import { actionProduct } from '../../store/reduces/ReducerProduct';

import './styles.css'

const ProductSimilar: React.FC = (props: any) => {

  const { reducerProduct } = useSelector((state: RootState) => state)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actionProduct.searchProducts({ page: 1, limit: 10, filter: { id_type_product_animal: props.children, nr_status: 2 } }))

  }, [reducerProduct.params])

  const breakpoints = {
    620: {
      spaceBetween: 30,
    },
    1023: {
      centeredSlides: false,
    },
  }

  const initialValue = () => {
    if (window.screen.width / 361 <= 3) {
      return window.screen.width / 361
    }
    return 3
  }

  const [numSlides, setNumSlides] = useState(initialValue)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleResize = () => {
    if (window.screen.width / 361 <= 3) {
      setNumSlides(window.screen.width / 361)
    } else {
      setNumSlides(3)
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize, false)
  }, [handleResize, numSlides])

  return (
    <>
      <section id="product-similar">
        <Carousel
          width="1120px"
          space={40}
          numSlides={numSlides}
          breakpoints={breakpoints}
          variant="product"
        >
          {reducerProduct.data.map(
            (product: any) =>
              product?.TypeProductStatus.des_type_product_status ===
              "Disponível" && (

                <SwiperSlide
                  key={product?.id_product}

                >
                  <a href={`/product/detail/${product.id_product}`}>
                    <AnimalCard
                      key={product.id_product}
                      image={product?.ProductPhotos[0]?.des_product_photo_url}
                      alt={product?.des_title}
                      title={product?.des_title}
                      price={FormatPrice(product?.vl_price_total)}
                      address={
                        product?.AddressProduct?.City?.des_city +
                        "/" +
                        product?.AddressProduct?.City?.uf
                      }
                      animalQtd={product?.qtd_animals}
                      animalWeight={product?.nr_weight}
                      variant="lista"
                    />
                  </a>
                </SwiperSlide>
              )
          )}
        </Carousel>
      </section>
    </>
  );
}
export default ProductSimilar;
