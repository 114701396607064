import * as React from 'react'
import { Route } from 'react-router-dom'
import Home from '../pages/Home';
import Contact from '../pages/Contact';
import Helps from '../pages/Helps';
import DownloadsApp from '../pages/DownloadsApp';
import AboutUs from '../pages/AboutUs';
import Product from '../pages/Product';
import ProductDetail from '../pages/ProductDetail';
import Login from '../pages/Login';
import TermsCompromisse from '../pages/RegisterUser/termsCompromisse';
import Partners from '../pages/Partners';
//Leilão
import ListAuctions from '../pages/AuctionsList';
import LeilaoDetail from '../pages/AuctionDetail';
import ProductDetailAuction from '../pages/ProductDetailAuction';

const AppRouter = () => {
  return (
    <>
      <Route exact path="/" component={Home} />
      <Route path="/terms" component={TermsCompromisse} />
      <Route path="/list/auctions" component={ListAuctions} />
      <Route path="/auction/detail/:id_auction" component={LeilaoDetail} />
      <Route path="/auction/product/detail/:id_product/:id_auction/:dt_deadline/:time_deadline/:des_title" component={ProductDetailAuction} />
      <Route path="/partners" component={Partners} />
      <Route exact path="/downloads/app" component={DownloadsApp} />
      <Route exact path="/about/us" component={AboutUs} />
      <Route exact path="/helps" component={Helps} />
      <Route exact path="/contact" component={Contact} />
      <Route exact path="/product/list/:paramsurl?/:valueurl?/:paramsurl2?/:valueurl2?" component={Product} />
      <Route exact path="/product/detail/:id_product/:title?" component={ProductDetail} />


      <Route path="/authentication" component={Login} />
    </>
);
}


export default AppRouter
