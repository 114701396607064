import styled, { css } from "styled-components"
import { ButtonProps } from "."

type WrapperProps = Omit<ButtonProps, "children" & "icon">

const wrapperModifiers = {
  light: () => css`
    background-color: #fd880d;
    padding: 11px 20px 11px 18px;

    align-items: center;
    justify-content: end;

    gap: 8px;

    &:hover {
      background-color: #ca6d0a;
    }
  `,
  dark: () => css`
    background-color: #ce4600;
    padding: 11px 32px;

    align-items: center;
    justify-content: center;

    &:hover {
      background-color: #a53800;
    }
  `,
  icon: () => css`
    background-color: #fd880d;
    padding: 11px 32px;

    align-items: center;
    justify-content: center;

    &:hover {
      background-color: #ca6d0a;
    }
  `,
}

export const Wrapper = styled.a<WrapperProps>`
  ${({ width, variant }) => css`
    width: ${width};
    height: 45px;

    display: flex;
    margin: 0;
    border: 0;
    padding: 0;
    border-radius: 22px;
    position: relative;

    cursor: pointer;
    text-decoration: none !important;

    transition: 0.1s ease;

    > span {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 23px;
      color: #ffffff;
    }

    ${!!variant && wrapperModifiers[variant]}
  `}
`

export const Icon = styled.div`
  display: flex;
  align-items: center;

  & > svg {
    width: 100%;
    height: auto;
    cursor: pointer;
  }
`
