import * as React from 'react'
import { Route } from 'react-router-dom'
import AppRouter from './app.router'
import RegisterUser from '../pages/RegisterUser';
import ForgotPassword from '../pages/ForgotPassword';
import ForgotPasswordRestForm from '../pages/ForgotPasswordRest';

const AuthRouter = () => {
  return (
      
        <>
          <Route exact path="/forgot/password" component={ForgotPassword} />
          <Route exact path="/forgot/rest/password" component={ForgotPasswordRestForm} />
          <Route path="/register/user" component={RegisterUser} />
          <AppRouter />
        </>
    );
}

export default AuthRouter
