/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';

import Header from '../../../components/HeaderAdmin'
import Sidebar from '../SideNav'

import checked_status from '../../../assets/image/checked_status.png'

import './styles.css'
import { Col, Container, ProgressBar, Row, Form, Button} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandHoldingUsd, faFile, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FormatCep, FormatCpf, FormatDateHoursPtBr, FormatPhone, FormatPrice } from '../../../helpers/format';

import { checkDevice } from '../../../helpers/utils';
import { Link, useHistory } from 'react-router-dom';
import Loading from '../../../components/Loading';
import { FormatError } from '../../../helpers/formatError';
import { toast } from 'react-toastify';
import { api } from '../../../services/apiLeloae/config';
import axios from 'axios';
import { useStateGlobalContext } from "../../../providers/state-global";


const RequestsDetailResume: React.FC = (propos: any) => {
    const id_buy_product = propos.match.params.id_buy_product
    
    const { setAdminRequestNav } = useStateGlobalContext()
    const history = useHistory()

    const [expandedResume, setExpandedResume] = useState({ expanded: null })
    const [ProductLoading, setProductLoading] = useState(false)
    const [product, setProduct] = useState(null)
    const [productStatus, setProductStatus] = useState(null)
    const [userBuy, setUserBuy] = useState(null)
    const [userBuyAddress, setUserBuyAddress] = useState(null)
    const [userSeller, setUserSeller] = useState(null)
    const [userSellerAddress, setUserSellerAddress] = useState(null)
    const [typeProductStatus, setTypeProductStatus] = useState([])
    
    let mounted = true;
    const signal = axios.CancelToken.source();

    const getProduct = async () => {
        setProductLoading(true)
        try {
            const product: any = await api.get(`/admin/buy/product/show/${id_buy_product}`)

            setProductStatus(product.data?.message?.Product)

            setProduct(product.data?.message)

            setProductLoading(false)
        } catch (error) {
            setProductLoading(false)
            const errorFormat = FormatError(error)
            errorFormat.forEach(element => {
            toast.error(element)
            });
        }
    }


    const getUserBuy = async (id_person: number) => {
        setProductLoading(true)
        try {
            const user: any = await api.get(`/admin/user/${id_person}`)
            const address: any = await api.get(`/admin/address/${id_person}`)

            setUserBuy(user.data?.message)
            setUserBuyAddress(address.data?.message)

            setProductLoading(false)
        } catch (error) {
            setProductLoading(false)
            const errorFormat = FormatError(error)
            errorFormat.forEach(element => {
            toast.error(element)
            });
        }
    }

    const getUserseller = async (id_person: number) => {
        setProductLoading(true)
        try {
            const user: any = await api.get(`/admin/user/${id_person}`)
            const address: any = await api.get(`/admin/address/${id_person}`)

            setUserSeller(user.data?.message)
            setUserSellerAddress(address.data?.message)

            setProductLoading(false)
        } catch (error) {
            setProductLoading(false)
            const errorFormat = FormatError(error)
            errorFormat.forEach(element => {
            toast.error(element)
            });
        }
    }

    const getListTypeProductStatus = async () => {
        try {
          const responseTypeMessage: any = await api.get('/utils/type/animal/product/buy/negotiation/status', {cancelToken: signal.token})
          if (mounted) {setTypeProductStatus(responseTypeMessage?.data?.message)}
        } catch (error) {
          toast.error('Error e buscar lista de tipo de produto status', {toastId: 1})
        }
    }

    const handleChangeFormStatus = async (event: any) => {
        const name = event.target.name
        const value = event.target.value

        setProduct(field => {
            return ({
                ...field, [`${name}`]: value
            })
        })

        setProductStatus(field => {
            return ({
                ...field,  nr_status: parseInt(value) === 1 ? productStatus.nr_status + 1 
                : parseInt(value) === 2 ? productStatus.nr_status + 1 
                : parseInt(value) === 4 ? productStatus.nr_status + 1 
                : productStatus.nr_status
            })
        })
    }

    const handleSubmitStatus = async (event: any) => {
        event.preventDefault()
        try {
            await api.put(`/admin/buy/product/update/${product?.id_buy_product}`, product)
            await api.put(`/admin/product/update/${product?.id_product}`, productStatus)
            
            toast.success('Status alterado com sucesso!.', {toastId: 1})

          } catch (error) {
            toast.error('Ocorreu um erro em alterar status do serviço.', {toastId: 1})
          }
    }

    const handleGoBack = async () => {
        await setAdminRequestNav(2)
        history.push('/admin/requests')
    }
    
    useEffect(() => {
        getProduct()
        setExpandedResume({expanded: checkDevice()})
        getListTypeProductStatus()
    }, [])

    useEffect(() => {
        if(product?.id_person > 0) {
        getUserBuy(product?.id_person)
        getUserseller(product?.Product?.id_person)
        }
    }, [product])


  return (
    <>
      <Container fluid id="requests-detail">
          <Row>  
              <Sidebar />
              <Header />
                <div className={expandedResume.expanded ? 'resume' : 'resume-mobile'}>
                  <Row className="row-requests-detail">
                    <Col sm={12} className="shadow mb-3 bg-white colunm nav">
                      <Link className="btn-link" to={`/admin/requests/detail/resume/${id_buy_product}`}>Resumo</Link>
                      <Link className="btn-link" to={`/admin/requests/detail/${product?.id_product}`}>Dados do lote</Link>
                    </Col>
                    {product && (
                      <>
                      {ProductLoading && 
                          <Loading />
                      }
                      <Col sm={12} className="shadow mb-3 bg-white resume-request">
                        <Col sm={12}>
                            <div id="detail_status" className="shadow mb-3 bg-white">
                                <Container>
                                    <div className="text-progress">
                                        <p id="text-1">{FormatDateHoursPtBr(product?.updatedAt)}</p>
                                    </div>
                                    <div className="d-block d-lg-none">
                                        <div className="row justify-content-center bar-progress-vertical">
                                            <div className="col-6 text-right">
                                                {product?.nr_status >= 1 ? <p id="text-vertical-status-1">Negócio fechado</p> : <p id="text-vertical-status-1">Aguardando negócio</p>}
                                                {product?.nr_status >= 2 ? <p id="text-vertical-status-2">Pagamento efetuado</p>  : <p id="text-vertical-status-2">Aguardando pagamento</p>}
                                                {product?.nr_status >= 3 ? <p id="text-vertical-status-3">Aceite do lote pelo comprador</p> : <p id="text-vertical-status-3">Aceite do lote pelo comprador</p>}
                                                {product?.nr_status >= 4 ? <p id="text-vertical-status-4">Negociação concluída</p>  : <p id="text-vertical-status-4">Negociação concluída</p>}
                                            </div>
                                            <div className="col-6 row justify-content-left">
                                            <div className="icon-progress ">
                                                        {product?.nr_status >= 1 ?  <img src={checked_status} className="icon-vertical-1 " alt="icon-vertical-1"/> : <div className="cicle-icon-1 cicle-icon-extra-color icon-vertical-1 ">1</div>}
                                                        {product?.nr_status >= 2 ?  <img src={checked_status} className="icon-vertical-2 " alt="icon-vertical-2"/> : product?.nr_status >= 1 ? <div className="cicle-icon-1 cicle-icon-extra-color icon-vertical-2 ">2</div> : <div className="cicle-icon-1 icon-vertical-2 ">2</div>}
                                                        {product?.nr_status >= 3 ?  <img src={checked_status} className="icon-vertical-3 " alt="icon-vertical-3"/> : product?.nr_status >= 2 ? <div className="cicle-icon-1 cicle-icon-extra-color icon-vertical-3 ">3</div> : <div className="cicle-icon-1 icon-vertical-3 ">3</div>}
                                                        {product?.nr_status >= 4 ?  <img src={checked_status} className="icon-vertical-4 " alt="icon-vertical-4"/> : product?.nr_status >= 3 ? <div className="cicle-icon-1 cicle-icon-extra-color icon-vertical-4 ">4</div> : <div className="cicle-icon-1 icon-vertical-4 ">4</div>}
                                                    </div>
                                                <div className="progress progress-bar-vertical">
                                                    {product?.nr_status >= 1 ?  <div className="progress-bar progress-bar-success active bg-warning" role="progressbar" style={{height: '33%'}}></div> : ''}
                                                    {product?.nr_status >= 2 ?  <div className="progress-bar progress-bar-success active bg-warning" role="progressbar" style={{height: '66%'}}></div> : ''}
                                                    {product?.nr_status >= 3 ?  <div className="progress-bar progress-bar-success active bg-warning" role="progressbar" style={{height: '99%'}}></div> : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-none d-lg-block">
                                        <div className="row justify-content-center">
                                            <div className="col-8">
                                                <div className="icon-progress row">
                                                    {product?.nr_status >= 1 ?  <img src={checked_status} className="icon-1 " alt="icon-1"/> : <div className="cicle-icon-1 cicle-icon-extra-color icon-1 ">1</div>}
                                                    {product?.nr_status >= 2 ?  <img src={checked_status} className="icon-2 " alt="icon-1"/> : product?.nr_status >= 1 ? <div className="cicle-icon-1 cicle-icon-extra-color icon-2 ">2</div> : <div className="cicle-icon-1 icon-2 ">2</div>}
                                                    {product?.nr_status >= 3 ?  <img src={checked_status} className="icon-3 " alt="icon-1"/> : product?.nr_status >= 2 ? <div className="cicle-icon-1 cicle-icon-extra-color icon-3 ">3</div> : <div className="cicle-icon-1 icon-3 ">3</div>}
                                                    {product?.nr_status >= 4 ?  <img src={checked_status} className="icon-4 " alt="icon-1"/> : product?.nr_status >= 3 ? <div className="cicle-icon-1 cicle-icon-extra-color icon-4 ">4</div> : <div className="cicle-icon-1 icon-4 ">4</div>}
                                                </div>
                                                <ProgressBar>
                                                    {product?.nr_status >= 1 ?  <ProgressBar variant="warning" now={33} key={1} /> : ''}
                                                    {product?.nr_status >= 2 ?  <ProgressBar variant="warning" now={33} key={2} /> : ''}
                                                    {product?.nr_status >= 3 ?  <ProgressBar variant="warning" now={33} key={3} /> : ''}
                                                </ProgressBar>
                                            </div>
                                        </div>
                                        <div className="text-progress-status">
                                            {product?.nr_status >= 1 ? <p id="text-status-1">Negócio fechado</p> : <p id="text-status-1">Aguardando negócio</p>}
                                            {product?.nr_status >= 2 ? <p id="text-status-2">Pagamento efetuado</p>  : <p id="text-status-2">Aguardando pagamento</p>}
                                            {product?.nr_status >= 3 ? <p id="text-status-3">Aceite do lote pelo comprador</p> : <p id="text-status-3">Aceite do lote pelo comprador</p>}
                                            {product?.nr_status >= 4 ? <p id="text-status-4">Negociação concluída</p>  : <p id="text-status-4">Negociação concluída</p>}
                                        </div>
                                    </div>
                                    <div className="product-info row">
                                        <div className="col-sm-1">
                                            <FontAwesomeIcon icon={faFile} size="3x" />
                                        </div>
                                        <div className="col-12 col-md-3">
                                            <p id="text-status-1"><b>Número do pedido</b></p>
                                            <p id="text-status-2">{product?.id_product}</p>
                                        </div>
                                        <div className="col-12 col-md-3">
                                            <p id="text-status-1"><b>Valor total</b></p>
                                            <p id="text-status-2">{FormatPrice(product?.Product?.nr_type_sales == 2 ? product?.Product?.vl_price_auction_total : product?.Product?.vl_price_total)}</p>
                                        </div>
                                        <div className="col-12 col-md-3">
                                            <p id="text-status-1"><b>Forma de pagamento</b></p>
                                            <p id="text-status-2">{parseInt(product?.nr_type_payment) === 1 ? 'Transferência bancária' : 'Boleto bancário'}</p>
                                        </div>
                                    </div>
                                </Container>
                            </div>
                        </Col>
                        <Col sm={12} id="data-buyer">
                            <h4>Dados do comprador</h4>
                            <Row>
                                <Col sm={1}>
                                    <FontAwesomeIcon icon={faHandHoldingUsd} size="3x" />
                                </Col>
                                <Col sm={11}>
                                    <Row>
                                        <Col sm={12} md={3}>
                                            <b>Nome</b>
                                            <p>{userBuy?.Person?.des_person} {userBuy?.Person?.des_lest_person}</p>
                                        </Col>
                                        <Col sm={12} md={3}>
                                            <b>CPF</b>
                                            <p>{FormatCpf(userBuy?.Person?.nr_cpf)} </p>
                                        </Col>
                                        <Col sm={12} md={3}>
                                            <b>E-mail</b>
                                            <p>{userBuy?.des_email}</p>
                                        </Col>
                                        <Col sm={12} md={3}>
                                            <b>Telefone</b>
                                            <p>{FormatPhone(userBuy?.nr_phone)}</p>
                                        </Col>
                                        <Col sm={12}>
                                            <b>Endereço</b>
                                            <p>{userBuyAddress?.des_address}, {userBuyAddress?.des_number}</p>
                                            <p>{userBuyAddress?.City?.des_city} - {userBuyAddress?.State?.uf}</p>
                                            <p>{FormatCep(userBuyAddress?.nr_zip_code)}</p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={12} id="data-salesman">
                            <h4>Dados do vendedor</h4>
                            <Row>
                                <Col sm={1}>
                                    <FontAwesomeIcon icon={faUserCircle} size="3x" />
                                </Col>
                                <Col sm={11}>
                                    <Row>
                                        <Col sm={12} md={3}>
                                            <b>Nome</b>
                                            <p>{userSeller?.Person?.des_person} {userSeller?.Person?.des_lest_person}</p>
                                        </Col>
                                        <Col sm={12} md={3}>
                                            <b>CPF</b>
                                            <p>{FormatCpf(userSeller?.Person?.nr_cpf)} </p>
                                        </Col>
                                        <Col sm={12} md={3}>
                                            <b>E-mail</b>
                                            <p>{userSeller?.des_email}</p>
                                        </Col>
                                        <Col sm={12} md={3}>
                                            <b>Telefone</b>
                                            <p>{FormatPhone(userSeller?.nr_phone)}</p>
                                        </Col>
                                        <Col sm={12}>
                                            <b>Endereço</b>
                                            <p>{userSellerAddress?.des_address}, {userSellerAddress?.des_number}</p>
                                            <p>{userSellerAddress?.City?.des_city} - {userSellerAddress?.State?.uf}</p>
                                            <p>{FormatCep(userSellerAddress?.nr_zip_code)}</p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={12} className="progress_status text-center">
                            <h3 className="col-12 title-custom-h2">Andamento da venda</h3>
                            <Form onSubmit={handleSubmitStatus}>
                                <Row>
                                    <Form.Group controlId="nr_status" className="col-sm-6">
                                        <Form.Control as="select" value={product.nr_status} name="nr_status" onChange={handleChangeFormStatus}>
                                        <option value="DEFAULT" hidden>Selecione um status</option>
                                        {typeProductStatus.map((type: any) =>
                                            <option key={type.id_type_buy_negotiation_status} value={type.id_type_buy_negotiation_status}>{type.des_type_buy_negotiation_status}</option>
                                        )}
                                        </Form.Control>
                                    </Form.Group>
                                </Row>
                                <Button type="submit" className="btn btn-create col-6">Enviar</Button>
                            </Form>
                        </Col>
                        <Button type="button" onClick={handleGoBack} className="btn btn-primary col-3" style={{right: '0', position: 'absolute', margin: '0 10px' }}>voltar</Button>
                        <br/>
                        <br/>
                    </Col>
                  </>
                  )}
                  </Row>
                </div>
          </Row>

      </Container>
    </>
  );
}
export default RequestsDetailResume;
