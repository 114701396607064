import React, { useEffect, useState } from "react"

import Footer from "../../components/Footer"
import Navbar from "../../components/Navbar"

import "./styles.css"
import { toast } from "react-toastify"
import { Button, Card, Container, Row } from "react-bootstrap"

import { useHistory } from "react-router-dom"
import { useUserContext } from "../../providers/use-sign"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../store"
import { actionAuction } from "../../store/reduces/ReducerAuction"
import Loading from "../../components/Loading"
import Pagination from "rc-pagination"

const ListAuctions: React.FC = () => {
  const { signed, data } = useUserContext()
  const history = useHistory()
  const { reducerAuction } = useSelector((state: RootState) => state)
  const dispatch = useDispatch()

  const [params, setParams] = useState({
    page: 1,
    limit: 10,
    nr_status: 2,
  })

  const checkedLogin = () => {
    if (signed && data.user.nr_active_create_auction === 0) {
      toast.warn(
        "Você não tem permissão para cadastrar leilão, entre contato com nossa equipe.",
        { toastId: 1 }
      )
      return
    }
    toast.warn("Você precisa está logando para cadastrar leilão", {
      toastId: 1,
    })
    history.push("/authentication")
  }

  const handleRedirectToDetail = (id_auction: number) => {
    try {
      history.push(`/auction/detail/${id_auction}`)
    } catch (error) {
      toast.error("Ocorreu algum erro", { toastId: 1 })
    }
  }

  const handlePage = (page: number) => {
    setParams((field) => {
      return {
        ...field,
        page: page,
      }
    })
  }

  useEffect(() => {
    dispatch(actionAuction.searchAuctions(params))
  }, [dispatch, params])

  return (
    <>
      {reducerAuction.httprequest.loading && <Loading />}
      <Navbar />
      <Container>
        {reducerAuction.httprequest.error &&
          toast.error("Ocorreu erro em buscar lista de leilões", {
            toastId: 1,
          })}
        <div id="list-auction">
          <Row>
            <div className="col-6">
              <h2 className="title-custom-h2">Leilões Leiloaê</h2>
              <p className="description-title">
                Aqui você encontra os nossos leilões disponíveis
              </p>
            </div>
            <div className="col-6 text-right">
              {signed && data.user.nr_active_create_auction > 0 ? (
                <a
                  href="/register/auction"
                  className="btn btn-cadastro-auction"
                >
                  Realize seu Leilão conosco
                </a>
              ) : (
                <Button
                  onClick={checkedLogin}
                  className="btn btn-cadastro-auction"
                >
                  Realize seu Leilão conosco
                </Button>
              )}
            </div>
          </Row>
          <Row>
            {reducerAuction.data.map((auction: any) => (
              <div className="col-md-6 col-sm-12" key={auction.id_auction}>
                <Card>
                  <Card.Img
                    variant="top"
                    src={auction?.AuctionPhotos[0]?.des_auction_photo_url}
                  />
                  <Card.Body>
                    <Card.Title>{auction.des_title}</Card.Title>
                    <Card.Text>{auction.des_organizer}</Card.Text>
                    <Button
                      onClick={() => handleRedirectToDetail(auction.id_auction)}
                      className="btn btn-primary"
                    >
                      Visualizar leilão
                    </Button>
                  </Card.Body>
                </Card>
              </div>
            ))}
          </Row>
          <div className="text-center">
            <Pagination
              current={params.page}
              onChange={handlePage}
              pageSize={10}
              total={reducerAuction.headers["x-total-count"]}
              style={{ margin: "20px" }}
            />
          </div>
        </div>
      </Container>
      <Footer />
    </>
  )
}
export default ListAuctions
