import React from "react"

import Footer from "../../components/Footer"
import Navbar from "../../components/Navbar"

import "./styles.css"
import { Container } from "react-bootstrap"

const Partners: React.FC = () => {
  return (
    <>
      <Navbar />
      <Container>
        <div id="partners-page">
          <h2 className="text-center title-custom-h2">Parceiros Leiloaê</h2>
          <p className="description-title">
            Parceiros que acreditam na operação da Leiloaê.
          </p>
        </div>
      </Container>
      <Footer />
    </>
  )
}
export default Partners
