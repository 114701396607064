/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react"
import { Button, Card, Container, Form, Row } from "react-bootstrap"
import { toast } from "react-toastify"

import Footer from "../../components/Footer"
import Navbar from "../../components/Navbar"

import { api } from "../../services/apiLeloae/config"
import axios from "axios"
import { useUserContext } from "../../providers/use-sign"

import "./styles.css"

import { Link, useHistory } from "react-router-dom"
import {
  CalculationAnimalPriceTotal,
  FormatPriceInput,
  FormatPriceEua,
  FormatPrice,
} from "../../helpers/format"
import {
  ValidationFormCreateAnnunce,
  ValidationInputCreateAnnunce,
} from "../../helpers/dataValidation"
import { FormatError } from "../../helpers/formatError"
import Loading from "../../components/Loading"

import padraoFotos from "../../assets/image/modelo-fotos.png"

const Announce: React.FC = (propos: any) => {
  const { data } = useUserContext()
  const myRef = useRef<null | HTMLDivElement>(null)
  const [updateLoading, setUpdateLoading] = useState(false)
  const [listStateData, setListStateData] = useState([])
  const [listCityData, setListCityData] = useState([])
  const [typeListProduct, setTypeListProduct] = useState<any>({
    typeProduct: [],
    typePlacingRaces: [],
    typeRaces: [],
    typeCategories: [],
    typeAverageWeight: [],
    typeCalculation: [],
  })
  const { id_auction, nr_type_sales } = propos.match.params

  const [createAnnounce, setCreateAnnounce] = useState<any>({
    id_person: data.user?.id_person,
    id_type_product_animal: 0,
    id_type_animal_placing_races: 0,
    id_animal_sex: 0,
    id_category: 0,
    id_races: 0,
    id_custom_races: 0,
    custom_races: [],
    id_average_weight: 0,
    des_title: "",
    des_description: "",
    vl_price: "",
    vl_price_total: "",
    qtd_animals: 0,
    nr_weight: 0,
    nr_pregnancy: 0,
    nr_pregnancy_days: 0,
    nr_liters_day: 0,
    nr_age_months: 0,
    id_type_calculation_animal: 0,
    nr_shipper_access: 0,
    nr_truck_access: 0,
    nr_balance_access: 0,
    id_uf: 0,
    code_city: 0,
    nr_kilometers: 0,
    nr_terms: 1,
    nr_status: 1,
  })

  let mounted = true
  const signal = axios.CancelToken.source()
  const history = useHistory()

  const listState = async () => {
    try {
      const responseListState: any = await api.get("/address/list/state", {
        cancelToken: signal.token,
      })

      if (mounted) {
        setListStateData(responseListState?.data?.message)
      }
    } catch (error) {
      toast.error("Ocorreu erro em buscar listar os estados", { toastId: 1 })
    }
  }

  const listCity = async () => {
    try {
      const responseListCity: any = await api.get(
        `/address/list/search/city/${createAnnounce.id_uf}`
      )
      setListCityData(responseListCity?.data?.message)
    } catch (error) {
      toast.error("Ocorreu erro em buscar listar cidades", { toastId: 1 })
    }
  }

  const getAddress = async () => {
    try {
      const responseAddress: any = await api.get(
        `/address/${data.user.id_person}`,
        { cancelToken: signal.token }
      )
      if (mounted) {
        setCreateAnnounce((field) => {
          return {
            ...field,
            id_uf: responseAddress?.data?.message.id_uf,
            code_city: responseAddress?.data?.message.code_city,
          }
        })
      }
    } catch (error) { }
  }

  const getListTypeProduct = async () => {
    try {
      const responseTypeProduct: any = await api.get(
        "/utils/type/animal/product",
        { cancelToken: signal.token }
      )
      const responseTypePlacingRaces: any = await api.get(
        "/utils/type/animal/placing/races",
        { cancelToken: signal.token }
      )
      const responseTypeCategories: any = await api.get(
        `/utils/type/animal/categories/${createAnnounce.id_type_product_animal == 2
          ? 3
          : createAnnounce.id_animal_sex
        }`,
        { cancelToken: signal.token }
      )
      const responseTypeRaces: any = await api.get(
        `/utils/type/animal/races/${createAnnounce.id_type_product_animal == 2
          ? "3"
          : createAnnounce.id_type_animal_placing_races == 3
            ? "1/2"
            : createAnnounce.id_type_animal_placing_races
        }`,
        { cancelToken: signal.token }
      )
      const responseTypeAverageWeight: any = await api.get(
        "/utils/type/animal/average/weight",
        { cancelToken: signal.token }
      )
      const responseTypeCalculation: any = await api.get(
        `/utils/type/animal/calculation/${createAnnounce.id_type_product_animal}`,
        { cancelToken: signal.token }
      )
      if (mounted) {
        setTypeListProduct({
          typeProduct: responseTypeProduct?.data?.message,
          typePlacingRaces: responseTypePlacingRaces?.data?.message,
          typeCategories: responseTypeCategories?.data?.message,
          typeRaces: responseTypeRaces?.data?.message,
          typeAverageWeight: responseTypeAverageWeight?.data?.message,
          typeCalculation: responseTypeCalculation?.data?.message,
        })
      }
    } catch (error) {
      toast.error("Erro em buscar lista de tipo de produto", { toastId: 1 })
    }
  }

  const handleChangeForm = async (event: any) => {
    const name = event.target.name
    const value = event.target.value
    const checked = event.target.checked
    setCreateAnnounce((field) => {
      return {
        ...field,
        [`${name}`]: value,
      }
    })
    if (name === "nr_terms") {
      setCreateAnnounce((field) => {
        return {
          ...field,
          [`${name}`]: checked === true ? 1 : 0,
        }
      })
    }
    if (name === "vl_price") {
      setCreateAnnounce((field) => {
        return {
          ...field,
          [`${name}`]: FormatPriceInput(value),
        }
      })
    }
    if (
      name === "vl_price" ||
      name === "qtd_animals" ||
      name === "nr_weight" ||
      name === "id_type_calculation_animal"
    ) {
      setCreateAnnounce((field) => {
        return {
          ...field,
          vl_price_total: 0,
        }
      })
    }
    if (
      name === "id_type_product_animal" ||
      name === "id_animal_sex" ||
      name === "id_category" ||
      name === "id_races" ||
      name === "id_average_weight" ||
      name === "nr_balance_access" ||
      name === "id_type_animal_placing_races"
    ) {
      await new Promise((resolve) => setTimeout(resolve, 20))
      const element = myRef.current;
      const yOffset = -100;
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" })
    }
  }

  const handleChangeCustomRaces = (event: any) => {
    const name = event.target.name
    const value = event.target.value
    setCreateAnnounce((field) => {
      return {
        ...field,
        id_custom_races: 0,
      }
    })
    for (let index = 0; index < createAnnounce.custom_races.length; index++) {
      if (createAnnounce.custom_races[index]?.id.includes(name)) {
        createAnnounce.custom_races[index].value = value
        if (createAnnounce.custom_races[index].value === "") {
          createAnnounce.custom_races.splice(index, 1)
          return
        }
        return
      }
    }

    setCreateAnnounce((field) => {
      return {
        ...field,
        custom_races: field.custom_races.concat([{ id: name, value: value }]),
      }
    })
  }

  const handleShowInputCustomRaces = (event: any) => {
    const id_custom_races = event.target.dataset.id
    if (id_custom_races) {
      let inputStyle = document.getElementById(
        `custom_races_${id_custom_races}`
      ).style
      let inputClass = event.target.classList

      if (inputStyle.display == "none") {
        inputStyle.display = "block"
        inputClass.remove("btn-announce-default")
        inputClass.add("btn-announce-selected")
      } else {
        inputClass.remove("btn-announce-selected")
        inputClass.add("btn-announce-default")
        inputStyle.display = "none"
      }
    }
  }

  const handleVerificadCustomRacesFinish = (event: any) => {
    let sum = 0
    for (let index = 0; index < createAnnounce.custom_races.length; index++) {
      if (createAnnounce.custom_races[index].value == 0) {
        toast.error("Percentual tem que ser maior que zero")
        return
      }
      sum += parseInt(createAnnounce.custom_races[index].value)
    }
    if (sum > 100) {
      toast.error("Percentual da raça cruzada ultrapassou 100%")
      return
    }
    if (sum < 100) {
      toast.error("Percentual da raça cruzada precisa atingir 100%")
      return
    }
    if (sum <= 0) {
      toast.error("Você precisa informa o percentual das raças")
      return
    }
    setCreateAnnounce((field) => {
      return {
        ...field,
        id_custom_races: 1,
      }
    })
    window.scrollTo(10, document.body.scrollHeight)
  }

  const handleFinishDetail = async () => {
    const errorMgs: any = ValidationInputCreateAnnunce(createAnnounce)
    if (errorMgs) {
      return toast.error(errorMgs)
    }

    const result = CalculationAnimalPriceTotal(
      createAnnounce.id_type_calculation_animal,
      createAnnounce.qtd_animals,
      FormatPriceEua(createAnnounce.vl_price),
      createAnnounce.nr_weight
    )

    setCreateAnnounce((field) => {
      return {
        ...field,
        vl_price_total: result,
      }
    })

    await new Promise((resolve) => setTimeout(resolve, 20))
    myRef.current.scrollIntoView({ behavior: "smooth", block: "start" })
  }

  const handleSubimitFormCreateAnnunce = async (event: any) => {
    event.preventDefault()
    const errorMgs: any = ValidationFormCreateAnnunce(createAnnounce)
    if (errorMgs) {
      return toast.error(errorMgs)
    }
    setUpdateLoading(true)
    try {
      const responseCreateAnnounce: any = await api.post("/product", {
        ...createAnnounce,
        id_auction,
        nr_type_sales,
      })

      if (responseCreateAnnounce.data?.message.Product.id_product) {
        toast.success("Agora falta pouco para seu lote ser publicado!!", {
          toastId: 1,
        })
        if (id_auction) {
          history.push(
            `/files/announce/${responseCreateAnnounce.data?.message.Product.id_product}/${id_auction}/${nr_type_sales}`
          )
        } else {
          history.push(
            `/files/announce/${responseCreateAnnounce.data?.message.Product.id_product}`
          )
        }
      }
      setUpdateLoading(false)
    } catch (error) {
      setUpdateLoading(false)
      if (error.response.data.message) {
        const errorFormat = FormatError(error.response.data.message)
        errorFormat.forEach((element) => {
          toast.error(element)
        })
      } else if (error.response.status === 500) {
        toast.error("Ocorreu um erro, tente novamente mais tarde")
      } else {
        const errorFormat = FormatError(error)
        errorFormat.forEach((element) => {
          toast.error(element)
        })
      }
    }
  }

  useEffect(() => {
    if (
      createAnnounce.qtd_animals > 0 &&
      createAnnounce.id_category > 0 &&
      createAnnounce.nr_weight > 0
    ) {
      setCreateAnnounce((field) => {
        return {
          // Titulo Anuncio
          ...field,
          des_title: `${createAnnounce.qtd_animals} ${typeListProduct.typeCategories.filter(
            (typeCategorie: any) =>
              Number(typeCategorie.id_animal_categories) ===
              Number(createAnnounce.id_category)
          )[0]?.des_category
            }  ${createAnnounce.id_races
              ? typeListProduct.typeRaces.filter(
                (typeRaces: any) =>
                  Number(typeRaces.id_animal_races) ===
                  Number(createAnnounce.id_races)
              )[0]?.des_races
              : "Raças cruzadas"
            } - Peso: ${createAnnounce.nr_weight} Kg`,
        }
      })
    }
  }, [
    createAnnounce.qtd_animals,
    createAnnounce.id_category,
    createAnnounce.nr_weight,
  ])

  useEffect(() => {
    getListTypeProduct()
    getAddress()
    listState()
    const cleanup = () => {
      mounted = false
    }
    return cleanup
  }, [
    mounted,
    createAnnounce.id_type_animal_placing_races,
    createAnnounce.id_animal_sex,
    createAnnounce.id_type_product_animal == 2,
  ])

  useEffect(() => {
    if (createAnnounce.id_uf) {
      listCity()
    }
  }, [createAnnounce])

  return (
    <>
      {updateLoading && <Loading />}
      <Navbar />
      <div className="lazyload imgGuia">
        <img src={padraoFotos} alt="Imagem guiando a tirar fotos de 5 metros de distância dos animais" width="100%" />
      </div>

      <Container>
        <div id="annuonce" className="text-center">
          <Row>
            <h2 className="title-custom-h2 col-12">Anunciar</h2>
            <div className="col-12">
              <Card.Body className="shadow p-3 mb-5 bg-white text-center">
                <Card.Title>
                  <b>Qual tipo de produto deseja anunciar?</b>
                </Card.Title>
                {typeListProduct.typeProduct.map((type: any) => (
                  <Button
                    name="id_type_product_animal"
                    value={type.id_type_product_animal}
                    onClick={handleChangeForm}
                    className={
                      createAnnounce.id_type_product_animal ==
                        type.id_type_product_animal
                        ? "btn-announce-selected"
                        : "btn-announce-default"
                    }
                    key={type.id_type_product_animal}
                  >
                    {type.des_type_product_animal}
                  </Button>
                ))}
              </Card.Body>

              {createAnnounce.id_type_product_animal >= 1 && (
                <div ref={myRef}>
                  <Card.Body className="shadow p-3 mb-5 bg-white text-center id_animal_sex">
                    <Card.Title>
                      <b>Qual o sexo do animal?</b>
                    </Card.Title>
                    <Button
                      name="id_animal_sex"
                      value={1}
                      onClick={handleChangeForm}
                      className={
                        createAnnounce.id_animal_sex == 1
                          ? "btn-announce-selected"
                          : "btn-announce-default"
                      }
                    >
                      Macho
                    </Button>
                    <Button
                      name="id_animal_sex"
                      value={2}
                      onClick={handleChangeForm}
                      className={
                        createAnnounce.id_animal_sex == 2
                          ? "btn-announce-selected"
                          : "btn-announce-default"
                      }
                    >
                      Fêmea
                    </Button>
                  </Card.Body>
                </div>
              )}
              {createAnnounce.id_animal_sex >= 1 && (
                <div ref={myRef}>
                  <Card.Body className="shadow p-3 mb-5 bg-white text-center">
                    <Card.Title>
                      <b>Qual é a categoria do animal?</b>
                    </Card.Title>
                    {typeListProduct.typeCategories.map((type: any) => (
                      <Button
                        name="id_category"
                        value={type.id_animal_categories}
                        onClick={handleChangeForm}
                        className={
                          createAnnounce.id_category ==
                            type.id_animal_categories
                            ? "btn-announce-selected"
                            : "btn-announce-default"
                        }
                        key={type.id_animal_categories}
                      >
                        {type.des_category}
                      </Button>
                    ))}
                  </Card.Body>
                </div>
              )}
              {createAnnounce.id_type_product_animal == 1 ? (
                <>
                  {createAnnounce.id_category >= 1 && (
                    <div ref={myRef}>
                      <Card.Body className="shadow p-3 mb-5 bg-white text-center">
                        <Card.Title>
                          <b>Qual é a raça do animal?</b>
                        </Card.Title>
                        {typeListProduct.typePlacingRaces.map((type: any) => (
                          <Button
                            name="id_type_animal_placing_races"
                            value={type.id_type_animal_placing_races}
                            onClick={handleChangeForm}
                            className={
                              createAnnounce.id_type_animal_placing_races ==
                                type.id_type_animal_placing_races
                                ? "btn-announce-selected"
                                : "btn-announce-default"
                            }
                            key={type.id_type_animal_placing_races}
                          >
                            {type.des_type_animal_placing_races}
                          </Button>
                        ))}
                      </Card.Body>
                    </div>
                  )}
                  {createAnnounce.id_type_animal_placing_races >= 1 && (
                    <div ref={myRef}>
                      <Card.Body className="shadow p-3 mb-5 bg-white text-center">
                        <Card.Title>
                          <b>Qual é a raça do animal?</b>
                        </Card.Title>
                        <p>
                          Para animais cruzados, informar o percentual de raças
                          utilizadas
                        </p>
                        {createAnnounce.id_type_animal_placing_races == 3 ? (
                          <>
                            <div
                              className="row justify-content-center "
                              onChange={handleChangeCustomRaces}
                            >
                              {typeListProduct.typeRaces.map((type: any) => (
                                <div
                                  id="custom-races-form"
                                  key={type.id_animal_races}
                                >
                                  <Button
                                    data-id={type.id_animal_races}
                                    value={type.id_animal_races}
                                    onClick={handleShowInputCustomRaces}
                                    className="btn-announce-default"
                                  >
                                    {type.des_races}
                                  </Button>
                                  <Form.Group
                                    style={{ display: "none" }}
                                    id={`custom_races_${type.id_animal_races}`}
                                  >
                                    <Form.Control
                                      type="number"
                                      name={type.id_animal_races}
                                      placeholder="Informe percentual"
                                      id={`input_custom_races_${type.id_animal_races}`}
                                    />
                                  </Form.Group>
                                </div>
                              ))}
                            </div>
                            <p>Após concluír clique no botão finalizar</p>
                            {createAnnounce.id_custom_races == 0 && (
                              <Button
                                onClick={handleVerificadCustomRacesFinish}
                                className="btn btn-create"
                              >
                                Finalizar
                              </Button>
                            )}
                          </>
                        ) : (
                          typeListProduct.typeRaces.map((type: any) => (
                            <Button
                              name="id_races"
                              value={type.id_animal_races}
                              onClick={handleChangeForm}
                              className={
                                createAnnounce.id_races == type.id_animal_races
                                  ? "btn-announce-selected"
                                  : "btn-announce-default"
                              }
                              key={type.id_animal_races}
                            >
                              {type.des_races}
                            </Button>
                          ))
                        )}
                      </Card.Body>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {createAnnounce.id_category >= 1 && (
                    <div ref={myRef}>
                      <Card.Body className="shadow p-3 mb-5 bg-white text-center">
                        <Card.Title>
                          <b>Qual é a raça do animal?</b>
                        </Card.Title>
                        {typeListProduct.typeRaces.map((type: any) => (
                          <Button
                            name="id_races"
                            value={type.id_animal_races}
                            onClick={handleChangeForm}
                            className={
                              createAnnounce.id_races == type.id_animal_races
                                ? "btn-announce-selected"
                                : "btn-announce-default"
                            }
                            key={type.id_animal_races}
                          >
                            {type.des_races}
                          </Button>
                        ))}
                      </Card.Body>
                    </div>
                  )}
                </>
              )}
              {createAnnounce.id_custom_races >= 1 && (
                <div ref={myRef}>
                  <Card.Body className="shadow p-3 mb-5 bg-white text-center">
                    <Card.Title>
                      <b>Qual intervalo de peso?</b>
                    </Card.Title>
                    {typeListProduct.typeAverageWeight.map((type: any) => (
                      <Button
                        name="id_average_weight"
                        value={type.id_animal_average_weight}
                        onClick={handleChangeForm}
                        className={
                          createAnnounce.id_average_weight ==
                            type.id_animal_average_weight
                            ? "btn-announce-selected"
                            : "btn-announce-default"
                        }
                        key={type.id_animal_average_weight}
                      >
                        {type.des_average_weight}
                      </Button>
                    ))}
                  </Card.Body>
                </div>
              )}
              {createAnnounce.id_races >= 1 && (
                <div ref={myRef}>
                  <Card.Body className="shadow p-3 mb-5 bg-white text-center">
                    <Card.Title>
                      <b>Em qual intervalo de peso?</b>
                    </Card.Title>
                    {typeListProduct.typeAverageWeight.map((type: any) => (
                      <Button
                        name="id_average_weight"
                        value={type.id_animal_average_weight}
                        onClick={handleChangeForm}
                        className={
                          createAnnounce.id_average_weight ==
                            type.id_animal_average_weight
                            ? "btn-announce-selected"
                            : "btn-announce-default"
                        }
                        key={type.id_animal_average_weight}
                      >
                        {type.des_average_weight}
                      </Button>
                    ))}
                  </Card.Body>
                </div>
              )}
              {createAnnounce.id_average_weight >= 1 && (
                <div ref={myRef}>
                  <Card.Body className="shadow p-3 mb-5 bg-white text-center detail-lots">
                    <Card.Title>
                      <b>Preencha agora os detalhes do seu lote</b>
                    </Card.Title>
                    <div className="form-detail-lots">
                      <Row>
                        <Form.Group controlId="des_title" className="col-8">
                          <Form.Label className="labelControl text-center">
                            Título do anúncio
                          </Form.Label>
                          {createAnnounce.qtd_animals &&
                            createAnnounce.id_animal_sex &&
                            createAnnounce.nr_weight ? (
                            <Form.Control
                              type="text"
                              name="des_title"
                              value={createAnnounce.des_title}
                              onChange={handleChangeForm}
                            />
                          ) : (
                            <Form.Control
                              type="text"
                              name="des_title"
                              disabled={createAnnounce.des_title ? false : true}
                              value={
                                createAnnounce.des_title
                                  ? createAnnounce.des_title
                                  : "Será preenchido automaticamente..."
                              }
                              onChange={handleChangeForm}
                            />
                          )}
                        </Form.Group>
                        <Form.Group controlId="qtd_animals" className="col-4">
                          <Form.Label className="labelControl text-center">
                            Qtd. Animais
                          </Form.Label>
                          <Form.Control
                            type="number"
                            name="qtd_animals"
                            value={
                              createAnnounce.qtd_animals
                                ? createAnnounce.qtd_animals
                                : ""
                            }
                            onChange={handleChangeForm}
                          />
                        </Form.Group>
                      </Row>
                      <Form.Group controlId="des_description">
                        <Form.Label className="labelControl text-center">
                          Descrição
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="des_description"
                          value={createAnnounce.des_description}
                          onChange={handleChangeForm}
                        />
                      </Form.Group>

                      <Row>
                        <Form.Group
                          controlId="id_type_calculation_animal"
                          className="col-sm-12 col-md-6"
                        >
                          <Form.Label className="labelControl text-center">
                            Tipo de cálculo total
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name="id_type_calculation_animal"
                            onChange={handleChangeForm}
                          >
                            <option value="DEFAULT" hidden>
                              Selecione tipo de calculo
                            </option>
                            {typeListProduct.typeCalculation.map(
                              (type: any) => (
                                <option
                                  key={type.id_type_calculation_animal}
                                  selected={
                                    parseInt(
                                      createAnnounce.id_type_calculation_animal
                                    ) === type.id_type_calculation_animal
                                      ? true
                                      : null
                                  }
                                  value={type.id_type_calculation_animal}
                                >
                                  {type.des_type_calculation_animal}
                                </option>
                              )
                            )}
                          </Form.Control>
                        </Form.Group>
                        <Form.Group
                          controlId="vl_price"
                          className="col-sm-6 col-md-3"
                        >
                          <Form.Label className="labelControl text-center">
                            Valor (R$)
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="vl_price"
                            value={
                              createAnnounce.vl_price
                                ? FormatPriceInput(createAnnounce.vl_price)
                                : ""
                            }
                            onChange={handleChangeForm}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="nr_weight"
                          className="col-sm-6 col-md-3"
                        >
                          <Form.Label className="labelControl text-center">
                            Peso
                          </Form.Label>
                          <Form.Control
                            type="number"
                            name="nr_weight"
                            value={
                              createAnnounce.nr_weight
                                ? createAnnounce.nr_weight
                                : ""
                            }
                            onChange={handleChangeForm}
                          />
                        </Form.Group>

                        {createAnnounce.id_animal_sex == 2 && (
                          <div className="input-radio-pregnancy col-sm-12 col-md-4">
                            <Form.Label className="label-nr-pregnancy">
                              Prenhez(opcional)
                            </Form.Label>
                            <Form.Group controlId="nr_pregnancy_yes">
                              <Form.Check
                                type="radio"
                                name="nr_pregnancy"
                                value="1"
                                label="Sim"
                                onChange={handleChangeForm}
                              />
                            </Form.Group>
                            <Form.Group controlId="nr_pregnancy_no">
                              <Form.Check
                                type="radio"
                                name="nr_pregnancy"
                                value="2"
                                label="Não"
                                onChange={handleChangeForm}
                              />
                            </Form.Group>
                          </div>
                        )}
                        {createAnnounce.id_type_product_animal == 2 &&
                          createAnnounce.id_animal_sex == 2 && (
                            <>
                              <Form.Group
                                controlId="nr_pregnancy_days"
                                className="col-sm-6 col-md-4 mt-2"
                              >
                                <Form.Label className="labelControl text-center">
                                  Dias de Gestação (Opcional)
                                </Form.Label>
                                <Form.Control
                                  type="number"
                                  name="nr_pregnancy_days"
                                  value={
                                    createAnnounce.nr_pregnancy_days
                                      ? createAnnounce.nr_pregnancy_days
                                      : ""
                                  }
                                  onChange={handleChangeForm}
                                />
                              </Form.Group>
                              <Form.Group
                                controlId="nr_liters_day"
                                className="col-sm-6 col-md-4 mt-2"
                              >
                                <Form.Label className="labelControl text-center">
                                  Litros produzidos/Dia (Opcional)
                                </Form.Label>
                                <Form.Control
                                  type="number"
                                  name="nr_liters_day"
                                  value={
                                    createAnnounce.nr_liters_day
                                      ? createAnnounce.nr_liters_day
                                      : ""
                                  }
                                  onChange={handleChangeForm}
                                />
                              </Form.Group>
                              <Form.Group
                                controlId="nr_lactations"
                                className="col-sm-6 col-md-6"
                              >
                                <Form.Label className="labelControl text-center">
                                  Nº Lactações (Opcional)
                                </Form.Label>
                                <Form.Control
                                  type="number"
                                  name="nr_lactations"
                                  value={
                                    createAnnounce.nr_lactations
                                      ? createAnnounce.nr_lactations
                                      : ""
                                  }
                                  onChange={handleChangeForm}
                                />
                              </Form.Group>
                            </>
                          )}
                        {createAnnounce.id_type_product_animal == 2 && (
                          <>
                            <Form.Group
                              controlId="nr_age_months"
                              className="col-sm-6 col-md-6"
                            >
                              <Form.Label className="labelControl text-center">
                                Idade em meses (Opcional)
                              </Form.Label>
                              <Form.Control
                                type="number"
                                name="nr_age_months"
                                value={
                                  createAnnounce.nr_age_months
                                    ? createAnnounce.nr_age_months
                                    : ""
                                }
                                onChange={handleChangeForm}
                              />
                            </Form.Group>
                          </>
                        )}
                      </Row>
                    </div>
                    <Row className="rowFinishDetail">
                      {createAnnounce.vl_price_total == 0 && (
                        <Button
                          onClick={handleFinishDetail}
                          className="btn btn-create"
                        >
                          Avançar
                        </Button>
                      )}
                    </Row>
                    <Row className="rowFinishDetailPriceTotal">
                      <Form.Group
                        controlId="vl_price_total"
                        className="col-8 vlPriceTotalInput"
                      >
                        <Form.Label className="labelControl text-center">
                          Valor total do lote (R$)
                        </Form.Label>
                        <Form.Control
                          className="text-center"
                          readOnly
                          type="text"
                          name="vl_price_total"
                          placeholder="..."
                          value={
                            createAnnounce.vl_price_total
                              ? FormatPrice(createAnnounce.vl_price_total)
                              : ""
                          }
                          onChange={handleChangeForm}
                        />
                      </Form.Group>
                    </Row>
                  </Card.Body>
                </div>
              )}
              {createAnnounce.vl_price_total >= 1 && (
                <div ref={myRef}>
                  <Card.Body className="shadow p-3 mb-5 bg-white text-center address-lots">
                    <Card.Title>
                      <b>Localização do lote</b>
                    </Card.Title>
                    <div className="form-address-lots">
                      <Row>
                        <Form.Group
                          controlId="id_uf"
                          className="col-sm-12 col-md-4"
                        >
                          <Form.Label className="labelControl text-center">
                            Estado
                          </Form.Label>
                          <Form.Control
                            as="select"
                            value={createAnnounce.id_uf}
                            name="id_uf"
                            onChange={handleChangeForm}
                          >
                            <option value="DEFAULT" hidden>
                              Selecione seu Estado
                            </option>
                            {listStateData.map((state: any) => (
                              <option key={state.id_uf} value={state.id_uf}>
                                {state.des_uf}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                        <Form.Group
                          controlId="code_city"
                          className="col-sm-12 col-md-4"
                        >
                          <Form.Label className="labelControl text-center">
                            Cidade
                          </Form.Label>
                          <Form.Control
                            as="select"
                            value={createAnnounce.code_city}
                            name="code_city"
                            onChange={handleChangeForm}
                          >
                            <option value="DEFAULT" hidden>
                              Selecione sua Cidade
                            </option>
                            {listCityData.map((city: any) => (
                              <option
                                key={city.code_city}
                                value={city.code_city}
                              >
                                {city.des_city}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                        <Form.Group
                          controlId="nr_kilometers"
                          className="col-sm-12 col-md-4"
                        >
                          <Form.Label className="labelControl text-center">
                            Quantos KM da cidade
                          </Form.Label>
                          <Form.Control
                            type="number"
                            name="nr_kilometers"
                            onChange={handleChangeForm}
                          />
                        </Form.Group>
                        <div className="input-radio-shipper-access col-sm-12 col-md-4">
                          <Form.Label className="label-nr-shipper-access">
                            Possui embarcador?
                          </Form.Label>
                          <Row className="justify-content-center">
                            <Form.Group controlId="nr_shipper_access_yes">
                              <Form.Check
                                type="radio"
                                name="nr_shipper_access"
                                value="1"
                                label="Sim"
                                onClick={handleChangeForm}
                              />
                            </Form.Group>
                            <Form.Group controlId="nr_shipper_access_no">
                              <Form.Check
                                type="radio"
                                name="nr_shipper_access"
                                value="2"
                                label="Não"
                                onClick={handleChangeForm}
                              />
                            </Form.Group>
                          </Row>
                        </div>
                        <div className="input-radio-truck-access col-sm-12 col-md-4">
                          <Form.Label className="label-nr-truck-access">
                            Possui acesso a caminhões?
                          </Form.Label>
                          <Row className="justify-content-center">
                            <Form.Group controlId="nr_truck_access_yes">
                              <Form.Check
                                type="radio"
                                name="nr_truck_access"
                                value="1"
                                label="Sim"
                                onClick={handleChangeForm}
                              />
                            </Form.Group>
                            <Form.Group controlId="nr_truck_access_no">
                              <Form.Check
                                type="radio"
                                name="nr_truck_access"
                                value="2"
                                label="Não"
                                onClick={handleChangeForm}
                              />
                            </Form.Group>
                          </Row>
                        </div>
                        <div className="input-radio-balance-access col-sm-12 col-md-4">
                          <Form.Label className="label-nr-balance-access">
                            Possui balança
                          </Form.Label>
                          <Row className="justify-content-center">
                            <Form.Group controlId="nr_balance_access_yes">
                              <Form.Check
                                type="radio"
                                name="nr_balance_access"
                                value="1"
                                label="Sim"
                                onClick={handleChangeForm}
                              />
                            </Form.Group>
                            <Form.Group controlId="nr_balance_access_no">
                              <Form.Check
                                type="radio"
                                name="nr_balance_access"
                                value="2"
                                label="Não"
                                onClick={handleChangeForm}
                              />
                            </Form.Group>
                          </Row>
                        </div>
                      </Row>
                    </div>
                  </Card.Body>
                </div>
              )}
              {createAnnounce.nr_balance_access >= 1 && (
                <div ref={myRef}>
                  <Form onSubmit={handleSubimitFormCreateAnnunce}>
                    <Card.Body className="shadow p-3 mb-5 bg-white text-center check-public">
                      <Card.Title>
                        <b>Publicar</b>
                      </Card.Title>
                      <Form.Group
                        controlId="nr_terms"
                        className="row justify-content-center"
                        style={{ marginLeft: "0", marginRight: "0" }}
                      >
                        <Form.Check
                          type="checkbox"
                          name="nr_terms"
                          defaultChecked={Boolean(createAnnounce.nr_terms)}
                          onClick={handleChangeForm}
                        />
                        <Form.Label>
                          Ao publicar um anúncio você concorda e aceita os{" "}
                          <Link to="/terms" target="_blank">
                            Termos e Condições do Leiloaẽ
                          </Link>
                        </Form.Label>
                      </Form.Group>
                      <Button className="btn btn-create" type="submit">
                        Avançar
                      </Button>
                    </Card.Body>
                  </Form>
                </div>
              )}
            </div>
          </Row>
        </div>
      </Container>

      <Footer />
    </>
  )
}
export default Announce
