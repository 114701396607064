/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, createContext, useState, useCallback, useEffect } from "react";
import { api } from "../../services";

interface iResult {
  data: {
    user: any;
    address: IAddress;
  }
  message: IUser;
}

export interface IUser {
  des_person?: string;
  des_lest_person?: string;
  nr_phone?: string;
  dt_birth?: string;
  nr_cpf?: string;
  des_email?: string;
  des_password?: string;
  nr_receive_ads_email?: number;
  nr_policy_terms?: number;
};

export interface IAddress {
  id_person?: number
  nr_zip_code?: string,
  id_uf?: number;
  code_city?: number;
  des_uf?: string;
  des_city?: string;
  des_address?: string;
  des_number?: string;
  des_complement?: string;
  nr_cpf?: string,
  nr_receive_ads_whatsapp?: number;
}

type ILoadMsg = {
  createUserLoading?: boolean;
  msgError?: string;
  msgSuccess?: string;
}

type ICreateUSerContext = {
  dataUserContext: IUser;
  dataAddressContext: IAddress;
  loginData: any
  progress: Number;
  loadMsg: ILoadMsg;
  setUserStorage(UserStorage: IUser): Promise<void>;
  setUserAddressStorage(UserAddressStorage: IAddress): Promise<void>;
  createUser(nr_policy_terms: any): Promise<void>;
};
const CreateUserContext = createContext<Partial<ICreateUSerContext>>({});

export const CreateUserProvider: React.FC = ({ children }) => {
  const [dataUserContext, setDataUserContext] = useState<IUser>();

  const [dataAddressContext, setDataAddressContext] = useState<IAddress>();

  const [dataCreateUser, setDataCreateUser] = useState<IUser | IAddress>();

  const [progress, SetProgress] = useState(1)

  const [loadMsg, setLoadMsg] = useState<ILoadMsg>({
    msgError: '',
    msgSuccess: '',
    createUserLoading: false,
  });

  const [loginData, setLoginData] = useState<any>({})

  const dataUserMemory = useCallback(() => {
      return new Promise(async (resolve) => {
        const dataUserStorage: any = JSON.parse(localStorage.getItem('@leloae/user/create'))
        const dataUserAddressStorage = JSON.parse(localStorage.getItem('@leloae/user/address/create'))
        if(dataUserStorage){
          setDataUserContext(dataUserStorage)
          setDataAddressContext(dataUserAddressStorage)
          SetProgress(1)
        }
        resolve(true)
      })
  }, [])

  useEffect(() => {
    ( async () => {
        await dataUserMemory()
    })()
  }, [])

  const setUserStorage = async (UserStorage: IUser) => {
    try {
      setLoadMsg({ createUserLoading: true });

        localStorage.setItem('@leloae/user/create', JSON.stringify(UserStorage))

        setLoadMsg({createUserLoading: false});
        SetProgress(2)

    } catch (error) {
      setLoadMsg({
            createUserLoading: false,
            msgError: "Ocorreu algum erro, tente novamente"
        });
    }
  }

  const setUserAddressStorage = async (UserAddressStorage: IAddress) => {
    try {
      setLoadMsg({ createUserLoading: true });

        localStorage.setItem('@leloae/user/address/create', JSON.stringify(UserAddressStorage))

        const dataUserStorage = JSON.parse(localStorage.getItem('@leloae/user/create'))
        const dataUserAddressStorage = JSON.parse(localStorage.getItem('@leloae/user/address/create'))
        if(dataUserStorage){
          setLoadMsg({createUserLoading: false});
          setDataUserContext(dataUserStorage)
          setDataAddressContext(dataUserAddressStorage)
          SetProgress(3)

        }
    } catch (error) {
        setLoadMsg({
            createUserLoading: false,
            msgError: "Ocorreu algum erro, tente novamente"
        });
    }
  }

  useEffect(() => {

    if(progress === 3 && dataAddressContext.nr_cpf) {
      setDataCreateUser({
          des_person: dataUserContext?.des_person,
          des_lest_person: dataUserContext?.des_lest_person,
          dt_birth: dataUserContext?.dt_birth,
          des_email: dataUserContext?.des_email,
          nr_cpf: dataAddressContext?.nr_cpf,
          nr_phone: dataUserContext?.nr_phone,
          des_password: dataUserContext?.des_password,
          nr_receive_ads_email: dataUserContext?.nr_receive_ads_email,
          nr_policy_terms: 1,
          nr_zip_code: dataAddressContext.nr_zip_code,
          id_uf: dataAddressContext.id_uf,
          code_city: dataAddressContext.code_city,
          des_address: dataAddressContext.des_address,
          des_number: dataAddressContext.des_number,
          des_complement: dataAddressContext.des_complement,
          nr_receive_ads_whatsapp: dataAddressContext.nr_receive_ads_whatsapp
      })
    }

  }, [dataAddressContext?.nr_cpf, progress])

  const createUser = async (nr_policy_terms: any) => {

    try {
      setLoadMsg({ createUserLoading: true });

      setLoginData({
        des_username: dataUserContext.des_email,
        des_password: dataUserContext.des_password
      })

      const userData = await api.apiLeloae.createuser.createUser<iResult>(dataCreateUser)

      if(userData.data.user){
        localStorage.setItem('@leloae/user/create', JSON.stringify(null))
        localStorage.setItem('@leloae/user/address/create', JSON.stringify(null))
        setLoadMsg({
          createUserLoading: false,
          msgSuccess: 'Conta cadastrada com sucesso',
          msgError: ''
        });
      }else{
        setLoadMsg({
          createUserLoading: false,
          msgError: 'Ocorreu algum erro, tente novamente'
        });
      }

    } catch (error) {
      SetProgress(1)
      setLoadMsg({
          createUserLoading: false,
          msgError: error.response.data.message,
        });
    }
  };

  return (
    <CreateUserContext.Provider
      value={{
        dataUserContext,
        dataAddressContext,
        loginData,
        progress,
        loadMsg,
        setUserStorage,
        setUserAddressStorage,
        createUser
      }}
    >
      {children}
    </CreateUserContext.Provider>
  );
};

export const useCreateUserContext = () => {
  return useContext(CreateUserContext);
};