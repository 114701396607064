import * as React from 'react'
import { Route } from 'react-router-dom'
import AppRouter from './app.router'
import Admin from '../pages/Admin';
import Requests from '../pages/Admin/Requests';
import RequestDetail from '../pages/Admin/RequestDetail';
import RequestDetailResume from '../pages/Admin/RequestDetailResume';
import RequestsNegotiation from '../pages/Admin/RequestsNegotiation';
import RequestNegotiationDetailResume from '../pages/Admin/RequestNegotiationDetailResume';

import Auction from '../pages/Admin/Auction';
import AuctionDetail from '../pages/Admin/AuctionDetail';
import AuctionProductDetail from '../pages/Admin/AuctionProductDetail';
import AuctionProductBid from '../pages/Admin/AuctionProductBid';
import AuctionRequest from '../pages/Admin/AuctionRequest';
import AuctionCreateSearchUser from '../pages/Admin/AuctionCreateSearchUser';
import AuctionCreate from '../pages/Admin/AuctionCreate';
import AuctionFiles from '../pages/Admin/AuctionFiles';
import AuctionRegisterUser from '../pages/Admin/AuctionRegisterUser';

import Lots from '../pages/Admin/Lots';
import LotsCreateSearchUser from '../pages/Admin/LotsCreateSearchUser';

import Announce from '../pages/Admin/Announce';
import AnnounceFiles from '../pages/Admin/AnnounceFiles';

import LotsDetail from '../pages/Admin/LotsDetail';
import LotsUpdate from '../pages/Admin/LotsUpdate';
import LotsFilesUpdate from '../pages/Admin/LotsFilesUpdate';

import Users from '../pages/Admin/Users';
import UserUpdate from '../pages/Admin/UserUpdate';
import UserPasswordUpdate from '../pages/Admin/UserPasswordUpdate';
import Messages from '../pages/Admin/Messages';

const ClienteRouter = () => {
    return (
        <>
          <Route exact path="/admin" component={Admin} />
          <Route exact path="/admin/requests" component={Requests} />
          <Route exact path="/admin/requests/negotiation" component={RequestsNegotiation} />
          <Route exact path="/admin/auction/lots" component={Auction} />
          <Route exact path="/admin/lots" component={Lots} />
          <Route exact path="/admin/users" component={Users} />
          <Route exact path="/admin/messages" component={Messages} />

          <Route exact path="/admin/requests/detail/:id_product" component={RequestDetail} />
          <Route exact path="/admin/requests/detail/resume/:id_buy_product" component={RequestDetailResume} />

          <Route exact path="/admin/requests/negotiation/detail/resume/:id_product_negotiation_request" component={RequestNegotiationDetailResume} />

          <Route exact path="/admin/auction/detail/:id_auction" component={AuctionDetail} />
          <Route exact path="/admin/auction/product/detail/:id_product/:id_auction" component={AuctionProductDetail} />
          <Route exact path="/admin/acution/product/bid/:id_product/:id_auction" component={AuctionProductBid} />
          <Route exact path="/admin/bid/detail/:id_product/:id_bid_lots_auction" component={AuctionRequest} />
          <Route exact path="/admin/auction/lots/create/search/user" component={AuctionCreateSearchUser} />
          <Route exact path="/admin/create/auction/lots/:id_person" component={AuctionCreate} />
          <Route exact path="/admin/create/auction/files/:id_auction/:id_person" component={AuctionFiles} />
          <Route exact path="/admin/auction/active/user/:id_auction/:id_person" component={AuctionRegisterUser} />

          <Route exact path="/admin/lots/create/search/user" component={LotsCreateSearchUser} />
          <Route exact path="/admin/create/announce/lots/:id_person/:id_auction?/:nr_type_sales?" component={Announce} />
          <Route exact path="/admin/files/announce/:id_product/:id_auction?/:nr_type_sales?" component={AnnounceFiles} />
          <Route exact path="/admin/lots/detail/:id_product" component={LotsDetail} />
          <Route exact path="/admin/lots/update/:id_product" component={LotsUpdate} />
          <Route exact path="/admin/lots/files/update/:id_product" component={LotsFilesUpdate} />

          <Route exact path="/admin/user/update/:id_person" component={UserUpdate} />
          <Route exact path="/admin/user/password/update/:id_person" component={UserPasswordUpdate} />


          <AppRouter />
        </>
    );
}

export default ClienteRouter
