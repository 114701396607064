/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';

import { Form, Accordion, Card, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { useParamsProductContext } from '../../../providers/product-params';
import { api } from '../../../services/apiLeloae/config';

const Category: React.FC = () => {
    const { setParamsProduct } = useParamsProductContext()

    
    const [attrRacesBox, setAttrRacesBox] = useState({
        category: '+',
    })

    const [listTypeCategorytData, setListTypeCategorytData] = useState<any>([])


    const listTypeCategory = async () => {
        try {
            const responseTypeRaces: any = await api.get(`/utils/type/animal/categories/3`)
          
            setListTypeCategorytData(responseTypeRaces?.data?.message)
        } catch (error) {
            toast.error('Ocorreu erro em buscar listar categorias', {toastId: 1})    
        }
    }
    
    const menuLeftBox = (event: any) => {
        const nameBox = event.target.dataset.id
        setAttrRacesBox(field => {
            return {
              ...field, [`${nameBox}`]: 
              nameBox === 'category' ? attrRacesBox.category === '+' ? '-' : '+' : ''
            }
          })
    }

    const handleChangeListFilter = async (event: any) => {
        const name = event.target.name
        const value = event.target.value

        await setParamsProduct({type: name, value: value})
    }

    useEffect(() => {
        listTypeCategory()
    }, [])

  return (
    <>
        <Accordion defaultActiveKey="0">
            <Card className="shadow mb-3 bg-white">
                <Card.Header>
                    <Accordion.Toggle as={Button} data-id="category" onClick={menuLeftBox} className="text-left" variant="link" eventKey="1">
                        <b data-id="category">Categoria <b data-id="category">{attrRacesBox.category}</b></b>
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                    <Card.Body>
                        <Form.Check type="radio" id="category_0" onClick={handleChangeListFilter} value="" defaultChecked name="id_category" label="Todas" />
                        {listTypeCategorytData.map((category: any) =>
                            <Form.Check id={`category_${category.id_animal_races}`} key={category.id_animal_categories} type="radio" onClick={handleChangeListFilter} value={category.id_animal_categories} name="id_category" label={category.des_category} />
                        )}

                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    </>
  );
}
export default Category;
