import { combineReducers } from '@reduxjs/toolkit'
import { reducerProduct } from './ReducerProduct'
import { reducerProductSearch } from './ReducerProductSearch'
import { reducerProductViews } from './ReducerProductViews'
import { reducerProductUserSales } from './ReducerProductUserSales'
import { reducerProductUserRequest } from './ReducerProductUserRequest'
import { reducerProductUserRequestNegotiation } from './ReducerProductUserRequestNegotiation'
import { reducerAuction } from './ReducerAuction'
import { reducerAuctionProduct } from './ReducerAuctionProduct'

export const reducers = combineReducers({
    reducerProduct,
    reducerProductSearch,
    reducerProductUserSales,
    reducerProductUserRequest,
    reducerProductUserRequestNegotiation,
    reducerProductViews,
    reducerAuction,
    reducerAuctionProduct
})