/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react"
import {
  Button,
  Container,
  Figure,
  Row,
  Jumbotron,
  Card,
} from "react-bootstrap"
import { toast } from "react-toastify"
import { Redirect, useHistory } from "react-router-dom"

import Footer from "../../components/Footer"
import Navbar from "../../components/Navbar"

import "./styles.css"

import {
  formatDataToCountDown,
  FormatDatePtBr,
  FormatHours,
  FormatPrice,
} from "../../helpers/format"
import { useDispatch, useSelector } from "react-redux"
import { actionAuctionProduct } from "../../store/reduces/ReducerAuctionProduct"

import CountDownTime from "../../components/CountDownTime"
import { useUserContext } from "../../providers/use-sign"
import { RootState } from "../../store"
import Pagination from "rc-pagination"
import Loading from "../../components/Loading"
import { FormatError } from "../../helpers/formatError"
import { api } from "../../services"

const AuctionDetail: React.FC = (propos: any) => {
  const id_auction = propos.match.params.id_auction

  const history = useHistory()
  const dispatch = useDispatch()
  const { reducerAuctionProduct } = useSelector((state: RootState) => state)

  const [auctionLoading, setAuctionLoading] = useState(false)
  const [params, setParams] = useState({
    id_auction,
    page: 1,
    limit: 10,
    nr_status: 2,
  })

  const { signed, data } = useUserContext()
  const [auction, setAuction] = useState(null)
  const handleDetailProductauction = async (
    id_product: number,
    des_title: string
  ) => {
    try {
      await api.apiLeloae.product.createView({ id_product })
      history.push(
        `/auction/product/detail/${id_product}/${auction.id_auction}/${auction?.dt_deadline}/${auction?.time_deadline}/${des_title}`
      )
    } catch (error) {
      toast.error("Ocorreu algum erro", { toastId: 1 })
    }
  }

  const getAuction = async () => {
    setAuctionLoading(true)
    try {
      const product = await api.apiLeloae.auction.get<any>(id_auction)

      setAuction(product.data?.message)
      setAuctionLoading(false)
    } catch (error) {
      setAuctionLoading(false)
      const errorFormat = FormatError(error)
      errorFormat.forEach((element) => {
        toast.error(element)
      })
    }
  }

  const handlePage = (page: number) => {
    setParams((field) => {
      return {
        ...field,
        page: page,
      }
    })
  }

  useEffect(() => {
    getAuction()
  }, [id_auction])

  useEffect(() => {
    dispatch(actionAuctionProduct.searchProducts(params))
  }, [params])

  if (!id_auction) {
    return <Redirect to="/list/auctions"></Redirect>
  }

  return (
    <>
      {auctionLoading && <Loading />}
      <Navbar />
      <Container>
        <div id="auction-detail">
          <Row>
            <div className="col-12 row">
              {/* TODO colocar margem para a parte lateral da tela */}
              <div className="col-12 col-sm-12 col-md-3 menu-left">
                <h2 className="title-custom-h2 col-12">Leilão Virtual</h2>
                <Jumbotron id="side-info-auction">
                  <Container>
                    {auction?.dt_deadline && (
                      <h3>
                        {" "}
                        {FormatDatePtBr(auction?.dt_deadline)} as{" "}
                        {FormatHours(auction?.time_deadline)}{" "}
                      </h3>
                    )}
                    <p>Data de término do Leilão</p>
                    <br />
                    <h4>Informações do leilão</h4> <br />
                    <br />
                    <p>
                      Aqui fica reservado para a parte institucional do Leilão
                    </p>
                  </Container>
                </Jumbotron>
              </div>

              {reducerAuctionProduct.httprequest.error &&
                toast.error("Ocorreu erro em buscar lista de lotes", {
                  toastId: 1,
                })}
              <div className="col-12 col-sm-12 col-md-9 list-right text-center">
                {signed && data?.user?.id_person == auction?.id_person && (
                  <div className="text-right register-lote">
                    <a
                      href={`/announce/${id_auction}/2`}
                      className="btn btn-create"
                    >
                      Cadastrar lotes
                    </a>
                    <a
                      href={`/auction/active/user/${id_auction}/${auction?.id_person}`}
                      className="btn btn-create"
                    >
                      Cadastrar Usuários
                    </a>
                  </div>
                )}
                {auction?.nr_status === 3 ? (
                  <h1 className="text-finish-auction">Leilão finalizado</h1>
                ) : (
                  auction?.dt_deadline && (
                    <CountDownTime
                      children={formatDataToCountDown(
                        auction?.dt_deadline,
                        auction?.time_deadline
                      )}
                    />
                  )
                )}
                <Card>
                  <Card.Img
                    variant="top"
                    src={auction?.AuctionPhotos[0]?.des_auction_photo_url}
                  />
                  <Card.Body>
                    <Card.Title>{auction?.des_title}</Card.Title>
                    <Card.Text>{auction?.des_organizer}</Card.Text>
                  </Card.Body>
                </Card>
                {auction &&
                  reducerAuctionProduct.data.map((product: any) => (
                    <div
                      className="shadow mb-3 bg-white colunm row item-product"
                      key={product.id_product}
                    >
                      <div className="col-sm-12 col-md-5 div-figureProduct">
                        <Figure>
                          <Figure.Image
                            className="lazyload"
                            src={
                              product?.Product?.ProductPhotos[0]
                                ?.des_product_photo_url
                            }
                            alt="Leilão mermo"
                          />
                        </Figure>
                      </div>
                      <div className="col-sm-12 col-md-7 info-lots">
                        <div className="row titles">
                          <h2>{product?.Product?.des_title} </h2>{" "}
                          <p>Leilão Virtual</p>
                        </div>
                        <div className="description">
                          <p>{product?.Product?.des_description}</p>
                        </div>
                        <div className="row vl-show-details">
                          <p className="type-coin text-center text-sm-center text-lg-left"></p>
                          <div className="col-sm-12 col-lg-4 text-center text-sm-center text-lg-left">
                            <h3 className="vl">
                              {FormatPrice(product?.Product?.vl_price_total)}
                            </h3>
                          </div>
                          <div className="col-12 col-lg-8 text-sm-center text-md-right d-none d-md-block d-lg-block d-xl-block">
                            <Button
                              className="btn btn-create"
                              onClick={() =>
                                handleDetailProductauction(
                                  product.id_product,
                                  product?.Product?.des_title
                                )
                              }
                            >
                              Ver Detalhes
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 d-block d-sm-block d-md-none d-lg-none d-xl-none">
                        <Button
                          className="btn btn-create"
                          onClick={() =>
                            handleDetailProductauction(
                              product.id_product,
                              product?.Product?.des_title
                            )
                          }
                        >
                          Ver Detalhes
                        </Button>
                      </div>
                    </div>
                  ))}
                <Pagination
                  current={params.page}
                  onChange={handlePage}
                  pageSize={10}
                  total={reducerAuctionProduct.headers["x-total-count"]}
                  style={{ margin: "20px" }}
                />
              </div>
            </div>
          </Row>
        </div>
      </Container>
      <Footer />
    </>
  )
}
export default AuctionDetail
