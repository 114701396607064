import React from 'react';
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify';
import ReactGA from 'react-ga';
import lazysizes from 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

import Routes from './routes/index.router';
import { UserProvider } from './providers/use-sign'
import { CreateAuctionProvider } from './providers/auction-create'
import { CreateUserProvider } from './providers/use-create'
import { CreateParamsProvider } from './providers/product-params'
import { CreateStateProvider } from './providers/state-global'
import { store } from './store'

function initializeReactGA() {
  ReactGA.initialize('G-KWH48RRMRZG');
  ReactGA.pageview(window.location.pathname + window.location.search);
};

function App() {
  initializeReactGA();
  lazysizes.init();
  return (
    <>
      <ToastContainer />
      <CreateStateProvider>
        <CreateUserProvider>
          <UserProvider>
            <CreateAuctionProvider>
              <CreateParamsProvider>
                <Provider store={store}>
                  <BrowserRouter>
                    <Routes />
                  </BrowserRouter>
                </Provider>
              </CreateParamsProvider>
            </CreateAuctionProvider>
          </UserProvider>
        </CreateUserProvider>
      </CreateStateProvider>
    </>
  );
}

export default App;
