/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react"
import Pagination from "rc-pagination"

import { useDispatch, useSelector } from "react-redux"
import { actionProduct } from "../../store/reduces/ReducerProduct"
import { RootState } from "../../store"
import { useParamsProductContext } from "../../providers/product-params"

import { toast } from "react-toastify"
import {
  Container,
  Row,
  Figure,
  Form,
  Navbar,
  Accordion,
  Card,
  Button,
} from "react-bootstrap"

import "rc-slider/assets/index.css"
import "rc-pagination/assets/index.css"
import "./styles.css"

import NavbarComp from "../../components/Navbar"
import Footer from "../../components/Footer"
import Loading from "../../components/Loading"
import MenuLeftBeefCattle from "./MenuLeftBeefCattle"
import MenuLeftMilkCow from "./MenuLeftMilkCow"

import { FormatPrice } from "../../helpers/format"
import { api } from "../../services"
import { useHistory } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"
import BuyPage from "../../components/BuyPage"

const Product: React.FC = (propos: any) => {
  const { params, setParamsProduct } = useParamsProductContext()

  const history = useHistory()
  const [MenuLeft, setMenuLeft] = useState("")
  const [attrRacesBoxDefault, setAttrRacesBoxDefault] = useState({
    typeProduct: "-",
  })
  const { reducerProduct } = useSelector((state: RootState) => state)
  const dispatch = useDispatch()
  const paramsURL = propos.match.params.paramsurl
  const valueURL = propos.match.params.valueurl
  const paramsURL2 = propos.match.params.paramsurl2
  const valueURL2 = propos.match.params.valueurl2

  useEffect(() => {
    dispatch(actionProduct.searchProducts({ filter: params }))
  }, [params])

  useEffect(() => {
    paramsFilterUrl()
  }, [Boolean(paramsURL) === true && valueURL])

  const paramsFilterUrl = async () => {
    if (Boolean(paramsURL2) === true) {
      await setParamsProduct({ type: [`${paramsURL}`], value: valueURL })
      await setParamsProduct({ type: [`${paramsURL2}`], value: valueURL2 })
    } else {
      await setParamsProduct({ type: [`${paramsURL}`], value: valueURL })
    }
  }

  const menuLeftType = async (event: any) => {
    const value = event.target.value

    await setParamsProduct({ type: "id_type_product_animal", value: value })

    if (value === "1") {
      setMenuLeft("MenuLeftBeefCattle")
    } else {
      setMenuLeft("MenuLeftMilkCow")
    }
  }

  const menuLeftBoxDefault = (event: any) => {
    const nameBox = event.target.dataset.id

    setAttrRacesBoxDefault((field) => {
      return {
        ...field,
        [`${nameBox}`]: attrRacesBoxDefault.typeProduct === "+" ? "-" : "+",
      }
    })
  }

  const handleCreateProductView = async (
    id_product: number,
    des_title: string,
    nr_type_sales: number
  ) => {
    try {
      await api.apiLeloae.product.createView({ id_product })
      if (nr_type_sales === 1) {
        history.push(`/product/detail/${id_product}/${des_title}`)
      } else {
        const result: any = await api.apiLeloae.auction.getAuctionByIdProduct(
          id_product
        )
        const { id_auction, dt_deadline, time_deadline } =
          result?.data?.message?.Auction
        history.push(
          `/auction/product/detail/${id_product}/${id_auction}/${dt_deadline}/${time_deadline}/${des_title}`
        )
      }
    } catch (error) {
      toast.error("Ocorreu algum erro", { toastId: 1 })
    }
  }

  const handlePage = (page: number) => {
    setParamsProduct({ type: "page", value: page })
  }
  return (
    <>
      <NavbarComp />
      <BuyPage />
      <Footer />
    </>
  )
}
export default Product
