/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';

import Header from '../../components/HeaderAdmin'
import Sidebar from './SideNav'

import { PieChart } from 'react-chartkick'
import 'chart.js'

import imgIconCow from '../../assets/image/icon-cow.png'

import './styles.css'
import { Col, Container, Figure, Row} from 'react-bootstrap';
import { checkDevice } from '../../helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserFriends } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { api } from '../../services/apiLeloae/config';
import { toast } from 'react-toastify';
import { FormatPrice } from '../../helpers/format';

const Admin: React.FC = () => {

  const [expandedResume, setExpandedResume] = useState({ expanded: null })
  const [listLots, setListLots] = useState<any>([])
  const [listLotsNew, setListLotsNew] = useState<any>([])
  const [listLotsTotal, setListLotsTotal] = useState<any>([])
  const [listUsers, setListUsers] = useState<any>([])  
  
  let mounted = true;
  const signal = axios.CancelToken.source();
  const dateNow: any = new Date() 

  const getListLots = async () => {
    try {
    const responseListLots: any = await api.get('/admin/product/search/params', {params: {nr_status: 2}, cancelToken: signal.token})

    if (mounted) {setListLots(responseListLots?.data?.message)}
    } catch (error) {
      toast.error('Error e buscar lista de lotes', {toastId: 1})
    }
  }

  const getListLotsTotal = async () => {
    try {
    const responseListLots: any = await api.get('/admin/product', {params: {limit:120 * 60 * 60 * 1000 }, cancelToken: signal.token})

    if (mounted) {setListLotsTotal(responseListLots?.data?.message)}
    } catch (error) {
      toast.error('Error e buscar lista de lotes', {toastId: 1})
    }
  }

  const getListLotsNew = async () => {
    try {
    const responseListLots: any = await api.get('/admin/product/search/params', {params: {nr_status: 2, nr_date_old: new Date( dateNow - 120 * 60 * 60 * 1000), limit: 1000}, cancelToken: signal.token})

    if (mounted) {setListLotsNew(responseListLots?.data?.message)}
    } catch (error) {
      toast.error('Error e buscar lista de lotes', {toastId: 1})
    }
  }

  const getListUsersNew = async () => {
    try {
      const responseListUsers: any = await api.get('/admin/user', {params: {nr_date_old: new Date( dateNow - 120 * 60 * 60 * 1000), limit: 1000} ,cancelToken: signal.token})
      if (mounted) {setListUsers(responseListUsers?.data?.message)}
    } catch (error) {
      toast.error('Error e buscar lista de usuários', {toastId: 1})
    }
  }

  const typeProductBeefCattle = listLotsTotal.filter(typeProduct => parseInt(typeProduct.Animal.id_type_product_animal) === 1).length
  const typeProductMilkCow = listLotsTotal.filter(typeProduct => parseInt(typeProduct.Animal.id_type_product_animal) === 2).length

  useEffect(() => {
    setExpandedResume({expanded: checkDevice()})
    getListLots()
    getListUsersNew()
    getListLotsNew()
    getListLotsTotal()
  },[])

  return (
    <>
      <Container fluid id="home-admin">
          <Row>  
              <Sidebar />
              <Header />
                <div className={expandedResume.expanded ? 'resume' : 'resume-mobile'}>
                  <Row className="row-resume">
                    <h4 className="col-12">Resumo de movimentações</h4>
                    <Col className="shadow mb-3 bg-white colunm lots-graphic"  sm={12} md={6}>
                      <div className="container">
                        <PieChart donut={true} data={{"Gado de corte": typeProductBeefCattle, "Vaca de leite": typeProductMilkCow}} />
                        <p>{listLotsTotal.length} Lotes cadastrados</p>
                      </div>
                    </Col> 
                    <Col  sm={12} md={6}>
                      <Col className="shadow mb-3 bg-white colunm new-user" sm={12} >
                        <div className="icon-user text-center"><FontAwesomeIcon icon={faUserFriends} size="3x" /></div>
                        <div className="description">
                          <h1>{listUsers.length}</h1>
                          <p>Novos usuários</p>
                        </div>
                      </Col>
                      <Col className="shadow mb-3 bg-white colunm new-lots" sm={12} >
                        <Figure>
                            <Figure.Image src={imgIconCow} />
                        </Figure>
                        <div className="description">
                          <h1>{listLotsNew.length}</h1>
                          <p>Novos lotes</p>
                        </div>
                      </Col>
                    </Col> 
                    <h4 className="col-12">Últimos lotes cadastrados</h4>
                    <Col sm={12} className="shadow mb-3 bg-white colunm new-register-lots  text-center">
                      {listLots.map((lots: any) => 
                        <div id="lots" key={lots.id_product}>
                          <Figure className="col-md-2 col-sm-4">
                              <Figure.Image src={lots?.ProductPhotos[0]?.des_product_photo_url} width={100} height={100} />
                          </Figure>
                          <div className="description col-md-2 col-sm-4">
                            <h5>{lots?.des_title}</h5>
                            <p>{lots?.des_description.length > 10 ? lots?.des_description.substring(0,25) + '...' : lots?.des_description}</p>
                          </div>
                          <div className="description col-md-2 col-sm-4 d-none d-md-block d-lg-block d-xl-block ">
                            <h6>{lots?.Animal?.AnimalCategory?.des_category}</h6>
                          </div>
                          <div className="description col-md-2 col-sm-4 d-none d-md-block d-lg-block d-xl-block ">
                            <h6>{lots?.Animal?.id_custom_races ?
                                <>
                                  Raças cruzadas
                                </> 
                                :
                                <>{lots?.Animal?.AnimalRace?.des_races}</> 
                            }</h6>
                          </div>
                          <div className="description col-md-2 col-sm-4 d-none d-md-block d-lg-block d-xl-block ">
                            <h6>{FormatPrice(lots.vl_price_total)}</h6>
                          </div>
                          
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
          </Row>
      </Container>
    </>
  );
}
export default Admin;
