/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-unreachable */
/* eslint-disable eqeqeq */
import React, { useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router-dom"

import Footer from "../../../components/Footer"
import Navbar from "../../../components/Navbar"
import Loading from "../../../components/Loading"

import "./styles.css"
import { Button, Card, Container, Figure, Form, Row } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCamera, faVideo, faTrash } from "@fortawesome/free-solid-svg-icons"

import { toast } from "react-toastify"
import axios from "axios"
import { api } from "../../../services/apiLeloae/config"
import { FormatError } from "../../../helpers/formatError"
import { useUserContext } from "../../../providers/use-sign"

const AuctionFiles: React.FC = (propos: any) => {
  const id_auction = propos.match.params.id_auction

  const [updateLoading, setUpdateLoading] = useState(false)
  const [imgAuction, setImgAuction] = useState([])
  const [videoAuction, setVideoAuction] = useState([])
  const [imgPreview, setimgPreview] = useState<any>([])
  const [videoPreview, setVideoPreview] = useState<any>([])
  const [ProgressUpload, setProgressUpload] = useState<any>(0)

  const [auction, setAuction] = useState<any>([])

  const signal = axios.CancelToken.source()
  const { data } = useUserContext()
  const history = useHistory()

  useEffect(() => {
    renderViewImg()
  }, [imgAuction])

  const renderViewImg = () => {
    let url: any
    for (let index = 0; index < imgAuction.length; index++) {
      url = imgAuction ? [...imgAuction].map(URL.createObjectURL) : null
    }
    setimgPreview(url)
  }

  useMemo(() => {
    let url: any
    for (let index = 0; index < videoAuction.length; index++) {
      url = videoAuction ? [...videoAuction].map(URL.createObjectURL) : null
    }
    setVideoPreview(url)
  }, [videoAuction])

  const handleChangeFormAuctionImg = (event: any) => {
    const images = []

    const files = event.target.files
    images.push(...imgAuction, ...files)

    setImgAuction(images)
  }

  const handleChangeFormAuctionVideo = (event: any) => {
    setVideoAuction(event.target.files)
  }

  const handleDeleteImg = (index: number) => {
    imgAuction.splice(index, 1)

    setImgAuction(imgAuction)
    renderViewImg()
  }

  const handleProgressUpload = (event: any) => {
    const value = event.target.value

    if (!imgAuction[0]) {
      return toast.error("Envio de fotos e obrigatório")
    }
    setProgressUpload(value)
  }

  const checkStateProduct = async () => {
    try {
      const responseAuction: any = await api.get(
        `/auction/lots/show/${id_auction}`,
        { cancelToken: signal.token }
      )

      setAuction(responseAuction.data?.message)

      if (responseAuction.data?.message.nr_status != 1) {
        toast.error("Seu leilão ja está ativo", { toastId: 1 })
        history.push("/auctions")
      }
    } catch (error) {
      toast.error("Ocorreu algum erro", { toastId: 1 })
    }
  }

  const handleSubmitFiles = async (event: any) => {
    event.preventDefault()
    setUpdateLoading(true)
    try {
      const dataPhotoAuction = new FormData()
      for (let index = 0; index < imgAuction.length; index++) {
        dataPhotoAuction.append("des_auction_photo", imgAuction[index])
      }
      dataPhotoAuction.append("id_auction", String(id_auction))
      dataPhotoAuction.append("id_person", String(data.user.id_person))
      await api.post(`/auction/photo`, dataPhotoAuction)

      if (videoAuction[0]) {
        const dataVideoAuction = new FormData()
        for (let index = 0; index < videoAuction.length; index++) {
          dataVideoAuction.append("des_auction_video", videoAuction[index])
        }
        dataVideoAuction.append("id_auction", String(id_auction))
        dataVideoAuction.append("id_person", String(data.user.id_person))
        dataVideoAuction.append("des_title", String(auction.des_title))
        await api.post(`/video/auction`, dataVideoAuction)
      }

      await api.put(`/auction/lots/update/${id_auction}`, auction)

      setUpdateLoading(false)

      toast.success("Leilão concluído com sucesso!", { toastId: 1 })
      history.push(`/auction/detail/${id_auction}`)
    } catch (error) {
      if (error.response.data.message) {
        const errorFormat = FormatError(error.response.data.message)
        errorFormat.forEach((element) => {
          toast.error(element)
        })
      } else {
        toast.error("Ocorreu algum erro")
      }
      setUpdateLoading(false)
    }
  }

  useEffect(() => {
    setAuction((field) => {
      return {
        ...field,
        nr_status: 2,
      }
    })
  }, [imgAuction])

  useEffect(() => {
    checkStateProduct()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {updateLoading && <Loading />}
      <Navbar />
      <Container>
        <div id="send-files-auction" className="text-center">
          <Row>
            <h2 className="title-custom-h2 col-12">Envio de arquivos</h2>
            <Card.Body
              id="photo-product"
              className="shadow p-3 mb-5 bg-white text-center col-12"
            >
              <Card.Title className="col-12">
                <b>Enviei fotos do seu leilão</b>
              </Card.Title>
              <Row>
                <Figure className="row">
                  {imgPreview
                    ? imgPreview.map((img: any, index: number) => (
                        <div key={img}>
                          <Figure.Image src={img} />
                          <Button
                            className="btn-trash"
                            onClick={() => handleDeleteImg(index)}
                          >
                            <FontAwesomeIcon icon={faTrash} size="1x" />
                          </Button>
                        </div>
                      ))
                    : ""}
                </Figure>
                <Form>
                  <label className="upload-foto-profile">
                    <input
                      type="file"
                      accept="image/png, image/jpeg, image/jpg"
                      multiple
                      onChange={handleChangeFormAuctionImg}
                    />
                    <p className="upload-foto-profile-input">
                      <FontAwesomeIcon icon={faCamera} size="1x" />{" "}
                    </p>
                  </label>
                </Form>
                <p className="col-12 text-left">Arquivos PNG,JPG, JPEG</p>
              </Row>
              {ProgressUpload === 0 && (
                <Button
                  value="1"
                  onClick={handleProgressUpload}
                  className="btn btn-create"
                >
                  Avançar
                </Button>
              )}
            </Card.Body>
            {ProgressUpload >= 1 && (
              <Card.Body
                id="video-product"
                className="shadow p-3 mb-5 bg-white text-center col-12"
              >
                <Card.Title className="col-12">
                  <b>Deixe seu leilão mais completo com um vídeo</b>
                </Card.Title>
                <p>Opcional</p>
                <Row>
                  <Figure>
                    {videoPreview
                      ? videoPreview.map((video: any) => (
                          <div key={video}>
                            <video src={video}></video>
                            <Button
                              className="btn-trash"
                              onClick={() => setVideoAuction([])}
                            >
                              <FontAwesomeIcon icon={faTrash} size="1x" />
                            </Button>
                          </div>
                        ))
                      : ""}
                  </Figure>
                  <Form>
                    <label className="upload-foto-profile">
                      <input
                        type="file"
                        accept="video/*"
                        onChange={handleChangeFormAuctionVideo}
                      />
                      <p className="upload-foto-profile-input">
                        <FontAwesomeIcon icon={faVideo} size="1x" />{" "}
                      </p>
                    </label>
                  </Form>
                  <p className="col-12 text-left">
                    Arquivos MP4, AVI, WMV, 3GPP, limite tempo 2 minutos
                  </p>
                </Row>
                {ProgressUpload == 1 && (
                  <Button
                    onClick={handleSubmitFiles}
                    className="btn btn-create"
                  >
                    Finalizar
                  </Button>
                )}
              </Card.Body>
            )}
          </Row>
        </div>
      </Container>
      <Footer />
    </>
  )
}
export default AuctionFiles
