import styled, { css } from "styled-components"

import { BannerProps } from "."

const tamanhoBase = css`
  max-width: 1636px;
  min-height: 147px;
  max-height: 200px;
`

export const Wrapper = styled.div<BannerProps>`
  ${tamanhoBase}
  margin: 0 auto;

  ${({ p }) => css`
    padding: ${p};
  `}
`

export const Image = styled.img`
  ${tamanhoBase}
  border-radius: 25px;
  margin: 0 auto;
  object-fit: cover;
`
