import React, { useEffect, useState } from "react"

import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"

import AnimalCard from "../AnimalCard"
import Carousel from "../Carousel"

import { FormatPrice } from "../../helpers/format"

import { api } from "../../services"

import { RootState } from "../../store"
import { actionProductViews } from "../../store/reduces/ReducerProductViews"

import { SwiperSlide } from "swiper/react/swiper-react.js"

import "swiper/swiper.scss"
import "swiper/modules/navigation/navigation.scss"
import "swiper/modules/pagination/pagination.scss"
import "swiper/modules/autoplay/autoplay.scss"

const CarouselDestaque = ({ animalList = false }) => {
  const history = useHistory()
  const { reducerProductViews } = useSelector((state: RootState) => state)
  const dispatch = useDispatch()

  const handleCreateProductView = async (
    id_product: number,
    des_title: string,
    nr_type_sales: number
  ) => {
    try {
      await api.apiLeloae.product.createView({ id_product })
      if (nr_type_sales === 1) {
        history.push(`/product/detail/${id_product}/${des_title}`)
      } else {
        const result: any = await api.apiLeloae.auction.getAuctionByIdProduct(
          id_product
        )
        const { id_auction, dt_deadline, time_deadline } =
          result?.data?.message?.Auction
        history.push(
          `/auction/product/detail/${id_product}/${id_auction}/${dt_deadline}/${time_deadline}/${des_title}`
        )
      }
    } catch (error) {
      toast.error("Ocorreu algum erro", { toastId: 1 })
    }
  }

  useEffect(() => {
    dispatch(actionProductViews.searchProducts({ page: 1, limit: 10 }))
  }, [dispatch, reducerProductViews.params])

  const breakpoints = {
    620: {
      spaceBetween: 30,
    },
    1023: {
      centeredSlides: false,
    },
  }

  const initialValue = () => {
    if (window.screen.width / 361 <= 3) {
      return window.screen.width / 361
    }
    return 3
  }

  const [numSlides, setNumSlides] = useState(initialValue)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleResize = () => {
    if (window.screen.width / 361 <= 3) {
      setNumSlides(window.screen.width / 361)
    } else {
      setNumSlides(3)
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize, false)
  }, [handleResize, numSlides])

  return (
    <Carousel
      width="1120px"
      space={40}
      numSlides={numSlides}
      breakpoints={breakpoints}
      variant="product"
      animalList={animalList}
    >
      {reducerProductViews.data.map(
        (data: any) =>
          data?.product?.TypeProductStatus.des_type_product_status ===
          "Disponível" && (
            <SwiperSlide
              key={data?.id_product}
              onClick={() =>
                handleCreateProductView(
                  data?.id_product,
                  data?.product?.des_title,
                  data?.product?.nr_type_sales
                )
              }
            >
              <AnimalCard
                key={data.id_product}
                image={data?.product?.ProductPhotos[0]?.des_product_photo_url}
                alt={data?.product?.des_title}
                title={data?.product?.des_title}
                price={FormatPrice(data?.product?.vl_price_total)}
                address={
                  data?.product?.AddressProduct?.City?.des_city +
                  "/" +
                  data?.product?.AddressProduct?.City?.uf
                }
                animalQtd={data?.product?.qtd_animals}
                animalWeight={data?.product?.nr_weight}
                variant="destaque"
              />
            </SwiperSlide>
          )
      )}
    </Carousel>
  )
}
export default CarouselDestaque
