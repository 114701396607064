/* eslint-disable no-eval */
export const FormatPrice = (price: any) => {
    return Number(price).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
}

export const FormatPricePerAnimal = (price: any, qtdAnimals: any) => {
    let pricePerAnimal = price / qtdAnimals
    return Number(pricePerAnimal).toLocaleString('pt-br', {style: 'currency', currency: 'BRL'});
}

export const FormatPricePerWeight = (price: any, qtd_animals: any, weight: any) => {
    let priceWeight = (price / qtd_animals) / weight;
    return Number(priceWeight).toLocaleString('pt-br', {style: 'currency', currency: 'BRL'});
}

export const FormatPriceInput = (price: any) => {
    let v = String(price).replace(/\D/g,'');
	v = (Number(v)/100).toFixed(2) + '';
	v = v.replace(".", ",");
	v = v.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
    v = v.replace(/(\d)(\d{3}),/g, "$1.$2,");
	return v;
}

export const FormatPriceEua = (price: any) => {
    let v = price.replace(".", "");
	v = v.replace(",", ".");
	return v;
}

export const FormatPhone = (phone: any) => {
    const regex = /^([0-9]{2})([0-9]{4,5})([0-9]{4})$/
    let str = String(phone).replace(/[^0-9]/g, "").slice(0, 11)
    return str.replace(regex, "($1) $2-$3");
}

export const FormatCpf = (cpf: any) => {
    const regex = /^([0-9]{3})([0-9]{3})([0-9]{3})([0-9]{2})$/
    let str = String(cpf).replace(/[^0-9]/g, "").slice(0, 11)
    return str.replace(regex, "$1.$2.$3-$4");
}

export const FormatCnpj = (cpf: any) => {
    const regex = /^([0-9]{2})([0-9]{3})([0-9]{3})([0-9]{4})([0-9]{2})$/
    let str = String(cpf).replace(/[^0-9]/g, "").slice(0, 14)
    return str.replace(regex, "$1.$2.$3/$4-$5");
}

export const FormatCep = (zipcode: any) => {
    const regex = /^([0-9]{2})([0-9]{3})([0-9]{3})$/
    let str = String(zipcode).replace(/[^0-9]/g, "").slice(0, 8)
    return str.replace(regex, "$1.$2-$3");
}

export const FormatDate = (date: any) => {
    const regex = /^([0-9]{2})([0-9]{2})([0-9]{4})$/
    let str = String(date).replace(/[^0-9]/g, "").slice(0, 8)
    return str.replace(regex, "$1/$2/$3");
}

export const FormatDatePtBr = (date: any) => {
    if(date){
        return (date.substr(0, 10).split('-').reverse().join('/'));
    }
}

export const FormatHours = (hours: any) => {
    if(hours){
        return (hours.substr(0, 5))
    }
}

export const FormatDateHoursPtBr = (date: any) => {
    if(date){
        return new Date(date).toLocaleString('pt-br')
    }
}

export function onlyNumbers (str: string): string {
    const nr_regex = /\D+/g
    return String(str).replace(nr_regex, '')
}

export function CalculationAnimalPriceTotal (id_type_calculation_animal:number, qtd_animals: any, vl_price: any, nr_weight: any): any {
    switch (Number(id_type_calculation_animal)) {
        case 1:
            return Number(qtd_animals) * parseFloat(vl_price)
        case 2:
            return parseFloat(vl_price)
        case 3:
            return (parseFloat(nr_weight) * parseFloat(vl_price)) * Number(qtd_animals)
        case 4:
            return Number(qtd_animals) * parseFloat(vl_price)
        case 5:
            return Number(qtd_animals) * parseFloat(vl_price)
        case 6:
            return Number(qtd_animals) * parseFloat(vl_price)
        case 7:
            return parseFloat(vl_price)
    }
}

export const formatDataToCountDown = (date: any, time:any) => {
    const date_finish: any = date + ' ' + time

    const timeZone = new Date().getTimezoneOffset()
  
    const timeStamp = new Date(date_finish).getTime() - (((timeZone - 180) * 60 * 1000))

    return timeStamp
  
}
