/* eslint-disable react-hooks/rules-of-hooks */
import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useStateGlobalContext } from "../../providers/state-global";

function useOutside(ref: any) {
  const { setStateSearchHide, statusOutSide } = useStateGlobalContext()

    useEffect(() => {
    function handleClickOutside(event: any) {
      if(statusOutSide) {
        if (ref.current && !ref.current.contains(event.target)) {
          setStateSearchHide(false)
        }
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, setStateSearchHide, statusOutSide]);
}

function Outside(props: any) {
  const wrapperRef = useRef(null);
  useOutside(wrapperRef);

  return <div ref={wrapperRef}>{props.children}</div>;
}

Outside.propTypes = {
  children: PropTypes.element.isRequired
};

export default Outside;