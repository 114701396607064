/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"

import Footer from "../../components/Footer"
import Navbar from "../../components/Navbar"
import Loading from "../../components/Loading"
import RegisterPersonalData from "./registerPersonalData"
import RegisterContact from "./registerContact"
import DataConfirmation from "./dataConfirmation"

import imgGadoRegisterUser from "../../assets/image/gado_register_user.jpg"

import "./styles.css"

import { Container, Row, Figure, ProgressBar } from "react-bootstrap"

import { useCreateUserContext } from "../../providers/use-create"
import { useUserContext } from "../../providers/use-sign"

import { FormatError } from "../../helpers/formatError"
import { toast } from "react-toastify"

const RegisterUser: React.FC = () => {
  const { loadMsg, progress, loginData } = useCreateUserContext()
  const { signing, data } = useUserContext()
  const history = useHistory()

  let progressRegisterUser = progress
  let retgisterForm: {} | null | undefined
  let progressRegisterUserNow: number

  if (progressRegisterUser === 1) {
    progressRegisterUserNow = 33
    retgisterForm = <RegisterPersonalData />
  } else if (progressRegisterUser === 2) {
    progressRegisterUserNow = 66
    retgisterForm = <RegisterContact />
  } else {
    progressRegisterUserNow = 100
    retgisterForm = <DataConfirmation />
  }

  useEffect(() => {
    if (loadMsg.msgError) {
      const errorFormat = FormatError(loadMsg.msgError)
      errorFormat.forEach((element: any) => {
        toast.error(element)
      })
    }
  }, [loadMsg.msgError])

  useEffect(() => {
    if (loadMsg.msgSuccess) {
      const errorFormat = FormatError(loadMsg.msgSuccess)
      errorFormat.forEach((element: any) => {
        toast.success(element)
      })

      signing(loginData)
      history.push("/")
    }
  }, [data, history, loadMsg.msgSuccess, loginData, signing])

  return (
    <>
      {loadMsg.createUserLoading && <Loading />}
      <Navbar />
      <Container>
        <div id="register-user">
          <Row className="bg-clear-custom">
            <div className="col-lg-6 d-none d-lg-block d-xl-block img-gado-register-user">
              <Figure>
                <Figure.Image width="100%" src={imgGadoRegisterUser} />
              </Figure>
            </div>
            <div className="col-lg-6 col-12 text-center register-user-form">
              <h2 className="title-custom-h2">Criar Conta</h2>
              <div className="progress-register-user">
                <Row>
                  <div className="col-4">
                    <p>1. Dados pessoais</p>
                  </div>
                  <div className="col-4">
                    <p>2. Contato</p>
                  </div>
                  <div className="col-4">
                    <p>3. Confirmação</p>
                  </div>
                </Row>
                <ProgressBar variant="warning" now={progressRegisterUserNow} />
              </div>
              {retgisterForm}
            </div>
          </Row>
        </div>
      </Container>
      <Footer />
    </>
  )
}
export default RegisterUser
