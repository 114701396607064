import React from "react"

import Navbar from "../../components/Navbar"

import btnDownloadApp from "../../assets/image/btn-download-app.png"

import "./styles.css"
import { Container, Row } from "react-bootstrap"

const DownloadsApp: React.FC = () => {
  return (
    <>
      <Navbar />
      <Container fluid id="download-app-bg">
        <div id="download-app">
          <Row>
            <h1 className="text-center title-custom-h2 col-12 col-sm-8 col-md-6 col-lg-4">
              Baixe agora o nosso aplicativo
            </h1>
            <div className="col-12 btn-download">
              <a
                href="https://play.google.com/store/apps/details?id=com.leiloae.leiloaeapp"
                target="_blank"
                rel="noopener noreferrer"
              >
                {/* onClick={() => {alert('Aplicativo ainda não disponível para donwloads.')}} */}
                <img src={btnDownloadApp} alt="Leiloae download app" />
              </a>
            </div>
          </Row>
        </div>
      </Container>
    </>
  )
}
export default DownloadsApp
