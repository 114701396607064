/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle, faCalendar, faIdCard, faEnvelope, faMapMarked, faPhone } from '@fortawesome/free-solid-svg-icons';

import { useCreateUserContext } from '../../providers/use-create'

import { Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FormatCep, FormatCpf, FormatDatePtBr, FormatPhone } from '../../helpers/format';
import { toast } from 'react-toastify';

const DataConfirmation: React.FC = () => {
  const { createUser } = useCreateUserContext()
  const dataUserStorage = JSON.parse(localStorage.getItem('@leloae/user/create'))
  const dataUserAddressStorage = JSON.parse(localStorage.getItem('@leloae/user/address/create'))

  const [dataUser, setDataUser] = useState({
    nr_policy_terms: 1
  })

  const handleFormChangeCreateUser = (event: any) => {
    const name = event.target.name
    const checked = event.target.checked

    if(name === 'nr_policy_terms'){
        setDataUser(field => {
            return {
                ...field, [`${name}`]: checked === true ? 1 : 0
            }
        })
    }
  }

  const handleSubimitCreateUser = async (event: any) => {
    event.preventDefault()

    if(!dataUser.nr_policy_terms){
      return toast.error('Você precisa aceitar os termos e condições para se cadastrar')
    }

    await createUser(dataUser.nr_policy_terms)
  }

  return (
    <>
    <div id="data-confirmation-user" className="text-justify">
    <p><FontAwesomeIcon icon={faUserCircle} size="1x" /> {dataUserStorage?.des_person +' '+ dataUserStorage?.des_lest_person} </p>
    <hr/>
    <p><FontAwesomeIcon icon={faCalendar} size="1x" /> {FormatDatePtBr(dataUserStorage?.dt_birth)}</p>
    <hr/>
    <p><FontAwesomeIcon icon={faIdCard} size="1x" /> {FormatCpf(dataUserAddressStorage?.nr_cpf)}</p>
    <hr/>
    <p><FontAwesomeIcon icon={faEnvelope} size="1x" /> {dataUserStorage?.des_email}</p>
    <hr/>
    <p><FontAwesomeIcon icon={faMapMarked} size="1x" /> {dataUserAddressStorage?.des_address +', '+ dataUserAddressStorage?.des_number +', '+ dataUserAddressStorage?.des_city +' - '+ dataUserAddressStorage?.des_uf +', '+ FormatCep(dataUserAddressStorage?.nr_zip_code) }</p>
    <hr/>
    <p><FontAwesomeIcon icon={faPhone} size="1x" /> {FormatPhone(dataUserStorage?.nr_phone)}</p>
    <hr/>
    </div>
    <Form onSubmit={handleSubimitCreateUser}>
        <Form.Group controlId="nr_policy_terms">
            <Form.Check type="checkbox" name="nr_policy_terms" defaultChecked={Boolean(dataUser.nr_policy_terms)} onClick={handleFormChangeCreateUser} />
            <Form.Label className="label-dt-brith">Li e aceito os <Link to="/terms" target="_blank">Termos e Condições</Link></Form.Label>
        </Form.Group>
        <Button className="col-12 btn btn-create" type="submit">
            Tudo pronto! Entrar
        </Button>
    </Form>
    </>
  );
}
export default DataConfirmation;
