/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
//import { RootState } from '../../store'

import { Form, Accordion, Card, Button } from 'react-bootstrap';

import { useParamsProductContext } from '../../../providers/product-params';
//import Slider from 'rc-slider';

const VlKilo: React.FC = () => {
    //const createSliderWithTooltip = Slider.createSliderWithTooltip;
    //const Range = createSliderWithTooltip(Slider.Range);
    const { setParamsProduct } = useParamsProductContext()
    
    const [attrRacesBox, setAttrRacesBox] = useState({
        vlKilo: '+',

    })
    
    const menuLeftBox = (event: any) => {
        const nameBox = event.target.dataset.id
        setAttrRacesBox(field => {
            return {
              ...field, [`${nameBox}`]: 
              nameBox === 'vlKilo' ? attrRacesBox.vlKilo === '+' ? '-' : '+' : ''
            }
          })
    }

    const handleChangeListFilter = async (event: any) => {
        const name = event.target.name
        const value = event.target.value

        await setParamsProduct({type: name, value: value})
    }

  return (
    <>
        <Accordion defaultActiveKey="0">
            <Card className="shadow mb-3 bg-white">
                <Card.Header>
                <Accordion.Toggle as={Button} data-id="vlKilo" onClick={menuLeftBox} className="text-left" variant="link" eventKey="1">
                    <b data-id="vlKilo">Valor por quilo <b data-id="vlKilo">{attrRacesBox.vlKilo}</b></b>
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                <Card.Body>
                    <Form.Group className="row">
                        <Form.Control size="sm" className="col-12 col-sm-12 col-md-5" type="number" placeholder="Min" name="min_nr_weight" onChange={handleChangeListFilter} />
                        <br />
                        <Form.Control size="sm" className="col-12 col-sm-12 col-md-5" type="number" placeholder="Max" name="max_nr_weight" onChange={handleChangeListFilter} />
                    </Form.Group>
                </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    </>
  );
}
export default VlKilo;
