/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import Pagination from 'rc-pagination';

import Header from '../../../components/HeaderAdmin'
import Sidebar from '../SideNav'

import imgIconEye from '../../../assets/image/icon_eye.png'

import 'rc-pagination/assets/index.css'
import './styles.css'

import { Col, Container, Row} from 'react-bootstrap';
import { checkDevice } from '../../../helpers/utils';
import { toast } from 'react-toastify';
import { api } from '../../../services/apiLeloae/config';
import axios from 'axios';
import { FormatPhone } from '../../../helpers/format';

const Messages: React.FC = () => {
  const [expandedResume, setExpandedResume] = useState({ expanded: null })
  const [showIfo, setShowInfo] = useState(null)
  const [listMessage, setListMessage] = useState<any>([])

  const [totalMessage, setTotalMessage] = useState(0)
  const [loadingPagination, setLoadingPagination] = useState(false)

  let mounted = true;
  const signal = axios.CancelToken.source();

  useEffect(() => {
    setExpandedResume({expanded: checkDevice()})
  },[])

  const handleClickShowInfo = (event: any) => {
    const id = event.target.dataset.id
    setShowInfo(id)
  }

  const getListMessage = async (page = 1) => {
    if( loadingPagination ) {return}
    if ( totalMessage > 0 && listMessage.length == totalMessage ) {return}
    setLoadingPagination(true)
    try {
      const responseListMessage: any = await api.get('/feedback', {params: {page: page, limit: 10}, cancelToken: signal.token})
      if (mounted) {setListMessage(responseListMessage?.data?.message)}
      setTotalMessage(responseListMessage.headers['x-total-count'])
      setLoadingPagination(false)
    } catch (error) {
      toast.error('Error e buscar lista de menssagens', {toastId: 1})
    }
  }

  useEffect(() => {
    getListMessage()
    const cleanup = () => { mounted = false; };
    return cleanup;
  }, [mounted])


  return (
    <>
      <Container fluid id="messages">
          <Row>  
              <Sidebar />
              <Header />
                <div className={expandedResume.expanded ? 'resume' : 'resume-mobile'}>
                    <Container className="col-12">
                        <Col sm={12} className="shadow mb-3 bg-white colunm list-messages  text-center">
                            <div id="user" className="row">
                                <div className="description col-md-3 col-sm-4">
                                    <b>Nome</b>
                                </div>
                                <div className="description col-md-3 col-sm-4">
                                    <b>Telefone</b>
                                </div>
                                <div className="description col-md-3 col-sm-4">
                                    <b>E-mail</b>
                                </div>
                                <div className="description col-md-2 col-sm-4">
                                    <b>tipo de mensagem</b>
                                </div>
                            </div>
                            <hr/>
                            {listMessage.map((message: any) => 
                              <div key={message?.id_messages_feedback}>
                                  <Col   sm={12} className="row" style={{margin: '0'}}>
                                      <div className="description col-md-3 col-sm-4 ">
                                          <p>{message?.des_person}</p>
                                      </div>
                                      <div className="description col-md-3 col-sm-4">
                                          <p>{FormatPhone(message?.nr_phone)}</p>
                                      </div>
                                      <div className="description col-md-3 col-sm-4">
                                          <p>{message?.des_email}</p>
                                      </div>
                                      <div className="description col-md-2 col-sm-4">
                                          <p>{message?.TypeFeedBack?.des_type_messages}</p>
                                      </div>
                                      <div className="description col-md-1 col-sm-4">
                                          <button data-id={message?.id_messages_feedback} className="btn-none" onClick={handleClickShowInfo}><img data-id={message?.id_messages_feedback}  src={imgIconEye} alt="Icon Eye" width={40}/></button>
                                      </div>
                                      <div className="description col-sm-12" style={showIfo == message?.id_messages_feedback ? {display: 'block'} : {display: 'none'}}>
                                          <b>Descrição</b>
                                          <p>{message?.des_message}</p>
                                      </div>
                                  </Col>
                                  <hr/>
                              </div>
                            )}
                            <Pagination defaultCurrent={1} onChange={getListMessage} pageSize={10} total={totalMessage} style={{ margin: '20px' }}  />
                        </Col>
                    </Container>
                </div>
          </Row>

      </Container>
    </>
  );
}
export default Messages;
