/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react"
import { io } from "socket.io-client"

import { Container, Row, Figure, Nav, Modal, Button } from "react-bootstrap"
import { checkDevice } from "../../helpers/utils"

import Navbar from "../../components/Navbar"
import Footer from "../../components/Footer"

import "react-responsive-carousel/lib/styles/carousel.min.css"
import "./styles.css"

import { Link, Redirect } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCheckSquare,
  faWeight,
  faTruck,
  faVenusMars,
  faMoneyBillWave,
} from "@fortawesome/free-solid-svg-icons"
import {
  faFacebookSquare,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons"

import imgVideoIcon from "../../assets/image/video-icon.png"

import imgQuantity from "../../assets/image/quantidade.png"

import { useUserContext } from "../../providers/use-sign"
import { toast } from "react-toastify"
import { api } from "../../services"
import { FormatError } from "../../helpers/formatError"
import Loading from "../../components/Loading"
import { Carousel } from "react-responsive-carousel"
import {
  formatDataToCountDown,
  FormatDatePtBr,
  FormatPrice,
  FormatPricePerWeight,
  onlyNumbers,
} from "../../helpers/format"
import axios from "axios"
import CountDownTime from "../../components/CountDownTime"

const ProductDetailLeilao: React.FC = (propos: any) => {
  const { data, signed } = useUserContext()

  const [urlWhatsApp, setUrlWhatsApp] = useState("")
  const { id_product, id_auction, dt_deadline, time_deadline } =
    propos.match.params
  const [ProductLoading, setProductLoading] = useState(false)
  const [product, setProduct] = useState(null)
  const [bidValue, setBidValue] = useState<any>([])
  const [userActiveAuction, setUserActiveAuction] = useState(null)
  const [productView, setProductView] = useState(null)
  const [createBidValue, setCreateBidValue] = useState("")
  const [checkAuctionStatus, setCheckAuctionStatus] = useState(false)

  const [showModalCarousel, setShowModalCarousel] = useState(false)
  const [showModalBid, setShowModalBid] = useState(false)

  const handleCloseBid = () => setShowModalBid(false)
  const handleShowBid = (value: any) => {
    setCreateBidValue(value)
    setShowModalBid(true)
  }

  const handleClose = () => setShowModalCarousel(false)
  const handleShow = () => setShowModalCarousel(true)

  const getUserActiveAuction = async () => {
    if (data?.user?.Person?.nr_cpf) {
      const product = await api.apiLeloae.auction.getUserActiveAuction<any>(
        data?.user?.Person?.nr_cpf
      )

      setUserActiveAuction(product.data?.message)
    }
  }

  const getBidValue = async () => {
    try {
      const bid = await api.apiLeloae.auction.getBidValues<any>(id_product)

      setBidValue(bid.data?.message)
    } catch (error) {
      const errorFormat = FormatError(error)
      errorFormat.forEach((element) => {
        toast.error(element)
      })
    }
  }

  const getProduct = async () => {
    setProductLoading(true)
    try {
      const product = await api.apiLeloae.product.get<any>(id_product)
      const bid = await api.apiLeloae.auction.getBidValues<any>(id_product)

      setProduct(product.data?.message)
      setBidValue(bid.data?.message)
      setProductLoading(false)
    } catch (error) {
      setProductLoading(false)
      const errorFormat = FormatError(error)
      errorFormat.forEach((element) => {
        toast.error(element)
      })
    }
  }

  const downloadDocumentProduct = () => {
    if (!product?.ProductDocumentPhotos[0]) {
      toast.info("Documentos não anexados")
      return
    }
    product?.ProductDocumentPhotos.map((image: any) =>
      axios({
        url: image?.des_product_document_photo_url,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", image?.des_product_document_photo) //or any other extension
        document.body.appendChild(link)
        link.click()
      })
    )
  }

  const getProductView = async () => {
    setProductLoading(true)
    try {
      const product = await api.apiLeloae.product.getView<any>(id_product)

      setProductView(product.data?.message)

      setProductLoading(false)
    } catch (error) {
      setProductLoading(false)
      const errorFormat = FormatError(error)
      errorFormat.forEach((element) => {
        toast.error(element)
      })
    }
  }

  const handleBidValue = async () => {
    try {
      const bid = await api.apiLeloae.auction.createBidAuction<any>({
        id_person_auction: userActiveAuction?.id_person,
        nr_cpf: data?.user?.Person.nr_cpf,
        id_auction: id_auction,
        id_product: id_product,
        id_person: data?.user?.id_person,
        vl_bid: createBidValue,
        nr_status: 1,
      })
      if (bid.data.message) {
        toast.success("Lance enviado com sucesso.")
      }
      handleCloseBid()
    } catch (error) {
      handleCloseBid()
      console.log(error)
      const errorFormat = FormatError(error)
      errorFormat.forEach((element) => {
        toast.error(element)
      })
    }
  }

  const checkAuctionTime = () => {
    const date = formatDataToCountDown(dt_deadline, time_deadline)

    if (new Date(date).toLocaleString() > new Date().toLocaleString()) {
      setCheckAuctionStatus(true)
    }
  }

  useEffect(() => {
    const client = io("https://api.leiloae.com.br", {
      query: { id_product: id_product },
    })

    client.on("bid_request", async (data: any) => {
      getBidValue()
    })
  }, [])

  useEffect(() => {
    if (checkDevice()) {
      setUrlWhatsApp("https://web.whatsapp.com/send?text=")
    } else {
      setUrlWhatsApp("https://api.whatsapp.com/send?text=")
    }
    checkAuctionTime()
  }, [])

  useEffect(() => {
    getBidValue()
    getProductView()
    getProduct()
    getUserActiveAuction()
  }, [id_product])

  if (!id_product) {
    return <Redirect to="/product"></Redirect>
  }

  return (
    <>
      {product && (
        <>
          {ProductLoading && <Loading />}
          <Navbar />
          <Container>
            <div id="product-detail-auction">
              <Row className="row-top">
                <h1 className="col-12">{product?.des_title}</h1>
                <p className="col-12">{product?.des_description}</p>

                <Modal show={showModalCarousel} onHide={handleClose}>
                  <Modal.Header closeButton />
                  {product?.ProductVideos[0]?.des_product_video ? (
                    <>
                      <Carousel
                        showThumbs={true}
                        showStatus={true}
                        useKeyboardArrows
                        className="presentation-mode"
                      >
                        {product?.ProductPhotos.map((image: any) => (
                          <div key={image.id_product_photo}>
                            <img
                              className="lazyload img-carrousel"
                              data-src={image.des_product_photo_url}
                              alt={product?.des_title}
                            />
                          </div>
                        ))}
                        <div key="video-1" className="my-slide content">
                          <iframe
                            className="lazyload"
                            width="560"
                            height="400"
                            data-src={`https://player.vimeo.com/video/${onlyNumbers(
                              product?.ProductVideos[0].des_product_video
                            )}`}
                          />
                          <img
                            src={imgVideoIcon}
                            className="thumb-img-video"
                            alt="Icone de Vídeo"
                          />
                        </div>
                      </Carousel>
                    </>
                  ) : (
                    <>
                      <Carousel
                        showThumbs={true}
                        showStatus={true}
                        useKeyboardArrows
                        className="presentation-mode"
                      >
                        {product?.ProductPhotos.map((image: any) => (
                          <div key={image.id_product_photo}>
                            <img
                              className="lazyload"
                              data-src={image.des_product_photo_url}
                              alt={product?.des_title}
                            />
                          </div>
                        ))}
                      </Carousel>
                    </>
                  )}
                </Modal>
                <div className="col-12 col-md-12 col-lg-7">
                  {product?.ProductVideos[0]?.des_product_video ? (
                    <>
                      <Carousel
                        showThumbs={true}
                        showStatus={true}
                        useKeyboardArrows
                        className="presentation-mode"
                      >
                        <div key="video-1" className="my-slide content">
                          <iframe
                            className="lazyload"
                            width="560"
                            height="400"
                            src={`https://player.vimeo.com/video/${onlyNumbers(
                              product?.ProductVideos[0].des_product_video
                            )}`}
                          />
                          <img
                            src={imgVideoIcon}
                            className="thumb-img-video"
                            alt="Icone de Vídeo"
                          />
                        </div>
                        {product?.ProductPhotos.map((image: any) => (
                          <div
                            key={image.id_product_photo}
                            onClick={handleShow}
                          >
                            <img
                              className="lazyload"
                              data-src={image.des_product_photo_url}
                              alt={product?.des_title}
                            />
                          </div>
                        ))}
                      </Carousel>
                    </>
                  ) : (
                    <>
                      <Carousel
                        showThumbs={true}
                        showStatus={true}
                        useKeyboardArrows
                        className="presentation-mode"
                      >
                        {product?.ProductPhotos.map((image: any) => (
                          <div
                            key={image.id_product_photo}
                            onClick={handleShow}
                          >
                            <img
                              className="lazyload"
                              data-src={image.des_product_photo_url}
                              alt={product?.des_title}
                            />
                          </div>
                        ))}
                      </Carousel>
                    </>
                  )}
                </div>
                <div className="col-12 col-md-12 col-lg-5 col-detalhes">
                  <div className="shadow mb-3 bg-white detail-buy">
                    <div className="destaque">
                      <div className="row row-lance-atual">
                        <div className="col-lance-atual col-12 col-md-7 text-left">
                          <div className="col-12 titulo">
                            <p>Lance atual</p>
                          </div>
                          <div className="col-12 text-center">
                            <h4>
                              {FormatPrice(
                                bidValue[1]?.vl_total_bid
                                  ? product.vl_price_total /
                                      product.qtd_animals +
                                      parseFloat(bidValue[1]?.vl_total_bid)
                                  : product.vl_price_total / product.qtd_animals
                              )}
                            </h4>
                          </div>
                        </div>
                        <div className="col-12 col-md-5 text-center">
                          <p style={{ marginBottom: "5px" }}>Encerra em:</p>
                          {dt_deadline && time_deadline && (
                            <>
                              <CountDownTime
                                children={formatDataToCountDown(
                                  dt_deadline,
                                  time_deadline
                                )}
                              />
                            </>
                          )}
                          {/*h5 className="vl">{FormatDatePtBr(dt_deadline)} - AS {FormatHours(time_deadline)} </h5>*/}
                        </div>
                      </div>
                      <div className="row row-valores-lote">
                        <div className="col-md-4 col-sm-12">
                          <b>Valor Kg:</b>
                          <h4 className="vl">
                            {" "}
                            {FormatPricePerWeight(
                              product.vl_price_total,
                              product.qtd_animals,
                              product.nr_weight
                            )}
                          </h4>
                        </div>
                        <div className="col-md-4 col-sm-12">
                          <b>Valor Animal:</b>
                          <h4 className="vl">
                            {FormatPrice(
                              bidValue[1]?.vl_total_bid
                                ? product.vl_price_total / product.qtd_animals +
                                    parseFloat(bidValue[1]?.vl_total_bid)
                                : product.vl_price_total / product.qtd_animals
                            )}
                          </h4>
                        </div>
                        <div className="col-md-4 col-sm-12">
                          <b>Valor Total:</b>
                          <h4 className="vl">
                            {" "}
                            {FormatPrice(product.vl_price_total)}
                          </h4>
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <b className="text-center">Dar lance:</b>
                    </div>
                    <br />
                    {checkAuctionStatus ? (
                      <h1 className="text-finish-auction">Leilão finalizado</h1>
                    ) : signed &&
                      data?.user?.Person?.nr_cpf &&
                      userActiveAuction?.nr_cpf ? (
                      <>
                        <Modal show={showModalBid} onHide={handleCloseBid}>
                          <Modal.Header closeButton>
                            <Modal.Title>
                              Confirmação do envio do lance
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            Você tem certeza que deseja enviar este lance?
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              variant="secondary"
                              onClick={handleCloseBid}
                            >
                              cancelar
                            </Button>
                            <Button variant="primary" onClick={handleBidValue}>
                              Confirma
                            </Button>
                          </Modal.Footer>
                        </Modal>
                        <div className="row text-center btns-lance">
                          <div className="col-4">
                            <Button
                              className="btn btn-create call-to-shoot"
                              onClick={() => handleShowBid(5)}
                            >
                              +5
                            </Button>
                          </div>
                          <div className="col-4">
                            <Button
                              className="btn btn-create call-to-shoot"
                              onClick={() => handleShowBid(10)}
                            >
                              +10
                            </Button>
                          </div>
                          <div className="col-4">
                            <Button
                              className="btn btn-create call-to-shoot"
                              onClick={() => handleShowBid(20)}
                            >
                              +20
                            </Button>
                          </div>
                        </div>
                        <div className="col-md-12 col-sm-12">
                          <a
                            className="btn btn-cancel call-to-action-detail btn-fale-conosco"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`https://wa.me/5546991130554`}
                          >
                            Fale conosco
                          </a>
                        </div>
                      </>
                    ) : (
                      <>
                        <h5>
                          Para participar deste leilão você precisa entrar
                          contato com nossa equipe
                        </h5>
                        <div className="col-md-12 col-sm-12">
                          <a
                            className="btn btn-cancel call-to-action-detail btn-fale-conosco"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`https://wa.me/5546991130554`}
                          >
                            Fale conosco
                          </a>
                        </div>
                      </>
                    )}
                    <hr />
                    <div className="row row-detail-product">
                      <div className="col-12 col-sm-6 row detail-info">
                        <div className="col-4 text-center">
                          <Figure className="icon-quantidade">
                            <Figure.Image src={imgQuantity} />
                          </Figure>
                        </div>
                        <div className="col-8">
                          <b>Quantidade</b>
                          <p>{product?.qtd_animals} animal(is)</p>
                        </div>
                        <div className="col-4 text-center">
                          <FontAwesomeIcon icon={faMoneyBillWave} size="3x" />
                        </div>
                        <div className="col-8">
                          <b>Pagamento</b>
                          <p>A vista</p>
                        </div>
                        <div className="col-4 text-center">
                          <FontAwesomeIcon icon={faCheckSquare} size="3x" />
                        </div>
                        <div className="col-8">
                          <b>Tipo de produto</b>
                          <p>
                            {
                              product?.Animal?.TypeAnimalProduct
                                ?.des_type_product_animal
                            }
                          </p>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6 row detail-info">
                        <div className="col-4 text-center">
                          <FontAwesomeIcon icon={faTruck} size="3x" />
                        </div>
                        <div className="col-8">
                          <b>Frete</b>
                          <p>Comprador</p>
                        </div>
                        <div className="col-4 text-center">
                          <FontAwesomeIcon icon={faWeight} size="3x" />
                        </div>
                        <div className="col-8">
                          <b>Peso</b>
                          <p>{product?.nr_weight} Kg</p>
                        </div>
                        <div className="col-4 text-center">
                          <FontAwesomeIcon icon={faVenusMars} size="3x" />
                        </div>
                        <div className="col-8">
                          <b>Sexo</b>{" "}
                          <p>{product?.Animal?.AnimalSex.des_animal_sex}</p>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-md-6 col-sm-12 text-center">
                        <b>Clique aqui e compartilhe:</b>
                        <Nav
                          id="nav-product-detail-social-custom"
                          className="justify-content-center"
                          activeKey="/home"
                        >
                          <Nav.Item>
                            <a
                              href={`${urlWhatsApp} Os melhores lotes de bovinos você encontra na Leiloaê!!! Entre no site e confira: www.leiloae.com.br/product/detail/${product?.id_product}`}
                              id="whatsapp-share-btt"
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              <FontAwesomeIcon
                                className="icon-WhatsApp"
                                icon={faWhatsapp}
                                size="3x"
                              />
                            </a>
                          </Nav.Item>
                          <Nav.Item>
                            <div
                              data-href={`https://www.leiloae.com.br/product/detail/${product?.id_product}`}
                              data-layout="button"
                              data-size="large"
                            >
                              <a
                                rel="noopener noreferrer"
                                target="_blank"
                                href={`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.leiloae.com.br%2Fproduct%2Fdetail%2F${product?.id_product}&amp;src=sdkpreparse`}
                                className="fb-xfbml-parse-ignore"
                              >
                                <FontAwesomeIcon
                                  className="icon-Facebook"
                                  icon={faFacebookSquare}
                                  size="3x"
                                />
                              </a>
                            </div>
                          </Nav.Item>
                          <Nav.Item>
                            {/*<a href="#" id="whatsapp-share-btt" rel="noopener noreferrer"  target="_blank"><FontAwesomeIcon className="icon-Instagram" icon={faInstagram} size="2x" /></a>*/}
                          </Nav.Item>
                        </Nav>
                      </div>
                      <div className="col-md-6 col-sm-12 text-center">
                        <p className="visitors">
                          Esse lote recebeu{" "}
                          {productView?.nr_product_views_total * 3} visitas!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Row>

              <div>
                <h3 className="text-center title-custom-h3">Ficha técnica</h3>
                <div className="col-12 data-sheet shadow mb-3 bg-white">
                  <Link to="#" onClick={downloadDocumentProduct}>
                    <b>Baixar documentos em anexo</b>
                  </Link>

                  <div className="shadow-sm  bg-light rounded colum-light-gray row">
                    <b className="col-md-3 col-6">Tipo de produto:</b>{" "}
                    <p>
                      {
                        product?.Animal.TypeAnimalProduct
                          .des_type_product_animal
                      }
                    </p>
                  </div>
                  <div
                    className="shadow-sm  bg-white rounded colum-white row"
                    style={{ height: "100%" }}
                  >
                    {product?.Animal.id_custom_races ? (
                      <>
                        <b className="col-md-3 col-6">Raças cruzadas:</b>{" "}
                        <p>Segue lista abaixo</p>
                      </>
                    ) : (
                      <>
                        <b className="col-md-3 col-6">Raça:</b>
                        <p> {product?.Animal.AnimalRace.des_races}</p>
                      </>
                    )}
                    <hr />
                  </div>
                  {product?.Animal.AnimalCustomRaces.map((data: any) => (
                    <div
                      key={data.id_races}
                      style={{ marginLeft: "5%" }}
                      className="shadow-sm  bg-light rounded colum-light-gray row"
                    >
                      <b className="col-md-3 col-6">
                        * {data.AnimalRace.des_races}
                      </b>{" "}
                      <p>{data.nr_percentage}%</p>
                    </div>
                  ))}
                  <div className="shadow-sm  bg-light rounded colum-light-gray row">
                    <b className="col-md-3 col-6">Categoria:</b>{" "}
                    <p>{product?.Animal.AnimalCategory.des_category}</p>
                  </div>
                  <div className="shadow-sm  bg-white rounded colum-white row">
                    <b className="col-md-3 col-6">Quantidade:</b>{" "}
                    <p>{product?.qtd_animals} Animais</p>
                    <hr />
                  </div>
                  <div className="shadow-sm  bg-light rounded colum-light-gray row">
                    <b className="col-md-3 col-6">Intervalo de peso:</b>{" "}
                    <p>
                      {product?.Animal.AnimalAverageWeight.des_average_weight}
                    </p>
                  </div>
                  <div className="shadow-sm  bg-light rounded colum-light-gray row">
                    <b className="col-md-3 col-6">Sexo:</b>{" "}
                    <p>{product?.Animal.AnimalSex.des_animal_sex}</p>
                  </div>
                  {Boolean(product?.Animal?.nr_pregnancy) && (
                    <div className="shadow-sm  bg-light rounded colum-light-gray row">
                      <b className="col-md-3 col-6">Prenhez:</b>{" "}
                      <p>
                        {product?.Animal?.nr_pregnancy === 1 ? "Sim" : "Não"}
                      </p>
                    </div>
                  )}
                  {Boolean(product?.Animal?.nr_pregnancy_days) && (
                    <div className="shadow-sm  bg-light rounded colum-light-gray row">
                      <b className="col-md-3 col-6">Gestação:</b>{" "}
                      <p>{product?.Animal?.nr_pregnancy_days + " Dias"}</p>
                    </div>
                  )}
                  {Boolean(product?.Animal?.nr_liters_day) && (
                    <div className="shadow-sm  bg-light rounded colum-light-gray row">
                      <b className="col-md-3 col-6">Litros produzidos/Dia:</b>{" "}
                      <p>{product?.Animal?.nr_liters_day}</p>
                    </div>
                  )}
                  {Boolean(product?.Animal?.nr_lactations) && (
                    <div className="shadow-sm  bg-light rounded colum-light-gray row">
                      <b className="col-md-3 col-6">Nº Lactações:</b>{" "}
                      <p>{product?.Animal?.nr_lactations}</p>
                    </div>
                  )}
                  {Boolean(product?.Animal?.nr_age_months) && (
                    <div className="shadow-sm  bg-light rounded colum-light-gray row">
                      <b className="col-md-3 col-6">Idade:</b>{" "}
                      <p>{product?.Animal?.nr_age_months + " Meses"}</p>
                    </div>
                  )}
                  <div className="shadow-sm  bg-light rounded colum-light-gray row">
                    <b className="col-md-3 col-6">Possui embarcador:</b>{" "}
                    <p>{product?.nr_shipper_access === 1 ? "Sim" : "Não"}</p>
                  </div>
                  <div className="shadow-sm  bg-white rounded colum-white row">
                    <b className="col-md-3 col-6">Possui acesso a Caminhões:</b>{" "}
                    <p>{product?.nr_truck_access === 1 ? "Sim" : "Não"}</p>
                    <hr />
                  </div>
                  <div className="shadow-sm  bg-light rounded colum-light-gray row">
                    <b className="col-md-3 col-6">Possui balança:</b>{" "}
                    <p>{product?.nr_balance_access === 1 ? "Sim" : "Não"}</p>
                  </div>
                  <div className="shadow-sm  bg-light rounded colum-light-gray row">
                    <b className="col-md-3 col-6">Cidade - UF:</b>{" "}
                    <p>
                      {product?.AddressProduct?.City?.des_city +
                        " - " +
                        product?.AddressProduct?.State?.uf}
                    </p>
                  </div>
                  <div className="shadow-sm  bg-light rounded colum-light-gray row">
                    <b className="col-md-3 col-6">Quilometros da cidade:</b>{" "}
                    <p>{product?.AddressProduct?.nr_kilometers + " KM"}</p>
                  </div>
                  <div className="shadow-sm  bg-white rounded colum-white row">
                    <b className="col-md-3 col-6">Data da publicação:</b>{" "}
                    <p>{FormatDatePtBr(product?.createdAt)}</p>
                    <hr />
                  </div>
                </div>
              </div>
              {/* <ProductSimilar children={product?.Animal.id_type_product_animal} /> */}
            </div>
          </Container>
          <Footer />
        </>
      )}
    </>
  )
}
export default ProductDetailLeilao
