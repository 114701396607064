/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

import { Container, Row, Figure, Button } from 'react-bootstrap';

import imgLogoDark from '../../assets/image/leiloae-logo-1@4x.png'

import './styles.css'

const ConnectBusinesses: React.FC = () => {
  return (
    <>
    <section id="connectbusinesses">
        <Container>
          <Row>
            <div className="col-sm-6 col-md-6">
              <Row className="justify-content-center">
              <Figure>
                <Figure.Image
                  width={200}
                  src={imgLogoDark}
                  alt="Leiloaê Logo"
                />
              </Figure>
              </Row>
            </div>
            <div className="col-sm-6 col-md-6">
              <h1 className="text-center title-custom-h2">Conectamos negócios</h1>
              <p>Somos a Leiloaê! Uma plataforma de marketplace de bovinos
                com exposição online que permite segurança e inova o processo atual na mobilidade do seu celular e na comodidade da sua fazenda ou da sua casa. 
                De forma diferente do modelo de negócio presencial atual, estamos automatizando etapas e economizando tempo e dinheiro dos nossos parceiros.
              </p>
              <Button variant="outline-success" href="/about/us" className="btn btn-connectBusiness">Saiba mais</Button>
            </div>
          </Row>
        </Container>
    </section>
    </>
  );
}
export default ConnectBusinesses;
