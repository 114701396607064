/* eslint-disable jsx-a11y/alt-text */
import React from "react"

import Footer from "../../components/Footer"
import Navbar from "../../components/Navbar"

import "./styles.css"
import { Container, Row, Card, Figure } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUserCircle, faFileAlt } from "@fortawesome/free-solid-svg-icons"
import { Link } from "react-router-dom"
import { useUserContext } from "../../providers/use-sign"

import imgIconCow from "../../assets/image/icon-cow.png"

const User: React.FC = () => {
  const { data } = useUserContext()

  return (
    <>
      <Navbar />
      <Container>
        <div id="user">
          <Row className="bg-transparent-custom">
            <h5 className="col-12">Olá {data.user.Person.des_person}</h5>
            <div className="col-lg-4">
              <Link to="/user/update/data">
                <Card.Body className="shadow p-3 mb-5 bg-white text-center">
                  <FontAwesomeIcon icon={faUserCircle} size="3x" />
                  <Card.Title>Dados do perfil</Card.Title>
                </Card.Body>
              </Link>
            </div>
            <div className="col-lg-4">
              <Link to="/user/requests">
                <Card.Body className="shadow p-3 mb-5 bg-white text-center">
                  <FontAwesomeIcon icon={faFileAlt} size="3x" />
                  <Card.Title>Meus pedidos</Card.Title>
                </Card.Body>
              </Link>
            </div>
            <div className="col-lg-4">
              <Link to="/user/lots/sales">
                <Card.Body className="shadow p-3 mb-5 bg-white text-center">
                  <Figure>
                    <Figure.Image src={imgIconCow} />
                  </Figure>
                  <Card.Title>Meus lotes a venda</Card.Title>
                </Card.Body>
              </Link>
            </div>
          </Row>
        </div>
      </Container>
      <Footer />
    </>
  )
}
export default User
