import React from "react"
import { Link } from "react-router-dom"
import { Button } from "react-bootstrap"
import Navbar from "../../components/Navbar"

const TermsCompromisse: React.FC = () => {
  const closeTab = () => {
    window.close()
  }
  return (
    <>
      <Navbar />
      <div id="termsCompromisse" className="col-12 text-justify">
        <h3>Termo de uso e compromisso – Leiloaê</h3>
        <p>
          O presente documento contém informações relacionadas a deveres e
          obrigações que todos os usuários assumem ao utilizarem as plataformas
          Leiloaê, de modo que ao utilizar nossos serviços, você está
          concordando com os termos dispostos.
        </p>
        <p>
          1 - É imprescindível para a inscrição do usuário nas plataformas
          Leiloaê a adesão às normas aqui contidas:{" "}
        </p>
        <p>
          2 - O usuário deverá fornecer os seus dados para o cadastramento no
          site/aplicativo, tais como, CPF, RG, endereço e demais dados.
        </p>
        <p>
          3 - O cadastro do usuário no sistema bem como a habilitação concedida
          para comprar e vender, poderão ser cancelados ou suspensas a qualquer
          tempo pelo operador do sistema, por interesse administrativo ou
          eventual irregularidade verificada, independente de avisos ou
          notificações prévias, sem qualquer compensação ao usuário.{" "}
        </p>
        <p>
          4 - O serviço de cadastramento nas plataformas é gratuito e poderá ser
          cancelado pelo usuário a qualquer momento, desde que de boa-fé.
        </p>
        <p>
          5 - Ao se cadastrar, o usuário indicará um login e uma senha pessoal e
          intransferível, pela qual responderá pelo seu uso, bem como por todos
          os lances e/ou ofertas por ela apresentados, constituindo-se este
          termo de adesão em instrumento obrigatório entre as partes.
        </p>
        <p>
          <b>
            6 - O usuário deverá manter a senha em sigilo, sendo responsável
            pelas atividades realizadas na conta.
          </b>
        </p>
        <p>
          7 - A Leiloaê Ltda. não se responsabiliza por prejuízos ou quaisquer
          tipos de danos advindos das transações efetuadas entre os usuários,
          atuando somente como provedora de espaço virtual para divulgação
          on-line dos bens, limitando-se a veicular informações.
          <b>
            {" "}
            Cabe ao vendedor responder pela veracidade das informações
            veiculadas, pela transação de venda e compra, assim como pela
            qualidade, origem e legitimidade dos bens ofertados, de modo que a
            Leiloaê não pode ser responsabilizada por erros, fraudes,
            imprecisões ou divergências em dados, fotos e vídeos, relacionadas
            aos bens.
          </b>
        </p>
        <p>
          8 - A Leiloaê não se responsabiliza por eventuais danos decorrentes de
          demora, interrupção ou bloqueio nas transmissões de dados ocorridos no
          site e no aplicativo
        </p>
        <p>
          9 - A Leiloaê não se responsabiliza por eventuais obrigações
          tributárias relacionadas às interações e transações ocorridas entre
          usuários.
        </p>
        <p>
          10 -{" "}
          <b>
            O vendedor deverá pagar a comissão (indicada no cadastro do lote)
            devida à Leiloaê no valor correspondente a X % sobre o preço final
            do (s) bem(s) vendido (s), mais os valores de taxas e reembolsos
            previstos nas condições de venda.
          </b>{" "}
          Nem a comissão devida à Leiloaê nem os valores de reembolsos ou taxas
          estão incluídos no valor do lance ofertado, devendo os mesmos serem
          quitados em separado. A Leiloaê se reserva o direito de alterar a taxa
          de sua comissão na dependência de suas necessidades e da avaliação do
          mercado e do perfil dos lotes ofertados.
        </p>
        <p>
          11 –{" "}
          <b>
            Como regra geral a responsabilidade pelo transporte (frete) para
            deslocamento dos animais de uma propriedade a outra é do comprador.
          </b>{" "}
          Sendo que a partir do momento do embarque desses já será considerado
          que o comprador aceitou o lote, assim sendo liberado o valor referente
          a negociação para o vendedor.{" "}
          <b>
            Quando o frete for de responsabilidade do vendedor, isso deve estar
            descrito no cadastro do lote e o aceite por parte do comprador se
            dará mediante o desembarque do animal (ais) em sua propriedade.
          </b>{" "}
          As documentações exigidas por lei para transporte e negociação de
          animais são de inteira responsabilidade do cliente vendedor.
        </p>
        <p>
          12 - Na hipótese de desistência ou arrependimento do lance ou proposta
          por parte do arrematante, este ficará obrigado a pagar o valor da
          comissão devida à Leiloaê de (5%), além do valor correspondente a 20%
          sobre o lance ou proposta efetuada, a título de multa, sem prejuízo de
          poderem os interessados (leiloeiro e/ou empresa vendedora),
          alternativamente, optar pela execução prevista no artigo 39, do Dec.
          21.981/32.
        </p>
        <p>
          13 - A Leiloaê Ltda, a seu exclusivo critério, poderá cancelar
          qualquer oferta de compra, excluir ou invalidar lances, sempre que não
          for possível averiguar a identidade do usuário, ou em caso de
          descumprimento das normas aqui contidas.{" "}
        </p>
        <p>
          15 - Podemos modificar estes termos, a qualquer momento, seja em razão
          de alteração de leis ou por modificação na forma do serviço.
        </p>
        <p>
          16 - Declaro para todos os fins que as informações por mim cadastradas
          neste site são a expressão da verdade e que me responsabilizo civil e
          criminalmente pelas mesmas.{" "}
        </p>

        <p className="assinatura">
          <i>
            Marcos Andrade - Diretor Executivo Leiloaê - 39.531.002/0001-42
            Leiloaê Agente Comercial Ltda.
          </i>
        </p>
        <div className="row">
          <Link to="/">
            <Button
              className="col-12 btn btn-create btn-voltar"
              onClick={closeTab}
            >
              Entendido!
            </Button>
          </Link>
        </div>
      </div>
    </>
  )
}
export default TermsCompromisse
