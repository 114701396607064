import { IAuction, ICompany } from "../providers/auction-create"
import { IAddress, IUser } from "../providers/use-create"
import { onlyNumbers } from "./format"

export const ValidationFormContact = (data: any) => {
    if (!data.des_person) {
        return 'Preencha seu nome'
    }
    if (!data.nr_phone) {
        return 'Preencha seu telefone'
    }
    if (!data.des_email) {
        return 'Preencha seu email'
    }
    if (!data.nr_type_message) {
        return 'Selecione o tipo de mensagem'
    }
    if (!data.des_message) {
        return 'Escreva a mensagem que gostaria de enviar'
    }
}

export const ValidationFormUserAddress = (user: any, address: any) => {
    if (!user.des_person) {
        return 'Preencha seu nome'
    }

    if (!user.des_lest_person) {
        return 'Preencha seu sobre nome'
    }

    if (!user.nr_cpf) {
        return 'Preencha seu CPF'
    }

    if (!user.nr_phone) {
        return 'Preencha seu telefone'
    }

    if (!user.dt_birth) {
        return 'Preencha sua data nascimento'
    }

    if (!address.nr_zip_code) {
        return 'Preencha seu CEP'
    }

    if (!address.des_address) {
        return 'Preencha sua rua/avenida'
    }

    if (!address.des_number) {
        return 'Preencha seu número'
    }

    if (!address.des_complement) {
        return 'Preencha um complemento'
    }

    if (!address.id_uf) {
        return 'Selecione seu estado'
    }

    if (!address.code_city) {
        return 'Selecione sua cidade'
    }
}

export const ValidationFormPasswordChange = (password: any) => {
    if (!password.des_current_password) {
        return 'Informe a senha antiga'
    }

    if (!password.des_new_password) {
        return 'Informe a nova senha'
    }

    if (!password.des_confirmation_new_password) {
        return 'Informe a confirmação da nova senha'
    }
}

export const ValidationFormCreateUser = (data: IUser) => {
    if (!data.des_person) {
        return 'Informe seu primeiro nome'
    }

    if (!data.des_lest_person) {
        return 'Informe seu sobre nome'
    }

    if (!data.des_email) {
        return 'Informe seu e-mail'
    }

    if (!data.nr_phone) {
        return 'Informe o número do seu telefone'
    }

    data.nr_phone = onlyNumbers(data.nr_phone)

    if (data.nr_phone.length < 10 || data.nr_phone.length > 11) {
        return 'Número telefone inválido'
    }

    if (!data.dt_birth) {
        return 'Informe sua data nascimento'
    }

    if (!data.des_password) {
        return 'Informe sua senha'
    }
}

export const ValidationFormCreateAddress = (data: IAddress) => {
    if (!data.nr_zip_code) {
        return 'Informe seu CEP'
    }

    if (!data.des_address) {
        return 'Informe seu endereço'
    }

    if (!data.des_number) {
        return 'Informe seu número'
    }

    if (!data.des_complement) {
        return 'Informe o complemento'
    }

    if (!data.id_uf) {
        return 'Informe seu estado'
    }

    if (!data.code_city) {
        return 'Informe sua cidade'
    }

    if (!data.nr_cpf) {
        return 'Informe seu CPF'
    }
}

export const ValidationFormCreateAnnunce = (data: any) => {
    
    if (!data.id_person) {
        return 'Ocorreu algum erro, tente novamente'
    }
    if (!data.id_type_product_animal) {
        return 'Informe o tipo de produto'
    }
    if (!data.id_animal_sex) {
        return 'Informe o sexo'
    }
    if (!data.id_category) {
        return 'Informe a categoria'
    }
    if (!data.id_average_weight) {
        return 'Informe o invetervalo de peso'
    }
    if (!data.des_title) {
        return 'Informe o titulo do anúncio'
    }
    if (!data.des_description) {
        return 'Informe a descrição do anúncio'
    }
    if (!data.qtd_animals) {
        return 'Informe a quantidade de animais'
    }
    if (!data.vl_price) {
        return 'Informe o valor'
    }
    if (!data.nr_weight) {
        return 'Informe o peso do lote'
    }
    if (!data.id_type_calculation_animal) {
        return 'Selecione o tipo de calculo'
    }
    if (!data.nr_shipper_access) {
        return 'Informe se possui acesso a embarcador'
    }
    if (!data.nr_truck_access) {
        return 'Informe se possui acesso a caminhão'
    }
    if (!data.nr_balance_access) {
        return 'Informe se possui acesso a balança'
    }
    if (!data.id_uf) {
        return 'Selecione o estado'
    }
    if (!data.code_city) {
        return 'Selecione a cidade'
    }
    if (!data.nr_kilometers) {
        return 'Informe quantos quilometros da cidade está lote'
    }
    if (!data.nr_terms) {
        return 'Você precisa aceitar os termos'
    }
}

export const ValidationInputCreateAnnunce = (data: any) => {
    
    if (!data.des_title) {
        return 'Informe o titulo do anúncio'
    }
    if (!data.des_description) {
        return 'Informe a descrição do anúncio'
    }
    if (!data.qtd_animals) {
        return 'Informe a quantidade de animais'
    }
    if (data.vl_price < 1) {
        return 'Informe o valor'
    }
    if (!data.nr_weight) {
        return 'Informe o peso do lote'
    }
    if (!data.id_type_calculation_animal) {
        return 'Selecione o tipo de calculo'
    }
}

export const ValidationInputCreateAuction = (data: IAuction) => {
    
    if (!data.des_title) {
        return 'Informe o titulo do leilão'
    }
    if (!data.des_organizer) {
        return 'Informe o nome do organizador'
    }
    if (!data.dt_deadline) {
        return 'Informe a data e hora do termino do leilão'
    }
}

export const ValidationInputCreateCompany = (data: ICompany) => {
    
    if (!data.des_company) {
        return 'Informe á razão social'
    }
    if (!data.des_company_fantasy) {
        return 'Informe o nome fantasia'
    }
    if (!data.nr_cnpj) {
        return 'Informe o CNPJ'
    }
    if (!data.nr_zip_code) {
        return 'Informe o CEP'
    }
    if (!data.des_city) {
        return 'Informe a cidade'
    }
    if (!data.des_state) {
        return 'Informe o estado'
    }
}