/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, createContext, useState, useCallback, useEffect } from "react";
import { api } from '../../services'

interface IparamLogin {
    des_username: string,
    des_password: string
}

interface iResult {
    status: number
    data: {
      message: IUser;
      user: IUser;
      userCreate: IUser;
      token: string;
      tokenCreate: string;
    }
}

interface IUser {
    id_user: number;
    id_person: number;
    nr_phone: string;
    des_email: string;
    des_type_user: string;
    des_person: string;
    des_lest_person: string;
    nr_active_create_auction: number;
    Person: {
        des_person: string;
        des_lest_person: string;
        nr_cpf: string;
        dt_birth: string;
        ProfilePhoto: {
          des_profile_photo_url: any;
          id_profile_photo: number;
          des_profile_photo: string;
        }
    }
    ReceiveAd: {
      id_receive_ads: number;
      nr_receive_ads_email: number;
      nr_receive_ads_whatsapp: number;
    }
};

type IData = {
  user?: IUser;
  signedLoading?: boolean;
  msgError?: string;
  statusCode?: number
};

type IUSerContext = {
  data: IData;
  signed: Boolean;
  signing(paramLogin: IparamLogin): Promise<void>;
  signingGoogle(data: any): Promise<void>;
  signingFacebook(data: any): Promise<void>;
  getDataUser(id_person: IUser | any): Promise<void>;
  signingOut(): Promise<void>;
};
const UserContext = createContext<Partial<IUSerContext>>({});

export const UserProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<IData>({
    signedLoading: false,
    user: null,
    msgError: '',
    statusCode: 0
  });

  const dataUserMemory = useCallback(() => {
      return new Promise(async (resolve) => {
        const dataUserStorage = JSON.parse(localStorage.getItem('@leloae/user'))
        if(dataUserStorage){
            setData({
                user: dataUserStorage
            })
        }
        resolve(true)
      })
  }, [])

  useEffect(() => {
    ( async () => {
        await dataUserMemory()
    })()
  }, [])

  const signing = async (paramLogin: IparamLogin) => {

    try {
      setData({ signedLoading: true });

      const userData = await api.apiLeloae.authentication.login<iResult>(paramLogin)
    
      setData({
        signedLoading: false,
        user: userData.data?.user,
        msgError: ''
      });

      localStorage.setItem('@leloae/authorization', userData.data.token)
      localStorage.setItem('@leloae/user', JSON.stringify(userData.data.user))
        
    } catch (error) {
      await new Promise(resolve => setTimeout(resolve, 1000));
        setData({
          signedLoading: false,
          msgError: error.response.data.message
        });
    }
  };

  const signingGoogle = async (data: any) => {
    try {
      setData({ signedLoading: true });

      const userData = await api.apiLeloae.authentication.loginGoogle<iResult>(data)
    
      setData({
        signedLoading: false,
        user: userData.data?.user ? userData.data?.user : userData.data?.userCreate,
        msgError: '',
        statusCode: userData.status
      });

      if(userData.status === 201){
        localStorage.setItem('@leloae/authorization', userData.data.tokenCreate)
        localStorage.setItem('@leloae/user', JSON.stringify(userData.data.userCreate))
      }else {
        localStorage.setItem('@leloae/authorization', userData.data.token)
        localStorage.setItem('@leloae/user', JSON.stringify(userData.data.user))
      }
        
    } catch (error) {
      await new Promise(resolve => setTimeout(resolve, 1000));
        setData({
          signedLoading: false,
          msgError: error.response.data.message
        });
    }
  };

  const signingFacebook = async (data: any) => {
    try {
      setData({ signedLoading: true });

      const userData = await api.apiLeloae.authentication.loginFacebook<iResult>(data)
    
      setData({
        signedLoading: false,
        user: userData.data?.user ? userData.data?.user : userData.data?.userCreate,
        msgError: '',
        statusCode: userData.status
      });

      if(userData.status === 201){
        localStorage.setItem('@leloae/authorization', userData.data.tokenCreate)
        localStorage.setItem('@leloae/user', JSON.stringify(userData.data.userCreate))
      }else {
        localStorage.setItem('@leloae/authorization', userData.data.token)
        localStorage.setItem('@leloae/user', JSON.stringify(userData.data.user))
      }
        
    } catch (error) {
      await new Promise(resolve => setTimeout(resolve, 1000));
        setData({
          signedLoading: false,
          msgError: error.response.data.message
        });
    }
  };

  const getDataUser = async (id_person: IUser) => {

    try {
      const getData = await api.apiLeloae.authentication.getDataUser<iResult>(id_person)

      setData({
        user: getData?.data.message,
      });

      localStorage.setItem('@leloae/user', JSON.stringify(getData?.data?.message))
        
    } catch (error) {
      await new Promise(resolve => setTimeout(resolve, 1000));
        setData({
          signedLoading: false,
          msgError: error
        });
    }
  };

  const signingOut = async () => {
    setData({ user: null });
    localStorage.setItem('@leloae/authorization', null)
    localStorage.setItem('@leloae/user', null)
  };

  return (
    <UserContext.Provider
      value={{
        data,
        signing,
        signingGoogle,
        signingFacebook,
        getDataUser,
        signed: !!data.user,
        signingOut
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  return useContext(UserContext);
};


export const getToken = () => {
    return localStorage.getItem('@leloae/authorization')
}