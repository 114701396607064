/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

import { Container, Row, Figure } from 'react-bootstrap';

//import imgGadoCorte from '../../assets/image/gadodecorte.png'
import imgGadoLeite from '../../assets/image/homepage/categorias/leite.jpg'
import imgBezerro from '../../assets/image/homepage/categorias/bezerro.jpg'
import imgGadoCorte from '../../assets/image/homepage/categorias/corte.jpg'
import imgBoiGordo from '../../assets/image/homepage/categorias/gordo.jpg'


import './styles.css'

const OptionProduct: React.FC = () => {
  return (
    <>
      <section id="option-product">
        <Container>
        <h2 className="text-center title-custom-h2">O que você deseja comprar?</h2>
          <Row>
            
            <div className="item col-6 col-md-3">
              <a href="/product/list/id_type_product_animal/1">
                <Figure>
                  <Figure.Image
                    className="lazyload"
                    width="100%"
                    height={300}
                    data-src={imgGadoCorte}
                    alt="Gado Nelore a venda"
                  />
                  <Figure.Caption className="text-center">
                    <h3>Corte</h3>
                  </Figure.Caption>
                </Figure>
              </a>
            </div>
            <div className="item col-6 col-md-3">
              <a href="/product/list/id_type_product_animal/2">
                <Figure>
                  <Figure.Image
                    className="lazyload"
                    width="100%"
                    height={540}
                    data-src={imgGadoLeite}
                    alt="Vaca de Leite Holandesa a venda"
                  />
                  <Figure.Caption className="text-center">
                    <h3>Leite</h3>
                  </Figure.Caption>
                </Figure>
              </a>
            </div>
            <div className="item col-6 col-md-3">
              <a href="/product/list/id_type_product_animal/1/id_category/1">
                <Figure>
                  <Figure.Image
                    className="lazyload"
                    width="100%"
                    height={340}
                    data-src={imgBezerro}
                    alt="Bezerro Angus a venda"
                  />
                  <Figure.Caption className="text-center">
                    <h3>Bezerro</h3>
                  </Figure.Caption>
                </Figure>
              </a>
            </div>
            <div className="item col-6 col-md-3">
              <a href="/product/list/id_type_product_animal/1/id_category/5">
                <Figure>
                  <Figure.Image
                    className="lazyload"
                    width="100%"
                    height={340}
                    data-src={imgBoiGordo}
                    alt="Boi Gordo Angus a venda"
                  />
                  <Figure.Caption className="text-center">
                    <h3>Boi gordo</h3>
                  </Figure.Caption>
                </Figure>
              </a>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
}
export default OptionProduct;
