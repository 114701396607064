import React, { useContext, createContext, useState } from "react";

type IStateGlobalContext = {
  statusOutSide: boolean;
  adminRequestNavOption: number;
  setAdminRequestNav(data: number): Promise<void>;
  setStateSearchHide(data: boolean): Promise<void>;
};
const StateGlobal = createContext<Partial<IStateGlobalContext>>({});

export const CreateStateProvider: React.FC = ({ children }) => {
  const [statusOutSide, setStatusOutSide] = useState(true);
  const [adminRequestNavOption, setAdminRequestNavOption] = useState(0);
  
  const setStateSearchHide = async (data: boolean) => {
    setStatusOutSide(data)
  }

  const setAdminRequestNav = async (data: number) => {
    setAdminRequestNavOption(data)
  }

  return (
    <StateGlobal.Provider
      value={{
        statusOutSide,
        setStateSearchHide,
        adminRequestNavOption,
        setAdminRequestNav,
      }}
    >
      {children}
    </StateGlobal.Provider>
  );
};

export const useStateGlobalContext = () => {
  return useContext(StateGlobal);
};