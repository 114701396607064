/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Pagination from 'rc-pagination';

import { Col, Container, Form, FormControl, InputGroup, Navbar, NavDropdown, Row } from 'react-bootstrap';

import { faEye, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import axios from 'axios';
import { toast } from 'react-toastify';
import { api } from '../../../services/apiLeloae/config';

import 'rc-pagination/assets/index.css'
import './styles.css'

const List: React.FC = () => {
    const [listRequest, setListRequest] = useState<any>([])
    const [listTypeProduct, setListTypeProduct] = useState<any>([])
    const [listTypeCategories, setListTypeCategories] = useState<any>([])
    const [listTypeProductStatus, setListTypeProductStatus] = useState<any>([])
    const [listParams, setListParams] = useState<any>({
        id_type_product_animal: '',
        id_category: '',
        nr_status: ''
    })

    const [totalRequest, setTotalRequest] = useState(0)
    const [loadingPagination, setLoadingPagination] = useState(false)
    let mounted = true;
    const signal = axios.CancelToken.source();

    const getListRequest = async (page = 1) => {
        if( loadingPagination ) {return}
        if ( totalRequest > 0 && listRequest.length == totalRequest ) {return}
        setLoadingPagination(true)
        try {
            const responseListRequest: any = await api.get('/admin/buy/product', {params: {page: page, limit: 10}, cancelToken: signal.token})
            if (mounted) {setListRequest(responseListRequest?.data?.message)}

            setTotalRequest(responseListRequest.headers['x-total-count'])
            setLoadingPagination(false)
        } catch (error) {
            toast.error('Error e buscar lista de lotes', {toastId: 1})
        }
    }

    const listTypesProduct = async () => {
        try {
            const responseTypeProduct: any = await api.get(`/utils/type/animal/product`)
          
            setListTypeProduct(responseTypeProduct?.data?.message)
        } catch (error) {
            toast.error('Ocorreu erro em buscar lista tipo de produto', {toastId: 1})    
        }
    }

    const listTypesCategories = async (data: any) => {
        try {
            const responseTypeCategories: any = await api.get(`/utils/type/animal/categories/${data}`)
          
            setListTypeCategories(responseTypeCategories?.data?.message)
        } catch (error) {
            toast.error('Ocorreu erro em buscar lista categorias', {toastId: 1})    
        }
    }

    const listTypesProductBuyNegotiationStatus = async () => {
        try {
            const responseTypeProductStatus: any = await api.get(`/utils/type/animal/product/buy/negotiation/status`)
          
            setListTypeProductStatus(responseTypeProductStatus?.data?.message)
        } catch (error) {
            toast.error('Ocorreu erro em buscar lista de  status', {toastId: 1})    
        }
    }

    const handleSearchRequest = (async (event: any) => {
        const value = event.target.value

        try {
            const responseListUsers: any = await api.get('/admin/buy/product/search', {params: {search: value} })
            if (mounted) {setListRequest(responseListUsers?.data?.message)}
        } catch (error) {
        toast.error('Error e buscar lista de lotes', {toastId: 1})
        }
    })

    const handleSearchParamsRequest = async () => {
        try {
            const responseListUsers: any = await api.get('/admin/buy/product/search/params', {params: listParams})
            setListRequest(responseListUsers?.data?.message)
        } catch (error) {
            toast.error('Error e buscar lista de lotes', {toastId: 1})
        }
    }

    const handleChangeListFilter = async (event: any) => {
        const name = event.target.name
        const value = event.target.value

        if(name == 'id_type_product_animal') {
            (value == 1 ? listTypesCategories('1/2') : listTypesCategories('3'))

            setListParams({[`${name}`]: value, nr_status: ''})
        }

        setListParams(field =>{
            return {
                ...field, [`${name}`]:value
            }
        })
    }

    useEffect(() => {
        handleSearchParamsRequest()
    }, [listParams])

    useEffect(() => {
        listTypesProduct()
        listTypesProductBuyNegotiationStatus()
        getListRequest()
        const cleanup = () => { mounted = false; };
        return cleanup;
    }, [mounted])

  return (
    <>
      <Col sm={12} className="shadow mb-3 bg-white colunm nav">
      <Navbar id="navbar-custom" collapseOnSelect bg="light" expand="lg"  >
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav" className="form-row">
                    <Col sm={12}>
                        <Row>
                            <InputGroup className="col-sm-12 col-lg-4">
                                <FormControl
                                placeholder="Buscar"
                                aria-label="Buscar"
                                aria-describedby="basic-addon2"
                                onChange={handleSearchRequest}
                                />
                                <InputGroup.Append>
                                    <FontAwesomeIcon className="icon-search" icon={faSearch} size="2x" />
                                </InputGroup.Append>
                            </InputGroup>
                            <div className="col-sm-12 col-lg-1 text-filter">
                                <h6>Filtrar por:</h6>
                            </div>
                            <div className="col-sm-12 col-lg-3">
                                <NavDropdown title="Tipo de produto" id="nav-dropdown">
                                    {listTypeProduct.map((typeProduct: any) =>
                                        <Form.Check id={`typeProduct_${typeProduct.id_type_product_animal}`} key={typeProduct.id_type_product_animal} type="radio" onClick={handleChangeListFilter} value={typeProduct.id_type_product_animal} name="id_type_product_animal" label={typeProduct.des_type_product_animal} />
                                    )}
                                </NavDropdown>
                            </div>
                            <div className="col-sm-12 col-lg-2">
                                <NavDropdown title="Categoria" id="nav-dropdown">
                                    {listTypeCategories.map((categories: any) =>
                                        <Form.Check id={`categories_${categories.id_animal_categories}`} key={categories.id_animal_categories} type="radio" onClick={handleChangeListFilter} value={categories.id_animal_categories} name="id_category" label={categories.des_category} />
                                    )}
                                </NavDropdown>
                            </div>
                            <div className="col-sm-12 col-lg-2">
                                <NavDropdown title="Status" id="nav-dropdown">
                                    {listTypeProductStatus.map((productStatus: any) =>
                                        <Form.Check id={`productStatus_${productStatus.id_type_buy_negotiation_status}`} key={productStatus.id_type_buy_negotiation_status} type="radio" onClick={handleChangeListFilter} value={productStatus.id_type_buy_negotiation_status} name="nr_status" label={productStatus.des_type_buy_negotiation_status} />
                                    )}
                                </NavDropdown>
                            </div>
                        </Row>
                    </Col>
                </Navbar.Collapse>
            </Navbar>
        </Col>
        <Container className="col-12">
            <Col sm={12} className="shadow mb-3 bg-white colunm list-register-request-lots  text-center">
                <div id="lots" className="row">
                    <div className="description col-md-2 col-sm-4">
                        <b>Número do pedido</b>
                    </div>
                    <div className="description col-md-2 col-sm-4">
                        <b>Lote</b>
                    </div>
                    <div className="description col-md-2 col-sm-4">
                        <b>Comprador</b>
                    </div>
                    <div className="description col-md-2 col-sm-4">
                        <b>Vendedor</b>
                    </div>
                    <div className="description col-md-2 col-sm-4">
                        <b>Status</b>
                    </div>
                </div>
                <hr/>
                {listRequest.map((request: any) => 
                    <div key={request.id_buy_product}>
                        <Col sm={12} className="row">
                            <div className="description col-md-2 col-sm-4">
                                <p>{request?.id_buy_product}</p>
                            </div>
                            <div className="description col-md-2 col-sm-4">
                                <p>{request?.Product?.des_title}</p>
                            </div>
                            <div className="description col-md-2 col-sm-4">
                                <p>{request?.Person?.des_person}</p>
                            </div>
                            <div className="description col-md-2 col-sm-4">
                                <p>{request?.Product?.Person?.des_person}</p>
                            </div>
                            <div className="description col-md-2 col-sm-4">
                                <p>{request?.TypeBuyNegotiationStatus?.des_type_buy_negotiation_status}</p>
                            </div>
                            <div className="description col-md-2 col-sm-4">
                                <Link to={`/admin/requests/detail/resume/${request?.id_buy_product}`}><FontAwesomeIcon icon={faEye} size="2x" /></Link>
                            </div>
                        </Col>
                        <hr/>
                    </div>
                )}
                <Pagination defaultCurrent={1} onChange={getListRequest} pageSize={10} total={totalRequest} style={{ margin: '20px' }}  />
                
            </Col>
        </Container>
    </>
  );
}
export default List;
