/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';

import Header from '../../../components/HeaderAdmin'
import Sidebar from '../SideNav'

import './styles.css'
import { Container, Row, Figure, Form, Button, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faCamera } from '@fortawesome/free-solid-svg-icons';
import { Link, useHistory } from 'react-router-dom';

import imgNoProfilePhoto from '../../../assets/image/noprofilephoto.png'

import { api } from '../../../services/apiLeloae/config';
import { toast } from 'react-toastify';
import { FormatCep, FormatCpf, FormatDateHoursPtBr, FormatPhone } from '../../../helpers/format';
import { FormatError } from '../../../helpers/formatError';
import Loading from '../../../components/Loading';
import { ValidationFormUserAddress } from '../../../helpers/dataValidation';
import { checkDevice } from '../../../helpers/utils';

const AdminUserUpdate: React.FC = (propos: any) => {
    const id_person = propos.match.params.id_person
    const history = useHistory();

    const signal = axios.CancelToken.source();
    const [updateLoading, setUpdateLoading] = useState(false)
    const [expandedResume, setExpandedResume] = useState({ expanded: null })

    const [imgUser, setImgUser] = useState({
        des_profile_photo_url: '',
        id_profile_photo: 0,
        des_profile_photo: ''
    })
    const [formUpdateUser, setFormUpdateUser] = useState({
        des_person: '',
        des_lest_person: '',
        nr_cpf: '',
        nr_phone: '',
        des_email: '',
        dt_birth: '',
        nr_receive_ads_email: 0,
        nr_receive_ads_whatsapp: 0,
        des_type_user: '',
        open_id_network_social: '',
        created_at: ''

    })
    const [formUpdateAddress, setFormUpdateAddress] = useState({
      nr_zip_code: '',
      des_address: '',
      des_number: '',
      des_district: '',
      des_complement: '',
      id_uf: '',
      code_city: '',
      des_uf: '',
      uf: '',
      des_city: ''
    })
    
    const [listStateData, setListStateData] = useState([])
    const [listCityData, setListCityData] = useState([])

    const [imgProfile, setImgProfile] = useState(null);

    const [btnHideUpdaloadSet, setBtnHideUpdaloadSet] = useState(false)
    const [btnHideUpdaloadSubmit, ubmitBtnHideUpdaloadSetSubmit] = useState(true)

    const previewImgPorfile = useMemo(() => {
        return imgProfile ? URL.createObjectURL(imgProfile) : null
    }, [imgProfile])

    let mounted = true;

    const getUser = async () => {
        const responseUser: any = await api.get(`/admin/user/${id_person}`, {cancelToken: signal.token})
        console.log(responseUser)
        try {
            setFormUpdateUser({
                des_person: responseUser?.data?.message.Person.des_person,
                des_lest_person: responseUser?.data?.message.Person.des_lest_person,
                nr_cpf: responseUser?.data?.message.Person.nr_cpf,
                nr_phone: responseUser?.data?.message.nr_phone,
                des_email: responseUser?.data?.message.des_email,
                dt_birth: responseUser?.data?.message.Person.dt_birth,
                nr_receive_ads_email: responseUser.data?.message.ReceiveAd?.nr_receive_ads_email,
                nr_receive_ads_whatsapp: responseUser.data?.message.ReceiveAd?.nr_receive_ads_whatsapp,
                des_type_user: responseUser.data?.message.des_type_user,
                open_id_network_social: responseUser.data?.message.open_id_network_social,
                created_at: responseUser.data?.message.createdAt
                })
            setImgUser({
                des_profile_photo_url: responseUser.data?.message.Person.ProfilePhoto?.des_profile_photo_url,
                id_profile_photo: responseUser.data?.message.Person.ProfilePhoto?.id_profile_photo,
                des_profile_photo: responseUser.data?.message.Person.ProfilePhoto?.des_profile_photo
            })
        } catch (error) {
          toast.error('Ocorreu um erro em buscar seus dados', {toastId: 1})
        }
    }

    const getAddress = async () => {
        try {
            const responseAddress: any = await api.get(`/address/${id_person}`, {cancelToken: signal.token})
            if (mounted) {setFormUpdateAddress({
                nr_zip_code: responseAddress?.data?.message.nr_zip_code,
                des_address: responseAddress?.data?.message.des_address,
                des_number: responseAddress?.data?.message.des_number,
                des_district: responseAddress?.data?.message.des_district,
                des_complement: responseAddress?.data?.message.des_complement,
                id_uf: responseAddress?.data?.message.id_uf,
                code_city: responseAddress?.data?.message.code_city,
                des_uf: responseAddress?.data?.message.State.des_uf,
                uf: responseAddress?.data?.message.State.uf,
                des_city: responseAddress?.data?.message.City.des_city
            })}
        } catch (error) {
        toast.error('Ocorreu erro em buscar endereço', {toastId: 1})
        }
    }

    const listState = async () => {
        try {
            const responseListState: any = await api.get('/address/list/state')
            setListStateData(responseListState?.data?.message)
        } catch (error) {
            toast.error('Ocorreu erro em buscar listar os estados', {toastId: 1})    
        }
    }

    const listCity = async () => {
        try {
            const responseListCity: any = await api.get(`/address/list/search/city/${formUpdateAddress.id_uf}`)
            setListCityData(responseListCity?.data?.message)
        } catch (error) {
        toast.error('Ocorreu erro em buscar listar cidades', {toastId: 1})    
        }
    }

    const handleChangeFormProfileImg = (event: any) => {
        setImgProfile(event.target.files[0])

        ubmitBtnHideUpdaloadSetSubmit(false)
        setBtnHideUpdaloadSet(true)
    }

    const handleChangeFormUser = (event: any) => {
        const name = event.target.name
        const value = event.target.value
        const checked = event.target.checked

        setFormUpdateUser(field => {
            return {
                ...field, [`${name}`]:value
            }
        })

        if(name === 'nr_receive_ads_email' || name === 'nr_receive_ads_whatsapp'){
            setFormUpdateUser(field => {
                return {
                    ...field, [`${name}`]: checked === true ? 1 : 0
                }
            })
        }
    }

    const handleChangeFormAddress = (event: any) => {
        const name = event.target.name
        const value = event.target.value

        if(name === 'id_uf'){
            setFormUpdateAddress(field => {
                return {
                    ...field, code_city: ''
                }
            })
        }

        setFormUpdateAddress(field => {
            return {
                ...field, [`${name}`]:value
            }
        })
    }

    const handleSubmitPhofilePhoto = async (event: any) => {
        event.preventDefault()
        setUpdateLoading(true)
        try {
            const dataPhoto = new FormData();

            dataPhoto.append('des_profile_photo', imgProfile)
            dataPhoto.append('id_person', String(id_person))

            await api.post(`/profile/photo/`, dataPhoto)
            if(imgUser.des_profile_photo){
                await api.delete(`/profile/photo/${id_person}/${imgUser.des_profile_photo}`)
            }

            setUpdateLoading(false)
            ubmitBtnHideUpdaloadSetSubmit(true)
            setBtnHideUpdaloadSet(false)
        } catch (error) {
            const errorFormat = FormatError(error)
            errorFormat.forEach(element => {
                toast.error(element)
            });
            setUpdateLoading(false)
        }
    }

    const handleSubmitDataUser = async (event: any) => {
        event.preventDefault()
        const errorMgs: any = ValidationFormUserAddress(formUpdateUser, formUpdateAddress)

        if (errorMgs) {
        return toast.error(errorMgs)
        }
        setUpdateLoading(true)
        try {
            const UpdateUser: any = await api.put(`/admin/user/update/user/${id_person}`, formUpdateUser)
            await api.put(`/admin/user/update/person/${id_person}`, formUpdateUser)
            await api.put(`/admin/address/update/${id_person}`, formUpdateAddress)

            setUpdateLoading(false)

            
            if(UpdateUser.data?.message){
            toast.success(UpdateUser.data?.message, {toastId: 1})
            }
        } catch (error) {
            const errorFormat = FormatError(error)
            errorFormat.forEach(element => {
                toast.error(element)
            });

            setUpdateLoading(false)
        }
    }

    const handleAccountActiveAndDeactivate = async () => {
        setUpdateLoading(true)
        try {

            
            const updatePassword: any = await api.put(`/admin/user/update/user/${id_person}`, formUpdateUser.des_type_user === 'inban' ? {des_type_user: 'inclient'} : {des_type_user: 'inban'})

            setUpdateLoading(false)

            if(updatePassword.data?.message){
                toast.success("Conta desativada com sucesso", {toastId: 1})
            }
            history.push('/admin/users')

        } catch (error) {
            const errorFormat = FormatError(error)
            errorFormat.forEach(element => {
                toast.error(element)
            });
            setUpdateLoading(false)  
        }
    }

    useEffect(() => {
        setExpandedResume({expanded: checkDevice()})
    },[])

    useEffect(() => {
        getAddress()
        listState()
        getUser()
        const cleanup = () => { mounted = false; };
        return cleanup;
    }, [mounted])   

    useEffect(() => {
        if(formUpdateAddress.id_uf){
            listCity()
        }
    }, [formUpdateAddress])
      
  return (
    <>
    {updateLoading && 
        <Loading />
    }
      {formUpdateUser?.des_person && 
      <Container fluid id="user-update-data">
        <Row>
            <Sidebar />
            <Header />
            <div className={expandedResume.expanded ? 'resume' : 'resume-mobile'}>
                <Container className="col-12">
                    <Row className="bg-transparent-custom text-center">
                        <p><Link to="/admin/users">Gerenciador de usuários</Link> <FontAwesomeIcon icon={faAngleRight} size="1x" /> usuário </p>
                        <h2 className="title-custom-h2 col-12">Usuário</h2>
                        <div className="shadow p-3 mb-5 bg-white col-12 row forms">
                            <Col sm={12} md={6}>
                                <p id="text-1"><b>Data criação: </b>{FormatDateHoursPtBr(formUpdateUser?.created_at)}</p>
                            </Col>
                            <Col sm={12} md={6}>
                                <p id="text-1"><b>Tipo de registro: </b>{formUpdateUser?.open_id_network_social ? 'Rede Social': 'Cadastro normal'}</p>
                            </Col>
                            <div className="col-sm-12 col-md-12 col-lg-2">
                                <Figure>
                                    <Figure.Image
                                        roundedCircle
                                        src={previewImgPorfile ? previewImgPorfile : imgUser.des_profile_photo_url ? imgUser.des_profile_photo_url : imgNoProfilePhoto}
                                    />
                                </Figure>
                                <Form onSubmit={handleSubmitPhofilePhoto}>
                                    <label id="upload-foto-profile">
                                        <input type="file" accept="image/*" onChange={handleChangeFormProfileImg}/>
                                        <p hidden={btnHideUpdaloadSet} id="upload-foto-profile-input" ><FontAwesomeIcon icon={faCamera} size="1x" /> Alterar foto</p>
                                    </label>
                                    <Button id="upload-foto-profile-button" hidden={btnHideUpdaloadSubmit} className="link-update-photo" type="submit">
                                        <FontAwesomeIcon icon={faCamera} size="1x" /> Enviar foto
                                    </Button>
                                </Form>
                            </div>
                            <Form onSubmit={handleSubmitDataUser} className="col-sm-12 col-md-12 col-lg-10 row">
                                <div className="col-sm-12 col-md-12 col-lg-6  update-user">
                                    <Row>
                                        <Form.Group controlId="des_person" className="col-sm-6">
                                        <Form.Control type="text" placeholder="Nome" name="des_person" value={formUpdateUser.des_person} onChange={handleChangeFormUser}/>
                                        </Form.Group>
                                        <Form.Group controlId="des_lest_person" className="col-sm-6">
                                        <Form.Control type="text" placeholder="Sobre nome" name="des_lest_person" value={formUpdateUser.des_lest_person} onChange={handleChangeFormUser} />
                                        </Form.Group>
                                    </Row>
                                    <Form.Group controlId="nr_cpf">
                                        <Form.Control type="text" placeholder="CPF" name="nr_cpf" value={FormatCpf(formUpdateUser.nr_cpf)} onChange={handleChangeFormUser} />
                                    </Form.Group>
                                    <Form.Group controlId="nr_phone">
                                        <Form.Control type="text" placeholder="Telefone" name="nr_phone" value={FormatPhone(formUpdateUser.nr_phone)} onChange={handleChangeFormUser} />
                                    </Form.Group>
                                    <Form.Group controlId="des_email">
                                        <Form.Label className="label-dt-brith">E-mail:</Form.Label>
                                        <Form.Control type="text" placeholder="E-mail" name="des_email" value={formUpdateUser.des_email} onChange={handleChangeFormUser} />
                                    </Form.Group>
                                    <Form.Group controlId="dt_brith">
                                        <Form.Label className="label-dt-brith">Data nascimento</Form.Label>
                                        <Form.Control type="date" placeholder="Data de nascimento" name="dt_birth" value={formUpdateUser.dt_birth} onChange={handleChangeFormUser} />
                                    </Form.Group>
                                    <Form.Group controlId="nr_receive_ads_email">
                                        <Form.Check type="checkbox" label="Receber novidades por Email" name="nr_receive_ads_email" defaultChecked={Boolean(formUpdateUser?.nr_receive_ads_email)}  onClick={handleChangeFormUser} />
                                    </Form.Group>
                                    <Form.Group controlId="nr_receive_ads_whatsapp">
                                        <Form.Check type="checkbox" label="Receber novidades por WhatsApp" name="nr_receive_ads_whatsapp" defaultChecked={Boolean(formUpdateUser?.nr_receive_ads_whatsapp)}   onClick={handleChangeFormUser}/>
                                    </Form.Group>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-6 update-address">
                                    <Form.Group controlId="nr_zip_code">
                                        <Form.Control type="text" placeholder="CEP" name="nr_zip_code" value={FormatCep(formUpdateAddress.nr_zip_code)} onChange={handleChangeFormAddress}/>
                                    </Form.Group>
                                    <Form.Group controlId="id_uf">
                                        <Form.Control as="select" value={formUpdateAddress.id_uf} name="id_uf" onChange={handleChangeFormAddress}>
                                            <option value="DEFAULT" hidden>Selecione seu Estado</option>
                                            {listStateData.map((state: any) =>
                                                <option key={state.id_uf} value={state.id_uf}>{state.des_uf}</option>
                                            )}
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group controlId="code_city">
                                        <Form.Control as="select" value={formUpdateAddress.code_city} name="code_city" onChange={handleChangeFormAddress}>
                                            <option value="DEFAULT" hidden>Selecione sua Cidade</option>
                                            {listCityData.map((city: any) =>
                                                <option key={city.code_city} value={city.code_city}>{city.des_city}</option>
                                            )}
                                        </Form.Control>
                                    </Form.Group>
                                    <Row>
                                        <Form.Group controlId="des_address" className="col-sm-9">
                                        <Form.Control type="text" placeholder="Endereço" name="des_address" value={formUpdateAddress.des_address} onChange={handleChangeFormAddress}/>
                                        </Form.Group>
                                        <Form.Group controlId="nr_number" className="col-sm-3">
                                        <Form.Control type="text" placeholder="Nº" name="des_number" value={formUpdateAddress.des_number} onChange={handleChangeFormAddress}/>
                                        </Form.Group>
                                    </Row>
                                    <Form.Group controlId="des_complement">
                                        <Form.Control type="text" placeholder="Complemento" name="des_complement" value={formUpdateAddress.des_complement} onChange={handleChangeFormAddress}/>
                                    </Form.Group>
                                    <Form.Group controlId="des_type_user_inadmin">
                                        <Form.Check type="radio" label="Administrador" name="des_type_user" value="inadmin"  defaultChecked={formUpdateUser?.des_type_user === 'inadmin'}  onClick={handleChangeFormUser} />
                                    </Form.Group>
                                    <Form.Group controlId="des_type_user_inclient">
                                        <Form.Check type="radio" label="Cliente" name="des_type_user" value="inclient"  defaultChecked={formUpdateUser?.des_type_user === 'inclient'}   onClick={handleChangeFormUser}/>
                                    </Form.Group>
                                </div>
                                <div className="col-12 col-sm-3 text-center text-sm-left">
                                    <Link to={`/admin/user/password/update/${id_person}`} className="link-update-password">Alterar minha senha</Link>
                                </div>
                                <div className="col-12 col-sm-9 text-center text-sm-right">
                                    <Button className="btn btn-create" type="submit">
                                        Salvar
                                    </Button>
                                    <Link to="/admin/users" className="btn btn-cancel">voltar</Link>
                                </div>
                            </Form>
                        </div>
                        <div className="col-12 text-center">
                            <Button onClick={handleAccountActiveAndDeactivate} className="link-delete-user">{formUpdateUser.des_type_user === 'inban' ? 'Ativar usuário' : 'Desativar usuário'}</Button>
                        </div>
                    </Row>
                </Container>
            </div>
        </Row>
      </Container>
      }
    </>
  );
}
export default AdminUserUpdate;
