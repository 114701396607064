/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';

import Header from '../../../components/HeaderAdmin'
import Sidebar from '../SideNav'

import './styles.css'
import { Button, Container, Form, Row, } from 'react-bootstrap';
import { FormatError } from '../../../helpers/formatError';
import { toast } from 'react-toastify';
import Loading from '../../../components/Loading';
import { FormatCpf, onlyNumbers } from '../../../helpers/format';
import { api } from '../../../services';
import { useHistory } from 'react-router-dom';
import { checkDevice } from '../../../helpers/utils';

const AuctionRegisterUser: React.FC = (propos: any) => {
  const {id_auction, id_person} = propos.match.params

  const valueCpfBase = {nr_cpf: ''}
  const history = useHistory()

  const [expandedResume, setExpandedResume] = useState({ expanded: null })
  const [usersCpf, setUsersCpf] = useState([{...valueCpfBase}]);
  const [loading, setLoading] = useState(false)

  const handleChange = (e, ix) => {
    const {name, value} = e.target;
    let values = [...usersCpf];
    values[ix][name] = onlyNumbers(value);
    setUsersCpf(state => [...values]);

    if(onlyNumbers(value).length == 11){
      setUsersCpf(state => [...state, {...valueCpfBase}]);
    }
  }
  const handleDelete = (ix: number) => {
    let values = usersCpf.filter((a, b) => {    
      if (b !== ix) {
        return a;
      }
    });
    setUsersCpf(state => [...values]);    
  }

  const handleSubimitAuctionRegisterUsers = async (event) => {
    event.preventDefault()

    if (!usersCpf[0].nr_cpf) {
      return toast.error("Informe o CPF do usuário")
    }

    try {
      setLoading(true)
      const result: any = await api.apiLeloae.auction.createUsersActiveAuction(id_person, usersCpf)

      if(result.data?.message.id_users_auction){
        toast.success('CPF de usuários registrados com sucesso.', {toastId: 1})
        history.push(`/admin/auction/detail/${id_auction}`)
      }
      setLoading(false)

    } catch (error) {
      setLoading(false)
      if(error.response.data.message){
        const errorFormat = FormatError(error.response.data.message)
        errorFormat.forEach(element => {
          toast.error(element)
        });
      }else{
        const errorFormat = FormatError(error)
        errorFormat.forEach(element => {
          toast.error(element)
        });
      }
    }
  }

  useEffect(() => {
    setExpandedResume({expanded: checkDevice()})
  },[])
  
  return (
    <>
      {loading && 
        <Loading />
      }
      <Container fluid id="admin-auction-register-user" >
        <Row>
          <Sidebar />
          <Header />
          <div className={expandedResume.expanded ? 'resume text-center ' : 'resume-mobile text-center'}>
            <h2 className="title-custom-h2">Cadastrar usuário para participar do leilão</h2>
            <div className="content col-12 row justify-content-center">
              <Form onSubmit={handleSubimitAuctionRegisterUsers}>
                {usersCpf.map((data, index) => (
                  <div key={data.nr_cpf}>
                    <Form.Group controlId="des_title">
                      <Form.Control type="text" name="nr_cpf" value={FormatCpf(data.nr_cpf)} max={11}  placeholder="Informe CPF do usuário" onChange={event => handleChange(event, index)}/>
                      { ' ' }
                      <Button className="btn btn-danger" onClick={() => handleDelete(index)}>Remover</Button>
                    </Form.Group>
                  </div>
                ))}
                <p>Basta inserir CPF do usuário que os próximo campos será revelado para próximo CPF</p>

                <Button type="submit" className=" btn btn-create">
                  Cadastrar
                </Button>
              </Form>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
}
export default AuctionRegisterUser;
