import * as React from 'react'
import { Route, Switch, Redirect, BrowserRouter } from 'react-router-dom'
import AppRouter from './app.router'
import AccountDisabled from '../pages/AccountDisabled';


const AccountDisabledRouter = () => {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/account/disabled" component={AccountDisabled} />
          <Route path="*" render={ () => <Redirect to="/account/disabled" />}/>
          <AppRouter />
        </Switch>
      </BrowserRouter>
    );
}

export default AccountDisabledRouter
