/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Header from '../../../components/HeaderAdmin'
import Sidebar from '../SideNav'

import './styles.css'
import { Col, Container, Row} from 'react-bootstrap';
import { checkDevice } from '../../../helpers/utils';
import Pagination from 'rc-pagination';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

import { actionAuction  } from '../../../store/reduces/ReducerAuction';
import { RootState } from '../../../store';
import { toast } from 'react-toastify';

const Auction: React.FC = (props: any) => {
  const { reducerAuction } = useSelector((state: RootState) => state)
  const dispatch = useDispatch()

  const [expandedResume, setExpandedResume] = useState({ expanded: null })
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
    nr_status: ''
  })

  const handlePage = (page: number) => {
    setParams(field => {
      return {
          ...field, page: page
      }
    })
  }

  useEffect(() => {
    dispatch(actionAuction.searchAuctions(params))
  }, [dispatch, params])

  useEffect(() => {
    setExpandedResume({expanded: checkDevice()})
  },[])

  return (
    <>
      <Container fluid id="auction">
          <Row>  
              <Sidebar />
              <Header />
                {reducerAuction.httprequest.error && toast.error('Ocorreu erro em buscar lista de leilões', { toastId: 1 })}
                <div className={expandedResume.expanded ? 'resume' : 'resume-mobile'}>
                  <Row className="row-auction">
                    <Container className="col-12">
                        <Col sm={12} className="shadow mb-3 bg-white colunm list-register-auction-lots  text-center">
                            <div className="create-auction row justify-content-end">
                              <a href="/admin/auction/lots/create/search/user" className="btn btn-create">Cadastrar leilão</a>
                            </div>
                            <div id="lots" className="row">
                                <div className="description col-md-2 col-sm-4">
                                    <b>Número do pedido</b>
                                </div>
                                <div className="description col-md-3 col-sm-4">
                                    <b>Título</b>
                                </div>
                                <div className="description col-md-3 col-sm-4">
                                    <b>Responsável</b>
                                </div>
                                <div className="description col-md-2 col-sm-4">
                                    <b>Status</b>
                                </div>
                            </div>
                            <hr/>
                            {reducerAuction.data.map((auction: any) =>
                                <div key={auction.id_auction}>
                                    <Col sm={12} className="row">
                                        <div className="description col-md-2 col-sm-4">
                                            <p>{auction?.id_auction}</p>
                                        </div>
                                        <div className="description col-md-3 col-sm-4">
                                            <p>{auction?.des_title}</p>
                                        </div>
                                        <div className="description col-md-3 col-sm-4">
                                            <p>{auction?.Company?.des_company}</p>
                                        </div>
                                        <div className="description col-md-2 col-sm-4">
                                            <p>{auction?.nr_status === 1 ? 'Pendente' : auction?.nr_status === 2 ? 'Em andamento' : 'Concluído'}</p>
                                        </div>
                                        <div className="description col-md-2 col-sm-4">
                                            <Link to={`/admin/auction/detail/${auction?.id_auction}`}><FontAwesomeIcon icon={faEye} size="2x" /></Link>
                                        </div>
                                    </Col>
                                    <hr/>
                                </div>
                            )}
                            <Pagination current={params.page} onChange={handlePage} pageSize={10} total={reducerAuction.headers['x-total-count']} style={{ margin: '20px' }} />
                            
                        </Col>
                    </Container>
                  </Row>
                </div>
          </Row>

      </Container>
    </>
  );
}
export default Auction;
