import React from "react"
import * as S from "./styles"

export type ButtonProps = {
  icon?: React.ReactNode
  href?: string
  children: React.ReactNode
  width?: string
  variant?: "light" | "dark" | "icon"
  onClick?: () => void
}

const Button = ({
  icon,
  href,
  children,
  width = "165px",
  variant = "light",
}: ButtonProps) => {
  return (
    <S.Wrapper width={width} variant={variant} href={href}>
      {!!icon && <S.Icon>{icon}</S.Icon>}
      {!!children && <span>{children}</span>}
    </S.Wrapper>
  )
}

export default Button
