/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';

import { Form, Accordion, Card, Button } from 'react-bootstrap';

import { useParamsProductContext } from '../../../providers/product-params';

const Sex: React.FC = () => {
    const { setParamsProduct } = useParamsProductContext()

    const [attrRacesBox, setAttrRacesBox] = useState({
        sex: '+'
    })
    
    const menuLeftBox = (event) => {
        const nameBox = event.target.dataset.id
        setAttrRacesBox(field => {
            return {
              ...field, [`${nameBox}`]: 
              nameBox === 'sex' ? attrRacesBox.sex === '+' ? '-' : '+' : ''
            }
        })
    }

    const handleChangeListFilter = async (event) => {
        const name = event.target.name
        const value = event.target.value

        await setParamsProduct({type: name, value: value})
    }

  return (
    <>
        <Accordion defaultActiveKey="0">
            <Card className="shadow mb-3 bg-white">
                <Card.Header>
                <Accordion.Toggle as={Button} data-id="sex" onClick={menuLeftBox} className="text-left" variant="link" eventKey="1">
                    <b data-id="sex">Sexo <b data-id="sex">{attrRacesBox.sex}</b></b>
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                <Card.Body>
                    <Form.Check type="radio" id="sex_0" onClick={handleChangeListFilter} value="" defaultChecked name="id_animal_sex" label="Todos" />
                    <Form.Check type="radio" id="sex_1" name="id_animal_sex" value="1" onClick={handleChangeListFilter} label="Macho" />
                    <Form.Check type="radio" id="sex_2" name="id_animal_sex" value="2" onClick={handleChangeListFilter} label="Fêmea" />
                </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    </>
  );
}
export default Sex;
