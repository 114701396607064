import { api } from './config'

export class CreateUser {
    async createUser<T>(data: any): Promise<T> {
       return await api.post('/user', data)
    }

    async createAddress<T>(data: any): Promise<T> {
        return await api.post('/address', data)
    }
} 