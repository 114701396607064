import { api } from './config'

export class Authentication {
    async login<T>(data: any): Promise<T> {
      return await api.post('/session/autentication', data)
    }

    async loginGoogle<T>(data: any): Promise<T> {
      return await api.post('/session/autentication/google', data)
    }

    async loginFacebook<T>(data: any): Promise<T> {
      return await api.post('/session/autentication/facebook', data)
    }

    async getDataUser<T>(id_person: any): Promise<T> {
        return await api.get(`/user/${id_person}`)
     }
} 