/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';

import './styles.css'
import { Row, Form, Button, Col } from 'react-bootstrap';
import { IAuction, useCreateAuctionContext } from '../../../providers/auction-create';
import { useUserContext } from '../../../providers/use-sign';
import { toast } from 'react-toastify';
import { ValidationInputCreateAuction } from '../../../helpers/dataValidation';

const Auction: React.FC = () => {
    const { data } = useUserContext()

    const { setAuctionStorage } = useCreateAuctionContext()
    const [dataAuction, setDataAuction] = useState<IAuction>({
        des_title: '',
        des_organizer: '',
        dt_deadline: '',
        time_deadline: '',
        id_person: data.user.id_person
    })

    const handleFormChangeCreateAuction = (event: any) => {
        const name = event.target.name
        const value = event.target.value

        setDataAuction(field =>{
            return {
                ...field, [`${name}`]:value
            }
        })
    }

    const handleSubimitAuction = async (event: any) => {
        event.preventDefault()
        const errorMgs: any = ValidationInputCreateAuction(dataAuction);
        if (errorMgs) {
            return toast.error(errorMgs)
        }
        await setAuctionStorage(dataAuction);
    }

  return (
    <>
        <div className="auction col-sm-12 text-center">
            <h2 className="title-custom-h2">Cadastrar leilão</h2>
            <Form onSubmit={handleSubimitAuction}>
                <Form.Group controlId="des_title">
                    <Form.Control type="text" name="des_title" value={dataAuction.des_title} placeholder="Titulo do leilão" onChange={handleFormChangeCreateAuction} />
                </Form.Group>
                <Form.Group controlId="des_organizer">
                    <Form.Control type="text" name="des_organizer" value={dataAuction.des_organizer}  placeholder="Nome do organizador" onChange={handleFormChangeCreateAuction} />
                </Form.Group>
                <Row>
                    <Form.Label className="label-dt-time col-12 ">Data e hora para finalização do leilão</Form.Label>
                    <Col sm="12" md="6">
                        <Form.Group controlId="dt_deadline">
                            <Form.Control type="date" name="dt_deadline" value={dataAuction.dt_deadline} placeholder="Data que leilão ira finalizar" onChange={handleFormChangeCreateAuction} />
                        </Form.Group>
                    </Col>
                    <Col sm="12" md="6">
                        <Form.Group controlId="time_deadline">
                            <Form.Control type="time" name="time_deadline" value={dataAuction.time_deadline} placeholder="Hora que leilão ira finalizar" onChange={handleFormChangeCreateAuction} />
                        </Form.Group>
                    </Col>
                </Row>
                <Button type="submit" className="col-12 btn btn-access">
                    Continuar
                </Button>
            </Form>
        </div>
    </>
  );
}
export default Auction;
