/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';

import { Form, Accordion, Card, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { api } from '../../../services/apiLeloae/config';
import { useParamsProductContext } from '../../../providers/product-params';

const RacesComposed: React.FC = () => {
    const { setParamsProduct } = useParamsProductContext()
    
    const [attrRacesBox, setAttrRacesBox] = useState({
        racesComposed: '+'

    })
    const [listTypeRacesData, setListTypeRacesData] = useState<any>([])

    const listTypeRaces = async () => {
        try {
            const responseTypeRaces: any = await api.get(`/utils/type/animal/races/2`)
          
            setListTypeRacesData(responseTypeRaces?.data?.message)
        } catch (error) {
            toast.error('Ocorreu erro em buscar listar tipo de racas', {toastId: 1})    
        }
    }

    const menuLeftBox = (event) => {
        const nameBox = event.target.dataset.id
        setAttrRacesBox(field => {
            return {
              ...field, [`${nameBox}`]: 
              nameBox === 'racesComposed' ? attrRacesBox.racesComposed === '+' ? '-' : '+' : ''
            }
          })
    }

    const handleChangeListFilter = async (event) => {
        const name = event.target.name
        const value = event.target.value

        await setParamsProduct({type: name, value: value})
    }

    useEffect(() => {
        listTypeRaces()
    }, [])

  return (
    <>
        <Accordion defaultActiveKey="0">
            <Card className="shadow mb-3 bg-white">
                <Card.Header>
                <Accordion.Toggle as={Button} data-id="racesComposed" onClick={menuLeftBox} className="text-left" variant="link" eventKey="1">
                    <b data-id="racesComposed"> Raças composta <b data-id="racesComposed">{attrRacesBox.racesComposed}</b></b>
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                    <Card.Body>
                        <Form.Check type="radio" id="races_composed_0" onClick={handleChangeListFilter} value="" defaultChecked name="id_races" label="Todas" />
                        {listTypeRacesData.map((race: any) =>
                            <Form.Check id={`races_composed_${race.id_animal_races}`} key={race.id_animal_races} onClick={handleChangeListFilter} value={race.id_animal_races} type="radio" name="id_races" label={race.des_races} />
                        )}
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    </>
  );
}
export default RacesComposed;
